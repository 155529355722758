
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { Options, prop, Vue } from "vue-class-component";
import debounce from "lodash/debounce";
import { PaginationV2 } from "@/domain/entities/Pagination";
import SelectSearchV2 from "@/app/ui/components/select-search/index.vue";
import { OptionsClass } from "@/domain/entities/MainApp";
import { ClientNamePOS } from "@/domain/entities/Partner";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

class Props {
  errorMandatory = prop<boolean>({
    default: false,
    type: Boolean,
    required: true
  });
  isErrorClient = prop<boolean>({
    default: false,
    type: Boolean,
    required: true
  });
  modelValue = prop<any>({
    required: true,
  })
}

@Options({
  emits: ["update:modelValue", "update:error", "getClientStatus"],
  components: {
    SelectSearchV2
  },
})
export default class SectionFilterClient extends Vue.with(Props) {
  
  //filter  
  filterClient = {
    cityOriginCode: "" as any,
    shipmentType: [] as any,
  };

  get sumFilter(): number {
    return (
      (this.filterClient.cityOriginCode?.name ? 1 : 0) +
      (this.filterClient.shipmentType.length ? 1 : 0)
    );
  }

  onResetFilter() {
    this.filterClient.cityOriginCode = "";
    this.filterClient.shipmentType = [];
    this.summaryFilter = [];
    this.modelValue.clientName = "";
    this.fetchdDataClients();
    this.onGetClientStatus("");
  }

  isAdvancedFilterOpened = false;
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }

  onChangeAdvancedFilter() {
    if (this.showPopupFilterClient) {
      this.pagination.page = 1;
      this.searchValue = this.modelValue.clientName?.name;
      this.fetchdDataClients({
        isDirectory: true
      });
    }
  }

  get isLoadingClients() {
    return PartnerController.loadingClientsNamePOS;
  }

  setClientLabel(item: any) {
    this.modelValue.clientName = item;
    const isExist =
      this.summaryFilter.filter((key: any) => key.value === item.name).length >
      0;
    if (!isExist) {
      this.summaryFilter.push({
        value: this.modelValue.clientName?.name || item.name,
        from: "client",
      });
      this.summaryFilter = this.summaryFilter.filter(
        (key: any) =>
          (key.from === "client" && key.value === item.name) ||
          key.from !== "client"
      );
    }
    this.searchValue = "";
  }

  onChageClientName(item: any) {
    if (item) this.setClientLabel(item);
    this.onGetClientStatus(item);
  }

  goToDetail(item: any) {
    this.setClientLabel(item);
    this.onGetClientStatus(item);
    this.onShowPopupFilterClient();
  }

  get assigned3lc() {
    const assigned3lc = AccountController.accountData.assigned3lc || "";
    return assigned3lc.toUpperCase();
  }

  // city destination
  fetchCityDestination = debounce((search: string) => {
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    if (!search || search.length > 2) {
      LocationController.getCityList({
        page: 1,
        limit: 10,
        search,
        status: "",
        newEndpoint: true,
        code: this.assigned3lc !== "ALL" ? this.assigned3lc : ""
      });
    }
  });

  get loadingCityDestination() {
    return LocationController.isLoading;
  }

  get isAll3lc() {
    return this.assigned3lc.includes("ALL");
  }

  get cityDestinationData() {
    const listData = LocationController.cityData.cityData
      .filter((item) => item.name !== "")
      .map((item) => {
        return { ...item, name: `${item.code} - ${item.name}` };
      });

    if (!this.isAll3lc) return listData;

    return [{ id: 0, name: "All", code: "" }, ...listData];
  }

  onChangeCityDestination(item: any) {
    this.filterClient.cityOriginCode = this.filterClient.cityOriginCode || "";
    this.onChangeAdvancedFilter();
    if (this.filterClient.cityOriginCode || item) {
      this.summaryFilter.push({
        value: `${
          item?.name === "All"
            ? "All City"
            : this.filterClient.cityOriginCode?.name
        }`,
        from: "city",
      });
      const isExist =
        this.summaryFilter.filter((key: any) => key.from === "city").length > 1;
      if (isExist) {
        const filterAgain = this.summaryFilter.filter(
          (key: any) =>
            (key.from === "city" && key.value === item.name) ||
            key.from !== "city"
        );
        this.summaryFilter = filterAgain;
      }
    } else this.onDeleteSummary("city");
  }

  onDeleteSummary(from: string) {
    this.summaryFilter = this.summaryFilter.filter(
      (key: any) => key.from !== from
    );
    switch (from) {
      case "city":
        this.filterClient.cityOriginCode = "";
        break;
      case "shipment":
        this.filterClient.shipmentType = [];
        break;
      case "client":
        this.modelValue.clientName = "";
        this.onGetClientStatus("");
        break;
      default:
        break;
    }
  }

  async onShowPopupFilterClient(isDirectory = false) {
    MainAppController.showLoading();
    this.searchValue = "";
    this.pagination.page = 1;
    await this.fetchdDataClients({
      isDirectory
    });
    MainAppController.closeLoading();
    this.showPopupFilterClient = !this.showPopupFilterClient;
  }

  onClosePopupFilter() {
    this.showPopupFilterClient = false;
    this.searchValue = "";
  }

  get optionsProduct() {
    return [
      new OptionsClass({
        name: "COD",
        value: "cod"
      }),
      new OptionsClass({
        name: "DFOD",
        value: "dfod"
      }),
      new OptionsClass({
        name: "DO",
        value: "do"
      }),
      new OptionsClass({
        name: "Non COD",
        value: "non_cod"
      }),
    ]
  }

  // filter search
  searchValue = "";
  onSearchValue(val: string, isDirectory = false) {
    this.searchValue = val;
    this.pagination.page = 1;
    this.filterClients({ isDirectory });
  }

  clearSearch() {
    this.searchValue = "";
    this.pagination.page = 1;
    this.filterClients({ isDirectory: true });
  }

  get columns() {
    return [
      {
        name: "Nama Klien",
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.originalClientName}</div>`;
        },
      },
      {
        name: "Alias",
        styleHead: "w-56 text-left truncate",
        render: (item: any) => {
          return `<div class="text-left truncate">${item.clientAlias ||
            "-"}</div>`;
        }
      },
      {
        name: "Kota Asal",
        styleHead: "w-48 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.clientOriginCity}</div>`;
        },
      },
      {
        name: "Tipe Klien",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.clientCategory}</div>`;
        },
      },
      {
        name: "Layanan Pengiriman",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.clientPackageType}</div>`;
        },
      },
      {
        name: "Kode Klien",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.clientCode}</div>`;
        },
      },
    ];
  }

  summaryFilter: any[] = [];

  pagination = new PaginationV2({
    limit: 10
  });

  get isEmpty(): boolean {
    return !this.clients.length && this.sumFilter === 0;
  }

  showPopupFilterClient = false;

  onChangeShipmentType(item: any) {
    const filterShipmentPostion = this.summaryFilter.findIndex(
      (key: any) => key.from === "shipment"
    );
    const filterShipment = {
      value: item.map((option: OptionsClass) => option.name).join(" & "),
      from: "shipment",
    };
    this.onChangeAdvancedFilter();
    if (!item.length) this.onDeleteSummary("shipment");
    else
      filterShipmentPostion < 0 ? this.summaryFilter.push(filterShipment) : 
    this.summaryFilter[filterShipmentPostion] = filterShipment;
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
      isCodBooking:
        AccountController.accountData.account_type_detail.partnerIsCodBooking,
      accountTypeDetail: AccountController.accountData.account_type_detail,
    };
  }

  // clients
  filterClients = debounce(
    async (params: { isDirectory?: boolean; infiniteScroll?: boolean }) => {
      this.$emit("filter");
      await this.fetchdDataClients(params);
    },
    250
  );

  getClientNamePOS(params?: {
    isDirectory?: boolean;
    infiniteScroll?: boolean;
  }) {
    return PartnerController.getClientNamePOS({
      search:
        this.searchValue ||
        this.modelValue.clientName?.originalClientName ||
        "",
      page: this.pagination.page,
      limit: 10,
      isBranch: true,
      isApiCbp: false,
      packageType:
        this.filterClient.shipmentType
          ?.map((option: OptionsClass) => option.value)
          .join(",") || "",
      cityCode: this.filterClient.cityOriginCode?.code || "",
      isDirectory: !!params?.isDirectory,
      infiniteScroll: !!params?.infiniteScroll
    });
  }
  async fetchdDataClients(params?: {
    isDirectory?: boolean;
    infiniteScroll?: boolean;
  }): Promise<any> {
    if (
      this.getAccount.accountType === "partner" ||
      this.getAccount.accountType === "internal"
    ) {
      return await this.getClientNamePOS(params);
    }

    return false;
  }

  onGetClientStatus(obj: any){
    this.$emit("getClientStatus", obj);
  }

  get isErrorGetClient() {
    return PartnerController.errorCauseGetClientsNamePOS;
  }

  mappingListData(data: any[]): any[] {
    return data.map((e: any) => ({
      id: e.clientId,
      name: (e.clientAlias ? `${e.clientAlias} - ` : "") + e.clientName,
      originalClientName: e.clientName,
      status: e.clientStatus,
      isDO: e.clientIsDO,
      isCOD: e.clientIsCOD,
      clientCode: e.clientCode,
      clientOriginCity: e.clientOriginCity,
      clientCategory: e.clientCategory,
      clientPackageType: e.clientPackageType?.toString(),
      clientCodConfigAmountData: e.clientCodConfigAmount,
      clientAlias: e.clientAlias
    }));
  }
  get clients() {
    if (
      this.getAccount.accountType === "partner" ||
      this.getAccount.accountType === "internal"
    ) {
      return this.mappingListData(PartnerController.clientDataNamePOS.data);
    }
    return [];
  }
}
