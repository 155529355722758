import store from "@/store";
import { getModule, VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { RequestPriorityDeliveryPresenter } from "../presenters/RequestPriorityDeliveryPresenter";
import { container } from "tsyringe";
import { CancelRequestPriorityDelivery, CreateRequestPriorityDelivery } from "@/domain/entities/RequestPriorityDelivery";
import { RequestPriorityDeliveryComponent } from "@/app/infrastructures/dependencies/modules/RequestPriorityDeliveryComponents";


export interface RequestPriorityDeliveryState {
    attachments: any[],
}

RequestPriorityDeliveryComponent.init();
@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: "request-priority-delivery"
})

class RequestPriorityDeliveryStore extends VuexModule implements RequestPriorityDeliveryState {
    public attachments: any[] = [];

    @Mutation
    public addAttachment(attachmentImage: any) {
        this.attachments.push(attachmentImage);
    }

    @Mutation
    public deleteAttachment(param: any) {
        this.attachments = param;
    }

    @Action
    public getDetailRpd(id: any) {
        const presenter = container.resolve(RequestPriorityDeliveryPresenter);
        return presenter.getDetailRpd(id);
    }

    @Action
    public createRpd(params: CreateRequestPriorityDelivery) {
        const presenter = container.resolve(RequestPriorityDeliveryPresenter);
        return presenter.createRpd(params);
    }

    @Action
    public cancelRpd(params: CancelRequestPriorityDelivery) {
        const presenter = container.resolve(RequestPriorityDeliveryPresenter);
        return presenter.cancelRpd(params);
    }

}

export const RequestPriorityDeliveryController = getModule(RequestPriorityDeliveryStore);