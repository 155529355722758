import jsPDF from "jspdf";

export const createRoundRect = (
  doc: jsPDF | any,
  text: string,
  x: number,
  y: number
) => {
  doc.setFontSize(9);
  doc.setDrawColor(0);
  doc.setFillColor(0, 0, 0);

  doc.roundedRect(
    x,
    y,
    doc.getTextDimensions(text).w + 2.3,
    doc.getTextDimensions(text).h + 1.9,
    1,
    1,
    "F"
  );

  doc.setTextColor("#FFFFFF");
  doc.setFont("Montserrat", "bold");
  doc.text(text, x + 1, y + 3.6);
  return {
    w: doc.getTextDimensions(text).w + 2.3,
    h: doc.getTextDimensions(text).h + 1.9
  };
};
