import { RequestPriorityDelivery } from "@/domain/entities/RequestPriorityDelivery";
import { ResponsePayload, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { UrgentDeilveryRequest } from "@/domain/entities/UrgentDeliveryRequest";
import { AxiosResponse } from "axios";

export class RequestPriorityDeliveryMapper {
    public convertDetailRpd(res: AxiosResponse): RequestPriorityDelivery {
        const { data: { data } } = res;

        return new RequestPriorityDelivery({
            rpdId: data.rpd_id,
            rpdPriorityDeliveryId: data.rpd_priority_delivery_id,
            rpdSttNo: data.rpd_stt_no,
            rpdRequestType: data.rpd_request_type,
            rpdReason: data.rpd_reason,
            rpdDeadlineReturn: data.rpd_deadline_return,
            rpdStatusReturn: data.rpd_status_return,
            sttLastStatusId: data.stt_last_status_id,
            sttDestinationCity: data.stt_destination_city,
            deliveryReasonCode: data.delivery_reason_code,
            deliveryReasonTitle: data.delivery_reason_title,
            rpdNotes: data.rpd_notes,
            rpdProofPhoto: data.rpd_proof_photo,
            rpdCreatedAt: data.rpd_created_at,
            rpdUpdatedAt: data.rpd_updated_at,
            sttProductType: data.stt_product_type,
            isSttRetail: data.is_stt_retail,
            bookedBy: data.booked_by
        })
    }

    public convertCreateRpd(res: AxiosResponse): UrgentDeilveryRequest {
        const {
            data
        } = res;
        return new UrgentDeilveryRequest({
            success: data.success,
            message: data.message.id,
            sttNumber: data.data.stt_no,
        })
    }

    public convertCancelRpd(res: AxiosResponse): UrgentDeilveryRequest {
        const {
            data
        } = res;
        return new UrgentDeilveryRequest({
            success: data.success,
            message: data.message.id,
            sttNumber: data.data.stt_no
        })
    }
}