/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagPermissionUrgentDeliveryRequests = {
  permission_urgent_delivery_requests_enable: new Rox.Flag(false),
  permission_urgent_delivery_requests_for_pos_enable: new Rox.Flag(false),
  config_pos_penalty: new Rox.RoxString("{\"amount\":10,\"type\":\"percentage\",\"min\":5000,\"max\":50000}")
};

export function initFlagPermissionUrgentDeliveryRequests() {
  Rox.register("featureUrgentDelivery", FlagPermissionUrgentDeliveryRequests);
}
