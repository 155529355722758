
import { Options, Vue, prop } from "vue-class-component";
import HALIcon from "./image/hal.vue";
import InvalidIcon from "./image/invalid.vue";
import PODIcon from "./image/pod.vue";
import RTSIcon from "./image/rts.vue";
import STIIcon from "./image/sti.vue";
import BAGIcon from "./image/bag.vue";
import DangerousGoodsIcon from "./image/dangerous-goods.vue";
import TakenByRecipientIcon from "./image/taken-by-recipient.vue";
import RelabelIcon from "./image/relabel.vue";

class Props {
  flag = prop<string>({
    type: String,
    default: ""
  });
  isSTT = prop<boolean>({
    type: Boolean,
    default: true
  });
  isInvalid = prop<boolean>({
    type: Boolean,
    default: false
  });
  regionName = prop<string>({
    type: String,
    default: ""
  });
  isStiDest = prop<boolean>({
    type: Boolean,
    default: false
  });
  isUnderpaid = prop<boolean>({
    type: Boolean,
    default: false
  });
  isDangerousGoods = prop<boolean>({
    type: Boolean,
    default: false
  });
  isRelabel = prop<boolean>({
    type: Boolean,
    default: false
  });
}

@Options({
  components: {
    HALIcon,
    InvalidIcon,
    PODIcon,
    RTSIcon,
    STIIcon,
    BAGIcon,
    DangerousGoodsIcon,
    TakenByRecipientIcon,
    RelabelIcon
  }
})
export default class STIReverseScanIllustration extends Vue.with(Props) {
  get isPOD() {
    return (
      this.flag.toLowerCase() === "kiriman penting" ||
      this.flag.toLowerCase() === "lewat batas sla" ||
      this.flag.toLowerCase() === "sla hari ini" ||
      this.flag.toLowerCase() === "delivery ulang"
    );
  }

  get isRTS() {
    return (
      this.flag.toLowerCase() === "lewat batas rts" ||
      this.flag.toLowerCase() === "rts sekarang" ||
      this.flag.toLowerCase() === "rts besok"
    );
  }

  get isHAL() {
    return this.flag.toLowerCase() == "hal sekarang";
  }

  get isTakenByRecipient() {
    return this.flag.toLowerCase() === "diambil penerima";
  }

  get decideSTTIconComponent(): string {
    if (this.isInvalid) return "InvalidIcon";
    if (this.isDangerousGoods) return "DangerousGoodsIcon";
    if (this.isPOD) return "PODIcon";
    if (this.isRTS) return "RTSIcon";
    if (this.isHAL) return "HALIcon";
    if (this.isTakenByRecipient) return "TakenByRecipientIcon";
    if (this.isRelabel) return "RelabelIcon";
    return "STIIcon";
  }
}
