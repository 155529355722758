
import { PropType } from "vue";
import { Vue, Options, prop } from "vue-class-component";
import Checkbox from "primevue/checkbox";
import { ClientController } from "@/app/ui/controllers/ClientController";
import debounce from "lodash/debounce";
import { ManualSTTController } from "@/app/ui/controllers/ManualSTTController";
import {
  ClientMappingList,
  ClientPartnerData,
  RequestApiClientMappingList
} from "@/domain/entities/Client";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { PartnerController } from "@/app/ui/controllers/PartnerController";

class Props {
  onClose = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
}

@Options({
  name: "UploadForm",
  components: {
    Checkbox
  }
})
export default class extends Vue.with(Props) {
  mounted() {
    this.getClientMapping("");
  }

  // State Value
  checked = false;
  typeName = "";
  typeSTT = [
    { name: "Pilih Jenis STT", value: "" },
    { name: "Client", value: "client" },
    { name: "Partner", value: "partner" }
  ];

  amountSTT = "";

  typeSelect = false;
  partnerOptions: any[] = [];

  onOpenTypeSelect() {
    this.typeSelect = true;
  }

  onCloseTypeSelect() {
    this.typeSelect = false;
  }

  isTypeSttSelected = true;
  onSelectType(name: string, value: string) {
    this.clientValue = "";
    this.typeName = value;
    this.onCloseTypeSelect();
    this.checked = false;

    this.isTypeSttSelected = !value ? true : false;
    this.fetchClientPartner("");

    if (value === "partner" && this.dataProfile.account_type === "partner") {
      this.clientValue = this.defaultPartnerValue;
    }
  }

  //  Is disabled
  get isDisabled() {
    return (
      !this.typeName ||
      !Number(this.amountSTT) ||
      !this.clientValue ||
      Number(this.amountSTT) > Number(this.maxGenerateManualSTT)
    );
  }

  _onAdd() {
    if (!this.isDisabled) {
      const clientData: any = this.clientValue;
      ManualSTTController._onAdd({
        accountType: this.typeName,
        accountRefId: Number(clientData.id),
        totalStt: Number(this.amountSTT),
        isMixpack: this.checked
      });
    }
  }

  get isLoading() {
    return ManualSTTController.isLoadingAdd;
  }

  get defaultPartnerValue() {
    const dataAccount = {
      accountType: AccountController.accountData.account_type,
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : 0,
      accountName:
        AccountController.accountData.account_type_detail.company_name,
      accountCode: AccountController.accountData.account_type_detail.code
    };
    return new ClientPartnerData(
      dataAccount.accountClientId,
      dataAccount.accountName,
      dataAccount.accountCode,
      dataAccount.accountType,
      ""
    );
  }

  get dataAccount() {
    return {
      accountType: AccountController.accountData.account_type,
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : 0,
      accountName:
        AccountController.accountData.account_type_detail.company_name,
      accountCode: AccountController.accountData.account_type_detail.code
    };
  }

  // client/partner
  get getAccount(): any {
    if (
      this.dataAccount.accountType != "internal" &&
      this.dataAccount.accountType != ""
    ) {
      this.typeName = this.dataAccount.accountType;
      this.clientValue = this.isClientParent ? "" : this.defaultPartnerValue;
    }
    if (this.dataAccount.accountType === "partner") {
      this.isTypeSttSelected = false;
      this.partnerOptions = [this.defaultPartnerValue];
    }
    this.fetchClientPartner("");
    return this.dataAccount;
  }
  clientValue: any = "";
  fetchClientPartner = debounce((search: string) => {
    if (this.dataProfile.account_type !== "client") {
      ClientController._onGetListPartner({
        page: 1,
        limit: 10,
        search: search,
        partnerType: this.typeName === "partner" ? "pos" : "",
        type: this.typeName,
        isBranch: true
      });
    }
    if (
      this.typeName === "client" &&
      this.dataProfile.account_type === "partner"
    ) {
      PartnerController.getClientNamePOS({
        search: search,
        page: 1,
        limit: 10,
        isBranch: !this.isClientParent,
        isApiCbp: false,
        packageType: "",
        cityCode: "",
        isDirectory: false
      });
    }
  }, 250);

  onSearchClientName = debounce((value: string) => {
    if (!value || value.length >= 3) {
      this.clientValue = value;
      if (this.isClientParent) {
        ClientController._onGetCLientMappingList(
          new RequestApiClientMappingList({
            page: 1,
            limit: 10,
            search: value,
            isBranch: this.isClientParent
          })
        );
      }
    }
  }, 250);

  getClientMapping(search: string) {
    if (this.isClientParent) {
      ClientController._onGetCLientMappingList(
        new RequestApiClientMappingList({
          page: 1,
          limit: 10,
          search: search,
          isBranch: this.isClientParent
        })
      );
    }
  }

  get isClient() {
    return this.dataProfile.account_type === "client";
  }

  get isClientParent() {
    return this.isClient && !this.dataProfile.account_type_detail.parentId;
  }
  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  get clientPartnerData() {
    if (this.dataProfile.account_type === "client") {
      return ClientController.clientPartnerData;
    }

    if (
      this.typeName === "partner" &&
      this.dataProfile.account_type !== "internal"
    ) {
      return this.partnerOptions;
    }

    if (
      this.typeName === "partner" &&
      this.dataProfile.account_type === "internal"
    ) {
      return ClientController.clientPartnerData;
    }

    if (
      this.typeName === "client" &&
      this.dataProfile.account_type === "internal"
    ) {
      return ClientController.clientPartnerData;
    }

    return PartnerController.clientDataNamePOS.data.map(item => {
      return new ClientPartnerData(
        item.clientId,
        item.clientName,
        item.clientCode,
        "",
        ""
      );
    });
  }

  get mappedDataClient() {
    return ClientController.clientMappingData.map(
      (client: ClientMappingList) => ({
        id: client.clientId,
        name: client.clientName,
        code: client.clientCode
      })
    );
  }

  get isLoadingClientPartner() {
    return ClientController.isLoading;
  }

  get maxGenerateManualSTT() {
    return FlagsMedusa.config_max_generate_manual_stt.getValue();
  }
}
