import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full grid grid-cols-2 pb-4" }
const _hoisted_2 = { class: "w-full grid grid-cols-2 py-4" }
const _hoisted_3 = { class: "w-full grid grid-cols-2 py-4" }
const _hoisted_4 = { class: "text-gray-lp-500" }
const _hoisted_5 = { class: "text-gray-lp-500" }
const _hoisted_6 = { class: "w-full grid grid-cols-2 py-4" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "w-full grid grid-cols-1 py-4" }
const _hoisted_10 = { class: "w-full grid grid-cols-1 py-4" }
const _hoisted_11 = {
  key: 0,
  class: "grid grid-cols-4 w-full gap-2"
}
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_ImagePreview = _resolveComponent("ImagePreview")!
  const _component_ModalV2 = _resolveComponent("ModalV2")!

  return (_openBlock(), _createBlock(_component_ModalV2, {
    width: "w-6/12",
    showFooter: false,
    onClose: () => _ctx.close(),
    title: _ctx.data.rpdSttNo
  }, {
    content: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_DataWrapper, {
          label: "Tipe Permintaan",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.rpdRequestType || "-"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, {
          label: "Status Terakhir",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.sttLastStatusId || "-"), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_DataWrapper, {
          label: "Batas Waktu",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.formatDeadline), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, {
          label: "Alasan",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.rpdReason || "-"), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_DataWrapper, {
          label: "Tanggal dibuat",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createVNode("div", null, _toDisplayString(_ctx.data.formatCreatedAt), 1),
            _createVNode("div", _hoisted_4, _toDisplayString(_ctx.data.rpdCreatedBy || "-"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, {
          label: "Tanggal diubah",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createVNode("div", null, _toDisplayString(_ctx.data.formatUpdatedAt), 1),
            _createVNode("div", _hoisted_5, _toDisplayString(_ctx.data.rpdUpdatedBy || "-"), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_DataWrapper, {
          label: "Link data pendukung",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            (_ctx.data.rpdLink.length > 0)
              ? (_openBlock(), _createBlock("div", _hoisted_7, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.data.rpdLink, (link, index) => {
                    return (_openBlock(), _createBlock("div", {
                      key: index,
                      class: `${index > 0 ? 'mt-2' : ''}`
                    }, [
                      _createVNode("a", {
                        href: _ctx.isValidLink(link),
                        target: "_blank",
                        class: "underline text-blue-lp-700 pr-1",
                        style: {"word-break":"break-all","overflow-wrap":"break-word"}
                      }, _toDisplayString(link), 9, ["href"])
                    ], 2))
                  }), 128))
                ]))
              : (_openBlock(), _createBlock("div", _hoisted_8, "-"))
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, {
          label: "Tujuan",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.sttDestinationCity || "-"), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode("div", _hoisted_9, [
        _createVNode(_component_DataWrapper, {
          label: "Catatan",
          class: "grid-cols-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.rpdNotes || "-"), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode("div", _hoisted_10, [
        _createVNode(_component_DataWrapper, { label: "Bukti" }, {
          default: _withCtx(() => [
            (_ctx.data.rpdProofPhoto.length > 0)
              ? (_openBlock(), _createBlock("div", _hoisted_11, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.data.rpdProofPhoto, (image) => {
                    return (_openBlock(), _createBlock("div", {
                      key: image,
                      class: ""
                    }, [
                      _createVNode("img", {
                        src: image,
                        alt: "",
                        class: "w-48 h-48 rounded-md",
                        onClick: () => _ctx.onPreview(image)
                      }, null, 8, ["src", "onClick"])
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createBlock("div", _hoisted_12, "-"))
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_ImagePreview, {
        modelValue: _ctx.photoPreview,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.photoPreview = $event)),
        data: _ctx.photoPreviews,
        image: _ctx.photo,
        isTerm: false
      }, null, 8, ["modelValue", "data", "image"])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}