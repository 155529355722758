import {
  PartnerDetail,
  ConsolidatorByCity,
  ConsolidatorByCityData,
  PartnerBank,
  ClientNamePOS,
  ClientNamePOSData,
  VaNumbers
} from "@/domain/entities/Partner";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Pagination } from "@/domain/entities/Pagination";
import { ClientPartnerOptions } from "@/domain/entities/CnManifest";
import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";
export class PartnerMapper {
  public convertPartnerDetailFromApi(result: AxiosResponse): PartnerDetail {
    const {
      data: { data }
    } = result;
    return new PartnerDetail({
      partnerId: data.id,
      partnerType: data.type,
      partnerName: data.name,
      partnerCode: data.code,
      partnerElexysCode: data.partner_external_code,
      partnerBalance: data.balance,
      partnerAddress: data.address,
      partnerLocationId: data.partner_location?.id || 0,
      partnerLocationCityCode: data.partner_location?.city_code || "",
      partnerDistrict: data.partner_location?.district.name || "",
      partnerDistrictCode: data.partner_location?.district_code || "",
      partnerCity: data.partner_location_list
        .filter((item: any) => {
          return !item.default_location;
        })
        .map((item: any) => {
          return {
            code: item.city.code,
            name: item.city.name
          };
        }),
      partnerPhoneNumber: data.partner_phone_number,
      partnerTaxNumber: data.partner_tax_number,
      partnerParentType: data.partner_parent_partner_type,
      partnerParentId: data.parent_id,
      partnerParentName: data.parent_partner_name,
      partnerStartContract: data.partner_contract_start,
      partnerEndContract: data.partner_contract_end,
      partnerContactName: data.partner_contact_name,
      partnerContactJobTitle: data.partner_contact_job_title,
      partnerContactEmail: data.partner_contact_email,
      partnerContactPhoneNumber: data.partner_contact_phone_number,
      partnerContractStatus: data.partner_status,
      partnerIsBanned: data.partner_is_banned,
      partnerBannedReason: data.partner_banned_reason || "-",
      partnerCreatedAt: data.created_at,
      partnerUpdatedAt: data.updated_at,
      partnerCreatedBy: data.created_name,
      partnerUpdatedBy: data.updated_name,
      vaNumbers:
        data.va_numbers?.map(
          (item: any) =>
            new VaNumbers(
              item.bank_account_account_number,
              item.bank_account_bank_logo || "",
              item.bank_account_bank_name,
              item.bank_account_label
            )
        ) || [],
      partnerLatitude: data.lat,
      partnerLongitude: data.long,
      partnerReferralCode: data.partner_referral_code || "-",
      partnerReferrerCode: data.partner_referrer_code || "-",
      partnerIsCodDelivery: data.partner_is_cod_delivery,
      partnerIsCodBooking: data.partner_is_cod_booking,
      partnerIsPcu: data.partner_is_pcu,
      partnerIsAllowBookVippack: data.partner_is_allow_book_vippack,
      partnerIdSttReturn: data.partner_id_stt_return,
      partnerNameSttReturn: data.partner_name_stt_return,
      partnerTypeSttReturn: data.partner_type_stt_return,
      partnerPosType: data.partner_pos_type,
      partnerPosParentId: data.partner_pos_parent_id,
      partnerPosParentName: data.partner_pos_parent_name,
      partnerPosParentIdDefault: data.partner_pos_parent_id,
      partnerPosParentNameDefault: data.partner_pos_parent_name,
      partnerPosReverseJourney: `${data.pos_code_default_reverse_journey ||
        ""} - ${data.pos_name_default_reverse_journey || ""}`,
      partnerPosBranchCommission: data.partner_pos_branch_commission || 0,
      partnerPosReverseJourneyCode: data.pos_code_default_reverse_journey,
      partnerPosReverseJourneyName: data.pos_name_default_reverse_journey,
      partnerPosAttachFiles: data.partner_pos_attach_files?.length ? data.partner_pos_attach_files : [],
      partnerPosSaldoDeposit: data.partner_pos_saldo_deposit,
      partnerPosSaldoDepositFormated: formatInputMoney(
        data.partner_pos_saldo_deposit ? data.partner_pos_saldo_deposit : 0
      ),
      partnerPosFirstDateTopup: data.partner_pos_first_date_topup,
      partnerPosFirstTransaction: data.partner_pos_first_transaction,
      partnerPosPickupManifest: data.partner_pos_pickup_manifest,
      partnerPosTieringLevel: data.partner_pos_tiering_level?.toLowerCase()
    });
  }

  public convertPayloadData(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertConsolidatorByCityFromApi(
    result: AxiosResponse<any>
  ): ConsolidatorByCity {
    const { data } = result;

    const districtListData: ConsolidatorByCityData[] = [] || null;
    if (data.data === []) {
      [];
    } else {
      data.data.forEach((item: any) => {
        districtListData.push(
          new ConsolidatorByCityData(
            item.id,
            item.name,
            item.address,
            item.balance,
            item.code,
            item.lat,
            item.long,
            item.type,
            item.createdAt,
            item.updatedAt,
            item.parentId,
            item.parentName,
            item.parentType,
            item.contractEnd,
            item.contractStatus,
            item.isBanned,
            item.isRegistry
          )
        );
      });
    }

    const pagination = new Pagination(data.meta.page, data.meta.limit);

    return new ConsolidatorByCity(pagination, districtListData);
  }

  public convertListPartnerBankFromApi(res: AxiosResponse<any>): PartnerBank[] {
    const { data } = res;
    return data.data.map((item: any) => {
      return new PartnerBank({
        bankCode: item.code,
        bankName: item.name,
        bankRouting: item.routing_code
      });
    });
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertClientNamePOSFromApi(
    result: AxiosResponse<any>
  ): ClientNamePOS {
    const { data } = result;

    const clientListData: ClientNamePOSData[] = [] || null;
    if (data.data === []) {
      [];
    } else {
      data.data.forEach((item: any) => {
        clientListData.push(
          new ClientNamePOSData(
            item.client_id,
            item.client_code,
            item.client_name,
            item.client_is_banned,
            item.client_is_cod,
            item.client_is_do,
            item.client_origin_city,
            item.client_category,
            item.client_package_type,
            item.client_cod_config_amount,
            item.client_alias
          )
        );
      });
    }

    const pagination = new Pagination(data.meta.page, data.meta.limit);

    return new ClientNamePOS(pagination, clientListData);
  }

  public convertPartnerOptionsFromApi(
    result: AxiosResponse
  ): ClientPartnerOptions[] {
    try {
      const {
        data: { data }
      } = result;
      return data.map(
        (item: any) =>
          new ClientPartnerOptions({
            id: item.id,
            name: item.name,
            code: item.code,
            type: item.type,
            contractStatus: item.contract_status,
            partnerExternalCode: item.partner_external_code,
            cityCode : item.city_code,
            cityName: item.partner_location?.district.name || "",
            districtCode: item.partner_location?.district_code || "",
            partnerIsCodDelivery: item.partner_is_cod_delivery || ""
          })
      );
    } catch (e) {
      return [];
    }
  }
}
