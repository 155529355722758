import { Pagination } from "./Pagination";

export class ManualSTTEntities {
  pagination: Pagination;
  data: ManualSTTData[];
  constructor(pagination: Pagination, data: ManualSTTData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ManualSTTData {
  id = 0;
  accountRefId = 0;
  accountType = "";
  sttManualStatus = "";
  sttManualCreatedAt = "";
  sttManualUpdatedAt = "";
  sttManualMixpack = false;
  sttManualName = "";
  sttManualCode = "";
  sttClientName = "";
  campaignSttQuote = "";
  constructor(fields?: Partial<ManualSTTData>) {
    Object.assign(this, fields);
  }
}

export class DownloadEntities {
  file: any;
  constructor(file: any) {
    this.file = file;
  }
}
