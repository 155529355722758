
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { Options, Vue } from "vue-class-component";
import {
  Customer,
  CustomerListEntities,
  RequestCustomerList
} from "@/domain/entities/Customer";
import { ApiStateData } from "@/domain/entities/Api";
import { PaginationV2 } from "@/domain/entities/Pagination";
import { CustomerManagementController } from "@/app/ui/controllers/CustomerManagementController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { OptionsClass } from "@/domain/entities/MainApp";
import router from "@/app/ui/router";
import debounce from "lodash/debounce";
import UploadForm from "./component/upload-form.vue";
import { BulkController } from "@/app/ui/controllers/BulkController";
import { MainAppController } from "../../controllers/MainAppController";

@Options({
  components: {
    UploadForm,
  }
})

export default class ManagementCustomerList extends Vue {

  apiListData = new ApiStateData();
  listData: CustomerListEntities  = new CustomerListEntities();
  filter = {
    districtId: "" as any,
    zipCode: "" as any,
    status: "" as any,
    search: "" as any,
    sortBy: "DESC" as any,
    orderBy: "customer_created_at" as any,
  };

  mounted() {
    this.getCustomerManagementList();
  }

  async getCustomerManagementList() {
    this.apiListData.loading = true;
    try {
      const resp = await CustomerManagementController.getCustomerManagementList(
        new RequestCustomerList({
          districtId: this.filter.districtId,
          zipCode: this.filter.zipCode,
          status: this.filter.status,
          search: this.filter.search,
          sortBy: this.filter.sortBy,
          orderBy: this.filter.orderBy,
          page: this.listData.pagination.page,
        })
      );
      this.listData = new CustomerListEntities({
        pagination: new PaginationV2({
          page: resp.pagination.page,
          limit: resp.pagination.limit,
          totalData: resp.pagination.totalData
        }),
        data: resp.data,
      });
      this.apiListData.errorType = "";
    } catch (error) {
      this.apiListData.errorType = parsingErrorResponse(error).type;
    } finally {
      this.apiListData.loading = false;
    }
  }

  get title(): string {
    return (this.$route as any)?.meta?.title;
  }

  // search
  get searchValue() {
    return this.filter.search
  }
  onSearch(value: string) {
    Object.assign(this.filter, {
      search: value,
    });
    this.onChangeFilter();
  }

  clearSearch() {
    Object.assign(this.filter, {
      search: "",
    });
    this.onChangeFilter();
  }

  // To add
  goAdd() {
    router.push(`/management-customer/add`);
  }

get columns() {
  return [
    {
      name: "Nama",
      styleHead: "w-56 text-left",
      render: (item: Customer) => {
        return `<div class="text-left break-all">${item.customerName}</div>`;
      },
    },
    {
      name: "No. telepon",
      styleHead: "w-44 text-left whitespace-nowrap",
      render: (item: Customer) => {
        return `<div class="text-left">+${item.customerPhoneNumber}</div>`;
      },
    },
    {
      name: "Email",
      styleHead: "w-48 text-left whitespace-nowrap",
      render: (item: Customer) => {
        return `<div class="overflow-ellipsis text-left break-all">${item.customerEmail || "-" }</div>`;
      },
    },
    {
      name: "Alamat",
      styleHead: "w-60 text-left whitespace-nowrap",
      render: (item: Customer) => {
        return `<div class="overflow-ellipsis text-left break-all">${item.customerAddress || "-"}</div>`;
      },
    },
    {
      name: "Kecamatan, kota",
      styleHead: "w-60 text-left whitespace-nowrap",
      render: (item: Customer) => {
        return `<div class="overflow-ellipsis text-left">${item.customerDistrictName || "-"}</div>`;
      },
    },
    {
      name: "Kode pos",
      styleHead: "w-44 text-left whitespace-nowrap",
      render: (item: Customer) => {
        return `<div class="overflow-ellipsis text-left">${item.customerZipCode || "-"}</div>`;
      },
    },
    {
      name: "Tipe alamat",
      styleHead: "w-44 text-left whitespace-nowrap",
      render: (item: Customer) => {
        return `<div class='text-black-lp-300 mr-2 flex flex-row'><div class='rounded px-2 py-0 bg-gray-lp-200 capitalize'>${item.customerAddressType || " - "}</div></div>`
      }
    },
    {
      name: "Dibuat",
      styleHead: "w-60 text-left whitespace-nowrap",
      render: (item: Customer) => {
        return `<div class="text-black-lp-300">${
            item.formatCreatedAt
          }</div>`;
      }
    },
    {
      name: "Diperbarui",
      styleHead: "w-60 text-left whitespace-nowrap",
      render: (item: Customer) => {
        return `<div class="text-black-lp-300">${
            item.formatUpdatedAt
          }</div>`;
      }
    },
    {
      name: "Atur",
      key: "actionable_column",
      styleHead: "w-28 text-left",
      showButton: () => {
        return true;
      }
    }
  ];
}

  get isEmpty(): boolean {
    return !this.listData.data.length && this.isEmptyAndSearch;
    // add no filter
  }

  get isEmptyAndSearch(): boolean {
    if (this.filter.search) {
      return true
    } else if (!this.filter.search && this.isFiltered === 0) {
      return true
    } else {
      return false
    }
  }

  get sumFilter(): number {
    return (
      (this.filter.districtId ? 1 : 0) +
      (this.filter.zipCode ? 1 : 0)
    );
  }

  get isFiltered(): number {
    return (
    (this.filter.districtId ? 1 : 0) +
    (this.filter.zipCode ? 1 : 0)
    )
  }

  get emptyIcon(): string {
    return this.filter.search ? "noData_filter" : "noData_empty"
  }

  get emptyTitle(): string {
    return this.filter.search ? "" : "Belum ada data pelanggan"
  }

  get emptyMessage(): string {
    if (this.filter.search && this.isFiltered >= 1) {
      return "Nama atau nomor telepon pelanggan dengan filter tersebut tidak ditemukan"
    } else if (this.filter.search && this.isFiltered === 0) {
      return "Nama atau nomor telepon pelanggan tidak ditemukan."
    } else {
      return ""
    }
  }

  actionGroupOptions(item: any) {
    const groupOptions =
    [
      {
        icon: "eye",
        label: "Detail",
        clickFunction: () => {
          this.onClickListDetail(item.customerId)
        }
      },
      {
        icon: "pencil-alt-disable",
        label: "Ubah",
        clickFunction: () => {
          this.onClickListEdit(item.customerId)
        }
      },
      {
        icon: "cloud-upload",
        label: "Lihat daftar STT",
        clickFunction: () => this.onClickListStt(item.customerPhoneNumber)
      },
      {
        icon: "delete",
        label: "Hapus",
        clickFunction: () => this.openDeleteModal(item.customerId, item.customerName)
      }
    ];
    return groupOptions;
  }

  onClickListStt(phoneNumber: string) {
    ShipmentBookingController.setAdvanceFilterData({
      phoneSender: phoneNumber
    })
    const routeData = this.$router.resolve(`/shipment/booking/booking-list?phoneNumber=${phoneNumber}`)
    window.open(routeData.href, "_blank")
  }

  onClickListEdit(id: number) {
    router.push(`/management-customer/edit?id=${id}`);
  }

  onClickListDetail(id: number) {
    router.push(`/management-customer/${id}`);
  }

  // advance filter
  isAdvancedFilterOpened = false;

  onChangeAdvancedFilter() {
    Object.assign(this.filter, {
      districtId: this.destinationCity.id,
      zipCode: this.zipCode.name
    });
    this.onChangeFilter();
  };

  onChangeFilter = debounce(() => {
    this.listData.pagination.page = 1;
    this.getCustomerManagementList();
  }, 500);

  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }

  async onResetAdvancedFilter() {
    this.apiListData.loading = true;
    Object.assign(this.filter, {
      districtId: "",
      zipCode: "",
      status: "",
      sortBy: "DESC",
      orderBy: "customer_created_at",
    });

    this.destinationCity = ""
    this.zipCode = ""
    this.listData.pagination.page = 1;
    await this.getCustomerManagementList();
  }

  onSelectPostalCode(data: OptionsClass) {
    Object.assign(this.filter, {
      zipCode: data.name,
    });
    this.onChangeFilter();
  }

  // city
  destinationCity: any = {
    id: 0,
    name: "",
    code: ""
  }
  destinationCityList: Array<any> = [];
  onFocusdestinationCity() {
    if (!this.destinationCityList.length || !this.destinationCity) {
      LocationController.setLoadingDistrict(true);
      this.fetchdestinationCity("");
    }
  }
  fetchdestinationCity = debounce(async (search: string) => {
    const typeAccuracyAllowed = true;
    const response = await LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: typeAccuracyAllowed ? 50 : 10,
      cache: true
    });
    this.destinationCityList = this.filterDistrict(response);
  }, 250);

  get isLoadingdestinationCity() {
    return LocationController.isLoadingDistrict;
  }

  filterDistrict(response: any) {
    return response
      .filter((key: any) => key.status.toLowerCase() === "active")
      .map((e: any) => ({
        id: e.id,
        name: `${e.name}, ${e.cityName}`,
        code: `${e.originZipCode}`
      }));
  }

  onChangeDestinationCity() {
    if (this.destinationCity.code?.includes(",")) {
      const arrayZipcode: string[] = this.destinationCity.code.split(",")
      this.zipCodeList = arrayZipcode.map((e: any) => ({
        code: e,
        name: e
      }))
    } else {
      this.zipCode = this.destinationCity.code ? {code: this.destinationCity.code, name: this.destinationCity.code} : this.zipCode
      this.zipCodeList = [{code: this.destinationCity.code, name: this.destinationCity.code}]
    }

    this.onChangeAdvancedFilter()
  }

  //zipcode
  zipCode: any = ""
  zipCodeList: Array<any> = [];

  fetchZipCode = debounce(async (search: string) => {
    if (!this.destinationCity.code) {
    const response = await LocationController.getZipCodeList({
      search: search,
      page: 1,
      limit: 10,
    });
    this.zipCodeList = this.filterZipCode(response);
    }
  }, 250);

  filterZipCode(response: any) {
    return response
      .zipCodeData
      .map((e: any) => ({
        code: e.originZipCode,
        name: e.originZipCode,
        id: e.id,
        key: `${e.name}, ${e.cityName}`
      }));
  }

  get isLoadingZipCode() {
    return LocationController.isLoadingZipCode;
  }

  onFocusZipCode() {
    if (!this.zipCodeList.length || !this.zipCode) {
      this.fetchZipCode("");
    }
  }

  onChangeZipCode() {
    if (!this.destinationCity.code) {
      this.destinationCity = this.zipCode.name ? {id: this.zipCode.id, name: this.zipCode.key} : this.destinationCity
    }
    this.onChangeAdvancedFilter()
  }

  //order by

  orderByTitle = "";
  orderBySelect = false;
  orderByData = [
    { name: "Terbaru", value: "DESC" },
    { name: "Terlama", value: "ASC" }
  ];

  onOpenOrderBy() {
    this.orderBySelect = true;
  }

  onCloseOrderBy() {
    this.orderBySelect = false;
  }

  onSelectOrderBy(name: string, value: string) {
    Object.assign(this.filter, {
      sortBy: value || "",
    });
    this.onChangeFilter();
    this.orderByTitle = name;
  }

  // Bulk

  async created() {
    this.getBulkList();
    this.setFirstPage();
    await BulkController.searchActivity("");
    await BulkController.selectStatus("");
  }

  getBulkList() {
    BulkController.getBulkList({
      activityName: "",
      archiveType: "customer-management",
      status: "",
      page: 1,
      limit: 10
    });
  }

  public setFirstPage() {
    return BulkController.setFirstPage();
  }

  public get bulkListData() {
    return BulkController.bulkData.bulkData;
  }

  public get isLoadingListData() {
    return BulkController.isLoadingListData;
  }

  public get page() {
    return BulkController.page;
  }

  get isOpenUpload() {
    return BulkController.isOpenUpload;
  }

  onOpenUpload() {
    BulkController.setIsOpenUpload(true);
  }

  get openModalDelete() {
    return CustomerManagementController.openModalDelete;
  }

  openDeleteModal(id: number, name: string) {
    this.deletedId = id
    this.deletedName = name
    this.setOpenModalDelete(true)
  }

  setOpenModalDelete(open: boolean) {
    CustomerManagementController.setOpenModalDelete(open)
  }

  handlerDelete() {
    this.setOpenModalDelete(false)
    this._onDelete()
  }

  onCloseModalDelete() {
    this.setOpenModalDelete(false)
  }

  deletedId = 0
  deletedName = ""

  async _onDelete() {
    MainAppController.showLoading();
    try {
      await CustomerManagementController.deleteCustomer(this.deletedId);
      MainAppController.setSnackbarMessage(`Penghapusan data pelanggan "${this.deletedName}" berhasil!`);
      MainAppController.setSnackbarPositivity(false);
      MainAppController.setSnackbar(true);
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Menghapus Customer", () => {
          MainAppController.closeErrorMessage();
          this.openDeleteModal(this.deletedId, this.deletedName);
        })
      );
    } finally {
        MainAppController.closeLoading();
        this.onResetAdvancedFilter();
    }
  }

};
