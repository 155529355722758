import { SubmitFeedbackApiRequest } from "@/data/payload/api/FeedbackApiRequest";
import store from "@/store";
import { container } from "tsyringe";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from "vuex-module-decorators";
import { FeedbackPresenter } from "../presenters/FeedbackPresenter";
import { MainAppController } from "./MainAppController";
import {
  FAILED_ERROR,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import { FeedbackComponent } from "@/app/infrastructures/dependencies/modules/FeedbackComponent";
FeedbackComponent.init();

export interface FeedbackState {
  isLoading: boolean;
  isShowModalFeedback: boolean;
  isOpenModalFeedbackFromSidebar: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "Feedback" })
class FeedbackStore extends VuexModule implements FeedbackState {
  isLoading = false;
  isShowModalFeedback = false;
  isOpenModalFeedbackFromSidebar = false;

  @Action
  public submitFeedback(payload: SubmitFeedbackApiRequest) {
    const presenter = container.resolve(FeedbackPresenter);

    return new Promise((resolve, reject) => {
      MainAppController.showLoading();
      presenter
        .submitFeedback(payload)
        .then(() => {
          resolve(true);
        })
        .catch(err => {
          const message = err.response.data.message;
          MainAppController.showErrorMessage(
            typeof message === "string"
              ? new ErrorMessageEntities({
                  message: message,
                  title: "Server Error",
                  type: FAILED_ERROR,
                })
              : parsingErrorResponse(err, "Gagal Submit Feedback & Rating")
          );
          reject(err);
        })
        .finally(() => {
          MainAppController.closeLoading();
        });
    });
  }

  @Mutation
  public setLoading(bool: boolean): void {
    this.isLoading = bool;
  }

  @Mutation
  public setShowModalFeedback(bool: boolean): void {
    this.isShowModalFeedback = bool;
  }

  @Mutation
  public setOpenModalFeedbackFromSidebar(bool: boolean): void {
    this.isOpenModalFeedbackFromSidebar = bool;
  }
}

export const FeedbackController = getModule(FeedbackStore);
