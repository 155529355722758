
  import { Options, Vue } from "vue-class-component";
  import ClientBranch from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/client-branch.vue";
  import ReturnConfig from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/return-config.vue";
  import ContactInfo from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/contact-info.vue";
  import DeliveryService from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/delivery-service.vue";
  import DOReturn from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/delivery-order-return.vue";
  import STTReturn from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/stt-return.vue";
  import ParentClientDetail from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/parent-client-detail.vue";
  import { MainAppController } from "@/app/ui/controllers/MainAppController";
  import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
  import {
    convertArrayToString,
    convertPhoneNumber,
  } from "@/app/infrastructures/misc/Utils";
  import { ModalMessageEntities } from "@/domain/entities/MainApp";
  @Options({
    components: {
      ClientBranch,
      ReturnConfig,
      ContactInfo,
      DeliveryService,
      DOReturn,
      ParentClientDetail,
      STTReturn,
    },
  })
  export default class CreateBranch extends Vue {
    clientBranchData = {
      companyName: "",
      companyAlias: "",
      address: "",
      district: "" as any,
      email: "",
      phone: "",
      taxNumber: "",
    };
  
    parentClientDetailModel = {
      parent: {
        parentName: "" as any,
        posName: "" as any,
        commision: "",
        commisionType: "",
        numeratorCommission: "",
      },
    };
  
    contactInfoData = {
      name: "",
      position: "",
      phone: "",
      email: "",
    };
  
    errorClientBranch = {
      email: true,
      phone: true,
      taxNumber: true,
    };
  
    returnConfigData = {
      isActive: false,
      limitConfirm: "7",
      returnConfigReasonIds: [{ name: "Semua alasan", value: "all" }],
    };
  
    sttReturnData = {
      isCod: false,
      isDo: false,
      clientIsDfod: false,
      codRej: {
        receiverName: "",
        receiverAddress: "",
        district: "" as any,
        commision: "",
        padCommision: "",
        phone: "",
        codAmountType: "",
        codShipmentDiscount: "",
      },
    };
  
    clientBranchDoPaymentType = "";
  
    replaceComma(str: string) {
      return str?.replace(/,/g, ".");
    }
  
    showPopupRegistBranch() {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          image: "badge-confirmation-general",
          title: "Konfirmasi Pendaftaran Klien Cabang?",
          message: `Pastikan data yang Anda ajukan telah benar & sesuai.`,
          textCancel: "Kembali",
          textSuccess: "Ya, Daftar",
          onClose: () => {
            MainAppController.closeMessageModal();
          },
          onSubmit: async () => {
            MainAppController.closeMessageModal();
            await this.createClientBranch();
          },
        })
      );
    }
  
    showSnackbarSuccess() {
      MainAppController.setSnackbarMessage(
        `Pembuatan klien cabang “${this.clientBranchData.companyName}” berhasi! Tunggu Admin menyetujui permintaan Anda.`
      );
      MainAppController.setSnackbarTimeout(4000);
      MainAppController.setAutoClose(true);
      MainAppController.setSnackbar(true);
    }
  
    async createClientBranch() {
      const payload = {
        branchName: this.clientBranchData.companyName,
        branchAddress: this.clientBranchData.address,
        branchDistrictCode: this.clientBranchData.district
          ? this.clientBranchData.district.code
          : this.clientBranchData.district,
        branchEmail: this.clientBranchData.email,
        branchPhoneNumber: convertPhoneNumber(this.clientBranchData.phone),
        branchTaxNumber: this.clientBranchData.taxNumber,
        branchParentId: this.parentClientDetailModel.parent.parentName
          ? this.parentClientDetailModel.parent.parentName?.id
          : this.parentClientDetailModel.parent.parentName,
        branchPartnerPosId: this.parentClientDetailModel.parent.posName
          ? this.parentClientDetailModel.parent.posName.id
          : this.parentClientDetailModel.parent.posName,
        branchPartnerPosCommission: Number(
          this.replaceComma(this.parentClientDetailModel.parent.commision)
        ),
        branchPartnerPosCommissionType: this.parentClientDetailModel.parent
          .commisionType,
        branchContactName: this.contactInfoData.name,
        branchContactJobTitle: this.contactInfoData.position,
        branchContactEmail: this.contactInfoData.email,
        branchContactPhone: convertPhoneNumber(this.contactInfoData.phone),
        branchIsCod: this.sttReturnData.isCod,
        branchIsDo: this.sttReturnData.isDo,
        branchReturnCodName: this.sttReturnData.codRej.receiverName,
        branchReturnCodPhone: convertPhoneNumber(this.sttReturnData.codRej.phone),
        branchReturnCodAddress: this.sttReturnData.codRej.receiverAddress,
        branchReturnCodCity: this.sttReturnData.codRej.district
          ? this.sttReturnData.codRej.district.cityCode
          : "",
        branchReturnCodDistrict: this.sttReturnData.codRej.district
          ? this.sttReturnData.codRej.district.code
          : "",
        branchReturnCodFee: Number(
          this.replaceComma(this.sttReturnData.codRej.commision)
        ),
        clientBranchPartnerPosCommissionNumerator: this.parentClientDetailModel
          .parent.numeratorCommission,
        branchPadFee: +this.sttReturnData.codRej.padCommision,
        clientCodConfigAmount: this.sttReturnData.codRej.codAmountType,
        clientCodShipmentDiscount: Number(
          this.replaceComma(this.sttReturnData.codRej.codShipmentDiscount)
        ),
        clientBranchDoPaymentType: this.clientBranchDoPaymentType,
        clientIsDfod: this.sttReturnData.clientIsDfod,
        isReturnConfig: this.returnConfigData.isActive,
        returnConfigLimit: this.returnConfigData.isActive
          ? +this.returnConfigData.limitConfirm
          : 0,
        returnConfigReasonIds: this.returnConfigData.isActive
          ? this.payloadReturnConfigReasonIds(
              this.returnConfigData.returnConfigReasonIds
            )
          : "",
        clientAlias: this.clientBranchData.companyAlias
      };
      const add = await ClientRegistrationController.createClientBranch(payload);
      if (add) {
        // show poup success
        this.goBack();
        this.showSnackbarSuccess();
      }
    }
  
    isCodMandatory() {
      if (this.sttReturnData.isCod) {
        return this.sttReturnData.codRej.receiverName &&
          this.sttReturnData.codRej.receiverAddress &&
          this.sttReturnData.codRej.district &&
          this.sttReturnData.codRej.phone &&
          this.sttReturnData.codRej.codAmountType
          ? true
          : false;
      } else {
        return true;
      }
    }

get validationBranch() {
  return (
    this.isClientBranchDataValid() &&
    this.isParentClientDetailValid() &&
    this.isContactInfoDataValid()
  );
}

private isClientBranchDataValid() {
  const { companyName, companyAlias, district, address, email, phone } = this.clientBranchData;
  return companyName && companyAlias && district && address && email && phone;
}

private isParentClientDetailValid() {
  const { parentName } = this.parentClientDetailModel.parent;
  return !!parentName;
}

private isContactInfoDataValid() {
  const { name, email: contactEmail, phone: contactPhone, position } = this.contactInfoData;
  return name && contactEmail && contactPhone && position;
}


get isFormValidBranch() {
  const hasValidPosDetails = this.hasValidPosDetails();
  const isDO = !this.sttReturnData.isDo || !!this.clientBranchDoPaymentType;

  return this.validationBranch && this.isCodMandatory() && hasValidPosDetails && !this.anyFormError && isDO;
}

private hasValidPosDetails() {
  const { commision, commisionType, numeratorCommission } = this.parentClientDetailModel.parent;

  return !this.isCodMandatory() || !!(commision && commisionType && numeratorCommission);
}

  
    get anyFormError() {
      let result = false;
      Object.entries(this.error).forEach(([, value]) => {
        if (value) {
          result = value;
        }
      });
      return result;
    }
  
    error = {
      companyName: false,
      email: false,
      phone: false,
      address: false,
      district: false,
      versionBasic: false,
      discount: false,
      priceRateStartDate: false,
      priceRateEndDate: false,
      versionName: false,
      archiveFile: false,
      contactEmail: false,
      contactPhone: false,
      contactWhatsApp: false,
      commision: false,
      codRejdistrict: false,
      codRejCommision: false,
      codRejPhone: false,
      padCommision: false,
      emailBilling: false,
      NIKNumber: false,
      taxNumber: false,
      invalidDate: false,
      codRejShipmentDiscount: false,
    };
    // Convert Value
    convertToString(data: Array<any>, key: string) {
      return convertArrayToString(data, key);
    }
  
    payloadReturnConfigReasonIds(val: Array<any>) {
      let formattedVal = "";
      if (val.includes("all")) formattedVal = "all";
      else
        formattedVal = this.convertToString(val, "value")
          .split(",")
          .toString();
      return formattedVal;
    }
    // route navigation
    goBack() {
      this.$router.push("/client/client-registration");
    }
    getTitle() {
      return this.$route.meta.name;
    }

  onResetFormCOD() {
    if (!this.sttReturnData.isCod) {
      this.sttReturnData.clientIsDfod = false;
      this.sttReturnData.codRej.commision = "";
      this.sttReturnData.codRej.codAmountType = "";
      this.sttReturnData.codRej.commision = "";
      this.sttReturnData.codRej.codShipmentDiscount = "";
      this.sttReturnData.codRej.receiverName = "";
      this.sttReturnData.codRej.receiverAddress = "";
      this.sttReturnData.codRej.district = "";
      this.sttReturnData.codRej.phone = "";
    }
  }
  }
  