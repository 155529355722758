import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }
const _hoisted_2 = { class: "font-semibold self-center mr-10 text-20px whitespace-no-wrap text-black-lp-200 capitalize" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-row justify-between items-center"
}
const _hoisted_4 = { class: "flex pt-4 pb-4 w-full justify-between" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "space-y-3 px-2 pb-4" }
const _hoisted_7 = { class: "relative flex justify-center overflow-auto h-screen" }
const _hoisted_8 = { class: "overflow-auto" }
const _hoisted_9 = {
  class: "gap-y-4 flex flex-col",
  fontSize: 14
}
const _hoisted_10 = {
  key: 0,
  class: "flex flex-row items-center"
}
const _hoisted_11 = { class: "text-yellow-lp-500 text-14px font-montserrat font-normal" }
const _hoisted_12 = { class: "flex flex-row gap-x-8 mt-3" }
const _hoisted_13 = { class: "flex flex-row rounded-md bg-pink-lp-200 py-3 px-4 justify-start items-center gap-3" }
const _hoisted_14 = { class: "text-14px text-red-lp-500" }
const _hoisted_15 = { class: "mt-2 w-full inline-block relative" }
const _hoisted_16 = { class: "flex flex-row items-center justify-center gap-x-4 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Search = _resolveComponent("Search")!
  const _component_TimeRangePicker = _resolveComponent("TimeRangePicker")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_AdvancedFilter = _resolveComponent("AdvancedFilter")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_lp_input = _resolveComponent("lp-input")!
  const _component_Icons = _resolveComponent("Icons")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_Select = _resolveComponent("Select")!
  const _component_LpInput = _resolveComponent("LpInput")!
  const _component_MultiselectSearchTag = _resolveComponent("MultiselectSearchTag")!
  const _component_UploadFileUrgentDelivery = _resolveComponent("UploadFileUrgentDelivery")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_ModalV2 = _resolveComponent("ModalV2")!
  const _component_Detail = _resolveComponent("Detail")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Header, { class: "items-center" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, _toDisplayString(_ctx.getTitle), 1),
          _createVNode(_component_lp_button, {
            title: "Buat permintaan",
            textColor: "white",
            onClick: _ctx.onShowModalRequestUrgentDelivery
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }),
      (!(_ctx.isEmpty && !_ctx.isFiltered))
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode("div", _hoisted_4, [
              _createVNode("div", _hoisted_5, [
                _createVNode(_component_Search, {
                  onInput: _ctx.onSearch,
                  onClear: () => _ctx.onSearch(''),
                  value: _ctx.filter.search,
                  placeholder: "Cari No. STT"
                }, null, 8, ["onInput", "onClear", "value"]),
                _createVNode(_component_TimeRangePicker, {
                  startDate: _ctx.filter.startDate,
                  endDate: _ctx.filter.endDate,
                  onSelect: _ctx.setDateRange,
                  default: "7 hari terakhir",
                  isCloseDateAfterSelect: "",
                  placeholder: "Tanggal dibuat",
                  ref: "dateRangeFilter",
                  class: "mr-4"
                }, null, 8, ["startDate", "endDate", "onSelect"]),
                _createVNode(_component_lp_button, {
                  id: "btnSort",
                  title: "Urutkan",
                  icon: "sort-filter",
                  textColor: _ctx.getActiveSortButton.textColor,
                  customClass: _ctx.getActiveSortButton.customClass,
                  color: _ctx.getActiveSortButton.borderColor,
                  outline: "",
                  small: "",
                  onClick: _cache[1] || (_cache[1] = () => _ctx.toggleSortButton())
                }, null, 8, ["textColor", "customClass", "color"]),
                _createVNode(_component_AdvancedFilter, {
                  "custom-max-height": "60vh",
                  "model-value": _ctx.filter,
                  isScroll: false,
                  isDisabled: false,
                  onOpened: _ctx.onOpenedAdvancedFilter,
                  onReset: _ctx.onResetAdvancedFilter,
                  addSumFilter: _ctx.sumFilter,
                  showResetText: true
                }, {
                  default: _withCtx(() => [
                    _createVNode("div", _hoisted_6, [
                      _createVNode(_component_DataWrapper, { label: "Tipe permintaan" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CustomDropdown, {
                            isDisabled: _ctx.isPosAccount,
                            disableBackground: _ctx.isPosAccount,
                            selectedItem: _ctx.filter.requestType,
                            options: _ctx.requestTypeData,
                            placeholder: "Pilih tipe permintaan",
                            onSelect: _ctx.selectRequestType,
                            class: "mt-2"
                          }, null, 8, ["isDisabled", "disableBackground", "selectedItem", "options", "onSelect"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_DataWrapper, { label: "Status terakhir" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CustomDropdown, {
                            selectedItem: _ctx.filter.lastStatus,
                            options: _ctx.lastStatusData,
                            placeholder: "Pilih status terakhir",
                            onSelect: _ctx.selectLastStatus,
                            class: "mt-2"
                          }, null, 8, ["selectedItem", "options", "onSelect"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_DataWrapper, { label: "Status permintaan" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CustomDropdown, {
                            selectedItem: _ctx.filter.requestStatus,
                            options: _ctx.requestStatusData,
                            placeholder: "Pilih status permintaan",
                            onSelect: _ctx.selectRequestStatus,
                            class: "mt-2"
                          }, null, 8, ["selectedItem", "options", "onSelect"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_DataWrapper, { label: "Diajukan oleh" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CustomDropdown, {
                            isDisabled: _ctx.isPosAccount,
                            disableBackground: _ctx.isPosAccount,
                            selectedItem: _ctx.filter.requestBy,
                            options: _ctx.requestByData,
                            placeholder: "Pilih diajukan oleh",
                            onSelect: _ctx.selectRequestBy,
                            class: "mt-2"
                          }, null, 8, ["isDisabled", "disableBackground", "selectedItem", "options", "onSelect"])
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }, 8, ["model-value", "onOpened", "onReset", "addSumFilter"])
              ]),
              _createVNode(_component_lp_button, {
                id: "btnDowloadUrgentDelivery",
                icon: "download-new",
                title: "Download",
                textColor: "red-lp-300",
                customClass: "w-44",
                outline: "",
                small: "",
                onClick: _cache[2] || (_cache[2] = () => _ctx.onDownload('request-priority-delivery/download')),
                class: "right-0"
              })
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode("div", _hoisted_7, [
        _createVNode("div", _hoisted_8, [
          _createVNode(_component_TableV2, {
            class: "my-2",
            borderTop: false,
            isFilter: _ctx.isFiltered,
            isEmpty: _ctx.isEmpty,
            emptyHeaderMessage: "Belum ada permintaan",
            emptyMessage: "Yuk! Mulai buat permintaan dan cek statusnya di sini.",
            columns: _ctx.columns,
            data: _ctx.urgentDeliveryApiList.response.data,
            errorCause: _ctx.apiListData.errorType,
            onTryAgain: _ctx.fetchData,
            loading: _ctx.apiListData.loading,
            pagination: _ctx.urgentDeliveryApiList.response.pagination,
            "onUpdate:pagination": _cache[3] || (_cache[3] = ($event: any) => (_ctx.urgentDeliveryApiList.response.pagination = $event)),
            onRequest: _ctx.fetchData,
            onClick: _ctx.goToDetail,
            isDetailAble: "",
            paginationStyle: "v3",
            isPinnedScrollBottom: "",
            isRightFreeze: true,
            totalColumnFreeze: _ctx.isPosAccount ? 1 : 0
          }, null, 8, ["isFilter", "isEmpty", "columns", "data", "errorCause", "onTryAgain", "loading", "pagination", "onRequest", "onClick", "totalColumnFreeze"])
        ])
      ])
    ]),
    (_ctx.showModalRequestUrgentDelivery)
      ? (_openBlock(), _createBlock(_component_ModalV2, {
          key: 0,
          width: "w-7/12",
          showFooter: false,
          title: "Permintaan kiriman penting",
          onClose: () => _ctx.onShowModalRequestUrgentDelivery()
        }, {
          content: _withCtx(() => [
            _createVNode("div", _hoisted_9, [
              _createVNode(_component_DataWrapper, { label: "No STT" }, {
                default: _withCtx(() => [
                  _createVNode(_component_lp_input, {
                    modelValue: _ctx.form.sttNo,
                    "onUpdate:modelValue": [
                      _cache[4] || (_cache[4] = ($event: any) => (_ctx.form.sttNo = $event)),
                      _ctx.onInputSttNo
                    ],
                    placeholder: "Masukkan No STT",
                    errorIcon: false,
                    errorCaption: _ctx.error.captionError,
                    showMaxLength: false,
                    maxLength: 17,
                    error: _ctx.error.sttNo,
                    onBlur: _ctx.onBlurSttNo,
                    onChange: _ctx.onChangeSttNo
                  }, null, 8, ["modelValue", "errorCaption", "error", "onUpdate:modelValue", "onBlur", "onChange"]),
                  (_ctx.captionWarning)
                    ? (_openBlock(), _createBlock("div", _hoisted_10, [
                        _createVNode(_component_Icons, {
                          width: "18",
                          height: "18",
                          class: "mr-2",
                          name: "warning-circle",
                          color: "#F5A324"
                        }),
                        _createVNode("p", _hoisted_11, " STT ini sudah ditandai sebagai " + _toDisplayString(_ctx.captionWarning) + ", ubah nomor stt lainnya. ", 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_DataWrapper, {
                label: "Tipe Permintaan",
                fontSize: 14
              }, {
                default: _withCtx(() => [
                  _createVNode("div", _hoisted_12, [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.requestTypes, (item) => {
                      return (_openBlock(), _createBlock("div", {
                        key: item.value,
                        class: 
                  `flex flex-row gap-x-2 items-center ${
                    item.disabled
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                  }`
                
                      }, [
                        _createVNode(_component_RadioButton, {
                          modelValue: _ctx.form.requestType,
                          "onUpdate:modelValue": [
                            _cache[5] || (_cache[5] = ($event: any) => (_ctx.form.requestType = $event)),
                            _ctx.onChangeRadioButton
                          ],
                          id: item.value,
                          value: item.value,
                          label: item.name,
                          disabled: item.disabled
                        }, null, 8, ["modelValue", "id", "value", "label", "disabled", "onUpdate:modelValue"]),
                        _createVNode("label", {
                          for: item.value,
                          class: ["text-gray-lp-300 text-12px font-semibold", 
                    item.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                  ]
                        }, _toDisplayString(item.value), 11, ["for"])
                      ], 2))
                    }), 128))
                  ])
                ]),
                _: 1
              }),
              _withDirectives(_createVNode("div", _hoisted_13, [
                _createVNode(_component_Icons, {
                  name: "warning-circle",
                  class: "w-6 text-red-lp-500"
                }),
                _createVNode("div", _hoisted_14, _toDisplayString(_ctx.getPenaltyMessage), 1)
              ], 512), [
                [_vShow, _ctx.showPenaltyMessage]
              ]),
              _createVNode(_component_DataWrapper, {
                label: "Alasan",
                fontSize: 14
              }, {
                default: _withCtx(() => [
                  (_ctx.showSelectTypes)
                    ? (_openBlock(), _createBlock(_component_Select, {
                        key: 0,
                        onOpenSelect: _ctx.onOpenSelectReasonTypes,
                        onCloseSelect: _ctx.onCloseSelectReasonTypes,
                        isOpenMenu: _ctx.isOpenSelectReasonTypes,
                        value: _ctx.form.reasonType,
                        onChooseSelect: _ctx.onSelectReasonTypes,
                        isDisabled: !_ctx.form.requestType.length,
                        dataSelect: _ctx.reasonTypes,
                        class: "w-full whitespace-no-wrap my-2 z-10"
                      }, null, 8, ["onOpenSelect", "onCloseSelect", "isOpenMenu", "value", "onChooseSelect", "isDisabled", "dataSelect"]))
                    : (_openBlock(), _createBlock(_component_lp_input, {
                        key: 1,
                        modelValue: _ctx.form.reasonType,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.form.reasonType = $event)),
                        disabled: "",
                        disableBackground: "",
                        isInputBlack: ""
                      }, null, 8, ["modelValue"]))
                ]),
                _: 1
              }),
              _createVNode(_component_DataWrapper, {
                label: "Catatan",
                fontSize: 14
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_LpInput, {
                    modelValue: _ctx.form.remarks,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.form.remarks = $event)),
                    type: "textarea",
                    placeholder: "Masukkan catatan",
                    minLength: 1,
                    maxLength: 255,
                    showMaxLength: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_DataWrapper, {
                label: "Link data pendukung",
                optional: "",
                fontSize: 16
              }, {
                default: _withCtx(() => [
                  _createVNode("div", _hoisted_15, [
                    _createVNode(_component_MultiselectSearchTag, {
                      "tag-placeholder": "Tekan enter atau spasi untuk menambah link data pendukung",
                      placeholder: "Masukkan link, pisahkan dengan spasi jika lebih dari satu.",
                      isTextArea: "",
                      "open-direction": "below",
                      showNoOptions: false,
                      options: _ctx.linkOptions,
                      multiple: "",
                      taggable: "",
                      maxLength: 20,
                      height: 100,
                      modelValue: _ctx.form.links,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.form.links = $event)),
                      allowSpaceAsEnter: ""
                    }, null, 8, ["options", "modelValue"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_DataWrapper, {
                label: "Upload bukti",
                optional: "",
                fontSize: 14
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_UploadFileUrgentDelivery, { maxPhoto: 4 })
                ]),
                _: 1
              }),
              _createVNode("div", _hoisted_16, [
                _createVNode(_component_LpButton, {
                  outline: "",
                  isWidthFull: "",
                  title: "Batal",
                  textColor: "red-lp-300",
                  onClick: _ctx.onShowModalRequestUrgentDelivery
                }, null, 8, ["onClick"]),
                _createVNode(_component_LpButton, {
                  onClick: _ctx.onSave,
                  isWidthFull: "",
                  title: "Simpan",
                  textColor: "white",
                  disabled: _ctx.isDisabled
                }, null, 8, ["onClick", "disabled"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["onClose"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_Detail, {
      data: _ctx.urgentDeliveryDetailData,
      close: _ctx.closeDetail
    }, null, 8, ["data", "close"]), [
      [_vShow, _ctx.modalDetail]
    ]),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.handlerCancel,
      onClose: _ctx.onCloseModalCancel,
      title: "Batalkan permintaan HAL untuk STT ini?",
      message: "Jika permintaan HAL dibatalkan, POS akan tetap dikenakan penalti.",
      image: "badge-confirmation-general",
      textSuccess: "Ya, batalkan",
      textCancel: "Kembali",
      class: "px-8"
    }, null, 8, ["onSubmit", "onClose"]), [
      [_vShow, _ctx.openModalcancel]
    ])
  ], 64))
}