import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { Pagination, PaginationV2 } from "@/domain/entities/Pagination";
import {
  StiSCEntities,
  StiSCData,
  SttPiecesStiScDetail,
  PieceDetail,
  ValidationScanSTTData,
  StiScManifestData,
  StiScSTTManifestDetail,
  StiScSTTPieceManifestDetail,
  ResponseSTISCSession,
  STISCSession,
  StiScProcessData,
  ResponseSTISCCreateSession,
  ResponseFinishSessionSTISC,
  STISCReverseScanCreate,
  STISCReverseScanTemporary,
  STISCReverseScanCreateData,
  STIScListBookedEntities,
  STIScListBooked,
  STIScSTTDueEntities,
  NeedToSTIScSTT,
  STISCSTTDueSummary
} from "@/domain/entities/StiSc";

export class StiSCMapper {
  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data,
      stiScId: data.data.sti_sc_id
    });
  }

  public convertStiSCDataFromApi(res: AxiosResponse<any>): StiSCEntities {
    const { data } = res;

    const stiSC: StiSCData[] = [];
    if (data.data.length === 0) {
      return new StiSCEntities(
        new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
        []
      );
    } else {
      data.data.map((item: any) => {
        stiSC.push(
          new StiSCData(
            item.sti_sc_id,
            item.sti_sc_partner_id,
            item.sti_sc_partner_code,
            item.sti_sc_partner_name,
            item.sti_sc_total_stt,
            item.sti_sc_total_gross_weight,
            item.sti_sc_total_volume_weight,
            item.sti_sc_total_pieces,
            item.sti_sc_created_at,
            item.sti_sc_created_by,
            item.sti_sc_created_name,
            item.sti_sc_updated_at,
            item.has_stt_need_sti_sc
          )
        );
      });
    }
    return new StiSCEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      stiSC
    );
  }

  public convertSttPiecesStiScDetailFromApi(
    res: AxiosResponse<any>
  ): SttPiecesStiScDetail {
    const {
      data: { data }
    } = res;
    if (!data.is_allow_update_status || data.stt === null) {
      return new SttPiecesStiScDetail(
        data.is_allow_update_status,
        data.error_message,
        "",
        "",
        [],
        "",
        0,
        0,
        0,
        "",
        ""
      );
    } else {
      return new SttPiecesStiScDetail(
        data.is_allow_update_status,
        data.error_message,
        data.stt[0].stt_no,
        data.stt[0].stt_elexys_no === undefined
          ? "-"
          : data.stt[0].stt_elexys_no,
        data.stt[0].piece.map((item: any) => {
          return new PieceDetail(
            data.stt[0].stt_no,
            data.stt[0].stt_elexys_no === undefined
              ? "-"
              : data.stt[0].stt_elexys_no,
            `${data.stt[0].stt_no}-${item.stt_piece_no}`,
            item.stt_piece_no,
            `${data.stt[0].stt_destination_city_id}-${data.stt[0].stt_destination_city_name}`,
            data.stt[0].stt_product_type,
            data.stt[0].stt_wood_packing === "Yes" ? "Ya" : "Tidak",
            data.stt[0].stt_commodity_name,
            "",
            data.stt[0].stt_cod_amount,
            item.stt_piece_gross_weight,
            item.stt_piece_volume_weight,
            data.stt[0].booked_at,
            data.stt[0].stt_last_status_id,
            data.stt[0].pos_name
          );
        }),
        data.stt[0].stt_product_type,
        data.stt[0].stt_total_piece,
        data.stt[0].stt_gross_weight,
        data.stt[0].stt_volume_weight,
        data.stt[0].stt_origin_city_id,
        data.stt[0].stt_destination_city_id,
        data.is_paid
      );
    }
  }

  public convertStiScManifestDataFromApi(
    res: AxiosResponse<any>
  ): StiScManifestData {
    const {
      data: { data }
    } = res;
    if (data.piece === null) {
      return new StiScManifestData(0, "", "", "", 0, 0, 0, 0, "", 0, []);
    } else {
      return new StiScManifestData(
        data.sti_partner_id,
        data.sti_partner_name,
        data.sti_city_name,
        data.sti_city_code,
        data.sti_total_pieces,
        data.sti_total_stt,
        data.sti_total_gross_weight,
        data.sti_total_volume_weight,
        data.sti_created_at,
        data.need_to_sti_sc_total,
        data.stt.map(
          (item: any) =>
            new StiScSTTManifestDetail(
              item.stt_id,
              item.stt_no,
              item.stt_elexys_no === undefined ? "-" : item.stt_elexys_no,
              item.stt_product_type,
              item.stt_total_piece,
              item.stt_destination_city_id,
              item.stt_commodity_code,
              item.stt_gross_weight,
              item.stt_volume_weight,
              item.stt_chargeable_weight,
              item.stt_cod_amount,
              item.stt_last_status_id,
              item.piece.map(
                (item: any) =>
                  new StiScSTTPieceManifestDetail(
                    item.stt_piece_id,
                    item.stt_piece_gross_weight,
                    item.stt_piece_volume_weight,
                    item.stt_piece_no,
                    item.stt_piece_last_status_id
                  )
              ),
              item.stt_origin_city_id,
              item.stt_origin_city_name,
              item.stt_destination_city_name,
              item.stt_no_ref_external,
              item.ref_no
            )
        )
      );
    }
  }

  public convertValidationScanSTTApi(
    res: AxiosResponse<any>
  ): ValidationScanSTTData {
    const { data } = res;
    return new ValidationScanSTTData(
      data.data.is_allow_update_status,
      data.data.error_message
    );
  }

  public convertSessionSTISCFromApi(res: AxiosResponse<any>): ResponseSTISCSession {
    const { data } = res;
    return new ResponseSTISCSession({
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
      }),
      data: new STISCSession({
        manifestId: data.data.manifest_id,
        totalSTT: data.data.total_stt,
        totalPieces: data.data.total_pieces,
        totalVolumeWeight: data.data.total_volume_weight,
        totalGrossWeight: data.data.total_gross_weight,
        stiSCList: data.data.sti_sc_list?.length ?
          data.data.sti_sc_list.map((item: any) =>
            new StiScProcessData({
              sttNumber: item.stt_no,
              product: item.product_type,
              totalPieces: item.total_pieces,
              grossWeight: item.total_gross_weight,
              volumeWeight: item.total_volume_weight,
              destination: item.destination_city_code,
              status: item.status
            })
          ) : []
      })
    })
  }

  public convertCreateSessionSTISCFromApi(res: AxiosResponse<any>): ResponseSTISCCreateSession {
    const { data } = res;
    return new ResponseSTISCCreateSession({
        manifestId: data.data.manifest_id,
      })
    }

  public convertStiScProcessDataFromApi(res: AxiosResponse<any>): StiScProcessData {
    const { data } = res;
    return data.data?.stt_no ? new StiScProcessData({
      message: data.message,
      sttNumber: data.data.stt_no,
      product: data.data.product_type,
      totalPieces: data.data.total_pieces,
      grossWeight: data.data.total_gross_weight,
      volumeWeight: data.data.total_volume_weight,
      destination: data.data.destination_city_code,
      status: data.data.status,
    }) : new StiScProcessData()
  }

  public convertStiScFinishDataFromApi(res: AxiosResponse<any>): ResponseFinishSessionSTISC {
    const { data } = res;
    return new ResponseFinishSessionSTISC({
      stiScId: data.data.sti_sc_id
    });
  }
  
  public convertStiScReverseScanCreateFromApi(
    res: AxiosResponse
  ): STISCReverseScanCreate {
    const {
      data: { data, message, success }
    } = res;

    return new STISCReverseScanCreate({
      success,
      message,
      data: new STISCReverseScanCreateData({
        sttNo: data.stt_no,
        refNo: data.ref_no,
        productType: data.product_type,
        bookedType: data.booked_type,
        bookedId: data.booked_id,
        bookedName: data.booked_name,
        podDate: data.pod_date,
        totalGrossWeight: data.total_gross_weight,
        totalVolumeWeight: data.total_volume_weight,
        totalPieces: data.total_pieces,
        destinationCityCOde: data.destination_city_code,
        status: data.status,
        isPaid: data.is_paid,
        oldStatus: data.old_status,
        statusReturn: data.status_return
      })
    });
  }

  public convertStiScReverseScanTemporaryFromApi(
    res: AxiosResponse
  ): STISCReverseScanTemporary[] {
    const {
      data: { data }
    } = res;

    const temporaryData = data?.data || data || [];

    return temporaryData.map((res: any) => {
      return new STISCReverseScanTemporary({
        sstId: res.sst_id,
        sstAccountId: res.sst_account_id,
        sstIsActive: res.sst_is_active,
        sstSttNo: res.sst_stt_no,
        sstProduct: res.sst_product,
        sstOrigin: res.sst_origin,
        sstDestination: res.sst_destination,
        sstPodDate: res.sst_pod_date,
        sstGrossWeight: res.sst_gross_weight,
        sstTotalPieces: res.sst_total_piece,
        sstIsPaid: res.sst_is_paid,
        sstBookedType: res.sst_booked_type,
        sstBookedId: res.sst_booked_id,
        sstBookedName: res.sst_booked_name,
        sstMeta: res.sst_meta,
        sstCreatedAt: res.sst_created_at,
        sstUpdatedAt: res.sst_updated_at,
        sstRefNo: res.sst_ref_no,
        statusReturn: res.sst_meta?.status_return
      });
    });
  }

  public convertListBookedFromApi(res: AxiosResponse): STIScListBookedEntities {
    const { data } = res;

    const bookedList = data.data || [];

    return new STIScListBookedEntities({
      pagination: new Pagination(
        data.meta.page,
        data.meta.limit,
        data.meta.total_records
      ),
      data: bookedList.map((booked: any) => {
        return new STIScListBooked({
          bookedId: booked.booked_id,
          bookedName: booked.booked_name,
          bookedType: booked.booked_type
        });
      })
    });
  }

  public convertSTTDueListFromApi(res: AxiosResponse): STIScSTTDueEntities {
    const { data } = res;

    const sttList = data.data || [];

    return new STIScSTTDueEntities({
      pagination: new Pagination(
        data.meta.page,
        data.meta.limit,
        data.meta.total_records
      ),
      data: sttList.map((stt: any) => {
        return new NeedToSTIScSTT({
          sdId: stt.sd_id,
          bagNo: stt.bag_no,
          sttNo: stt.stt_no,
          refNo: stt.ref_no,
          sttLastStatusId: stt.stt_last_status_id,
          sttProductType: stt.stt_product_type,
          sttTotalPiece: stt.stt_total_piece,
          sttGrossWeight: stt.stt_gross_weight,
          sttDestinationCityId: stt.stt_destination_city_id,
          sttBookedAt: stt.stt_booked_at,
          sttCreatedName: stt.stt_created_name,
          sttBookedName: stt.stt_booked_name,
          deadline: stt.deadline
        });
      })
    })
  }
  public convertStiSCSTTDueSummaryFromApi(res: AxiosResponse): STISCSTTDueSummary {
    const { data } = res;

    const summaryData = data.data;

    return new STISCSTTDueSummary({
      total: summaryData.total,
      totalOverdue: summaryData.total_overdue,
      totalNow: summaryData.total_now,
      totalBooked: summaryData.total_booked
    });
  }
}
