import { FlagPermissionUrgentDeliveryRequests } from "@/feature-flags/flag-permission-urgent-delivery-requests";
const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const PermintaanKirimanPentingList = () =>
  import(
    /* webpackChunkName: "PermintaanKirimanPenting" */ `@/app/ui/views/urgent-delivery/index.vue`
  );

export default [
  {
    path: "/permintaan-kiriman-penting-pos",
    component: Layout,
    redirect: "/permintaan-kiriman-penting-pos",
    name: "permintaan-kiriman-penting-pos",
    meta: {
      header: true,
      icon: "box-opened",
      title: "Permintaan Kiriman Penting",
      name: "Permintaan Kiriman Penting",
      permission:
        FlagPermissionUrgentDeliveryRequests.permission_urgent_delivery_requests_for_pos_enable
    },
    children: [
      {
        path: "/permintaan-kiriman-penting-pos",
        name: "permintaan-kiriman-penting-pos",
        component: PermintaanKirimanPentingList,
        meta: {
          header: true
        },
        permission:
          FlagPermissionUrgentDeliveryRequests.permission_urgent_delivery_requests_for_pos_enable
      }
    ]
  }
];