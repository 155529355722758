import { JsPdfFont, loadFontsJsPdf } from "./module";

const fonts = [
  new JsPdfFont({
    path: `${process.env.VUE_APP_S3_AMAZON_AWS}/assets/fonts/Nepali-Bold.js`,
    variableName: "nepaliBoldFont",
    filename: "NotoSans-Bold-bold.ttf"
  })
];

export const downloadFontsJsPdf = async () => await loadFontsJsPdf(fonts);
