/* eslint-disable @typescript-eslint/camelcase */

import { Commodity, CommodityList } from "@/domain/entities/Commodity";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class CommodityMapper {
  public convertCommodityDataFromApi(res: AxiosResponse<any>): CommodityList {
    const { data } = res;
    const commodities: Commodity[] = [];
    if (data.data.length === 0) {
      return new CommodityList(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        commodities.push(
          new Commodity({
            commodity_id: item.commodity_id,
            commodity_code: item.commodity_code,
            commodity_name: item.commodity_name,
            commodity_name_en: item.commodity_name_en ? item.commodity_name_en : " ",
            commodity_group: item.commodity_group,
            hs_code: item.hs_code,
            commodity_status: item.status,
            isDangerousGoods: item.is_dangerous_goods,
            isQuarantine: item.is_quarantine
          })
        );
      });
    }
    return new CommodityList(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      commodities
    );
  }

  public convertDetailCommodityFromApi(res: AxiosResponse<any>): Commodity {
    const { data } = res;
    return new Commodity({
      commodity_id: data.data.commodity_id,
      commodity_code: data.data.commodity_code,
      commodity_name: data.data.commodity_name,
      commodity_name_en: data.data.commodity_name_en,
      commodity_group: data.data.commodity_group,
      hs_code: data.data.hs_code,
      commodity_status: data.data.commodity_status,
      description: data.data.description,
      commodity_surcharge_applicable: data.data.commodity_surcharge_applicable,
      commodity_document_surcharge: data.data.commodity_document_surcharge,
      commodity_service_type: data.data.commodity_service_type,
      commodity_min_price: data.data.commodity_min_price,
      created_at: data.data.created_at,
      updated_at: data.data.updated_at,
      created_by: data.data.created_by,
      updated_by: data.data.updated_by,
      isDangerousGoods: data.data.is_dangerous_goods
    });
  }

  public convertPayloadData(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
