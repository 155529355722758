<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M17 3.94737C17 3.42632 16.55 3 16 3C15.45 3 15 3.42632 15 3.94737H9C9 3.42632 8.55 3 8 3C7.45 3 7 3.42632 7 3.94737H6C4.35 3.94737 3 5.22632 3 6.78947V18.1579C3 19.7211 4.35 21 6 21H18C19.65 21 21 19.7211 21 18.1579V6.78947C21 5.22632 19.65 3.94737 18 3.94737H17ZM8 7.73684C7.45 7.73684 7 7.31053 7 6.78947V5.84211H6C5.45 5.84211 5 6.26842 5 6.78947V9.63158H19V6.78947C19 6.26842 18.55 5.84211 18 5.84211H17V6.78947C17 7.31053 16.55 7.73684 16 7.73684C15.45 7.73684 15 7.31053 15 6.78947V5.84211H9V6.78947C9 7.31053 8.55 7.73684 8 7.73684ZM5 11.5263H19V18.1579C19 18.6789 18.55 19.1053 18 19.1053H6C5.45 19.1053 5 18.6789 5 18.1579V11.5263Z"
            fill="currentColor" />
    </svg>
</template>

<script>
import { Vue } from "vue-class-component";

export default class CalendarOutlined extends Vue {}
</script>