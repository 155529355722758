import { ConfigPriceData } from "@/domain/entities/ConfigPrice";
import {
  ConfigurableInsurance,
  DetailInsurance,
  Insurance
} from "@/domain/entities/Insurance";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class InsuranceMapper {
  public convertDetailInsuranceFromApi(
    res: AxiosResponse<any>
  ): DetailInsurance {
    const {
      data: { data }
    } = res;
    return new DetailInsurance({
      configPrice: new ConfigPriceData({
        id: data.configurable_price_id,
        configurablePriceName: data.configurable_price_name,
        configurablePriceType: data.configurable_price_type,
        configurablePriceDescription: data.configurable_price_description,
        configurablePriceStatus: data.configurable_price_status,
        configurablePriceCreatedAt: data.configurable_price_created_at,
        configurablePriceCreatedBy: data.configurable_price_created_by,
        configurablePriceUpdatedAt: data.configurable_price_updated_at,
        configurablePriceUpdatedBy: data.configurable_price_updated_by
      }),
      configurableInsurance: new ConfigurableInsurance({
        insuranceId: data.configurable_insurance.insurance_id,
        insuranceConfigurablePriceId:
          data.configurable_insurance.insurance_configurable_price_id,
        insuranceVendorName: data.configurable_insurance.insurance_vendor_name,
        insuranceIsApiUrl: data.configurable_insurance.insurance_is_api_url,
        insuranceApiUrl: data.configurable_insurance.insurance_api_url,
        insuranceApiToken: data.configurable_insurance.insurance_api_token,
        insuranceTypes: data.configurable_insurance.insurance_types?.insurances?.map(
          (key: any) =>
            new Insurance({
              id: key.id,
              name: key.name,
              type: key.type,
              priceGoodsMin: key.price_goods_min,
              priceGoodsMax: key.price_goods_max,
              pricePremiMin: key.price_premi_min,
              pricePremiMax: key.price_premi_max,
              priceGoodsMinMY: key.price_goods_min_currency_myr,
              priceGoodsMaxMY: key.price_goods_max_currency_myr,
              pricePremiMinMY: key.price_premi_min_currency_myr,
              pricePremiMaxMY: key.price_premi_max_currency_myr,
              cost: key.cost,
              commisionPos: key.commission_pos
            })
        )
      })
    });
  }

  public convertPayloadData(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertInsuranceFromApi(res: AxiosResponse<any>): Insurance {
    const {
      data: { data }
    } = res;
    return new Insurance({
      insuranceCost: data.insurance_cost,
      commisionPos: data.commision_pos,
      insuranceAdminFee: data.insurance_admin_fee,
      viewCommissionPos: data.view_commision_pos
    });
  }
}
