
import { Vue } from "vue-class-component";
import { FeedbackController } from "../../controllers/FeedbackController";

export default class MenuRating extends Vue {
  onClickBanner() {
    FeedbackController.setOpenModalFeedbackFromSidebar(true);
    FeedbackController.setShowModalFeedback(true);
  }
}
