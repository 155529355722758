
import { replaceComma } from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { InsuranceController } from "@/app/ui/controllers/InsuranceController";
import { Insurance } from "@/domain/entities/Insurance";
import { Vue, prop } from "vue-class-component";
import { bannerInfo } from "../modules";

class Props {
  modelValue = prop<any>({
    required: true
  });
  bannerType = prop<string>({
    type: String,
    default: ""
  });
  commission = prop<number>({
    type: Number,
    default: 0
  });
  profit = prop<number>({
    type: Number,
    default: 0
  });
  maxProtection = prop<number>({
    type: Number,
    default: 0
  });
  cost = prop<number>({
    type: Number,
    default: 0
  });
  estimationPrice = prop<any>({
    type: Number,
    default: "0"
  });
}

export default class InfoInsurance extends Vue.with(Props) {
  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }

  get manualBooking() {
    return BookingController.manualBooking;
  }

  get insuranceData(): Insurance {
    return InsuranceController.insuranceData;
  }

  enableInsurance(
    bannerType: string,
    ccoIsInsuranceCod: boolean,
    ccoIsInsuranceDfod: boolean
  ): boolean {
    return (
      bannerType === "non-cod" ||
      (bannerType === "cod" && !ccoIsInsuranceCod) ||
      (bannerType === "dfod" && !ccoIsInsuranceDfod)
    );
  }

  exceeds5Million(enableInsurance: boolean, estimationPrice: string): boolean {
    return enableInsurance && +replaceComma(estimationPrice) > 5000000;
  }

  isDeliveryInsurance(enableInsurance: boolean, isInsurance: boolean): boolean {
    return enableInsurance && isInsurance;
  }

  isDeliveryNonInsurance(
    enableInsurance: boolean,
    isInsurance: boolean
  ): boolean {
    return enableInsurance && !isInsurance;
  }

  isCodOrDfod(
    ccoIsInsuranceCod: boolean,
    ccoIsInsuranceDfod: boolean,
    bannerType: string,
    isInsurance: boolean
  ): boolean {
    return (
      ((ccoIsInsuranceCod && bannerType === "cod") ||
        (ccoIsInsuranceDfod && bannerType === "dfod")) &&
      !isInsurance
    );
  }

  get textContent() {
    const { listCodActive, listDfodActive, estimationPrice, isInsurance } =
      this.modelValue || {};

    const enableInsurance = this.enableInsurance(
      this.bannerType,
      listCodActive.ccoIsInsurance,
      listDfodActive.ccoIsInsurance
    );

    const exceeds5Million = this.exceeds5Million(
      enableInsurance,
      estimationPrice
    );
    const deliveryInsurance = this.isDeliveryInsurance(
      enableInsurance,
      isInsurance
    );
    const deliveryNonInsurance = this.isDeliveryNonInsurance(
      enableInsurance,
      isInsurance
    );
    const codOrDfod = this.isCodOrDfod(
      listCodActive,
      listDfodActive,
      this.bannerType,
      isInsurance
    );

    const banner: any = {
      exceeds5Million,
      deliveryInsurance,
      deliveryNonInsurance,
      codOrDfod
    };

    const getActiveBanner =
      Object.keys(banner).find((key) => banner[key]) || "";

    return bannerInfo(this.insuranceData)?.[getActiveBanner] ?? "";
  }

  largeNumber(number: any) {
    if (number >= 1e9) {
      return (number / 1e9).toString().replace(".", ",") + " Miliar";
    } else if (number >= 1e6) {
      return (number / 1e6).toString().replace(".", ",") + " Juta";
    } else if (number >= 1e3) {
      return (number / 1e3).toString().replace(".", ",") + " Ribu";
    }
  }

  replaceNumber(result: any) {
    if (result?.split(",")[1]?.includes("0")) {
      result = result?.split(",")[0] + result?.split(",")[1].replace("0", "");
    }
    return result;
  }

  formatLargeNumber(number: any) {
    let result: any = "";
    const isLargeNumber = number >= 1e9 || number >= 1e6 || number >= 1e3;
    if (isLargeNumber) {
      result = this.largeNumber(number);
    } else {
      result = number.toString();
    }
    result = this.replaceNumber(result);
    return result;
  }
}
