/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { PartnerPresenter } from "../presenters/PartnerPresenter";
import { AddPartnerRequest } from "@/data/payload/api/PartnerRequest";
import {
  PartnerDetail,
  ConsolidatorByCity,
  PartnerBank,
  ClientNamePOS
} from "@/domain/entities/Partner";
import { Pagination } from "@/domain/entities/Pagination";
import {
  UpdatePartnerApiRequest,
  FreezeUnFreezePartnerApiRequest
} from "@/data/payload/api/PartnerApiRequest";
import { ClientPartnerOptions } from "@/domain/entities/CnManifest";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { BeneficiaryAccountController } from "./BeneficiaryAccountController";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
export interface PartnerState {
  errorCause: string;
  isError: boolean;
  isLoading: boolean;
  partnerDetail: PartnerDetail;
  loadingConsolidatorsByCity: boolean;
  consolidatorsByCity: ConsolidatorByCity;
  errorGetConsolidatorsByCity: boolean;
  errorCauseGetConsolidatorsByCity: string;
  loadingClientsNamePOS: boolean;
  clientDataNamePOS: ClientNamePOS;
  errorGetClientsNamePOS: boolean;
  errorCauseGetClientsNamePOS: string;
  modalSuccess: boolean;
}
@Module({ namespaced: true, dynamic: true, store, name: "partner-dashboard" })
class PartnerStore extends VuexModule implements PartnerState {
  public partnerDetail: PartnerDetail = new PartnerDetail();
  public errorCause = "";
  public errorMessageAdd = "";
  public isError = false;
  public isLoading = false;
  public loadingConsolidatorsByCity = false;
  public consolidatorsByCity = new ConsolidatorByCity(
    new Pagination(1, 10),
    []
  );
  public errorGetConsolidatorsByCity = false;
  public errorCauseGetConsolidatorsByCity = "";
  public isLoadingBank = false;
  public partnerBank: PartnerBank[] = [];
  public loadingClientsNamePOS = false;
  public clientDataNamePOS = new ClientNamePOS(new Pagination(1, 10), []);
  public errorGetClientsNamePOS = false;
  public errorCauseGetClientsNamePOS = "";
  public showAddBank = false;
  public formAdd: any = {
    consolidatorName: "",
    consolidatorAddress: "",
    districts: "",
    consolidators: "",
    partnerArea: "",
    cityExclude: [],
    phone: "",
    taxNumber: "",
    contactName: "",
    contactLevel: "",
    contactEmail: "",
    contactPhones: [
      {
        no: "",
        error: false
      }
    ],
    startDate: "",
    endDate: "",
    bank: {
      bankLogo: "",
      accountName: "",
      bankName: "",
      bankCode: "",
      accountNo: "",
      accountEmail: ""
    },
    longitude: "",
    latitude: "",
    referralCode: "",
    typePos: "",
    parentPos: "",
    branchCommission: "",
    partnerIsCodDelivery: false,
    partnerIsCodBooking: false,
    partnerIsPcu: false,
    partnerPosReverseJourney: "",
    partnerPosAttachFiles: [],
    debitBalance: "",
    partnerIsAllowBookVippack: false
  };
  public modalSuccess = false;

  @Action
  public async getPartnerDetail(params: {
    id: number, 
    tieringLevel: boolean
  }) {
    this.setLoading(true);
    const presenter = container.resolve(PartnerPresenter);
    await presenter
      .getPartnerDetail(params.id, params.tieringLevel)
      .then(async (res: PartnerDetail) => {
        this.setPartnerDetail(res);
        await this.getBalancePartner(res);
        this.partnerDetail.partnerStartContract = new Date(
          res.partnerStartContract
        );
        this.partnerDetail.partnerEndContract = new Date(
          res.partnerEndContract
        );
        this.contactPhoneNumbers(this.partnerDetail.partnerContactPhoneNumber);
        this.setIsError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setIsError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }
  @Action
  public fetchPartnerDetail(params: {
    id: number, 
    tieringLevel: boolean
  }) {
    const presenter = container.resolve(PartnerPresenter);
    return presenter.getPartnerDetail(params.id, params.tieringLevel);
  }

  @Action
  addPartner(params: {
    type: string;
    version: string,
    partnerName: string;
    partnerExternalCode: string;
    partnerAddress: string;
    partnerDefaultDistrict: string;
    partnerContractStart: string;
    partnerContractEnd: string;
    partnerContactName: string;
    partnerContactJobTitle: string;
    partnerContactEmail: string;
    partnerContactPhoneNumber: string;
    partnerBeneficiaryBankName: string;
    partnerBeneficiaryAccountNumber: string;
    partnerBeneficiaryAccountName: string;
    partnerBeneficiaryEmail: string;
    partnerBeneficialAccountLabel: string;
    partnerPhoneNumber: string;
    partnerTaxNumber: string;
    partnerParentId: number | undefined;
    partnerCity: string[] | undefined;
    partnerLatitude: string;
    partnerLongitude: string;
    partnerPosType: string;
    partnerPosParentId: number | undefined;
    partnerPosBranchCommission: number | undefined;
    partnerReferrerCode: string;
    partnerIsCodDelivery: boolean;
    partnerPosReverseJourney: number;
    partnerIsCodBooking: boolean;
    partnerIsPcu: boolean;
    partnerPosAttachFiles: string[],
    partnerPosSaldoDeposit: number,
    partnerIsAllowBookVippack: boolean
  }) {
    this.setLoading(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(PartnerPresenter);
    return presenter
      .addPartner(
        params.type,
        params.version,
        new AddPartnerRequest({
          partnerName: params.partnerName,
          partnerExternalCode: params.partnerExternalCode,
          partnerAddress: params.partnerAddress,
          partnerDefaultDistrict: params.partnerDefaultDistrict,
          partnerContractStart: params.partnerContractStart,
          partnerContractEnd: params.partnerContractEnd,
          partnerContactName: params.partnerContactName,
          partnerContactJobTitle: params.partnerContactJobTitle,
          partnerContactEmail: params.partnerContactEmail,
          partnerContactPhoneNumber: params.partnerContactPhoneNumber,
          partnerBeneficiaryBankName: params.partnerBeneficiaryBankName,
          partnerBeneficiaryAccountNumber:
            params.partnerBeneficiaryAccountNumber,
          partnerBeneficiaryAccountName: params.partnerBeneficiaryAccountName,
          partnerBeneficiaryEmail: params.partnerBeneficiaryEmail,
          partnerBeneficialAccountLabel: params.partnerBeneficialAccountLabel,
          partnerPhoneNumber: params.partnerPhoneNumber,
          partnerTaxNumber: params.partnerTaxNumber,
          partnerParentId: params.partnerParentId,
          partnerCity: params.partnerCity,
          partnerLatitude: params.partnerLatitude,
          partnerLongitude: params.partnerLongitude,
          partnerReferrerCode: params.partnerReferrerCode,
          partnerPosType: params.partnerPosType,
          partnerPosParentId: params.partnerPosParentId,
          partnerIsCodDelivery: params.partnerIsCodDelivery,
          partnerPosBranchCommission: params.partnerPosBranchCommission,
          partnerPosReverseJourney: params.partnerPosReverseJourney,
          partnerIsCodBooking: params.partnerIsCodBooking,
          partnerIsPcu: params.partnerIsPcu,
          partnerPosAttachFiles: params.partnerPosAttachFiles,
          partnerPosSaldoDeposit: params.partnerPosSaldoDeposit,
          partnerIsAllowBookVippack: params.partnerIsAllowBookVippack
        })
      )
      .then(() => {
        this.setIsError(false);
        this.setErrorMessageAdd("");
        this.setErrorCause("");
        this.setModalSuccess(true);
        return true;
      })
      .catch(err => {
        const message = err?.response?.data?.message?.en;
        this.setErrorMessageAdd(message);
        this.setModalSuccess(false);
        if (message === "Referal Code not found") return;
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Buat Partner Gagal", () => {
            this.addPartner(params);
          })
        );
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public getConsolidatorsByCity(params: {
    search: string;
    page: number;
    limit: number;
    cityCode: string;
    type: string;
    status: string;
  }) {
    this.setLoadingConsolidatorsByCity(true);
    const presenter = container.resolve(PartnerPresenter);
    return presenter
      .getConsolidatorsByCity(
        params.search,
        params.page,
        params.limit,
        params.cityCode,
        params.type,
        params.status
      )
      .then((res: ConsolidatorByCity) => {
        this.setConsolidatorsByCity(res);
        this.setErrorGetConsolidatorsByCity(false);
        this.setErrorCauseGetConsolidatorsByCity("");
        return true;
      })
      .catch((error: any) => {
        this.setErrorGetConsolidatorsByCity(true);
        this.setErrorCauseGetConsolidatorsByCity(
          error.response ? "server" : "internet"
        );
        return false;
      })
      .finally(() => {
        this.setLoadingConsolidatorsByCity(false);
      });
  }

  @Action
  public getClientNamePOS(params: {
    search: string;
    page: number;
    limit: number;
    isBranch: boolean;
    isApiCbp: boolean;
    packageType: string;
    cityCode: string;
    isDirectory: boolean;
    infiniteScroll?: boolean;
  }) {
    this.setLoadingClientNamePOS(true);
    const presenter = container.resolve(PartnerPresenter);
    return presenter
      .getClientNamePOS(
        params.search,
        params.page,
        params.limit,
        params.isBranch,
        params.isApiCbp,
        params.packageType,
        params.cityCode,
        params.isDirectory
      )
      .then((res: ClientNamePOS) => {
        if (params.infiniteScroll) {
          if (!res.data.length && res.pagination.page === 1) {
            this.setClientsNamePOS(res);
          } else {
            this.setClientsNamePOS(
              new ClientNamePOS(res.pagination, [
                ...this.clientDataNamePOS.data,
                ...res.data
              ])
            );
          }
        } else {
          this.setClientsNamePOS(res);
        }
        this.setErrorGetClientsNamePOS(false);
        this.setErrorCauseGetClientsNamePOS("");

        return res;
      })
      .catch((error: any) => {
        this.setErrorGetClientsNamePOS(true);
        this.setErrorCauseGetClientsNamePOS(
          error.response ? "server" : "internet"
        );

        throw error;
      })
      .finally(() => {
        this.setLoadingClientNamePOS(false);
      });
  }

  @Action
  public contactPhoneNumbers(res: any) {
    res = this.partnerDetail.partnerContactPhoneNumber
      .split(",")
      .map((e: any) => ({
        no: e,
        error: false
      }));
    this.setContactPhoneNumbers(res);
  }

  @Mutation
  public setContactPhoneNumbers(val: any) {
    this.partnerDetail.partnerContactPhoneNumber = val;
  }

  @Action
  public getListPartnerBank() {
    this.setLoadingBank(true);
    const presenter = container.resolve(PartnerPresenter);
    presenter
      .getListPartnerBank()
      .then((res: PartnerBank[]) => {
        this.setPartnerBank(res);
        this.setIsError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setIsError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => this.setLoadingBank(false));
  }

  @Action
  public async fetchListPartnerOptions(params: {
    page: number;
    limit: number;
    search: string;
    type?: string;
  }) {
    const presenter = container.resolve(PartnerPresenter);
    return presenter
      .getListPartner(params.page, params.limit, params.search, params.type)
      .then((res: ClientPartnerOptions[]) => {
        return res;
      })
      .catch(() => {
        return [];
      });
  }

  @Mutation
  public deleteBank() {
    this.formAdd.bank = {
      bankLogo: "",
      accountName: "",
      bankName: "",
      bankCode: "",
      accountNo: "",
      accountEmail: ""
    };
  }

  @Mutation
  public onResetForm() {
    this.formAdd = {
      consolidatorName: "",
      consolidatorAddress: "",
      districts: "",
      consolidators: "",
      partnerArea: "",
      cityExclude: [],
      phone: "",
      taxNumber: "",
      contactName: "",
      contactLevel: "",
      contactEmail: "",
      contactPhones: [
        {
          no: "",
          error: false
        }
      ],
      startDate: "",
      endDate: "",
      bank: {
        bankLogo: "",
        accountName: "",
        bankName: "",
        bankCode: "",
        accountNo: "",
        accountEmail: ""
      },
      longitude: "",
      latitude: "",
      referralCode: "",
      typePos: "",
      parentPos: "",
      branchCommission: "",
      partnerIsCodDelivery: false,
      partnerIsCodBooking: false,
      partnerIsPcu: false,
      partnerPosReverseJourney: "",
      partnerPosAttachFiles: [],
      debitBalance: "",
      partnerIsAllowBookVippack: false
    };
  }

  @Mutation
  public setAutoCheckBookingCodPartner(value: boolean) {
    this.formAdd.partnerIsCodBooking = value;
  }

  @Mutation
  public setShowAddBank(val: boolean) {
    this.showAddBank = val;
  }

  @Mutation
  public setLoadingBank(val: boolean) {
    this.isLoadingBank = val;
  }

  @Mutation
  public setLoadingClientNamePOS(val: boolean) {
    this.loadingClientsNamePOS = val;
  }

  @Mutation
  public setPartnerBank(val: PartnerBank[]) {
    this.partnerBank = val;
  }

  @Mutation
  public setClientsNamePOS(data: ClientNamePOS) {
    this.clientDataNamePOS = data;
  }

  @Mutation
  public setErrorGetClientsNamePOS(val: boolean) {
    this.errorGetClientsNamePOS = val;
  }

  @Mutation
  public setErrorCauseGetClientsNamePOS(val: string) {
    this.errorCauseGetClientsNamePOS = val;
  }

  @Action
  public freezeUnFreezePartner(params: {
    id: number;
    type: string;
    isBanned: boolean;
    bannedReason: string;
  }) {
    const presenter = container.resolve(PartnerPresenter);
    return presenter
      .freezeUnFreezePartner(
        new FreezeUnFreezePartnerApiRequest(
          params.id,
          params.isBanned,
          params.bannedReason
        ),
        params.id
      )
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  public updatePartner() {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(PartnerPresenter);
    return presenter
      .patchPartner(
        new UpdatePartnerApiRequest(
          this.partnerDetail.partnerId,
          this.partnerDetail.partnerName,
          this.partnerDetail.partnerAddress,
          new Date(this.partnerDetail.partnerEndContract.setUTCHours(24))
            .toISOString()
            .substr(0, 10),
          this.partnerDetail.partnerContactName,
          this.partnerDetail.partnerContactJobTitle,
          this.partnerDetail.partnerContactEmail,
          this.partnerDetail.partnerContactPhoneNumber
            .map((e: any) => {
              return e.no;
            })
            .toString(),
          BeneficiaryAccountController.beneficiaryDetail.bankName,
          BeneficiaryAccountController.beneficiaryDetail.bankAccountNumber,
          BeneficiaryAccountController.beneficiaryDetail.bankAccountName,
          BeneficiaryAccountController.beneficiaryDetail.bankAccountEmail,
          BeneficiaryAccountController.beneficiaryDetail.bankAccountLabel,
          typeof this.partnerDetail.partnerDistrict === "object"
            ? (this.partnerDetail.partnerDistrict as any).code
            : this.partnerDetail.partnerDistrictCode,
          this.partnerDetail.partnerLatitude,
          this.partnerDetail.partnerLongitude,
          typeof this.partnerDetail.partnerPosReverseJourney === "object"
            ? (this.partnerDetail.partnerPosReverseJourney as any).id
            : 0,
          this.partnerDetail.partnerPosType,
          this.partnerDetail.partnerPosParentId,
          this.partnerDetail.partnerPhoneNumber.replace(/^0|^62/g, "+62"),
          this.partnerDetail.partnerTaxNumber,
          this.partnerDetail.partnerParentId,
          this.partnerDetail.partnerParentType,
          this.partnerDetail.partnerCity.map(e => {
            return e.code;
          }),
          this.partnerDetail.partnerIsCodDelivery,
          Number(
            this.partnerDetail.partnerPosBranchCommission
              ? this.partnerDetail.partnerPosBranchCommission.toString().replace(",", ".")
              : this.partnerDetail.partnerPosBranchCommission
          ),
          this.partnerDetail.partnerIsCodBooking,
          this.partnerDetail.partnerIsPcu,
          this.partnerDetail.partnerIsAllowBookVippack,
          this.partnerDetail.partnerPosAttachFiles.filter((item: string) => item),
          Number(this.partnerDetail.partnerPosSaldoDepositFormated.replaceAll(".", ""))
        ),
        this.partnerDetail.partnerType,
        this.partnerDetail.partnerType === "pos" ? "v2" : "v1"
      )
      .then(() => {
        this.setErrorCause("");
        return true;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Partner Management Gagal!", () =>
            this.updatePartner()
          )
        );
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setIsError(err: boolean) {
    this.isError = err;
  }

  @Mutation 
  public setModalSuccess(status: boolean) {
    this.modalSuccess = status;
  }

  @Mutation
  public setErrorGetConsolidatorsByCity(val: boolean) {
    this.errorGetConsolidatorsByCity = val;
  }

  @Mutation
  public setErrorCauseGetConsolidatorsByCity(val: string) {
    this.errorCauseGetConsolidatorsByCity = val;
  }

  @Mutation
  public setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  @Mutation
  public setLoadingConsolidatorsByCity(val: boolean) {
    this.loadingConsolidatorsByCity = val;
  }

  @Mutation
  public setPartnerDetail(value: PartnerDetail) {
    this.partnerDetail = value;
  }

  @Mutation
  public setPartnerParent(value: any) {
    this.partnerDetail.partnerParentName = value.name;
    this.partnerDetail.partnerParentId = value.id;
  }

  @Mutation
  public setPartnerParentType(value: string) {
    this.partnerDetail.partnerParentType = value;
  }

  @Mutation
  public setConsolidatorsByCity(data: ConsolidatorByCity) {
    this.consolidatorsByCity = data;
  }

  @Mutation
  public setErrorMessageAdd(message: string) {
    this.errorMessageAdd = message;
  }

  @Action
  public async fetchPartnerApi(params: any) {
    const presenter = container.resolve(PartnerPresenter);
    return presenter
      .fetchPartnerApi(params)
      .then((res: ClientPartnerOptions[]) => {
        return res;
      })
      .catch(() => {
        return [];
      });
  }

  @Action
  public async getBalancePartner(params: PartnerDetail) {
    if (params.partnerType.toLowerCase() === "pos") {
      const respBalance = await BalanceController.getBalanceClientPrtner({
        type: "partner",
        partnerID: params.partnerId,
        partnerType: "pos",
        clientID: 0
      });
      params.partnerBalance = respBalance.walletBalance;
      this.setPartnerDetail(params);
    }
  }

  @Action
  public checkReferralCode(referralCode: string) {
    const presenter = container.resolve(PartnerPresenter);
    return presenter
      .checkReferralCode(referralCode)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }
}

export const PartnerController = getModule(PartnerStore);
