/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionBooking = {
  permission_booking_enable: new Rox.Flag(false),
  permission_booking_create_manual: new Rox.Flag(false),
  permission_booking_create_for_corporate: new Rox.Flag(false),
  permission_booking_create_shipment_id: new Rox.Flag(false),
  permission_instant_booking_create: new Rox.Flag(false),
  permission_instant_booking_detail: new Rox.Flag(false),
  permission_advance_filter_client_partner_click: new Rox.Flag(false),
  permission_booking_view_list: new Rox.Flag(false),
  permission_booking_view_detail: new Rox.Flag(false),
  permission_booking_edit: new Rox.Flag(false),
  permission_shipment_booking_mp: new Rox.RoxString(""),
  permission_pieces_booking_shipment: new Rox.RoxString(""),
  tokopedia_gap_weight_validation_prefix: new Rox.RoxString(""),
  hws_configuration: new Rox.Flag(false),
  permission_booking_version: new Rox.RoxString(""),
  config_whitelist_id_client_branch_add_pieces: new Rox.RoxString("24030"),
  adjust_recipient_prefix_stt: new Rox.RoxString("10,11")
};

export function initFlagsPermissionBooking() {
  // register flags with namespaces for better grouping
  Rox.register("featureBooking", FlagsPermissionBooking);
}
