import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { RequestPriorityDeliveryInterface } from "@/data/payload/contracts/RequestPriorityDelivery";

export class RequestPriorityDelivery {
    rpdId = 0;
    rpdPriorityDeliveryId = 0;
    rpdSttNo = '';
    rpdRequestType = '';
    rpdReason = '';
    rpdDeadlineReturn = '';
    rpdStatusReturn = '';
    sttLastStatusId = '';
    sttDestinationCity = '';
    deliveryReasonCode = '';
    deliveryReasonTitle: any = '';
    rpdNotes = '';
    rpdProofPhoto: Array<string> = [];
    rpdCreatedAt = '';
    rpdUpdatedAt = '';
    sttProductType = '';
    isSttRetail = false;
    bookedBy = 0;
    constructor(fields?: Partial<RequestPriorityDelivery>) {
        Object.assign(this, fields)
    }
}

export class CreateRequestPriorityDelivery implements RequestPriorityDeliveryInterface {
    rpdSttNo = '';
    rpdRequestType = '';
    rpdReason = '';
    rpdProofPhoto: Array<string> = [];
    rpdNotes = '';
    rpdLink: string[] = []
    constructor(fields?: Partial<CreateRequestPriorityDelivery>) {
        Object.assign(this, fields)
    }
    toJSON(): string {
        return JSON.stringify(
            ConvertObjectCamelToSnakeCase({
                rpdSttNo: this.rpdSttNo,
                rpdRequestType: this.rpdRequestType,
                rpdReason: this.rpdReason,
                rpdProofPhoto: this.rpdProofPhoto,
                rpdNotes: this.rpdNotes,
                rpdLink: this.rpdLink
            })
        );
    }
}

export class CancelRequestPriorityDelivery implements RequestPriorityDeliveryInterface {
    rpdId = 0;
    constructor(fields?: Partial<CancelRequestPriorityDelivery>) {
        Object.assign(this, fields)
    }
    toJSON(): string {
        return JSON.stringify(
            ConvertObjectCamelToSnakeCase({
                rpdId: this.rpdId
            })
        );
    }
}