
import { Vue, prop } from "vue-class-component";
import { defineAsyncComponent } from "vue";

class Props {
  name = prop<string>({
    type: String,
    default: "",
  });
  fill = prop<boolean>({
    default: false,
    type: Boolean,
  });
  status = prop<boolean>({
    default: false,
    type: Boolean,
  });
}

const icons: any = {
  "arrow-down": defineAsyncComponent({
    loader: () => import("./svgs/arrow-down.vue"),
  }),
  "arrow-up": defineAsyncComponent({
    loader: () => import("./svgs/arrow-up.vue"),
  }),
  announce: defineAsyncComponent({
    loader: () => import("./svgs/announce.vue"),
  }),
  attachment: defineAsyncComponent({
    loader: () => import("./svgs/attachment.vue"),
  }),
  info: defineAsyncComponent({
    loader: () => import("./svgs/info.vue"),
  }),
  "cargo-plane": defineAsyncComponent({
    loader: () => import("./svgs/cargo-plane.vue"),
  }),
  "cargo-truck": defineAsyncComponent({
    loader: () => import("./svgs/cargo-truck.vue"),
  }),
  "cargo-train": defineAsyncComponent({
    loader: () => import("./svgs/cargo-train.vue"),
  }),
  "cloud-upload": defineAsyncComponent({
    loader: () => import("./svgs/cloud-upload.vue"),
  }),
  transit: defineAsyncComponent({
    loader: () => import("./svgs/transit.vue"),
  }),
  misroute: defineAsyncComponent({
    loader: () => import("./svgs/misroute.vue"),
  }),
  shortland: defineAsyncComponent({
    loader: () => import("./svgs/shortland.vue"),
  }),
  "sti-dest": defineAsyncComponent({
    loader: () => import("./svgs/sti-dest.vue"),
  }),
  "sti-dest-sc": defineAsyncComponent({
    loader: () => import("./svgs/sti-dest-sc.vue"),
  }),
  delivery: defineAsyncComponent({
    loader: () => import("./svgs/delivery.vue"),
  }),
  dex: defineAsyncComponent({
    loader: () => import("./svgs/dex.vue"),
  }),
  "pod-dex": defineAsyncComponent({
    loader: () => import("./svgs/pod-dex.vue"),
  }),
  ellipsis: defineAsyncComponent({
    loader: () => import("./svgs/ellipsis.vue"),
  }),
  "file-chart": defineAsyncComponent({
    loader: () => import("./svgs/file-chart.vue"),
  }),
  "file-download": defineAsyncComponent({
    loader: () => import("./svgs/file-download.vue"),
  }),
  bkd: defineAsyncComponent({
    loader: () => import("./svgs/bkd.vue"),
  }),
  shipment: defineAsyncComponent({
    loader: () => import("./svgs/shipment.vue"),
  }),
  "sti-sc": defineAsyncComponent({
    loader: () => import("./svgs/sti-sc.vue"),
  }),
  sti: defineAsyncComponent({
    loader: () => import("./svgs/sti.vue"),
  }),
  bagging: defineAsyncComponent({
    loader: () => import("./svgs/bagging.vue"),
  }),
  cargo: defineAsyncComponent({
    loader: () => import("./svgs/cargo.vue"),
  }),
  "white-trash": defineAsyncComponent({
    loader: () => import("./svgs/white-trash.vue"),
  }),
  "grey-plus": defineAsyncComponent({
    loader: () => import("./svgs/grey-plus.vue"),
  }),
  "icon-x": defineAsyncComponent({
    loader: () => import("./svgs/icon-x.vue"),
  }),
  "cycle-time": defineAsyncComponent({
    loader: () => import("./svgs/cycle-time.vue"),
  }),
  reload: defineAsyncComponent({
    loader: () => import("./svgs/reload.vue"),
  }),
  "auto-copy": defineAsyncComponent({
    loader: () => import("./svgs/auto-copy.vue"),
  }),
  "chevron-up-down": defineAsyncComponent({
    loader: () => import("./svgs/chevron-up-down.vue"),
  }),
  "chevron-down-up": defineAsyncComponent({
    loader: () => import("./svgs/chevron-down-up.vue"),
  }),
  "turn-left": defineAsyncComponent({
    loader: () => import("./svgs/turn-left.vue"),
  }),
  "print-summary": defineAsyncComponent({
    loader: () => import("./svgs/print-summary.vue"),
  }),
  printer: defineAsyncComponent({
    loader: () => import("./svgs/printer.vue"),
  }),
  "check-circle": defineAsyncComponent({
    loader: () => import("./svgs/check-circle.vue"),
  }),
  hal: defineAsyncComponent({
    loader: () => import("./svgs/hal.vue"),
  }),
  rejected: defineAsyncComponent({
    loader: () => import("./svgs/rejected.vue"),
  }),
  eye: defineAsyncComponent({
    loader: () => import("./svgs/eye.vue"),
  }),
  "eye-red": defineAsyncComponent({
    loader: () => import("./svgs/eye-red.vue"),
  }),
  invisible: defineAsyncComponent({
    loader: () => import("./svgs/invisible.vue"),
  }),
  "x-circle": defineAsyncComponent({
    loader: () => import("./svgs/x-circle.vue"),
  }),
  "shortcut-booking-manual": defineAsyncComponent({
    loader: () => import("./svgs/shortcut-booking-manual.vue"),
  }),
  "shortcut-booking-client": defineAsyncComponent({
    loader: () => import("./svgs/shortcut-booking-client.vue"),
  }),
  "shortcut-booking-shipment": defineAsyncComponent({
    loader: () => import("./svgs/shortcut-booking-shipment.vue"),
  }),
  "shortcut-booking-instant": defineAsyncComponent({
    loader: () => import("./svgs/shortcut-booking-instant.vue"),
  }),
  "shortcut-booking-ppob": defineAsyncComponent({
    loader: () => import("./svgs/shortcut-booking-ppob.vue"),
  }),
  "bkd-to-sti": defineAsyncComponent({
    loader: () => import("./svgs/bkd-to-sti.vue"),
  }),
  "booking-retail": defineAsyncComponent({
    loader: () => import("./svgs/booking-retail.vue"),
  }),
  "booking-client": defineAsyncComponent({
    loader: () => import("./svgs/booking-client.vue"),
  }),
  "booking-app": defineAsyncComponent({
    loader: () => import("./svgs/booking-app.vue"),
  }),
  "booking-instant": defineAsyncComponent({
    loader: () => import("./svgs/booking-instant.vue"),
  }),
  "custom-process": defineAsyncComponent({
    loader: () => import("./svgs/custom-process.vue"),
  }),
  exclamation: defineAsyncComponent({
    loader: () => import("./svgs/exclamation.vue"),
  }),
  "outgoing-current-stt": defineAsyncComponent({
    loader: () => import("./svgs/outgoing-current-stt.vue"),
  }),
  "incoming-current-stt": defineAsyncComponent({
    loader: () => import("./svgs/incoming-current-stt.vue"),
  }),
  timer: defineAsyncComponent({
    loader: () => import("./svgs/timer.vue"),
  }),
  "input-manifest-dashboard": defineAsyncComponent({
    loader: () => import("./svgs/input-manifest-dashboard.vue"),
  }),
  "invalid-dashboard": defineAsyncComponent({
    loader: () => import("./svgs/invalid-dashboard.vue"),
  }),
  "incoming-outgoing": defineAsyncComponent({
    loader: () => import("./svgs/incoming-outgoing.vue"),
  }),
  backlog: defineAsyncComponent({
    loader: () => import("./svgs/backlog.vue"),
  }),
  hanging: defineAsyncComponent({
    loader: () => import("./svgs/hanging.vue"),
  }),
  whatsapp: defineAsyncComponent({
    loader: () => import("./svgs/whatsapp.vue"),
  }),
  ascending: defineAsyncComponent({
    loader: () => import("./svgs/ascending.vue"),
  }),
  descending: defineAsyncComponent({
    loader: () => import("./svgs/descending.vue"),
  }),
  "stt-paid-unpaid": defineAsyncComponent({
    loader: () => import("./svgs/stt-paid-unpaid.vue"),
  }),
  "progressive-commission": defineAsyncComponent({
    loader: () => import("./svgs/progressive-commission.vue"),
  }),
  "download-new": defineAsyncComponent({
    loader: () => import("./svgs/download-new.vue"),
  }),
  "codrej-dex": defineAsyncComponent({
    loader: () => import("./svgs/codrej-dex.vue"),
  }),
  check: defineAsyncComponent({
    loader: () => import("./svgs/check.vue"),
  }),
  lock: defineAsyncComponent({
    loader: () => import("./svgs/lock.vue"),
  }),
  clock: defineAsyncComponent({
    loader: () => import("./svgs/clock.vue"),
  }),
  "clock-v2": defineAsyncComponent({
    loader: () => import("./svgs/clock-v2.vue"),
  }),
  "clock-v3": defineAsyncComponent({
    loader: () => import("./svgs/clock-v3.vue"),
  }),
  "clock-cargo": defineAsyncComponent({
    loader: () => import("./svgs/clock-cargo.vue"),
  }),
  "plus-circle": defineAsyncComponent({
    loader: () => import("./svgs/plus-circle.vue"),
  }),
  ship: defineAsyncComponent({
    loader: () => import("./svgs/ship.vue"),
  }),
  "bill-add": defineAsyncComponent({
    loader: () => import("./svgs/bill-add.vue"),
  }),
  "claim-plus": defineAsyncComponent({
    loader: () => import("./svgs/claim-plus.vue"),
  }),
  "check-white": defineAsyncComponent({
    loader: () => import("./svgs/check-white.vue"),
  }),
  "exclamation-white": defineAsyncComponent({
    loader: () => import("./svgs/exclamation-white.vue"),
  }),
  "shield-white": defineAsyncComponent({
    loader: () => import("./svgs/shield-white.vue"),
  }),
  "task-white": defineAsyncComponent({
    loader: () => import("./svgs/task-white.vue"),
  }),
  "time-white": defineAsyncComponent({
    loader: () => import("./svgs/time-white.vue"),
  }),
  "x-white": defineAsyncComponent({
    loader: () => import("./svgs/x-white.vue"),
  }),
  "cms-banner": defineAsyncComponent({
    loader: () => import("./svgs/cms-banner.vue"),
  }),
  delete: defineAsyncComponent({
    loader: () => import("./svgs/delete.vue"),
  }),
  track: defineAsyncComponent({
    loader: () => import("./svgs/track.vue"),
  }),
  "cargo-configuration": defineAsyncComponent({
    loader: () => import("./svgs/cargo-configuration.vue"),
  }),
  "plus-square": defineAsyncComponent({
    loader: () => import("./svgs/plus-square.vue"),
  }),
  "plus-o": defineAsyncComponent({
    loader: () => import("./svgs/plus-o.vue")
  }),
  plane: defineAsyncComponent({
    loader: () => import("./svgs/plane.vue"),
  }),
  "trash-concentrated": defineAsyncComponent({
    loader: () => import("./svgs/trash-concentrated.vue"),
  }),
  "warning-circle": defineAsyncComponent({
    loader: () => import("./svgs/warning-circle.vue"),
  }),
  "warning-triangle": defineAsyncComponent({
    loader: () => import("./svgs/warning-triangle.vue"),
  }),
  "pencil-edit": defineAsyncComponent({
    loader: () => import("./svgs/pencil-edit.vue"),
  }),
  "whatsapp-outline": defineAsyncComponent({
    loader: () => import("./svgs/whatsapp-outline.vue"),
  }),
  chain: defineAsyncComponent({
    loader: () => import("./svgs/chain.vue"),
  }),
  refresh: defineAsyncComponent({
    loader: () => import("./svgs/refresh.vue"),
  }),
  "plus-circle-outline": defineAsyncComponent({
    loader: () => import("./svgs/plus-circle-outline.vue"),
  }),
  star: defineAsyncComponent({
    loader: () => import("./svgs/star.vue"),
  }),
  list: defineAsyncComponent({
    loader: () => import("./svgs/list.vue"),
  }),
  "chevron-right": defineAsyncComponent({
    loader: () => import("./svgs/chevron-right.vue"),
  }),
  "ready-to-cargo": defineAsyncComponent({
    loader: () => import("./svgs/ready-to-cargo.vue"),
  }),
  "booking-cargo": defineAsyncComponent({
    loader: () => import("./svgs/booking-cargo.vue"),
  }),
  coupon: defineAsyncComponent({
    loader: () => import("./svgs/coupon.vue"),
  }),
  "badge-success": defineAsyncComponent({
    loader: () => import("./svgs/badge-success.vue"),
  }),
  "badge-failed": defineAsyncComponent({
    loader: () => import("./svgs/badge-failed.vue"),
  }),
  "sto-sc": defineAsyncComponent({
    loader: () => import("./svgs/sto-sc.vue"),
  }),
  "profile-gray": defineAsyncComponent({
    loader: () => import("./svgs/profile-gray.vue"),
  }),
  "location-gray": defineAsyncComponent({
    loader: () => import("./svgs/location-gray.vue"),
  }),
  "send-white": defineAsyncComponent({
    loader: () => import("./svgs/send-white.vue"),
  }),
  "file-upload": defineAsyncComponent({
    loader: () => import("./svgs/file-upload.vue"),
  }),
  "image-upload": defineAsyncComponent({
    loader: () => import("./svgs/image-upload.vue"),
  }),
  "user-info": defineAsyncComponent({
    loader: () => import("./svgs/user-info.vue"),
  }),
  courier: defineAsyncComponent({
    loader: () => import("./svgs/courier.vue"),
  }),
  bars: defineAsyncComponent({
    loader: () => import("./svgs/bars.vue"),
  }),
  cod: defineAsyncComponent({
    loader: () => import("./svgs/cod.vue"),
  }),
  "bullet-point": defineAsyncComponent({
    loader: () => import("./svgs/bullet-point.vue"),
  }),

  calendar: defineAsyncComponent({
    loader: () => import("./svgs/calendar.vue"),
  }),
  "calendar-outlined": defineAsyncComponent({
    loader: () => import("./svgs/calendar-outlined.vue"),
  }),
  cash: defineAsyncComponent({
    loader: () => import("./svgs/cash.vue"),
  }),
  chart: defineAsyncComponent({
    loader: () => import("./svgs/chart.vue"),
  }),
  circle: defineAsyncComponent({
    loader: () => import("./svgs/circle.vue"),
  }),
  close: defineAsyncComponent({
    loader: () => import("./svgs/close.vue"),
  }),
  cube: defineAsyncComponent({
    loader: () => import("./svgs/cube.vue"),
  }),
  "download-document": defineAsyncComponent({
    loader: () => import("./svgs/download-document.vue"),
  }),
  download: defineAsyncComponent({
    loader: () => import("./svgs/download.vue"),
  }),
  error: defineAsyncComponent({
    loader: () => import("./svgs/error.vue"),
  }),
  home: defineAsyncComponent({
    loader: () => import("./svgs/home.vue"),
  }),
  "icon-location-pin": defineAsyncComponent({
    loader: () => import("./svgs/icon-location-pin.vue"),
  }),
  "location-pin": defineAsyncComponent({
    loader: () => import("./svgs/icon-location-pin.vue"),
  }),
  "icon-profile": defineAsyncComponent({
    loader: () => import("./svgs/icon-profile.vue"),
  }),
  invoice: defineAsyncComponent({
    loader: () => import("./svgs/invoice.vue"),
  }),
  logout: defineAsyncComponent({
    loader: () => import("./svgs/logout.vue"),
  }),
  notification: defineAsyncComponent({
    loader: () => import("./svgs/notification.vue"),
  }),
  scan: defineAsyncComponent({
    loader: () => import("./svgs/scan.vue"),
  }),
  topup: defineAsyncComponent({
    loader: () => import("./svgs/topup.vue"),
  }),
  upload: defineAsyncComponent({
    loader: () => import("./svgs/upload.vue"),
  }),
  amercement: defineAsyncComponent({
    loader: () => import("./svgs/amercement.vue"),
  }),
  "balance-notification": defineAsyncComponent({
    loader: () => import("./svgs/balance-notification.vue"),
  }),
  "cod-delivery": defineAsyncComponent({
    loader: () => import("./svgs/cod-delivery.vue"),
  }),
  "cod-reconcile": defineAsyncComponent({
    loader: () => import("./svgs/cod-reconcile.vue"),
  }),
  "cod-report-reconcile": defineAsyncComponent({
    loader: () => import("./svgs/cod-report-reconcile.vue"),
  }),
  "download-underscore": defineAsyncComponent({
    loader: () => import("./svgs/download-underscore.vue"),
  }),
  "home-type": defineAsyncComponent({
    loader: () => import("./svgs/home-type.vue"),
  }),
  coin: defineAsyncComponent({
    loader: () => import("./svgs/coin.vue"),
  }),
  "chevron-left": defineAsyncComponent({
    loader: () => import("./svgs/chevron-left.vue"),
  }),
  "chevron-right-small": defineAsyncComponent({
    loader: () => import("./svgs/chevron-right-small.vue"),
  }),
  office: defineAsyncComponent({
    loader: () => import("./svgs/office.vue"),
  }),
  server: defineAsyncComponent({
    loader: () => import("./svgs/server.vue"),
  }),
  "customer-service": defineAsyncComponent({
    loader: () => import("./svgs/customer-service.vue"),
  }),
  "dex-assessment": defineAsyncComponent({
    loader: () => import("./svgs/dex-assessment.vue"),
  }),
  "notebook-slash": defineAsyncComponent({
    loader: () => import("./svgs/notebook-slash.vue"),
  }),
  notebook: defineAsyncComponent({
    loader: () => import("./svgs/notebook.vue"),
  }),
  "polar-area-chart": defineAsyncComponent({
    loader: () => import("./svgs/polar-area-chart.vue"),
  }),
  "chevron-up": defineAsyncComponent({
    loader: () => import("./svgs/chevron-up.vue"),
  }),
  "chevron-down": defineAsyncComponent({
    loader: () => import("./svgs/chevron-down.vue"),
  }),
  "ticket-submission": defineAsyncComponent({
    loader: () => import("./svgs/ticket-submission.vue"),
  }),
  shopify: defineAsyncComponent({
    loader: () => import("./svgs/shopify.vue"),
  }),
  "shopify-active": defineAsyncComponent({
    loader: () => import("./svgs/shopify-active.vue"),
  }),
  user: defineAsyncComponent({
    loader: () => import("./svgs/user.vue"),
  }),
  maps: defineAsyncComponent({
    loader: () => import("./svgs/maps.vue"),
  }),
  "maps-v2": defineAsyncComponent({
    loader: () => import("./svgs/maps-v2.vue"),
  }),
  integration: defineAsyncComponent({
    loader: () => import("./svgs/integration.vue"),
  }),
  "success-status": defineAsyncComponent({
    loader: () => import("./svgs/success-status.vue"),
  }),
  "box-opened": defineAsyncComponent({
    loader: () => import("./svgs/box-opened.vue"),
  }),
  "icon-detail-sender": defineAsyncComponent({
    loader: () => import("./svgs/icon-detail-sender.vue"),
  }),
  "icon-partner": defineAsyncComponent({
    loader: () => import("./svgs/icon-partner.vue"),
  }),
  "icon-profile-fill": defineAsyncComponent({
    loader: () => import("./svgs/icon-profile-fill.vue"),
  }),
  "icon-money-fill": defineAsyncComponent({
    loader: () => import("./svgs/icon-money-fill.vue"),
  }),
  "check-green-fill": defineAsyncComponent({
    loader: () => import("./svgs/check-green-fill.vue"),
  }),
  "warning-circle-fill": defineAsyncComponent({
    loader: () => import("./svgs/warning-circle-fill.vue"),
  }),
  "close-circle-fill": defineAsyncComponent({
    loader: () => import("./svgs/close-circle-fill.vue"),
  }),
  "important-file": defineAsyncComponent({
    loader: () => import("./svgs/important-file.vue"),
  }),
  "plus-icon": defineAsyncComponent({
    loader: () => import("./svgs/plus-icon.vue"),
  }),
  plus: defineAsyncComponent({
    loader: () => import("./svgs/plus-icon.vue"),
  }),
  "bulk-upload": defineAsyncComponent({
    loader: () => import("./svgs/bulk-upload.vue"),
  }),
  save: defineAsyncComponent({
    loader: () => import("./svgs/save.vue"),
  }),
  "clipboard-check": defineAsyncComponent({
    loader: () => import("./svgs/clipboard-check.vue")
  }),
  archive: defineAsyncComponent({
    loader: () => import("./svgs/archive.vue")
  }),
  "download-overlay": defineAsyncComponent({
    loader: () => import("./svgs/download-overlay.vue")
  }),
  codrej: defineAsyncComponent({
    loader: () => import("./svgs/codrej.vue"),
  }),
  "icon-withdraw": defineAsyncComponent({
    loader: () => import("./svgs/icon-withdraw.vue")
  }),
  "icon-cod-amount": defineAsyncComponent({
    loader: () => import("./svgs/icon-cod-amount.vue")
  }),
  "weight-kg": defineAsyncComponent({
    loader: () => import("./svgs/weight-kg.vue")
  })
};

export default class LPIcons extends Vue.with(Props) {
  get iconComponent() {
    return icons[this.name];
  }
}
