import { InsuranceApiRepository } from "@/app/infrastructures/repositories/api/InsuranceApiRepository";
import { EditInsuranceRequestInteface } from "@/data/payload/contracts/InsuranceRequest";
import { DetailInsurance, Insurance } from "@/domain/entities/Insurance";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class InsurancePresenter {
  private repository: InsuranceApiRepository;

  constructor(repo: InsuranceApiRepository) {
    this.repository = repo;
  }

  public async getDetailInsurance(id: number): Promise<DetailInsurance> {
    return await this.repository.getDetailInsurance(id);
  }

  public async editInsurance(
    payload: EditInsuranceRequestInteface
  ): Promise<ResponsePayload> {
    return await this.repository.editInsurance(payload);
  }

  public async getInsurance(params: ApiRequestList): Promise<Insurance> {
    return await this.repository.getInsurance(params);
  }
}
