import {
  DetailClientManagement,
  ClientCurrentRate,
  ClientRenewalRate,
  ClientManagementData,
  ClientManagementEntities,
  ClientCod,
  ClientCodAsDefault,
  ClientLocation,
  ClientTax,
  ClientBilling,
  BankDetail,
} from '@/domain/entities/ClientManagement';
import {
  clientCodConfigAmountData,
  clientPaymentMethodData,
} from '@/domain/entities/ClientRegistration';
import { OptionsClass } from '@/domain/entities/MainApp';
import { Pagination } from '@/domain/entities/Pagination';
import { ResponsePayload } from '@/domain/entities/ResponsePayload';
import { AxiosResponse } from 'axios';
import startCase from 'lodash/startCase';
import { ConfigBucketStorage } from '@/feature-flags/flags-config-bucket-storage';
import parsingLinkGcpToAws from '@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws';

export class ClientManagementMapper {
  public convertClientManagementFromApi(
    result: AxiosResponse<any>,
  ): ClientManagementEntities {
    const { data } = result;
    return new ClientManagementEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data.length > 0
        ? data.data.map((item: any) => {
            return new ClientManagementData({
              clientId: item.client_id,
              clientAlias: item.client_alias,
              clientCompanyName: item.client_company_name,
              clientCode: item.client_code,
              rateVersionStartDate: item.rate_version_start_date,
              rateVersionEndDate: item.rate_version_end_date,
              rateVersionStatus: item.is_banned
                ? 'freeze'
                : item.rate_version_status,
              rateVersionStatusRenewal: item.rate_version_status_renewal,
              clientType: item.type,
              clientStatus: item.is_banned ? 'freeze' : 'active',
            });
          })
        : [],
    );
  }

  public convertDetailClientManagementFromApi(
    result: AxiosResponse<any>,
    version = 'v1',
  ): DetailClientManagement {
    const {
      data: { data },
    } = result;
    const isV1 = version === 'v1';

    const clientData = {
      ...this.mapBasicClientData(data, isV1),
      clientDistrict: this.createClientLocation(data, 'district'),
      clientCity: this.createClientLocation(data, 'city'),
      clientCurrentRate: this.createClientCurrentRate(data),
      clientCodReject: this.createClientCod(data),
      clientCodRejectAsDefault: this.createClientCodRejectAsDefault(data),
      clientBilling: this.createClientBilling(data),
      bankSettlement: this.createBankSettlement(data),
      clientRenewalRate: this.createClientRenewalRate(data),
      clientTax: this.createClientTax(data, isV1),
      clientPaymentMethod:
        clientPaymentMethodData.find(
          (payment: OptionsClass) =>
            payment.value === data.client_payment_method,
        ) || new OptionsClass(),
      clientCodConfigAmount:
        clientCodConfigAmountData.find(
          (cod: OptionsClass) => cod.value === data.client_cod_config_amount,
        ) || new OptionsClass(),
      clientPadFeePercentage: String(data.client_pad_fee).replaceAll('.', ','),
      clientCodShipmentDiscountPercentage: String(
        data.client_cod_shipment_discount,
      ).replaceAll('.', ','),
      ...this.createOtherDetailInfo(data),
    };

    return new DetailClientManagement(clientData);
  }

  // Basic property mapping to streamline property assignment
  private mapBasicClientData(data: any, isV1: boolean) {
    const mappings = {
      id: data.client_id,
      clientName: data.client_name,
      clientAlias: data.client_alias,
      clientCode: data.client_code,
      clientCompanySize: startCase(data.client_company_size),
      clientType: data.client_type,
      clientBalance: data.client_balance,
      clientCategory: data.client_category,
      clientIsBanned: data.client_is_banned,
      clientBannedReason: data.client_is_banned_reason,
      clientAccountName: data.client_beneficiary_account_name,
      clientAccountNumber: data.client_beneficiary_account_number,
      clientBankName: data.client_beneficiary_bank_name,
      clientBeneficiaryEmail: data.client_beneficiary_email,
      clientContactEmail: data.client_contact_email,
      clientContactJobTitle: data.client_contact_job_title,
      clientContactName: data.client_contact_name,
      clientContactPhone: data.client_contact_phone,
      clientIsCod: data.client_is_cod,
      clientIsDfod: data.client_is_dfod,
      clientIsDo: data.client_is_do,
      clientParentName: data.client_parent_name,
      clientParentId: data.client_parent_id,
      clientParentCode: data.client_parent_code,
      clientPartnerCommissionAmount: data.client_partner_commission_amount,
      clientPartnerCommissionAmountPercentage: String(
        data.client_partner_commission_amount,
      ).replaceAll('.', ','),
      clientPartnerId: data.client_partner_id,
      clientPartnerName: data.client_partner_name,
      clientPhone: data.client_phone,
      clientEmail: data.client_email,
      clientStatus: data.client_status,
      clientFreeze: data.client_is_banned,
      clientAddress: data.client_address,
      clientTaxNumber: isV1
        ? data.client_tax_number
        : data.client_tax_number.replaceAll('.', '').replaceAll('-', ''),
      clientCreatedAt: data.client_created_at,
      clientUpdatedAt: data.client_updated_at,
      clientCreatedBy: data.client_created_by,
      clientUpdatedBy: data.client_updated_by,
      clientPartnerQuantifierCommission:
        data.client_partner_quantifier_commission,
      clientPartnerNumeratorCommission:
        data.client_partner_numerator_commission,
      clientOdooId: data.client_odoo_id,
      isFtz: data.is_ftz,
      clientIsNeedAssessment: data.client_is_need_assessment,
      clientParentIsNeedAssessment: data.client_parent_is_need_assessment,
    };
    return mappings;
  }

  private createOtherDetailInfo(data: any) {
    const mappings = {
      clientPadFee: this.getClientPadFee(data),
      rateActiveDate: data.rate_active_date,
      clientCodShipmentDiscount: data.client_cod_shipment_discount,
      clientBranchDoPaymentType: data.client_branch_do_payment_type,
      isReturnConfig: this.getIsReturnConfig(data),
      returnConfigLimit: this.getReturnConfigLimit(data),
      returnConfigReasonIds: this.getReturnConfigReasonIds(data),
      priceType: data.price_type,
      rateVersionCustom3lc: data.rate_version_custom_3lc,
      insuranceType: this.getInsuranceType(data),
      insuranceValuePercentage: this.getInsuranceValuePercentage(data),
      dataProcessedCurrent: data.data_processed_current,
      dataProcessedRenewal: data.data_processed_renewal,
      totalDataCurrent: data.total_data_current,
      totalDataRenewal: data.total_data_renewal,
    };
    return mappings;
  }

  private getClientPadFee(data: any) {
    return data.client_pad_fee || 0;
  }

  private getIsReturnConfig(data: any) {
    return data.client_meta?.is_return_config || false;
  }

  private getReturnConfigLimit(data: any) {
    return data.client_meta?.return_config_limit || 0;
  }

  private getReturnConfigReasonIds(data: any) {
    return data.client_meta?.return_config_reason_ids || '';
  }

  private getInsuranceType(data: any) {
    return data.client_meta?.insurance_type || '';
  }

  private getInsuranceValuePercentage(data: any) {
    return data.client_meta?.insurance_value_percentage || '';
  }
  // Helper functions for more complex property objects
  private createClientLocation(data: any, type: 'district' | 'city') {
    return new ClientLocation({
      id: data[`client_${type}_id`],
      code: data[`client_${type}_code`],
      name: data[`client_${type}_name`],
      cityCode: data.client_city_code,
    });
  }

  private createClientCurrentRate(data: any) {
    if (!data.client_current_rate) return new ClientCurrentRate();
    return new ClientCurrentRate({
      clientCustomRateId: data.client_current_rate.client_custom_rate_id,
      clientStatusCustomRate:
        data.client_current_rate.client_status_custom_rate,
      clientRateversionStartDate:
        data.client_current_rate.client_rate_version_start_date,
      clientRateVersionEndDate:
        data.client_current_rate.client_rate_version_end_date,
      clientDiscountBaseRate: Number(
        data.client_current_rate.client_discount_base_rate,
      ).toLocaleString('id'),
      isBaseRate: !!data.client_current_rate.client_file_name_base_rate,
      isCustomRate: !!data.client_current_rate.client_file_name_custom_rate,
      clientRateInsuranceType:
        data.client_current_rate.client_rate_insurance_type || '',
      clientStatusUpdatedAt: data.client_current_rate.client_status_updated_at,
      clientStatusUpdatedBy: data.client_current_rate.client_status_updated_by,
      clientRateInsuranceValuePercentage: data.client_current_rate.client_rate_insurance_value_percentage,
      clientRatePriceType: data.client_current_rate.client_rate_price_type,
      clientVersionCustomRate:
        data.client_current_rate.client_version_custom_rate,
      clientVersionBaseRate: data.client_current_rate.client_version_base_rate
    });
  }

  private createClientCod(data: any) {
    return new ClientCod({
      clientCodName: data.client_branch_cod_return_receiver_name,
      clientCodAddress: data.client_branch_cod_return_receiver_address,
      clientCodDistrict:
        data.client_branch_cod_return_receiver_district_id === 0
          ? ''
          : {
              id: data.client_branch_cod_return_receiver_district_id,
              code: data.client_branch_cod_return_receiver_district_code,
              name: data.client_branch_cod_return_receiver_district_name,
              cityCode: data.client_branch_cod_return_receiver_city_code,
            },
      clientCodCity: data.client_branch_cod_return_receiver_city_name,
      clientCodPhone: data.client_branch_cod_return_receiver_phone,
      clientCodCommission: data.client_branch_cod_fee,
      clientCodCommissionPercentage: String(
        data.client_branch_cod_fee,
      ).replaceAll('.', ','),
    });
  }

  private createClientCodRejectAsDefault(data: any) {
    return new ClientCodAsDefault({
      defaultCodName: data.client_branch_cod_return_receiver_name,
      defaultCodAddress: data.client_branch_cod_return_receiver_address,
      defaultCodDistrict:
        data.client_branch_cod_return_receiver_district_id === 0
          ? ''
          : {
              id: data.client_branch_cod_return_receiver_district_id,
              code: data.client_branch_cod_return_receiver_district_code,
              name: data.client_branch_cod_return_receiver_district_name,
              cityCode: data.client_branch_cod_return_receiver_city_code,
            },
      defaultCodCity: data.client_branch_cod_return_receiver_city_name,
      defaultCodPhone: data.client_branch_cod_return_receiver_phone,
    });
  }

  private createClientBilling(data: any) {
    return new ClientBilling({
      noWhatsApp: data.client_billing_wa_number.startsWith('0')
        ? data.client_billing_wa_number.substr(1)
        : data.client_billing_wa_number.substr(2),
      billingSchedule: data.client_billing_schedule_so.toLowerCase(),
      timeRangePayment: data.client_billing_payment_period.replace(' Days', ''),
      email: data.client_billing_email
        ? data.client_billing_email.split(',')
        : [],
      startDate: data.client_contract_start_date,
      endDate: data.client_contract_end_date,
      attachmentUrl: parsingLinkGcpToAws(data.client_contract_attachment_url),
    });
  }

  private createBankSettlement(data: any) {
    return new BankDetail({
      bankLabel: `VA ${startCase(data.client_va_bank_name)}`,
      bankName: data.client_va_bank_name,
      accountNo: data.client_va_bank_number,
      accountName: data.client_va_bank_account_name,
      logo: `${ConfigBucketStorage.config_bucket_bank_logo.getValue()}${
        data.client_va_bank_name
      }.png`,
    });
  }

  private createClientRenewalRate(data: any) {
    return data.client_renewal_rate
      ? new ClientRenewalRate({
          clientCustomRateId: data.client_renewal_rate.client_custom_rate_id,
          clientStatusCustomRate:
            data.client_renewal_rate.client_status_custom_rate,
          clientDiscountBaseRate: Number(
            data.client_renewal_rate.client_discount_base_rate,
          ).toLocaleString('id'),
          clientStatusUpdatedAt:
            data.client_renewal_rate?.client_status_updated_at,
          clientStatusUpdatedBy:
            data.client_renewal_rate?.client_status_updated_by,
          clientRateversionStartDate:
            data.client_renewal_rate?.client_rate_version_start_date,
          clientRateVersionEndDate:
            data.client_renewal_rate?.client_rate_version_end_date,
          clientStatusUpdatedReason:
            data.client_renewal_rate?.client_status_updated_reason,
          clientVersionBaseRate:
            data.client_renewal_rate?.client_version_base_rate,
          ...this.mapperClientRenewalData(data),
          ...this.mapperOtherClientRenewalData(data)
        })
      : new ClientRenewalRate();
  }

  private mapperClientRenewalData(data: any) {
    return {
      clientFileNameBaseRate:
        data.client_renewal_rate?.client_file_name_base_rate,
      clientFileBaseRate: data.client_renewal_rate?.client_file_base_rate,
      clientVersionCustomRate:
        data.client_renewal_rate?.client_version_custom_rate,
      clientFileNameCustomRate:
        data.client_renewal_rate?.client_file_name_custom_rate,
      clientFileCustomRate: data.client_renewal_rate?.client_file_custom_rate,
      clientErrorNameCustomRate:
        data.client_renewal_rate?.client_error_name_custom_rate,
      clientErrorFileCustomRate:
        data.client_renewal_rate?.client_error_file_custom_rate,
      clientRateVersionCustom3lc:
        data.client_renewal_rate.client_rate_version_custom_3lc,
      clientRatePriceType: data.client_renewal_rate.client_rate_price_type,
    };
  }

  private mapperOtherClientRenewalData(data: any) {
    return {
      clientRateInsuranceType:
        data.client_renewal_rate.client_rate_insurance_type || '',
      clientRateInsuranceValuePercentage:
        data.client_renewal_rate.client_rate_insurance_value_percentage || 0,
    };
  }

  private createClientTax(data: any, isV1: boolean) {
    return new ClientTax({
      NIK: data.client_nik_id,
      NIKName: data.client_nik_name,
      NIKAddress: data.client_nik_address,
      isTaxPayer: data.client_efaktur_with_nik,
      taxNumber: isV1
        ? data.client_tax_number
        : data.client_tax_number.replaceAll('.', '').replaceAll('-', ''),
      taxPayerAddress: data.client_taxpayer_address,
      taxPayerName: data.client_taxpayer_name,
    });
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
    });
  }
}
