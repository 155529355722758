
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { PickupScheduleController } from "@/app/ui/controllers/PickupScheduleController";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { PickupCorporateAddress } from "@/domain/entities/PickupSchedule";
import { Options, Vue, prop } from "vue-class-component";

class Props {
  isInternal = prop<boolean>({
    default: false,
    type: Boolean,
  });
  isEdit = prop<boolean>({
    default: false,
    type: Boolean,
  });
  clientId = prop<number>({
    default: 0,
    type: Number,
  });
}

@Options({
  emits: [
    "onAddressListUpdated",
    "onClickPilihAlamatLain",
    "onClickTambahAlamat",
  ],
})
export default class PickupCorporateAddressSection extends Vue.with(Props) {
  mounted(): void {
    if (this.isInternal ? this.isEdit : true) {
      this.getListPickupCorporateAddress();
    }
  }

  get listPickupCorporateAddress(): PickupCorporateAddress[] {
    return PickupScheduleController.listPickupCorporateAddress;
  }
  get selectedAddress(): PickupCorporateAddress {
    return PickupScheduleController.selectedAddress;
  }
  async getListPickupCorporateAddress() {
    await PickupScheduleController.getListPickupCorporateAddress(
      new ApiRequestList({
        clientId: this.isInternal ? this.clientId : undefined,
        page: 1,
        limit: 50,
        isTotalData: false,
        selectAddress: !this.isEdit,
      })
    );
    this.$emit(
      "onAddressListUpdated",
      PickupScheduleController.listPickupCorporateAddress
    );
  }

  get loading(): boolean {
    return MainAppController.loadingModal;
  }

  get id() {
    return this.$route.params.id;
  }
}
