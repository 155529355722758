const CalculateTimeDifference = (startDate: string, endDate: string) => {
  const isInvalidDate = (date: string) => !date || isNaN(new Date(date).getTime());

  if (isInvalidDate(startDate) || isInvalidDate(endDate)) {
    return '';
  }

  const start: Date = new Date(startDate);
  const end: Date = new Date(endDate);

  if (start >= end) {
    return '';
  }

  const diffInMs = end.getTime() - start.getTime();
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;

  return `${hours} jam ${minutes} menit`;
};

export default CalculateTimeDifference;
