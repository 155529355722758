import { FeedbackApiRepository } from "@/app/infrastructures/repositories/api/FeedbackApiRepository";
import { SubmitFeedbackApiRequest } from "@/data/payload/api/FeedbackApiRequest";
import { FeedbackRepositoryInterface } from "@/data/persistences/repositories/contracts/FeedbackRepositoryInterface";
import { injectable } from "tsyringe";

@injectable()
export class FeedbackPresenter implements FeedbackRepositoryInterface {
  private repository: FeedbackApiRepository;

  constructor(repo: FeedbackApiRepository) {
    this.repository = repo;
  }

  submitFeedback(payload: SubmitFeedbackApiRequest): Promise<boolean> {
    return this.repository.submitFeedback(payload);
  }
}
