import { Insurance } from "@/domain/entities/Insurance";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import { convertDecimalWithComma } from "@/app/infrastructures/misc/Utils";

export const maxLengthWeight = (value: any) => {
  if (Number(value) >= 150) return 3;
  else if (Number(value.split(",")[0]) < 10) return 4;
  else if (Number(value.split(",")[0]) < 100) return 5;
  return 6;
};

export const bannerInfo = (data: Insurance): any => ({
  exceeds5Million: `<p><strong>Harga barang</strong> melebihi <strong>5 juta, asuransi otomatis terpilih. Agen dapat tambahan income ${formatPrice(
    data.commisionPos
  )} </strong> & customer dapat proteksi hingga <strong>150 Juta!</strong></p>`,
  deliveryNonInsurance: `<p><strong>Agen bisa dapat tambahan income ${convertDecimalWithComma(
    data.viewCommissionPos,
    2
  )}% dari premi,</strong> Yuk tawarkan customer pakai <strong>asuransi</strong> agar barang terproteksi <strong>150 Juta!</strong></p>`,
  deliveryInsurance: `<p><strong>Agen bisa dapat tambahan income ${formatPrice(
    data.commisionPos
  )} dari premi,</strong> Yuk tawarkan customer pakai <strong>asuransi seharga ${formatPrice(
    data.insuranceCost + data.insuranceAdminFee
  )}</strong> agar barang terproteksi <strong>150 Juta!</strong></p>`,
  codOrDfod: `<p><strong>Pengiriman COD sudah tercover asuransi!</strong> Barang customer terproteksi asuransi hingga <strong>150 Juta!</strong></p>`
});
