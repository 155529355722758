
export class RequestPriorityDeliveryEndpoint {
    createRequestPriorityDelivery(): string {
        return "/hydra/v1/request-priority-delivery";
    }

    getDetailRequestPriorityDelivery(id: any): string {
        return `/hydra/v1/request-priority-delivery/stt_no/${id}`
    }

    cancelRequestPriorityDelivery(): string {
        return "/hydra/v1/request-priority-delivery/cancel";
    }
}