import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import { Pagination } from "./Pagination";
export class PusatResolusiCardData {
  salesForId = "";
  sttNo = "";
  title = "";
  message = "";
  posName = "";
  createdAt = "";
  createdBy = "";
  updatedAt = "";
  updatedBy = "";
  status = "";
  isRead = false;
  attachmentImage: Array<string> = [];
  attachmentFile: Array<any> = [];
  constructor(fields?: Partial<PusatResolusiCardData>) {
    Object.assign(this, fields);
  }
}

export class PusatResolusiThreadData {
  id = 0;
  actorName = "";
  actorType = "";
  text = "";
  attachmentImage = [] as string[];
  attachmentFile = [] as { name: string; url: string }[];
  comments = [] as PusatResolusiCommentData[];
  createdAt = "";
  isExpand = false;
  constructor(fields?: Partial<PusatResolusiThreadData>) {
    Object.assign(this, fields);
  }
}

export class PusatResolusiCommentData {
  id = 0;
  actorName = "";
  actorType = "";
  createdAt = "";
  text = "";
  constructor(fields?: Partial<PusatResolusiCommentData>) {
    Object.assign(this, fields);
  }
}

export class PusatResolusiList {
  pagination: Pagination;
  data: PusatResolusiListData[];
  constructor(pagination: Pagination, data: PusatResolusiListData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class PusatResolusiListData {
  rcId = 0;
  rcTicketNoGenesis = "";
  rcTicketNo = "";
  rcSttNo = "";
  rcShipmentNo = "";
  rcCategory = "";
  rcStatus = "";
  newMessage = false;
  rcCreatedAt = "";
  rcCreatedName = "";
  rcCreatedActorName = "";
  rcUpdatedAt = "";
  rcUpdatedName = "";
  rcUpdatedActorName = "";
  rcCaseNumber = "";
  rcCaseOwner = "";
  
  constructor(fields?: Partial<PusatResolusiListData>) {
    Object.assign(this, fields);
  }

  get createdAt(): string {
    return formatDate(this.rcCreatedAt);
  }

  get updatedAt(): string {
    return formatDate(this.rcUpdatedAt);
  }
}

export class PusatResolusiStatusData {
  data: PusatResolusiStatus[];
  constructor(data: PusatResolusiStatus[]) {
    this.data = data;
  }
}

export class PusatResolusiStatus {
  status = "";
  total = "";
  constructor(fields?: Partial<PusatResolusiStatus>) {
    Object.assign(this, fields);
  }
}

export class PusatResolusiCategoryData {
  data: PusatResolusiCategory[];
  constructor(data: PusatResolusiCategory[]) {
    this.data = data;
  }
}

export class PusatResolusiCategory {
  value = "";
  name = "";
  categoryType = "";
  constructor(fields?: Partial<PusatResolusiCategory>) {
    Object.assign(this, fields);
  }
}

export class CheckValidationData {
  sttNo = "";
  isValid = false;
  reason = "";
  constructor(fields?: Partial<CheckValidationData>) {
    Object.assign(this, fields);
  }
}

export class PusatResolusiModalStatusData {
  ticketNoGenesis = "";
  ticketNoSf = "";
  sttOrShipmentNo = "";
  category = "";
  status = "";
  senderName = "";
  senderPhoneNumber = "";
  senderAddress = "";
  recipientName = "";
  recipientPhoneNumber = "";
  recipientAddress = "";
  caseNumber = "";
  caseOwner = "";
  statusHistory = [] as StatusHistoryPusatResolusi[];
  constructor(fields?: Partial<PusatResolusiModalStatusData>) {
    Object.assign(this, fields);
  }
}

export class StatusHistoryPusatResolusi {
  statusHistoryDescription = "";
  statusHistoryCreatedAt = ""; 
  constructor(fields?: Partial<StatusHistoryPusatResolusi>) {
    Object.assign(this, fields);
  }
}

export class RequestCreatePusatResolusi {
  category = "";
  sttOrShipment = "";
  description = "";
  imageFile1 = "";
  imageFile2 = "";
  imageFile3 = "";
  attachment1 = "";
  attachment2 = "";
  constructor(fields?: Partial<RequestCreatePusatResolusi>) {
    Object.assign(this, fields);
  }
}

export class DetailPusatResolusi {
  rcId = 0;
  rcTicketNoGenesis = "";
  rcTicketNo = "";
  rcSttNo = "";
  rcCategory = "";
  rcStatus = "";
  rcDescription = "";
  rcImageFiles: string[] = [];
  rcAttachment: string[] = [];
  rcCreatedAt = "";
  rcCreatedName = "";
  rcUpdatedAt = "";
  rcUpdatedName = "";
  rcCreatedActorName = "";
  rcUpdatedActorName = "";
  rcCaseNumber = "";
  rcCaseOwner = "";

  constructor(fields?: Partial<DetailPusatResolusi>) {
    Object.assign(this, fields);
  }

  get createdAt(): string {
    return formatDate(this.rcCreatedAt);
  }

  get updatedAt(): string {
    return formatDate(this.rcUpdatedAt);
  }
}

export class RequestCreateForumPusatResolusi {
  caseId = "";
  message = "";
  "imageFile_1" = "";
  "imageFile_2" = "";
  "imageFile_3" = "";
  "attachment_1" = "";
  "attachment_2" = "";
  constructor(fields?: Partial<RequestCreateForumPusatResolusi>) {
    Object.assign(this, fields);
  }
}


export class DetailForumPusatResolusi {
  dfId = 0;
  dfDescription = "";
  dfImageFiles: string[] = [];
  dfAttachment: string[] = [];
  dfCreatedAt = "";
  dfCreatedBy = "";
  dfCreatedName = "";
  dfCreatedActorId = "";
  dfUpdatedActorRole = "";
  dfCreatedActorName = "";
  isUndread = false;

  constructor(fields?: Partial<DetailForumPusatResolusi>) {
    Object.assign(this, fields);
  }

  get createdAt(): string {
    return formatDate(this.dfCreatedAt);
  }
}

export class ForumPusatResolusiList {
  data: DetailForumPusatResolusi[];
  constructor(data: DetailForumPusatResolusi[]) {
    this.data = data;
  }
}
