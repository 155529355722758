
import {
  convertDecimal,
  convertDecimalWithComma,
  downloadCsv,
  formatDateNormal,
  getDataLocalStorage,
  parsingErrorResponse,
  removeDataLocalStorage,
  storeDatatoLocalStorage
} from "@/app/infrastructures/misc/Utils";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { STIController } from "@/app/ui/controllers/STIController";
import {
  STIReverseScanTemporaryListRequest,
  GenerateSTIReverseScanRequest,
  STISTTDueSummaryListRequest,
  STIReverseScanManifestRequest
} from "@/data/payload/api/StiApiRequest";
import { ModalMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import {
  STIReverseScanGenerate,
  STIReverseScanGenerateData,
  STIReverseScanTemporary,
  STIReverseScanTemporaryMeta,
  STIScanFailed,
  STISTTDueSummary
} from "@/domain/entities/STI";
import { debounce } from "lodash";
import { Options, Vue } from "vue-class-component";
import STIReverseScanIllustration from "../component/scan-illustration/index.vue";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { Data } from "@vue/composition-api";
import SummaryCard from "../component/summary-card/index.vue";
import ScanFailedCard from "../component/scan-failed-card/index.vue";

@Options({
  components: {
    STIReverseScanIllustration,
    Skeleton,
    SummaryCard,
    ScanFailedCard
  }
})
export default class STIUpdateReverseScan extends Vue {
  pagination = {
    page: 1,
    limit: 50
  };
  controller = STIController;
  form = {
    sttNumber: "",
    statusReturn: new OptionsClass(),
    stiDestFilter: false,
    search: "",
    booked: new OptionsClass() as any
  };

  illustrationData = {
    flag: "",
    isSTT: true,
    regionName: "",
    isStiDest: false,
    isInvalid: false,
    isEmpty: true,
    sttNo: "",
    isUnderpaid: false,
    type: "STT" as "STT" | "BAG",
    isDangerousGoods: false,
    isRelabel: false
  };

  loadingIllustration = false;

  isConfirmManifestModal = false;

  refs?: Data = undefined;

  bookedListOptions: OptionsClass[] = [];

  filterOptions = [
    new OptionsClass({
      name: "Semua",
      value: ""
    }),
    new OptionsClass({
      name: "Segera POD",
      value: "Segera POD"
    }),
    new OptionsClass({
      name: "Segera RTS",
      value: "Segera RTS"
    }),
    new OptionsClass({
      name: "Segera HAL",
      value: "Segera HAL"
    })
  ];

  mounted(): void {
    this.fetchTemporaryList();
    this.controller.setScanFailedData([]);
    const stiScanFailedList = JSON.parse(
      getDataLocalStorage("sti-scan-failed")
    ) as STIScanFailed[];

    this.controller.setScanFailedData(stiScanFailedList || []);
  }

  get filterButtonStyle() {
    return this.form.stiDestFilter
      ? {
          textColor: "red-lp-300",
          customClass: "w-14 bg-red-lp-1300",
          color: "red-lp-300"
        }
      : {
          textColor: "gray-lp-500",
          customClass: "w-14",
          color: ""
        };
  }

  get columns() {
    return [
      ...this.columnsFirstSection,
      ...this.columnSecondSection,
      ...this.columnThirdSection
    ];
  }

  get columnsFirstSection() {
    return [
      {
        name: "No.",
        styleHead: "text-left w-12",
        render: (item: STIReverseScanTemporary, index: number) => {
          const number =
            index === 0
              ? this.controller.STIReverseScanTemporaryList.length
              : this.controller.STIReverseScanTemporaryList.length - index;
          return `<div class="text-sm text-black-lp-300 text-left ${this.getRowStyle(
            item.stMeta.statusReturn
          )} ${
            index === 0 && this.pagination.page === 1 ? "highlight" : ""
          }">${number -
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. Bag",
        styleHead: "text-left w-40",
        render: (item: STIReverseScanTemporary) =>
          `<div class="text-sm text-black-lp-300 text-left ${this.getRowStyle(
            item.stMeta.statusReturn
          )}">
            ${item.stMeta.bagNo || "-"}
          </div>`
      },
      {
        name: "No. STT",
        styleHead: "text-left w-40",
        render: (item: STIReverseScanTemporary) =>
          `<div class="text-sm text-black-lp-300 text-left ${this.getRowStyle(
            item.stMeta.statusReturn
          )}">
            ${item.stSttNo || "-"}
            ${
              item.stMeta.sttNeedToRelabel
                ? '<div class="relabel-chips whitespace-nowrap">Perlu relabel</div>'
                : ""
            }
            ${
              !item.stMeta.isPaid
                ? '<div class="unpaid-chips">Kurang bayar</div>'
                : ""
            }
            ${
              item.stMeta.sttAssessmentStatus === "waiting" ||
              item.stMeta.sttAssessmentStatus === "rejected"
                ? '<div class="unpaid-chips">STT perlu diadjust</div>'
                : ""
            }
          </div>`
      }
    ];
  }

  get columnSecondSection() {
    return [
      {
        name: "No. Referensi",
        styleHead: "text-left w-40",
        render: (item: STIReverseScanTemporary) =>
          `<div class="text-sm text-black-lp-300 text-left break-all ${this.getRowStyle(
            item.stMeta.statusReturn
          )}">
            ${item.stMeta.refNo || "-"}
          </div>`
      },
      {
        name: "STI - DEST",
        styleHead: "text-left w-36",
        render: (item: STIReverseScanTemporary) =>
          `<div class="text-sm text-black-lp-300 text-left ${this.getRowStyle(
            item.stMeta.statusReturn
          )}">
            ${`<img src=${require(`@/app/ui/assets/svg/${
              item.stMeta.isStiDest === 1
                ? "check-circle.svg"
                : "close-circle.svg"
            }`)}`} width="24" height="24" />
          </div>`
      },
      {
        name: "Penanda Paket",
        styleHead: "text-left w-36",
        render: (item: STIReverseScanTemporary) =>
          `<div class="text-sm text-black-lp-300 text-left ${this.getRowStyle(
            item.stMeta.statusReturn
          )}">
            ${item.stMeta.statusReturn || "-"}
          </div>`
      }
    ];
  }

  get columnThirdSection() {
    return [
      {
        name: "Jenis Pengiriman",
        styleHead: "text-left w-44",
        render: (item: STIReverseScanTemporary) =>
          `<div class="text-sm text-black-lp-300 text-left ${this.getRowStyle(
            item.stMeta.statusReturn
          )}">
            <div class="py-0.5 px-2.5 rounded-md bg-white">
              ${item.stProduct || "-"}
            </div>
          </div>`
      },
      {
        name: "Nama Partner",
        styleHead: "text-left w-44",
        render: (item: STIReverseScanTemporary) =>
          `<div class="text-sm text-black-lp-300 text-left break-all ${this.getRowStyle(
            item.stMeta.statusReturn
          )}">
            ${item.stBookedName || "-"}
          </div>`
      },
      {
        name: "Batas POD",
        styleHead: "text-left w-40",
        render: (item: STIReverseScanTemporary) =>
          `<div class="text-sm text-black-lp-300 text-left ${this.getRowStyle(
            item.stMeta.statusReturn
          )}">
            ${formatDateNormal(item.stDeadlineReturn, "dddd, DD MMMM YYYY")}
          </div>`
      }
    ];
  }

  get totalStt() {
    return this.controller.STIReverseScanTemporaryList.length;
  }

  get calculatedStt() {
    return this.controller.STIReverseScanTemporaryList.reduce(
      (a: any, b: any) => {
        return {
          sttTotalPiece: a.sttTotalPiece + b.stMeta.pieces,
          sttGrossWeight: a.sttGrossWeight + b.stMeta.grossWeight
        };
      },
      {
        sttTotalPiece: 0,
        sttGrossWeight: 0
      }
    );
  }

  // convert value decimal
  isValueDecimal(value: any) {
    return convertDecimal(value);
  }

  // format total kg
  convertTotalKg(value: any) {
    return convertDecimalWithComma(value, 2);
  }

  get totalPieces() {
    return this.calculatedStt.sttTotalPiece;
  }
  get totalGrossWeight() {
    return this.calculatedStt.sttGrossWeight;
  }

  get temporaryList() {
    return this.controller.STIReverseScanTemporaryList;
  }

  get lastTemporaryList() {
    return this.controller.STIReverseScanTemporaryList[0];
  }

  get STIGerenrateData() {
    return this.controller.STIReverseScanGenerateData;
  }

  get STISTTDueSummaryData() {
    return this.controller.STISTTDueSummaryData;
  }

  get STIScanFailedList() {
    return this.controller.scanFailedData;
  }

  get currentTotalCurrentScan() {
    return this.temporaryList.filter(
      data => data.stBookedId === this.temporaryList[0].stBookedId
    ).length;
  }

  async fetchTemporaryList(): Promise<void> {
    const payload = new STIReverseScanTemporaryListRequest({
      isStiDest: Boolean(this.form.stiDestFilter),
      statusReturn: this.form.statusReturn.value,
      sttOrBagNo: this.form.search,
      bookedId: this.form.booked.value,
      bookedType: this.form.booked.code
    });

    const res = await this.controller.getListSTIReverseScanTemporary(payload);

    if (!res.length) {
      this.controller.setSTISTTDueSummaryData(new STISTTDueSummary());
      this.resetIllustration();
      storeDatatoLocalStorage("sti-booked-list-options", []);
      this.controller.setBookedListOptions([]);
      return;
    }

    const lastData = res[0];
    const bookedData = this.getSummarySTISTTSummaryLocalStorage(
      lastData.stBookedId
    );
    this.controller.setSTISTTDueSummaryData(
      new STISTTDueSummary({
        ...bookedData
      })
    );
    if (this.form.search.length) return;
    if (this.form.stiDestFilter) return;
    if (this.form.statusReturn.value !== "") return;
    this.setIllustrationByResponse(res, !lastData.stMeta?.bagNo?.length);

    if (!this.form.booked?.id) {
      this.bookedListOptions = res
        .map(stt => {
          return new OptionsClass({
            name: stt.stBookedName,
            value: stt.stBookedId.toString(),
            code: stt.stBookedType
          });
        })
        .filter(
          (option1, i, arr) =>
            arr.findIndex(option2 => option2.name === option1.name) === i
        )
        .filter(option => option.name !== "");

      storeDatatoLocalStorage(
        "sti-booked-list-options",
        this.bookedListOptions
      );

      this.controller.setBookedListOptions(this.bookedListOptions);
    }
  }

  fetchBookedList(): void {
    this.controller.getStiBookedList();
  }

  fetchSummaryData(data: STIReverseScanTemporary): void {
    this.controller
      .getSTISTTDueSummary(
        new STISTTDueSummaryListRequest({
          bookedId: data.stBookedId,
          bookedType: data.stBookedType
        })
      )
      .then(res => {
        const newSummaryData = new STISTTDueSummary({
          id: data.stBookedId,
          posName: data.stBookedName,
          total: res.total,
          totalOverdue: res.totalOverdue,
          totalNow: res.totalNow,
          current: this.currentTotalCurrentScan,
          type: data.stBookedType
        });

        this.setSummarySTISTTSummaryLocalStorage(
          newSummaryData.id,
          newSummaryData,
          Boolean(
            !this.getSummarySTISTTSummaryLocalStorage(data.stBookedId)?.id
          )
        );

        const newOption = new OptionsClass({
          name: data.stBookedName,
          value: data.stBookedId.toString(),
          code: data.stBookedType
        });

        this.controller.setBookedListOptions([
          ...this.controller.bookedListOptions,
          newOption
        ]);

        storeDatatoLocalStorage(
          "sti-booked-list-options",
          this.controller.bookedListOptions
        );
      });
  }

  onScanSttNumber = debounce((val: string) => {
    const splitVal = val.split("#")[0];

    this.form.sttNumber = splitVal;
    this.generateSTIReverseScan(splitVal);
    this.setSttInputEl();
  }, 500);

  onSetFocusRefs() {
    this.refs = this.$refs;
  }

  setSttInputEl() {
    const inputElement = this.$refs["inputStt"] as HTMLInputElement | undefined;

    if (inputElement) {
      inputElement.focus();
    }
  }

  onSearch(val: string): void {
    this.form.search = val;
    this.fetchTemporaryList();
  }

  onClearSearch(): void {
    this.form.search = "";
    this.fetchTemporaryList();
  }

  generateSTIReverseScan(sttNo: string) {
    if (this.loadingIllustration) return;
    if (!this.form.sttNumber) return;
    const payload = new GenerateSTIReverseScanRequest({
      bagOrSttNo: sttNo
    });

    if (this.checkIsSTTAlreadyExist(sttNo)) {
      playNotification("error");
      this.form.sttNumber = "";
      this.illustrationData = {
        ...this.illustrationData,
        isEmpty: false,
        isInvalid: true
      };
      return;
    }

    this.loadingIllustration = true;
    this.controller
      .generateSTIReverseScan(payload)
      .then(async resp => {
        if (resp.success) {
          this._processSuccessScan(resp);
        } else {
          playNotification("error");
          this.illustrationData = {
            ...this.illustrationData,
            isInvalid: true,
            isEmpty: false
          };
          this.form.sttNumber = "";
        }
      })
      .catch(err => {
        this._processErrorScan(err);
      })
      .finally(() => {
        this.loadingIllustration = false;
      });
  }

  async _processSuccessScan(resp: STIReverseScanGenerate) {
    const generateData = resp;
    let generateLastData: STIReverseScanGenerateData = new STIReverseScanGenerateData();
    this.form = {
      ...this.form,
      search: "",
      statusReturn: new OptionsClass(),
      stiDestFilter: false,
      sttNumber: ""
    };
    const currentTemporaryListData = this.controller
      .STIReverseScanTemporaryList;

    if (!resp.bag.length) {
      generateLastData = resp.stt as STIReverseScanGenerateData;
      const newTemporaryData = this._mapSTIReverseScanTemporary(
        generateData.stt as STIReverseScanGenerateData
      );
      currentTemporaryListData.unshift(newTemporaryData);

      this.setIllustrationByResponse([newTemporaryData], true);

      this._processSummaryData(newTemporaryData, 1);
    } else {
      const newTemporaryDataList = resp.bag.map(item => {
        return this._mapSTIReverseScanTemporary(item);
      });
      newTemporaryDataList.forEach(item => {
        currentTemporaryListData.unshift(item);
      });
      generateLastData = resp.bag[resp.bag.length - 1];
      const firstCurrentTemporaryData = currentTemporaryListData[0];
      this._processSummaryData(firstCurrentTemporaryData, resp.bag.length);
      await this.setIllustrationByResponse(newTemporaryDataList, false);
    }

    this.controller.setSTIReverseScanTemporaryData(currentTemporaryListData);
    playNotification(
      resp.bag.length
        ? this.illustrationData.isSTT
          ? "success"
          : "bongkar"
        : this.getSoundByFlag(
            generateLastData?.flag || "",
            generateLastData.isDangerousGoods
          )
    );
  }

  _processErrorScan(err: any) {
    let isUnderpaid = false;
    if (err?.response) {
      isUnderpaid = err.response.data.message.en.includes("underpaid");
    }
    playNotification("error");
    this.illustrationData = {
      ...this.illustrationData,
      isInvalid: true,
      isEmpty: false,
      isSTT: true,
      isUnderpaid
    };
    this.form.sttNumber = "";
  }

  _processSummaryData(data: STIReverseScanTemporary, sum: number) {
    const storedSummaryData = this.getSummarySTISTTSummaryLocalStorage(
      data.stBookedId
    );

    if (storedSummaryData?.id) {
      this.setSummarySTISTTSummaryLocalStorage(
        storedSummaryData.id,
        new STISTTDueSummary({
          id: storedSummaryData.id,
          posName: data.stBookedName,
          total: storedSummaryData.total,
          totalOverdue: storedSummaryData.totalOverdue,
          totalNow: storedSummaryData.totalNow,
          current: storedSummaryData.current + sum,
          type: storedSummaryData.type
        })
      );
    } else {
      if (data.stBookedId) {
        this.fetchSummaryData(data);
      }
    }
  }

  _mapSTIReverseScanTemporary(
    item: STIReverseScanGenerateData
  ): STIReverseScanTemporary {
    if (!item) return new STIReverseScanTemporary();
    return new STIReverseScanTemporary({
      stSttNo: item.sttNo,
      stMeta: new STIReverseScanTemporaryMeta({
        bagNo: item.bagNo,
        flag: item.flag,
        isStiDest: item.isStiDest,
        pieces: item.piece.length,
        refNo: item.refNo,
        statusReturn: item.statusReturn,
        isDangerousGoods: item.isDangerousGoods,
        grossWeight: item.sttGrossWeight,
        isPaid: item.isPaid,
        sttAssessmentStatus: item.sttAssessmentStatus,
        sttNeedToRelabel: item.sttNeedToRelabel
      }),
      stProduct: item.sttProductType,
      stOrigin: item.sttOriginCityId,
      stOriginName: item.sttOriginCityName,
      stDestination: item.sttDestinationCityId,
      stDeadlineReturn: item.deadlineReturn,
      stRegionId: item.sttDestinationRegionName,
      stDestinationName: item.sttDestinationRegionName, //region destination
      stDestinationCityName: item.sttDestinationCityName, // city name destination
      stBookedId: item.bookedId,
      stBookedName: item.bookedName,
      stBookedType: item.bookedType,
    });
  }

  checkIsSTTAlreadyExist(sttNo: string): boolean {
    if (this.isSttNumber(sttNo)) {
      return (
        this.controller.STIReverseScanTemporaryList.findIndex(
          item => item.stSttNo === sttNo
        ) > -1
      );
    }
    return false;
  }

  getRowStyle(status: string): string {
    const statusLowerCase = status.toLowerCase();
    if (statusLowerCase.includes("segera pod")) return "row-red";
    if (statusLowerCase.includes("diambil penerima")) return "row-purple";
    if (
      statusLowerCase.includes("segera rts") ||
      statusLowerCase.includes("segera hal")
    )
      return "row-yellow";
    return "";
  }

  isSttNumber(sttNumber: string): boolean {
    return /^\d{1,2}LP\w+$/g.test(sttNumber);
  }

  onFilter(val: OptionsClass): void {
    this.form.statusReturn = val;
    this.fetchTemporaryList();
  }

  getSoundByFlag(flag: string, isDangerousGoods: boolean): string {
    const lowerCaseFlag = flag.toLowerCase();
    // implement notification sound here
    // decide sound notif
    if (isDangerousGoods) return "dangerous_goods";
    if (lowerCaseFlag.includes("sla") || lowerCaseFlag.includes("ulang"))
      return "pod";
    else if (lowerCaseFlag.includes("rts")) return "rts";
    else if (lowerCaseFlag.includes("hal")) return "hal";
    else if (lowerCaseFlag.includes("penting")) return "urgent_delivery";
    else return "success";
  }

  async setIllustrationByResponse(
    data: STIReverseScanTemporary[],
    isSTT = true
  ): Promise<void> {
    return new Promise(resolve => {
      if (isSTT) {
        const lastData = data[0];
        this.illustrationData = {
          flag: lastData.stMeta.flag,
          isEmpty: false,
          isInvalid: false,
          isStiDest: lastData.stMeta.isStiDest === 1,
          isSTT: true,
          regionName: lastData.stDestinationName || lastData.stRegionId,
          sttNo: lastData.stSttNo,
          isUnderpaid: false,
          type: "STT",
          isDangerousGoods: lastData.stMeta.isDangerousGoods,
          isRelabel: lastData.stMeta.sttNeedToRelabel
        };
      } else {
        if (!data.length) return;
        const lastData = data[0];
        const bagNo = lastData.stMeta.bagNo;
        const bags = data.filter(item => item.stMeta.bagNo === bagNo);
        const isStiDest =
          bags.findIndex(item => {
            return item.stMeta.isStiDest === 1;
          }) > -1;
        const regionName = bags.find(item => item.stRegionId)?.stRegionId;
        let flags = bags
          .map(item => item.stMeta.flag)
          .join(" ")
          .replaceAll("-", "")
          .trim();

        const isHasFlags = Boolean(flags.trim());

        const isDangerousGoods =
          bags.findIndex(item => {
            return item.stMeta.isDangerousGoods;
          }) > -1;

        let isShowBagLabel = false;

        if (isStiDest) {
          isShowBagLabel = false;
          if (isHasFlags) {
            isShowBagLabel = true;
          } else {
            isShowBagLabel = false;
          }
        } else {
          isShowBagLabel = false;
          flags = "";
        }

        if (isDangerousGoods) {
          isShowBagLabel = false;
        }

        const isNeedToRelabel = bags.some(bag => bag.stMeta.sttNeedToRelabel);

        this.illustrationData = {
          flag: flags,
          isEmpty: false,
          isInvalid: false,
          isStiDest: isStiDest,
          isSTT: !isShowBagLabel,
          regionName: regionName || "",
          sttNo: lastData.stMeta.bagNo,
          isUnderpaid: false,
          type: "BAG",
          isDangerousGoods: isDangerousGoods,
          isRelabel: isNeedToRelabel
        };

        resolve();
      }
    });
  }
  handleDownloadCsv() {
    downloadCsv({
      fileName: "STI Manifest.csv",
      fields: [
        "No",
        "Nomor Bagging",
        "Nomor STT",
        "Nomor Referensi",
        "Nama Produk",
        "Batas POD",
        "Total Koli",
        "Total Berat Kotor",
        "Kota Asal",
        "Kota Tujuan",
        "Wilayah",
        "STI DEST",
        "Penanda Paket",
        "Tanggal Manifest"
      ],
      listStt: this.mappingDataCsv(this.controller.STIReverseScanTemporaryList)
    });
  }

  // mapping success csv
  mappingDataCsv(data: STIReverseScanTemporary[]): any[] {
    const temp: any = [];
    data.map(stt => {
      temp.push({
        No: temp.length + 1,
        "Nomor Bagging": stt.stMeta.bagNo || "-",
        "Nomor STT": stt.stSttNo,
        "Nomor Referensi": stt.stMeta.refNo || "-",
        "Nama Produk": stt.stProduct,
        "Batas POD": formatDateNormal(stt.stDeadlineReturn, "DD/MM/YY"),
        "Total Koli": stt.stMeta.pieces,
        "Total Berat Kotor": stt.stMeta.grossWeight,
        "Kota Asal": `${stt.stOrigin} - ${stt.stOriginName}`,
        "Kota Tujuan": `${stt.stDestination} - ${stt.stDestinationCityName}`,
        Wilayah: stt.stDestinationName,
        "STI DEST": stt.stMeta.isStiDest === 1 ? "YA" : "TIDAK",
        "Penanda Paket": stt.stMeta.statusReturn,
        "Tanggal Manifest": ""
      });
    });
    return temp;
  }

  async printManifest(id: any) {
    const print: any = await import(
      "@/app/ui/views/out-going-shipment/sti/component/modules/generate-reverse-scan.vue"
    );
    const logo = await import(
      `@/app/ui/assets/images/logo-${this.roleAccount}.png`
    );
    print.default.methods.printManifestAndSttNeedSti(id, logo.default);
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  async stiProcessReverseManifest() {
    try {
      const payload = new STIReverseScanManifestRequest({
        bookedId: this.form.booked.value,
        bookedType: this.form.booked.code
      });
      this.isConfirmManifestModal = false;
      MainAppController.showLoading();
      MainAppController.closeErrorMessage();
      const resp = await STIController.stiProcessReverse(payload);
      if (this.form.booked?.value) {
        this.resetSummarySTISTTSummaryByIdLocalStorate(this.form.booked.value);

        const bookedListOptions = this.controller.bookedListOptions.filter(
          option => {
            return option.name !== this.form.booked.name;
          }
        );
        this.controller.setBookedListOptions(bookedListOptions);
      } else {
        removeDataLocalStorage("sti-stt-summary");
        removeDataLocalStorage("sti-booked-list-options");
        removeDataLocalStorage("sti-scan-failed");
        this.controller.setBookedListOptions([]);
        this.controller.setScanFailedData([]);
      }
      this.printManifest(resp.data);
      if (this.STISTTDueSummaryData.current < this.STISTTDueSummaryData.total) {
        this.onResetForm();
        this.fetchTemporaryList();
      } else {
        MainAppController.showMessageModal(
          new ModalMessageEntities({
            image: "image-modal-success",
            title: "Perubahan Status Berhasil !",
            message: "Status STT berhasil diupdate menjadi STI.",
            textSuccess: "OK",
            onSubmit: () => {
              MainAppController.closeMessageModal();
              this.onResetForm();
              this.fetchTemporaryList();
            }
          })
        );
      }
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Perubahan Status Gagal !")
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  onConfirmStiManifest() {
    if (this.STISTTDueSummaryData.current < this.STISTTDueSummaryData.total) {
      this.isConfirmManifestModal = true;
    } else {
      this.stiProcessReverseManifest();
    }
  }

  onResetForm() {
    this.form = {
      sttNumber: "",
      statusReturn: new OptionsClass(),
      stiDestFilter: false,
      search: "",
      booked: ""
    };
    this.form.booked = "";
    this.controller.setSTIReverseScanTemporaryData([]);
    this.resetIllustration();
    this.controller.setSTISTTDueSummaryData(new STISTTDueSummary());
  }

  resetIllustration() {
    this.illustrationData = {
      flag: "",
      isSTT: true,
      regionName: "",
      isStiDest: false,
      isInvalid: false,
      isEmpty: true,
      sttNo: "",
      isUnderpaid: false,
      type: "STT",
      isDangerousGoods: false,
      isRelabel: false
    };
  }

  getSummarySTISTTSummaryLocalStorage(id: number) {
    const summaryData =
      (JSON.parse(
        getDataLocalStorage("sti-stt-summary")
      ) as STISTTDueSummary[]) ?? [];

    return summaryData.find(summary => summary.id === id);
  }

  setSummarySTISTTSummaryLocalStorage(
    id: number,
    data: STISTTDueSummary,
    isNew = false
  ) {
    const sumamryListData =
      (JSON.parse(
        getDataLocalStorage("sti-stt-summary")
      ) as STISTTDueSummary[]) ?? [];

    if (!isNew) {
      const newSummaryListData = sumamryListData.map(summary => {
        if (summary.id === id) {
          return {
            ...summary,
            ...data
          };
        }

        return summary;
      });

      storeDatatoLocalStorage("sti-stt-summary", newSummaryListData);
    } else {
      sumamryListData.push(data);
      storeDatatoLocalStorage("sti-stt-summary", sumamryListData);
    }

    this.controller.setSTISTTDueSummaryData(data);
  }

  resetSummarySTISTTSummaryByIdLocalStorate(id: number) {
    const sumamryListData =
      (JSON.parse(
        getDataLocalStorage("sti-stt-summary")
      ) as STISTTDueSummary[]) ?? [];

    const newSummaryListData = sumamryListData.filter(
      summary => summary.id.toString() != id.toString()
    );

    storeDatatoLocalStorage("sti-stt-summary", newSummaryListData);
  }

  goToNeedToStiPage() {
    this.$router.push(`update/need-to-sti`);
  }
}
