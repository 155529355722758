export class TrackingSttData {
  sttDetail: TrackingSttDetailData;
  sttListTracking: TrackingSttListHistory[];
  constructor(
    sttDetail: TrackingSttDetailData,
    sttListTracking: TrackingSttListHistory[]
  ) {
    this.sttDetail = sttDetail;
    this.sttListTracking = sttListTracking;
  }
}

export class TrackingSttDetailData {
  sttNo = "";
  totalPieces = 0;
  status = "";
  statusLabel = "";
  productType = "";
  estimationsDate = "";
  estimations = "";
  chargeableWeight = 0;
  senderName = "";
  originLocation = "";
  recipientName = "";
  destinationLocation = "";
  sttId = 0;
  isPaid = false;
  rebuttalDex = "";
  sttNumberElexys = "";
  sttNoRef = "";
  shipmentId = "";
  sttIsCod = false;
  sttIsDfod = false;
  name = "";
  bookedName = "";
  sttNeedToRelabel = false;
  sttAssessmentStatus = "";
  bookedByPhone = "";
  bookedForPhone = "";
  constructor(fields?: Partial<TrackingSttDetailData>) {
    Object.assign(this, fields);
  }
}

export class TrackingSttListHistory {
  actorName: string;
  actorType: string;
  statusDate: string;
  groupDate: string;
  status: string;
  statusLabel: string;
  totalPieces: string;
  location: string;
  description: string;
  createdName: string;
  attachment: string[];
  timezone: string;
  countryID: string;
  hubName: string;
  sttWeightAttachFiles: string[] = [];
  actorPhone: string;

  constructor(
    actorName: string,
    actorType: string,
    statusDate: string,
    groupDate: string,
    status: string,
    statusLabel: string,
    totalPieces: string,
    location: string,
    description: string,
    createdName: string,
    attachment: string[],
    timezone: any,
    countryID: string,
    hubName: string,
    sttWeightAttachFiles: string[],
    actorPhone: string
  ) {
    this.actorName = actorName;
    this.actorType = actorType;
    this.statusDate = statusDate;
    this.groupDate = groupDate;
    this.status = status;
    this.statusLabel = statusLabel;
    this.totalPieces = totalPieces;
    this.location = location;
    this.description = description;
    this.createdName = createdName;
    this.attachment = attachment;
    this.timezone = timezone;
    this.countryID = countryID;
    this.hubName = hubName;
    this.sttWeightAttachFiles = sttWeightAttachFiles;
    this.actorPhone = actorPhone;
  }
}
