
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { StiSCController } from "@/app/ui/controllers/StiSCController";
import {
  ColumnManifestData,
  HeaderManifestData,
  SectionManifestData
} from "@/domain/entities/MainApp";
import { Vue } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { generateManifest2ColumnPotrait } from "./manifest-pdf-reverse-scan";

export default class Generate extends Vue {
  async printManifest(id: number) {
    MainAppController.showLoading();
    await StiSCController.getStiSCManifestDetail({ id });
    const dataManifest = StiSCController.stiSCManifestData;
    const data = new SectionManifestData({
      featureName: "STI-SC",
      headerDetailData: new HeaderManifestData({
        createdAt: dataManifest.stiCreatedAt,
        totalPiece: dataManifest.stiTotalPieces,
        totalGrossWeight: dataManifest.stiTotalGrossWeight,
        stiScId: id,
        needToStiScTotalStt: dataManifest.needToStiScTotalStt,
        accountData: AccountController.accountData,
        totalStt: dataManifest.stiTotalStt,
        partnerName: dataManifest.stiPartnerName,
        originCity: `${dataManifest.stiCityCode} - ${dataManifest.stiCityName}`
      }),
      data: dataManifest.stt.map(
        (item: any) =>
          new ColumnManifestData({
            sttNo: item.sttNo,
            totalGrossWeight: item.sttGrossWeight,
            totalPiece: item.sttTotalPiece,
            refNo: item.refNo || "-",
            destinationCity: item.sttDestinationCityId
          })
      ),
      signature: true
    });
    generateManifest2ColumnPotrait(data);
    MainAppController.closeLoading();
    return;
  }
}
