/* eslint-disable @typescript-eslint/camelcase */
import { getHubData } from "@/app/infrastructures/misc/Cookies";
import { NGEN_BOOKING } from "@/app/infrastructures/misc/Utils";
import {
  EditCargoRequestInteface,
  AppendBagOrSttNumberInterface,
  AddCargoRequestInteface,
  UpdateRetryCargoRequestInterface
} from "@/data/payload/contracts/CargoRequest";
import { DimensionData } from "@/domain/entities/Cargo";
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import { flags } from "@/feature-flags";

export class EditCargoApiRequest implements EditCargoRequestInteface {
  cargoNo: string;
  actualTotalGrossWeight: number;
  actualTotalVolumeWeight: number;
  cargoActualPiece: number;
  bagOrStt: EditCargoBagOrStt[];
  actualDimension: DimensionData;
  partnerId?: number;
  constructor(
    cargoNo: string,
    actualTotalGrossWeight: number,
    actualTotalVolumeWeight: number,
    cargoActualPiece: number,
    bagOrStt: EditCargoBagOrStt[],
    actualDimension: DimensionData,
    partnerId?: number
  ) {
    this.cargoNo = cargoNo;
    this.actualTotalGrossWeight = actualTotalGrossWeight;
    this.actualTotalVolumeWeight = actualTotalVolumeWeight;
    this.cargoActualPiece = cargoActualPiece;
    this.bagOrStt = bagOrStt;
    this.partnerId = partnerId;
    this.actualDimension = actualDimension;
  }

  toJSON(): string {
    return JSON.stringify({
      cargo_no: this.cargoNo,
      actual_total_gross_weight: this.actualTotalGrossWeight,
      actual_total_volume_weight: this.actualTotalVolumeWeight,
      actual_dimension: {
        cargo_actual_length_dimension: Math.round(
          this.actualDimension?.length || 0
        ),
        cargo_actual_width_dimension: Math.round(
          this.actualDimension?.width || 0
        ),
        cargo_actual_height_dimension: Math.round(
          this.actualDimension?.height || 0
        )
      },
      cargo_actual_piece: this.cargoActualPiece,
      bag_or_stt: this.bagOrStt,
      partner_id: this.partnerId,
      hub_id: getHubData()?.hubId || 0,
      hub_name: getHubData()?.hubName || "",
      hub_origin_city: getHubData()?.originCity || "",
      hub_district_code: getHubData()?.hubDistrictCode || ""
    });
  }
}

export class EditCargoBagOrStt {
  bag_no: string;
  stt_no: string;
  action: string;
  constructor(bagNo: string, sttNo: string, action: string) {
    this.bag_no = bagNo;
    this.stt_no = sttNo;
    this.action = action;
  }
}

export class AddBagOrSttNumberRequest implements AppendBagOrSttNumberInterface {
  private bagOrSttNo: BagOrSttNumber;
  private cargoProductType: string;
  private cargoCommodity: string;
  private cargoType: string;
  private partnerId?: number;

  constructor(
    bagOrSttNo: BagOrSttNumber,
    cargoProductType: string,
    cargoCommodity: string,
    cargoType: string,
    partnerId?: number
  ) {
    this.bagOrSttNo = bagOrSttNo;
    this.cargoProductType = cargoProductType;
    this.cargoCommodity = cargoCommodity;
    this.cargoType = cargoType;
    this.partnerId = partnerId;
  }

  toJSON(): string {
    return JSON.stringify({
      bag_or_stt: this.bagOrSttNo,
      cargo_product_type: this.cargoProductType,
      cargo_commodity: this.cargoCommodity,
      cargo_type: this.cargoType,
      partner_id: this.partnerId
    });
  }
}

export class BagOrSttNumber {
  bag_no: string;
  stt_no: string;

  constructor(bagNo: string, sttNumber: string) {
    this.bag_no = bagNo;
    this.stt_no = sttNumber;
  }
}

export class AddCargoRequest implements AddCargoRequestInteface {
  origin_airport_code: string;
  destination_airport_code: string;
  est_total_cargo_grossweigth: number;
  est_total_cargo_volumeweight: number;
  partner_name: string;
  partner_type: string;
  cargo_no: string;
  cargo_vehicle_no: string;
  actual_total_cargo_grossweigth: number;
  actual_total_cargo_volumeweight: number;
  route: string;
  total_stt: number;
  total_piece: number;
  cargo_commodity: string;
  product_cargo: string;
  cargo_type: string;
  origin_city_code: string;
  destination_city_code: string;
  departure_date: string;
  arrival_date: string;
  bag_or_stt: AddBagOrStt[];
  is_cargo_plane_manual: boolean | null;
  cargo_over_cutoff_limit: boolean | null;
  cargo_rtc_turn_auto: boolean | null;
  dimensions: DimensionPieces[];
  cargo_actual_piece: number;
  ngen_booking = {
    product_name: NGEN_BOOKING.PRODUCT_NAME,
    e_cargo_unique_id: NGEN_BOOKING.E_CARGO_UNIQUE_ID,
    awb_no: NGEN_BOOKING.AWB_NO,
    awb_prefix: NGEN_BOOKING.AWB_PREFIX,
    shc: "" as any,
    flight_plan_for_e_cargo: [] as FlightPlanForECargo[]
  };
  is_from_rtc: boolean;
  rtc_id: number;
  est_dimension?: DimensionData;
  actual_dimension?: DimensionData;
  nog?: string;
  rebookingId?: number;
  ngen_created_at?: string;
  hub_id?: number;
  hub_name?: number;
  hub_origin_city?:string;
  hub_district_code?:string;
  shc?: string;

  constructor(
    origin_airport_code: string,
    destination_airport_code: string,
    est_total_cargo_grossweigth: number,
    est_total_cargo_volumeweight: number,
    partner_name: string,
    partner_type: string,
    cargo_no: string,
    cargo_vehicle_no: string,
    actual_total_cargo_grossweigth: number,
    actual_total_cargo_volumeweight: number,
    route: string,
    total_stt: number,
    total_piece: number,
    cargo_commodity: string,
    product_cargo: string,
    cargo_type: string,
    origin_city_code: string,
    destination_city_code: string,
    departure_date: string,
    arrival_date: string,
    bag_or_stt: AddBagOrStt[],
    is_cargo_plane_manual: boolean | null,
    cargo_over_cutoff_limit: boolean | null,
    cargo_rtc_turn_auto: boolean | null,
    cargo_actual_piece: number,
    is_from_rtc: boolean,
    rtc_id: number,
    flight_plan_for_e_cargo?: FlightPlanForECargo[],
    dimensions?: DimensionPieces[],
    est_dimension?: DimensionData,
    actual_dimension?: DimensionData,
    nog?: string,
    rebookingId?: number,
    ngen_created_at?: string,
    hub_id?: number,
    hub_name?: number,
    hub_origin_city?:string,
    hub_district_code?:string,
    shc?: string
  ) {
    this.origin_airport_code = origin_airport_code;
    this.destination_airport_code = destination_airport_code;
    this.est_total_cargo_grossweigth = est_total_cargo_grossweigth;
    this.est_total_cargo_volumeweight = est_total_cargo_volumeweight;
    this.partner_name = partner_name;
    this.partner_type = partner_type;
    this.cargo_no = cargo_no;
    this.cargo_vehicle_no = cargo_vehicle_no;
    this.actual_total_cargo_grossweigth = actual_total_cargo_grossweigth;
    this.actual_total_cargo_volumeweight = actual_total_cargo_volumeweight;
    this.route = route;
    this.total_stt = total_stt;
    this.total_piece = total_piece;
    this.cargo_commodity = cargo_commodity;
    this.product_cargo = product_cargo;
    this.cargo_type = cargo_type;
    this.origin_city_code = origin_city_code;
    this.destination_city_code = destination_city_code;
    this.departure_date = departure_date;
    this.arrival_date = arrival_date;
    this.bag_or_stt = bag_or_stt;
    this.is_cargo_plane_manual = is_cargo_plane_manual;
    this.cargo_over_cutoff_limit =  cargo_over_cutoff_limit || false;
    this.cargo_rtc_turn_auto = cargo_rtc_turn_auto;
    // this.ngen_booking.product_name = product_cargo; // hardcoded for now
    this.ngen_booking.flight_plan_for_e_cargo = flight_plan_for_e_cargo || [];
    this.ngen_booking.shc = shc;
    this.dimensions = dimensions || [];
    this.is_from_rtc = is_from_rtc;
    this.rtc_id = rtc_id;
    this.est_dimension = est_dimension;
    this.actual_dimension = actual_dimension;
    this.nog = nog;
    this.rebookingId = rebookingId;
    this.cargo_actual_piece = cargo_actual_piece;
    this.ngen_created_at = ngen_created_at;
    this.hub_id = hub_id;
    this.hub_name = hub_name;
    this.hub_origin_city = hub_origin_city;
    this.hub_district_code = hub_district_code;
  }

  toJSON(): string {
    let data: any = {
      origin_airport_code: this.origin_airport_code,
      destination_airport_code: this.destination_airport_code,
      est_total_cargo_grossweigth: this.est_total_cargo_grossweigth,
      est_total_cargo_volumeweight: this.est_total_cargo_volumeweight,
      partner_name: this.partner_name,
      partner_type: this.partner_type,
      cargo_no: this.cargo_no,
      cargo_vehicle_no: this.cargo_vehicle_no,
      actual_total_cargo_grossweigth: this.actual_total_cargo_grossweigth,
      actual_total_cargo_volumeweight: this.actual_total_cargo_volumeweight,
      route: this.route,
      total_stt: this.total_stt,
      total_piece: this.total_piece,
      cargo_commodity: this.cargo_commodity,
      product_cargo: this.product_cargo,
      cargo_type: this.cargo_type,
      origin_city_code: this.origin_city_code,
      destination_city_code: this.destination_city_code,
      departure_date: this.departure_date,
      arrival_date: this.arrival_date,
      bag_or_stt: this.bag_or_stt,
      estimation_dimension: {
        cargo_est_length_dimension: this.est_dimension?.length,
        cargo_est_width_dimension: this.est_dimension?.width,
        cargo_est_height_dimension: this.est_dimension?.height
      },
      actual_dimension: {
        cargo_actual_length_dimension: Math.round(
          this.actual_dimension?.length || 0
        ),
        cargo_actual_width_dimension: Math.round(
          this.actual_dimension?.width || 0
        ),
        cargo_actual_height_dimension: Math.round(
          this.actual_dimension?.height || 0
        )
      },
      nog: this.nog,
      rebooking_id: this.rebookingId,
      cargo_actual_piece: this.cargo_actual_piece,
      ngen_created_at: this.ngen_created_at,
      hub_id: this.hub_id,
      hub_name: this.hub_name,
      hub_origin_city: this.hub_origin_city,
      hub_district_code: this.hub_district_code, 
      is_from_rtc: this.is_from_rtc,
      rtc_id: this.rtc_id, cargo_over_cutoff_limit: this.cargo_over_cutoff_limit, cargo_rtc_turn_auto: this.cargo_rtc_turn_auto
    };

    if (this.is_cargo_plane_manual !== null) {
      data = {
        ...data,
        is_cargo_plane_manual: this.is_cargo_plane_manual
      };
    }
    if (this.ngen_booking.flight_plan_for_e_cargo.length > 0) {
      data = {
        ...data,
        ngen_booking: this.ngen_booking,
        cargo_over_cutoff_limit: this.cargo_over_cutoff_limit,
        cargo_rtc_turn_auto: this.cargo_rtc_turn_auto
      };
    }
    const IS_PLANE_ONLY = flags.feature_dimension_plane_only.isEnabled();
    const isCargoPlane = this.cargo_type === "plane";
    if (IS_PLANE_ONLY && isCargoPlane) {
      data = {
        ...data,
        dimensions: this.dimensions
      }
    }
    else {
      if (!IS_PLANE_ONLY) {
        data = {
          dimensions: this.dimensions,
          ...data
        }
      }
    }
    return JSON.stringify(data);
  }
}

export class AddBagOrStt {
  bag_no?: string;
  stt_no?: string;
  constructor(bag_no?: string, stt_no?: string) {
    this.bag_no = bag_no;
    this.stt_no = stt_no;
  }
}

export class FlightPlanForECargo {
  daily_flights_no: string;
  is_root_station: boolean;
  flight_no: string;
  flight_date: string;
  flight_origin: string;
  flight_destination: string;
  flight_dep_datetime: string;
  flight_arr_datetime: string;
  flight_date_time: string;
  flight_threshold_time?: number;
  constructor(
    daily_flights_no: string,
    is_root_station: boolean,
    flight_no: string,
    flight_date: string,
    flight_origin: string,
    flight_destination: string,
    flight_dep_datetime: string,
    flight_arr_datetime: string,
    flight_date_time: string,
    flight_threshold_time?: number
  ) {
    this.daily_flights_no = daily_flights_no;
    this.is_root_station = is_root_station;
    this.flight_no = flight_no;
    this.flight_date = flight_date;
    this.flight_origin = flight_origin;
    this.flight_destination = flight_destination;
    this.flight_dep_datetime = flight_dep_datetime;
    this.flight_arr_datetime = flight_arr_datetime;
    this.flight_date_time = flight_date_time;
    this.flight_threshold_time = flight_threshold_time;
  }
}

export class DimensionPieces {
  length = 0;
  width = 0;
  height = 0;
  pieces = 0;
  grossWeight?= 0;
  constructor(fields?: Partial<DimensionPieces>) {
    Object.assign(this, fields);
  }
}

export class CargoListRequest {
  page = 1;
  limit = 10;
  search = "";
  cargoType = "";
  originCity = "";
  destinationCity = "";
  startDate = "";
  endDate = "";
  airportCode = "";
  partnerId = 0;
  status = "";
  hubId = 0;
  isTotalData = true;
  destinationAirport = "";
  constructor(fields?: Partial<CargoListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class CargoRecomendationsListRequest {
  originCode = "";
  destinationCode = "";
  flightDay = "";
  comodity = "";
  totalWeight = "";
  totalVolume = "";

  constructor(fields?: Partial<CargoRecomendationsListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class UpdateRetryCargoRequest
  implements UpdateRetryCargoRequestInterface {
  action: string;

  constructor(action: string) {
    this.action = action;
  }

  public toJSON(): string {
    return JSON.stringify({
      action: this.action
    });
  }
}
