
import {
  convertDecimal,
  convertDecimalWithComma,
  FAILED_ERROR,
  formatDateNormal,
  getDataLocalStorage,
  parsingErrorResponse,
  removeDataLocalStorage,
  storeDatatoLocalStorage
} from "@/app/infrastructures/misc/Utils";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { StiSCController } from "@/app/ui/controllers/StiSCController";
import {
  CreateSTISCReverseScanRequest,
  STISCReverseScanManifestRequest,
  STISCReverseScanTemporaryListRequest,
  STISCSTTDueSummaryRequest
} from "@/data/payload/api/StiScApiRequest";
import {
  ErrorMessageEntities,
  ModalMessageEntities,
  OptionsClass
} from "@/domain/entities/MainApp";
import {
  STISCReverseScanCreateData,
  STISCReverseScanTemporary,
  STISCScanFailed,
  STISCSTTDueSummary
} from "@/domain/entities/StiSc";
import { Data } from "@vue/composition-api";
import { Options, Vue } from "vue-class-component";
import STISCReverseScanIllustration from "../component/scan-illustration/index.vue";
import ScanFailedCard from "../component/scan-failed-card/index.vue";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import SummaryCard from "../component/summary-card/index.vue";
import { debounce } from "lodash";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

@Options({
  components: {
    STISCReverseScanIllustration,
    ScanFailedCard,
    Skeleton,
    SummaryCard
  }
})
export default class STISCUpdateReverseScan extends Vue {
  controller = StiSCController;
  pagination = {
    page: 1,
    limit: 20
  };

  form = {
    sttNumber: "",
    search: "",
    booked: new OptionsClass() as any
  };

  // Illustration state
  illustrationData = {
    isInvalid: false,
    isEmpty: true,
    statusReturn: ""
  };
  loadingIllustration = false;

  isConfirmManifestModal = false;
  refs?: Data = undefined;
  bookedListOptions: OptionsClass[] = [];
  loadingTemporaryList = false;

  mounted() {
    this.fetchTemporaryList();
    this.controller.setScanFailedData([]);
    const stiScScanFailedList = JSON.parse(
      getDataLocalStorage("sti-sc-scan-failed")
    ) as STISCScanFailed[];

    this.controller.setScanFailedData(stiScScanFailedList || []);
  }

  get columns() {
    return [
      ...this.columns1,
      {
        name: "Jenis pengiriman",
        styleHead: "text-left w-44",
        render: (item: STISCReverseScanTemporary) =>
          `<div class="text-sm text-black-lp-300 text-left">
            <div class="py-0.5 px-2.5 rounded-md bg-gray-lp-1400">
              ${item.sstProduct || "-"}
            </div>
          </div>`
      },
      {
        name: "Nama POS/Klien",
        styleHead: "text-left w-44",
        render: (item: STISCReverseScanTemporary) =>
          `<div class="text-sm text-black-lp-300 text-left break-all">
            ${item.sstBookedName || "-"}
          </div>`
      },
      {
        name: "Kota tujuan",
        styleHead: "text-left w-40",
        render: (item: STISCReverseScanTemporary) =>
          `<div class="text-sm text-black-lp-300 text-left">
            ${item.sstDestination}
          </div>`
      },
      {
        name: "Batas POD",
        styleHead: "text-left w-40",
        render: (item: STISCReverseScanTemporary) =>
          `<div class="text-sm text-black-lp-300 text-left">
            ${formatDateNormal(item.sstPodDate, "dddd, DD MMMM YYYY")}
          </div>`
      }
    ];
  }

  get columns1() {
    return [
      {
        name: "No.",
        styleHead: "text-left w-12",
        render: (_item: STISCReverseScanTemporary, index: number) => {
          const number =
            index === 0
              ? this.controller.STISCReverseScanTemporaryList.length
              : this.controller.STISCReverseScanTemporaryList.length - index;
          return `
            <div class="text-sm text-black-lp-300 text-left ${
              index === 0 && this.pagination.page === 1 ? "highlight" : ""
            }">
              ${number - this.pagination.limit * (this.pagination.page - 1)}
            </div>
          `;
        }
      },
      {
        name: "No. STT",
        styleHead: "text-left w-40",
        render: (item: STISCReverseScanTemporary) => {
          return `
            <div class="text-sm tect-black-lp-300 text-left">
              ${item.sstSttNo || "-"}
              ${
                !item.sstIsPaid
                  ? '<div class="unpaid-chips">Kurang bayar</div>'
                  : ""
              }
            </div>
          `;
        }
      },
      {
        name: "No. referensi",
        styleHead: "text-left w-40",
        render: (item: STISCReverseScanTemporary) => {
          return `
            <div class="text-sm tect-black-lp-300 text-left break-words">
              ${item.sstRefNo || "-"}
            </div>
          `;
        }
      }
    ];
  }

  get totalStt() {
    return this.controller.STISCReverseScanTemporaryList.length;
  }

  get calculatedStt() {
    return this.controller.STISCReverseScanTemporaryList.reduce(
      (a: any, b) => {
        return {
          sstGrossWeight: a.sstGrossWeight + b.sstGrossWeight,
          sstTotalPieces: a.sstTotalPieces + b.sstTotalPieces
        };
      },
      {
        sstGrossWeight: 0,
        sstTotalPieces: 0
      }
    );
  }

  get totalGrossWeight() {
    return this.calculatedStt.sstGrossWeight;
  }

  get totalPieces() {
    return this.calculatedStt.sstTotalPieces;
  }

  get temporaryList() {
    return this.controller.STISCReverseScanTemporaryList;
  }

  get STISCScanFailedList() {
    return this.controller.scanFailedData;
  }

  get STISCSTTDueSummaryData() {
    return this.controller.STISCSTTDueSummaryData;
  }

  get currentTotalCurrentScan() {
    return this.temporaryList.filter(
      data => data.sstBookedId === this.temporaryList[0].sstBookedId
    ).length;
  }

  fetchTemporaryList() {
    this.pagination.page = 1;
    this.loadingTemporaryList = true;
    const payload = new STISCReverseScanTemporaryListRequest({
      bookedId: this.form.booked.value,
      bookedType: this.form.booked.code,
      sttNo: this.form.search
    });

    this.controller
      .getListSTISCReverseScanTemporary(payload)
      .then(res => {
        if (!res.length) {
          this.controller.setSTISCSTTDueSummaryData(new STISCSTTDueSummary());
          this.illustrationData = {
            ...this.illustrationData,
            isEmpty: true,
            isInvalid: false
          };
          this.controller.setBookedListOptions([]);
          return;
        }

        const lastData = res[0];

        if (!this.form.search.length) {
          this.illustrationData = {
            ...this.illustrationData,
            isEmpty: false,
            isInvalid: false,
            statusReturn: lastData.statusReturn
          };

          if (!this.form.booked?.value) {
            this.bookedListOptions = res
              .map(stt => {
                return new OptionsClass({
                  name: stt.sstBookedName,
                  value: stt.sstBookedId.toString(),
                  code: stt.sstBookedType
                });
              })
              .filter(
                (option1, i, arr) =>
                  arr.findIndex(option2 => option2.name === option1.name) === i
              )
              .filter(option => option.name !== "");

            storeDatatoLocalStorage(
              "st-sc-booked-list-options",
              this.bookedListOptions
            );

            this.controller.setBookedListOptions(this.bookedListOptions);
          }

          const bookedData = this.getSummaryStiScSttSummaryLocalStorage(
            lastData.sstBookedId
          );

          this.controller.setSTISCSTTDueSummaryData(
            new STISCSTTDueSummary({
              ...bookedData
            })
          );
        }
      })
      .finally(() => {
        this.loadingTemporaryList = false;
      });
  }

  fetchSummaryData(data: STISCReverseScanTemporary): void {
    this.controller
      .getStiScSttDueSummary(
        new STISCSTTDueSummaryRequest({
          bookedId: data.sstBookedId,
          bookedType: data.sstBookedType
        })
      )
      .then(res => {
        const newSummaryData = new STISCSTTDueSummary({
          id: data.sstBookedId,
          posName: data.sstBookedName,
          total: res.total,
          totalOverdue: res.totalOverdue,
          totalNow: res.totalNow,
          current: this.currentTotalCurrentScan,
          type: data.sstBookedType
        });

        this.setSummaryStiScSummaruLocalStorage(
          newSummaryData.id,
          newSummaryData,
          Boolean(
            !this.getSummaryStiScSttSummaryLocalStorage(data.sstBookedId)?.id
          )
        );

        const newOption = new OptionsClass({
          name: data.sstBookedName,
          value: data.sstBookedId.toString(),
          code: data.sstBookedType
        });

        let newBookedListOptions = [
          ...this.controller.bookedListOptions,
          newOption
        ];

        newBookedListOptions = newBookedListOptions
          .filter(
            (option1, i, arr) =>
              arr.findIndex(option2 => option2.name === option1.name) === i
          )
          .filter(option => option.name !== "");

        this.controller.setBookedListOptions(newBookedListOptions);

        storeDatatoLocalStorage(
          "st-sc-booked-list-options",
          newBookedListOptions
        );
      });
  }

  onScanSttNumber = debounce((val: string) => {
    const splitVal = val.split("#")[0];

    this.form.sttNumber = splitVal;
    this.createSTISCReverseScan(splitVal);
    this.setSttInputEl();
  }, 500);

  createSTISCReverseScan(sttNo: string) {
    if (!sttNo) return;
    if (this.loadingIllustration || this.checkIsSTTAlreadyExist(sttNo)) return;
    const payload = new CreateSTISCReverseScanRequest({
      sttNo
    });

    this.loadingIllustration = true;
    this.controller
      .createSTISCReverseScan(payload)
      .then(async resp => {
        if (resp.success) {
          this.form = {
            ...this.form,
            search: "",
            sttNumber: ""
          };
          const newTemporaryData = this.createTemporaryData(
            resp.data as STISCReverseScanCreateData
          );

          const storedSummaryData = this.getSummaryStiScSttSummaryLocalStorage(
            newTemporaryData.sstBookedId
          );
          this.illustrationData = {
            ...this.illustrationData,
            isEmpty: false,
            isInvalid: false,
            statusReturn: resp.data?.statusReturn || ""
          };

          this._decideStoreSummaryToLocalStorage(
            storedSummaryData as STISCSTTDueSummary,
            newTemporaryData
          );

          this.controller.STISCReverseScanTemporaryList.unshift(
            newTemporaryData
          );

          playNotification(
            this.illustrationData.statusReturn?.toLowerCase()?.includes("hal")
              ? "hal"
              : "success"
          );
        } else {
          this.setErrorScanStiSc();
        }
      })
      .catch(() => {
        this.setErrorScanStiSc();
      })
      .finally(() => {
        this.loadingIllustration = false;
      });
  }

  _decideStoreSummaryToLocalStorage(
    storedSummaryData: STISCSTTDueSummary,
    newTemporaryData: STISCReverseScanTemporary
  ) {
    if (storedSummaryData?.id) {
      this.updateSummaryLocalStorage(storedSummaryData, newTemporaryData);
    } else {
      if (newTemporaryData.sstBookedId) {
        this.fetchSummaryData(newTemporaryData);
      }
    }
  }

  async stiScReverseScanManifest() {
    try {
      const payload = new STISCReverseScanManifestRequest({
        bookedId: this.form.booked.value,
        bookedType: this.form.booked.code
      });
      this.isConfirmManifestModal = false;
      MainAppController.showLoading();
      MainAppController.closeErrorMessage();
      const resp = await this.controller.stiScReverseScanManifest(payload);
      if (this.form.booked?.value) {
        this.resetSummaryStiScSttSummaryByIdLocalStorage(
          this.form.booked.value
        );

        const bookedListOptions = this.controller.bookedListOptions.filter(
          option => {
            return option.name != this.form.booked.name;
          }
        );
        this.controller.setBookedListOptions(bookedListOptions);
      } else {
        removeDataLocalStorage("sti-sc-stt-summary");
        removeDataLocalStorage("sti-sc-booked-list-options");
        removeDataLocalStorage("sti-sc-scan-failed");
        this.controller.setBookedListOptions([]);
        this.controller.setScanFailedData([]);
      }
      this.printManifest(resp.stiScId);

      if (
        this.STISCSTTDueSummaryData.current < this.STISCSTTDueSummaryData.total
      ) {
        this.resetForm();
        this.fetchTemporaryList();
      } else {
        MainAppController.showMessageModal(
          new ModalMessageEntities({
            image: "image-modal-success",
            title: "Pembuatan manifest berhasil!",
            textSuccess: "OK",
            onSubmit: () => {
              MainAppController.closeMessageModal();
              this.resetForm();
              this.fetchTemporaryList();
            }
          })
        );
      }
    } catch (error) {
      const message = error.response.data.message;
      MainAppController.showErrorMessage(
        typeof message === "string"
          ? new ErrorMessageEntities({
              message: message,
              title: "Server Error",
              type: FAILED_ERROR
            })
          : parsingErrorResponse(error, "Perubahan Status Gagal!")
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  checkIsSTTAlreadyExist(sttNo: string): boolean {
    const isExist =
      this.controller.STISCReverseScanTemporaryList.findIndex(
        item => item.sstSttNo === sttNo
      ) > -1;

    if (isExist) {
      playNotification("error");
      this.form.sttNumber = "";
      this.illustrationData = {
        ...this.illustrationData,
        isEmpty: false,
        isInvalid: true
      };
      return true;
    }

    return false;
  }

  updateIllustrationData() {
    this.illustrationData = {
      ...this.illustrationData,
      isEmpty: false,
      isInvalid: false
    };
  }

  updateSummaryLocalStorage(
    storedSummaryData: STISCSTTDueSummary,
    newTemporaryData: STISCReverseScanTemporary
  ) {
    this.setSummaryStiScSummaruLocalStorage(
      storedSummaryData.id,
      new STISCSTTDueSummary({
        id: storedSummaryData.id,
        posName: newTemporaryData.sstBookedName,
        total: storedSummaryData.total,
        totalOverdue: storedSummaryData.totalOverdue,
        totalNow: storedSummaryData.totalNow,
        current: storedSummaryData.current + 1,
        type: storedSummaryData.type
      })
    );
  }

  fetchSummaryDataIfNeeded(newTemporaryData: STISCReverseScanTemporary) {
    if (newTemporaryData.sstBookedId) {
      this.fetchSummaryData(newTemporaryData);
    }
  }

  onSetFocusRefs() {
    this.refs = this.$refs;
  }

  setSttInputEl() {
    (this.$refs["inputStt"] as HTMLInputElement).focus();
  }

  onSearch(val: string): void {
    this.form.search = val;
    this.fetchTemporaryList();
  }

  onClearSearch(): void {
    this.form.search = "";
    this.fetchTemporaryList();
  }

  isSttNumber(sttNumber: string): boolean {
    return /^\d{1,2}LP\w+$/g.test(sttNumber);
  }

  // convert value decimal
  isValueDecimal(value: number): number | string {
    return convertDecimal(value);
  }

  // format total kg
  convertTotalKg(value: number) {
    return convertDecimalWithComma(value, 2);
  }

  createTemporaryData(data: STISCReverseScanCreateData) {
    if (!data) return new STISCReverseScanTemporary();
    return new STISCReverseScanTemporary({
      sstSttNo: data.sttNo,
      sstRefNo: data.refNo,
      sstProduct: data.productType,
      sstBookedType: data.bookedType,
      sstBookedId: data.bookedId,
      sstBookedName: data.bookedName,
      sstPodDate: data.podDate,
      sstGrossWeight: data.totalGrossWeight,
      sstTotalPieces: data.totalPieces,
      sstIsPaid: data.isPaid,
      sstDestination: data.destinationCityCOde,
      statusReturn: data.statusReturn
    });
  }

  updateTemporaryList(newTemporaryData: STISCReverseScanTemporary) {
    const currentTemporaryListData = this.controller
      .STISCReverseScanTemporaryList;
    currentTemporaryListData.unshift(newTemporaryData);
  }

  getSummaryStiScSttSummaryLocalStorage(id: number) {
    const summaryData =
      (JSON.parse(
        getDataLocalStorage("sti-sc-stt-summary")
      ) as STISCSTTDueSummary[]) ?? [];

    return summaryData.find(summary => summary.id === id);
  }

  setSummaryStiScSummaruLocalStorage(
    id: number,
    data: STISCSTTDueSummary,
    isNew = false
  ) {
    const summaryListData =
      (JSON.parse(
        getDataLocalStorage("sti-sc-stt-summary")
      ) as STISCSTTDueSummary[]) ?? [];

    if (!isNew) {
      const newSummaryListData = summaryListData.map(summary => {
        if (summary.id === id) {
          return {
            ...summary,
            ...data
          };
        }

        return summary;
      });

      storeDatatoLocalStorage("sti-sc-stt-summary", newSummaryListData);
    } else {
      summaryListData.push(data);
      storeDatatoLocalStorage("sti-sc-stt-summary", summaryListData);
    }

    this.controller.setSTISCSTTDueSummaryData(data);
  }

  resetSummaryStiScSttSummaryByIdLocalStorage(id: number) {
    const summaryListData =
      (JSON.parse(
        getDataLocalStorage("sti-sc-stt-summary")
      ) as STISCSTTDueSummary[]) ?? [];

    const newSummaryListData = summaryListData.filter(
      summary => summary.id.toString() != id.toString()
    );

    storeDatatoLocalStorage("st-sc-stt-summary", newSummaryListData);
  }

  onConfirmStiScManifest() {
    if (
      this.STISCSTTDueSummaryData.current < this.STISCSTTDueSummaryData.total
    ) {
      this.isConfirmManifestModal = true;
    } else {
      this.stiScReverseScanManifest();
    }
  }

  setErrorScanStiSc() {
    this.illustrationData = {
      ...this.illustrationData,
      isEmpty: false,
      isInvalid: true
    };
    this.form.sttNumber = "";
    playNotification("error");
  }

  resetForm() {
    this.form = {
      booked: "",
      search: "",
      sttNumber: ""
    };
    this.form.booked = "";
    this.controller.setSTISCReverseScanTemporary([]);
    this.illustrationData = {
      ...this.illustrationData,
      isEmpty: true,
      isInvalid: false
    };
    this.controller.setSTISCSTTDueSummaryData(new STISCSTTDueSummary());
  }

  goToNeedToStiScPage() {
    this.$router.push({
      name: "need-to-sti-sc-booking"
    });
  }

  async printManifest(id: any) {
    const print: any = await import(
      "@/app/ui/views/out-going-shipment/sti-sc/component/modules/generate-reverse-scan.vue"
    );
    print.default.methods.printManifest(id);
  }
}
