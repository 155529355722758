
import { ReadyToCargo } from "@/domain/entities/ReadyToCargo";
import { Options, prop, Vue } from "vue-class-component";
import { cargoTypeData } from "@/app/ui/views/cargo/modules/cargo-module";
import CargoTypeCard from "@/app/ui/views/cargo/booking-cargo/components/cargo-type-card.vue";
import LongArrow from "@/app/ui/components/icons/modules/long-arrow.vue";
import IconKoli from "@/app/ui/components/icons/modules/icon-koli.vue";
import {
  dateDiffHoursMinute,
  formatDate,
  getDifferenceDate,
  formatDateNumber
} from "@/app/infrastructures/misc/Utils";
import CardDetailFlight from "@/app/ui/views/cargo/booking-cargo/components/card-detail-flight.vue";
import { FlightPlanForECargo } from "@/data/payload/api/CargoApiRequest";
import { FlightDetail } from "@/domain/entities/NGen";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import CalculateTimeDifference from "@/app/infrastructures/misc/common-library/CalculateTimeDifference";
import { CargoController } from '@/app/ui/controllers/CargoController';

class Props {
  data = prop<ReadyToCargo>({
    default: new ReadyToCargo(),
    type: ReadyToCargo
  });
}
@Options({
  emits: ["onProcess", "onEdit"],
  components: { CargoTypeCard, CardDetailFlight, LongArrow, IconKoli, Tooltip }
})
export default class CardReadyToCargo extends Vue.with(Props) {
  cargoType(type: string) {
    return cargoTypeData(type);
  }
  formatNumber(value: number): number | string {
    return value.toLocaleString("id");
  }
  dateDiffHoursMinute(startDate: any, endDate: any) {
    return dateDiffHoursMinute(startDate, endDate);
  }

  onProcess() {
    if (this.isCargoPlane) {
      CargoController.setIsBookingAuto(false);
      ReadyToCargoController.setDetailFlightData(this.data.flightsData);
      this.setCalculateFlightDate(this.result);
    } else {
      this.$emit("onProcess", this.data, this.withPrint, this.withPrintLabel);
      return;
    }
    this.$emit("onProcess", this.data);
  }
  onEdit() {
    if (this.isCargoPlane) {
      ReadyToCargoController.setDetailFlightData(this.data.flightsData);
      this.setCalculateFlightDate(this.result);
    }
    this.$emit("onEdit", this.data.id, this.data);
  }

  get isCargoPlane(): boolean {
    return this.data.cargoType === "plane";
  }
  toggleFlightsDetail = false;

  get differenceDate() {
    if (this.data.flightsData.length) {
      return getDifferenceDate(
        this.data.flightsData[0].DepartureDateTime,
        this.data.flightsData[this.data.flightsData.length - 1].ArrivalDateTime
      );
    }
    return "";
  }

  formatDate(value: string): string {
    return formatDate(value);
  }

  created() {
    if (this.isCargoPlane) {
      this.calculateFlightDate(this.data.flightsData);
    }
  }

  isOpenTooltip = false;

  openTooltip() {
    this.isOpenTooltip = true;
  }

  closeTooltip() {
    this.isOpenTooltip = false;
  }

  calculateFlightDate(flightsData: FlightDetail[]) {
    const data: FlightPlanForECargo[] = [];
    flightsData.forEach((key: FlightDetail, index: any) => {
      this.pushDataFlightPlan(key, index, data);
    });
    this.pushDataFlightDate(flightsData, data);
  }

  setCalculateFlightDate(data: FlightPlanForECargo[]) {
    ReadyToCargoController.setCalculateFlightDate(data);
  }

  isDiffDay(dep: any, arr: any): boolean {
    const depTime = +dep.split(":")[0];
    const arrTime = +arr.split(":")[0];
    return arrTime < depTime;
  }

  pushDataFlightPlan(
    key: FlightDetail,
    index: number,
    data: FlightPlanForECargo[]
  ) {
    if (index === 0) {
      data.push(
        new FlightPlanForECargo(
          "",
          true,
          key.FlightNo,
          this.calculateDayFirstFlight("", key.flightDayTransit),
          key.airportCodeOrigin,
          key.airportCodeDestination,
          this.calculateDayFirstFlight(
            // if isMoreThanCutOffTime, 00:00 only dummy data for time is not empty, in calculateDayFirstFlight time will get from nextCargoFlightTime
            this.data.isMoreThanCutOffTime ? "00:00" : key.departureHour,
            key.flightDayTransit
          ),
          this.isDiffDay(key.departureHour, key.arrivalHour)
            ? `${formatDateNumber(
                new Date(
                  this.calculateDayFirstFlight(
                    key.departureHour,
                    key.flightDayTransit
                  )
                ).setDate(
                  new Date(
                    this.calculateDayFirstFlight(
                      key.departureHour,
                      key.flightDayTransit
                    )
                  ).getDate() + 1
                )
              )} ${key.arrivalHour}`
            : this.calculateDayFirstFlight(
                this.data.isMoreThanCutOffTime ? "" : key.arrivalHour,
                key.flightDayTransit
              ),
          this.calculateDayFirstFlight(
            // if isMoreThanCutOffTime, 00:00 only dummy data for time is not empty, in calculateDayFirstFlight time will get from nextCargoFlightTime
            this.data.isMoreThanCutOffTime ? "00:00" : key.departureHour,
            key.flightDayTransit
          ),
          key.convertThresholdTime
        )
      );
    } else {
      data.push(
        new FlightPlanForECargo(
          "",
          true,
          key.FlightNo,
          this.calculateDayTransit("", data, index),
          key.airportCodeOrigin,
          key.airportCodeDestination,
          this.calculateDayTransit(key.departureHour, data, index),
          this.isDiffDay(key.departureHour, key.arrivalHour)
            ? `${formatDateNumber(
                new Date(
                  this.calculateDayTransit(key.departureHour, data, index)
                ).setDate(
                  new Date(
                    this.calculateDayTransit(key.departureHour, data, index)
                  ).getDate() + 1
                )
              )} ${key.arrivalHour}`
            : this.calculateDayTransit(key.arrivalHour, data, index),
          this.calculateDayTransit(key.departureHour, data, index),
          key.convertThresholdTime
        )
      );
    }
  }

  calculateDayTransit(
    time: string,
    data: FlightPlanForECargo[],
    index: number
  ): string {
    let result = "";

    const { isMoreThanCutOffTime, nextCargoFlightTime } = this.data;
    if (isMoreThanCutOffTime) {
      result = formatDateNumber(new Date(nextCargoFlightTime));
    } else {
      result = `${formatDateNumber(
        new Date(data[index - 1].flight_dep_datetime).setDate(
          new Date(data[index - 1].flight_dep_datetime).getDate() +
            this.data.flightsData[index].flightDayTransit
        )
      )} ${time}`;
      result = !time.length ? result.slice(0, -1) : result;
    }
    return result;
  }

  calculateDayFirstFlight(time: string, flightDayTransit: number): string {
    let result = "";

    const { isMoreThanCutOffTime, nextCargoFlightTime } = this.data;
    if (isMoreThanCutOffTime) {
      result = this.$moment(new Date(nextCargoFlightTime)).format(
        time ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"
      );
    } else {
      result = `${formatDateNumber(
        new Date().setDate(new Date().getDate() + flightDayTransit)
      )} ${time}`;
      result = !time.length ? result.slice(0, -1) : result;
    }
    return result;
  }

  result: FlightPlanForECargo[] = [];

  pushDataFlightDate(flightsData: FlightDetail[], data: FlightPlanForECargo[]) {
    flightsData.forEach((key: FlightDetail, index: number) => {
      data.forEach((secondKey: FlightPlanForECargo, secondIndex: number) => {
        if (index === secondIndex) {
          key.flightDate = secondKey.flight_date;
          key.DepartureDateTime = secondKey.flight_dep_datetime;
          key.ArrivalDateTime = secondKey.flight_arr_datetime;
        }
      });
    });
    this.result = data;
  }

  onHandleName(value: string) {
    return ellipsisString(value, 15);
  }

  get onFormatDateEstimationDeparture() {
    return formatDate(this.data.flightsData[0]?.DepartureDateTime);
  }
  get estimationNextDeparture() {
    return formatDate(this.data.nextCargoFlightTime);
  }

  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs?.op?.toggle(event);
  }
  openMenuNextConfig(event: any) {
    const refs: any = this.$refs;
    refs?.["op-rtc"]?.toggle(event);
  }

  get iconTogglePanel() {
    const refs: any = this.$refs;
    return refs?.op?.visible ? "chevron-up-white" : "chevron-down-white";
  }

  createAndPrint(withPrint: boolean, label = false) {
    const refs: any = this.$refs;
    refs.op.hide();
    this.withPrint = withPrint;
    this.withPrintLabel = label;
    this.onProcess();
  }

  withPrint = false;
  withPrintLabel = false;

  get dateNow() {
    return this.$moment().format("YYYY-MM-DDTHH:mm:ss");
  }

  get dateCutOff() {
    const date = this.$moment().format("YYYY-MM-DD");
    return date + "T" + this.data.cutOffTime;
  }

  get formatCalculateDiffTime() {
    return CalculateTimeDifference(this.dateNow, this.dateCutOff);
  }

  processNextConfig() {
    const refs: any = this.$refs;
    refs?.["op-rtc"]?.hide();

    const {
      cutOffTimeExceededWithNextConfig,
      nextConfigCargoFlight,
      flightsData
    } = this.data;
    ReadyToCargoController.setDetailFlightData(
      cutOffTimeExceededWithNextConfig ? nextConfigCargoFlight : flightsData
    );
    this.setCalculateFlightDate(this.result);
  }
  onBookingRtc() {
    CargoController.setIsBookingAuto(false);
    this.processNextConfig();
    this.$emit("onProcess", this.data, false, false, "rtc");
  }
  onBookingGenesis() {
    CargoController.setIsBookingAuto(true);
    this.processNextConfig();
    this.$emit("onProcess", this.data, false, false, "genesis");
  }
}
