import QRCode from "qrcode";
import { prefixAlgoEstimationShow } from "@/app/infrastructures/misc/Utils";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import jsPDF from "jspdf";
import { FlagsFeatureShipmentC1 } from "@/feature-flags/flags-shipment-c1";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import formatPriceRM from "@/app/infrastructures/misc/common-library/FormatPriceRM";

export const productRect = (product: string, doubleLayer: boolean) => {
  const type: any = {
    REGPACK: {
      rect: 56,
      rectDoubleLayer: 56.1,
    },
    LANDPACK: {
      rect: 55,
      rectDoubleLayer: 55.1,
    },
    ONEPACK: {
      rect: 56,
      rectDoubleLayer: 56.1,
    },
    INTERPACK: {
      rect: 55,
      rectDoubleLayer: 55.1,
    },
    DOCUPACK: {
      rect: 55,
      rectDoubleLayer: 55.1,
    },
    MIXPACK: {
      rect: 56,
      rectDoubleLayer: 56.1,
    },
    SDPACK: {
      rect: 58,
      rectDoubleLayer: 58.1,
    },
    BIGPACK: {
      rect: 56,
      rectDoubleLayer: 56.1,
    },
    DORPACK: {
      rect: 56,
      rectDoubleLayer: 56.1,
    },
    JAGOPACK: {
      rect: 55,
      rectDoubleLayer: 55.1,
    },
    OTOPACK150: {
      rect: 53,
      rectDoubleLayer: 53.1,
    },
    OTOPACK250: {
      rect: 53,
      rectDoubleLayer: 53.1,
    },
    JUMBOPACK: {
      rect: 53.5,
      rectDoubleLayer: 53.6,
    },
    SAMEDAY: {
      rect: 56,
      rectDoubleLayer: 56.1,
    },
    "": {
      rect: 55,
      rectDoubleLayer: 55.1,
    },
  };

  const checkProductTypeExist = product in type;
  if (checkProductTypeExist) {
    return doubleLayer ? type[product].rectDoubleLayer : type[product].rect;
  } else {
    return type[""].rect;
  }
};

export const productRectColor = (product: string, isThermalPdf = false) => {
  const type: any = {
    REGPACK: {
      color: "#4185F7",
    },
    LANDPACK: {
      color: "#36B37E",
    },
    ONEPACK: {
      color: "#8E78DB",
    },
    INTERPACK: {
      color: "#E13E49",
    },
    DOCUPACK: {
      color: "#9B581F",
    },
    MIXPACK: {
      color: "#4D4D4D",
    },
    SDPACK: {
      color: "#4D4D4D",
    },
    BIGPACK: {
      color: "#ED6240",
    },
    DORPACK: {
      color: "#4D4D4D",
    },
    JAGOPACK: {
      color: "#36B37E",
    },
    "": {
      color: "#4D4D4D",
    },
  };

  const checkProductTypeExist = product in type;
  if (checkProductTypeExist && !isThermalPdf) {
    return type[product].color;
  } else {
    return type[""].color;
  }
};

export const sentDate = (month: string) => {
  const monthLabel: any = {
    Januari: {
      sectOneLeft: 70,
      sectOneLeftDoc: 74,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176,
    },
    Februari: {
      sectOneLeft: 70,
      sectOneLeftDoc: 74,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176,
    },
    Maret: {
      sectOneLeft: 73,
      sectOneLeftDoc: 77,
      sectTwoLeft: 179,
      sectThreeLeft: 77,
      sectFourLeft: 176,
    },
    April: {
      sectOneLeft: 73,
      sectOneLeftDoc: 77,
      sectTwoLeft: 179,
      sectThreeLeft: 77,
      sectFourLeft: 176,
    },
    Mei: {
      sectOneLeft: 73,
      sectOneLeftDoc: 77,
      sectTwoLeft: 179,
      sectThreeLeft: 77,
      sectFourLeft: 176,
    },
    Juni: {
      sectOneLeft: 73,
      sectOneLeftDoc: 77,
      sectTwoLeft: 179,
      sectThreeLeft: 77,
      sectFourLeft: 176,
    },
    Juli: {
      sectOneLeft: 73,
      sectOneLeftDoc: 77,
      sectTwoLeft: 179,
      sectThreeLeft: 78,
      sectFourLeft: 176,
    },
    Agustus: {
      sectOneLeft: 70,
      sectOneLeftDoc: 40,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176,
    },
    September: {
      sectOneLeft: 50,
      sectOneLeftDoc: 40,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176,
    },
    Oktober: {
      sectOneLeft: 70,
      sectOneLeftDoc: 74,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176,
    },
    November: {
      sectOneLeft: 70,
      sectOneLeftDoc: 74,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176,
    },
    Desember: {
      sectOneLeft: 70,
      sectOneLeftDoc: 74,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176,
    },
  };

  return monthLabel[month];
};

export const senderSection = (senderAddress: string) => {
  const senderDetail = {
    maxLengthAddress: 70,
    sectOneAndTwo: {
      ifAddressNormal: {
        heightReceiverLabel: 59,
        heightReceiverAddress: 63,
      },
      ifAddressOver: {
        heightReceiverLabel: 58,
        heightReceiverAddress: 62,
      },
    },

    sectThreeAndFour: {
      ifAddressNormal: {
        heightReceiverLabel: 204,
        heightReceiverAddress: 208,
      },
      ifAddressOver: {
        heightReceiverLabel: 203,
        heightReceiverAddress: 207,
      },
    },
  };

  let heightReceiverLabelSect1And2 =
    senderDetail.sectOneAndTwo.ifAddressNormal.heightReceiverLabel;
  let heightReceiverAddressSect1And2 =
    senderDetail.sectOneAndTwo.ifAddressNormal.heightReceiverAddress;
  let heightReceiverLabelSect3And4 =
    senderDetail.sectThreeAndFour.ifAddressNormal.heightReceiverLabel;
  let heightReceiverAddressSect3And4 =
    senderDetail.sectThreeAndFour.ifAddressNormal.heightReceiverAddress;
  if (senderAddress.length > senderDetail.maxLengthAddress) {
    senderAddress = `${senderAddress.substring(0, 150)}...`;
    heightReceiverLabelSect1And2 =
      senderDetail.sectOneAndTwo.ifAddressOver.heightReceiverLabel;
    heightReceiverAddressSect1And2 =
      senderDetail.sectOneAndTwo.ifAddressOver.heightReceiverAddress;
    heightReceiverLabelSect3And4 =
      senderDetail.sectThreeAndFour.ifAddressOver.heightReceiverLabel;
    heightReceiverAddressSect3And4 =
      senderDetail.sectThreeAndFour.ifAddressOver.heightReceiverAddress;
  }

  return {
    senderAddress: senderAddress,
    heightReceiverLabelSect1And2: heightReceiverLabelSect1And2,
    heightReceiverAddressSect1And2: heightReceiverAddressSect1And2,
    heightReceiverLabelSect3And4: heightReceiverLabelSect3And4,
    heightReceiverAddressSect3And4: heightReceiverAddressSect3And4,
  };
};

export const receiverSection = (receiverAddress: string) => {
  const receiverDetail = {
    maxLengthAddress: 150,
  };

  if (receiverAddress.length > receiverDetail.maxLengthAddress) {
    receiverAddress = `${receiverAddress.substring(0, 150)}...`;
  }

  return {
    receiverAddress: receiverAddress,
  };
};

export const externalRefSection = (externalRef: string) => {
  const externalDetail = {
    maxLengthAddress: 25,
  };

  if (externalRef.length > externalDetail.maxLengthAddress) {
    externalRef = `${externalRef.substring(0, 25)}...`;
  }

  return {
    externalRef: externalRef,
  };
};

export const clientLabelSection = (clientValue: string) => {
  const clientLabelDetail = {
    sectOne: {
      normal: {
        x: 96,
        y: 99,
      },
      over: {
        x: 95,
        y: 99,
      },
    },
    sectTwo: {
      normal: {
        x: 196.5,
        y: 99,
      },
      over: {
        x: 196,
        y: 99,
      },
    },
    sectThree: {
      normal: {
        x: 96,
        y: 243,
      },
      over: {
        x: 95,
        y: 243,
      },
    },
    sectFour: {
      normal: {
        x: 196.5,
        y: 243,
      },
      over: {
        x: 196,
        y: 243,
      },
    },
    sectThermal: {
      normal: {
        x: 92.5,
        y: 99,
      },
      over: {
        x: 91.5,
        y: 99,
      },
    },
  };

  let clientValueCoorSect1 = clientLabelDetail.sectOne.normal;
  let clientValueCoorSect2 = clientLabelDetail.sectTwo.normal;
  let clientValueCoorSect3 = clientLabelDetail.sectThree.normal;
  let clientValueCoorSect4 = clientLabelDetail.sectFour.normal;
  let clientValueCoorSectThermal = clientLabelDetail.sectThermal.normal;
  if (clientValue.length === 3) {
    clientValueCoorSect1 = clientLabelDetail.sectOne.over;
    clientValueCoorSect2 = clientLabelDetail.sectTwo.over;
    clientValueCoorSect3 = clientLabelDetail.sectThree.over;
    clientValueCoorSect4 = clientLabelDetail.sectFour.over;
    clientValueCoorSectThermal = clientLabelDetail.sectThermal.over;
  }

  return {
    clientValueCoorSect1: clientValueCoorSect1,
    clientValueCoorSect2: clientValueCoorSect2,
    clientValueCoorSect3: clientValueCoorSect3,
    clientValueCoorSect4: clientValueCoorSect4,
    clientValueCoorSectThermal: clientValueCoorSectThermal,
  };
};

export const convertQRCode = async (text: string) => {
  return QRCode.toDataURL(text, { errorCorrectionLevel: "Q" }).then(
    (url: string) => {
      return url;
    }
  );
};

export const abbreviationVendorCode = (
  sttVendorName: string,
  sttDistrictType: string
) => {
  if (sttVendorName) {
    return sttDistrictType.toLowerCase().includes("lanjutan")
      ? `${sttVendorName.charAt(0).toUpperCase()}L `
      : `${sttVendorName.charAt(0).toUpperCase()} `;
  } else {
    if (sttDistrictType === "pt-pos-lanjutan") {
      return `PL `;
    } else if (sttDistrictType === "pt-pos") {
      return `P `;
    } else {
      return ``;
    }
  }
};

export const isShipmentC1Cod = (shipmentAlgo: string, isCod: boolean) => {
  return (
    shipmentAlgo.startsWith(
      FlagsFeatureShipmentC1.feature_shipment_c1.getValue()
    ) && isCod
  );
};

export const isShipmentFavoritCOD = (shipmentAlgo: string, isCod: boolean) => {
  return shipmentAlgo.match(/^AP|^AS/gi) && isCod;
};

export const estimationBound = (
  shipmentAlgo: string,
  bookingType: string,
  accountType: string,
  isCod: boolean
) => {
  if (bookingType === "universal") {
    return true;
  }
  const isCA = shipmentAlgo?.match(/^AP|^AS|^AR|^AG|^AD|^AP|^AO/gi);
  const isCPrefix = shipmentAlgo?.match(/^C1|^C2/gi);
  if (isCod && bookingType !== "client" && bookingType !== "shipment") {
    return bookingType === "manual" && accountType === "client" ? true : false;
  } else if (isCA) {
    return false;
  } else if (bookingType === "manual" && accountType !== "client") {
    return false;
  } else if (isCPrefix && bookingType === "shipment") {
    return true;
  } else {
    return true;
  }

  /*if (
    bookingType === "shipment" &&
    !prefixAlgoEstimationShow(shipmentAlgo) &&
    !isShipmentC1Cod(shipmentAlgo, isCod) &&
    (accountType === "partner" || accountType === "internal")
  ) {
    // shipment type by pos
    return true;
  } else if (bookingType === "manual" && accountType === "partner") {
    // manual type by pos
    return false;
  } else if (bookingType === "manual" && accountType === "client") {
    // manual type by client
    return true;
  } else if (bookingType === "client" && accountType === "partner" && !isShipmentC1Cod(shipmentAlgo, isCod)) {
    // client type by pos
    return true;
  } else if (bookingType === "client" && accountType === "internal" && !isShipmentC1Cod(shipmentAlgo, isCod)) {
    // client type by internal
    return true;
  } else return bookingType === "client" && accountType === "client" && !isShipmentC1Cod(shipmentAlgo, isCod);*/
};

export const vendorPickupDetail = (
  sttVendorName: string,
  sttDistrictType: string
) => {
  const vendorMap: any = {
    NINJA: "NX",
    NX: "NX",
    JNE: "JN",
    PI: "PI"
  };

  const vendor = vendorMap[sttVendorName] ?? sttVendorName;

  if (sttVendorName) {
    if (sttDistrictType === "vendor-lanjutan") {
      return `${vendor}`;
    } else if (sttDistrictType === "vendor") {
      return `${vendor}`;
    } else if (vendor.toLowerCase() == "pi") {
      return `${vendor}`.toUpperCase();
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const isVendorAvailable = (vendor: string, section: number) => {
  const rect = {
    sectionOneAndTwo: {
      normal: {
        vendorSectionHeight: 90,
        commoditySectionHeight: 93,
        bookedBySection: 99,
      },
      vendorAvailable: {
        vendorSectionHeight: 81,
        commoditySectionHeight: 85,
        bookedBySection: 89,
      },
    },
    sectionThreeAndFour: {
      normal: {
        vendorSectionHeight: 234,
        commoditySectionHeight: 237,
        bookedBySection: 242,
      },
      vendorAvailable: {
        vendorSectionHeight: 225,
        commoditySectionHeight: 229,
        bookedBySection: 233,
      },
    },
  };

  if (section === 1 || section === 2) {
    return vendor === ""
      ? rect.sectionOneAndTwo.normal
      : rect.sectionOneAndTwo.vendorAvailable;
  } else {
    return vendor === ""
      ? rect.sectionThreeAndFour.normal
      : rect.sectionThreeAndFour.vendorAvailable;
  }
};

export const DOPositions = (clientLabel: string, section: number) => {
  const rect: any = {
    0: {
      normal: {
        background: {
          x: 79,
          y: 94,
        },
        text: {
          x: 81.5,
          y: 99,
        },
      },
      over: {
        background: {
          x: 90,
          y: 94,
        },
        text: {
          x: 92.5,
          y: 99,
        },
      },
    },
    1: {
      normal: {
        background: {
          x: 82,
          y: 94,
        },
        text: {
          x: 84.5,
          y: 99,
        },
      },
      over: {
        background: {
          x: 93,
          y: 94,
        },
        text: {
          x: 95.5,
          y: 99,
        },
      },
    },
    2: {
      normal: {
        background: {
          x: 183,
          y: 94,
        },
        text: {
          x: 185.5,
          y: 99,
        },
      },
      over: {
        background: {
          x: 194,
          y: 94,
        },
        text: {
          x: 196.5,
          y: 99,
        },
      },
    },
    3: {
      normal: {
        background: {
          x: 82,
          y: 238,
        },
        text: {
          x: 84.5,
          y: 243,
        },
      },
      over: {
        background: {
          x: 93,
          y: 238,
        },
        text: {
          x: 95.5,
          y: 243,
        },
      },
    },
    4: {
      normal: {
        background: {
          x: 183,
          y: 238,
        },
        text: {
          x: 185.5,
          y: 243,
        },
      },
      over: {
        background: {
          x: 194,
          y: 238,
        },
        text: {
          x: 196.5,
          y: 243,
        },
      },
    },
  };

  if (clientLabel) {
    return rect[section].normal;
  }

  return rect[section].over;
};

export const isCourierRecommendation = (prefixRefNo: string) => {
  const prefixList: Array<string> = ["TKP"];
  const prefixBucket: Array<string> = [];

  for (const item of prefixList) {
    if (prefixRefNo.includes(item)) {
      prefixBucket.push(item);
    }
  }

  return prefixBucket.length > 0;
};

export const convertSla = (sla: string) => {
  return sla.replace(/[^\d.-]/g, "");
};

export const handleNameWithLabels = (data: PrintDataEntitas, isA4 = true) => {
  let nameWidth = 95;
  let countLabel = 0;
  if (!isA4) {
    nameWidth = 92;
  }
  if (!data.sttVendorRefferenceNo) {
    if (data.isDangerousGoods) {
      countLabel += 1;
    }
    if (data.sttShipmentSource) {
      countLabel += 1;
    }
    if (data.sttIsDo) {
      countLabel += 1;
    }
    if (countLabel >= 1) {
      nameWidth -= 12;
    }
    if (countLabel >= 2) {
      nameWidth -= 11;
    }
    if (countLabel >= 3) {
      nameWidth -= 11;
    }
  }
  return nameWidth;
};

export const splitBookedName = (params: {
  doc: jsPDF;
  bookedName: string;
  nameWidth: number;
}) => {
  const maxLine = 3;
  const { doc, bookedName, nameWidth } = params;
  let res = doc.splitTextToSize(bookedName, nameWidth);
  if (res.length > maxLine) {
    const lastLineIndex = maxLine - 1;
    res[lastLineIndex] = `${res[lastLineIndex].substring(
      0,
      res[lastLineIndex].length - 3
    )}...`;
    res = res.slice(0, maxLine);
  }
  return res;
};

export const maskPhone = (phone: string) => {
  if (!phone) {
    return;
  }
  const regexPattern =
    phone.length < 7 ? /(\+?\d{1})(\d+)(\d{1})/g : /(\+?\d{3})(\d+)(\d{2})/g;
  return phone.replace(
    regexPattern,
    (match: any, start: any, middle: any, end: any) => {
      return start + "*".repeat(middle.length) + end;
    }
  );
};

export const maskPhoneV2 = (phone: string) => {
  if (!phone) {
    return;
  }

  if (phone.length <= 10) {
    // Mask from the 5th character to the end for shorter numbers
    return phone.replace(
      /(\+?\d{4})(\d{3})(\d*)/,
      (_match: string, start: string, _middle: string) => start + "*".repeat(3)
    );
  }

  // Mask from the 5th to the 10th character for longer phone numbers
  const regexPattern = /(\+?\d{4})(\d{6})(\d+)/;
  return phone.replace(
    regexPattern,
    (_match: string, start: string, _middle: string, end: string) =>
      start + "*".repeat(3) + end
  );
};

export const badWordsMasking = (senteces: string) => {
  if (senteces) {
    const badWord: any = {
      DUREX: "OFFICIAL STORE RB",
      "DUREX STORE": "OFFICIAL STORE RB",
      "SUTRA FIESTA SHOP": "PT. DHARMENDRA KUMAR TRIYAGI INDONESIA",
      "SUTRA FIESTA ANDALAN OFFICIAL": "PT. DHARMENDRA KUMAR TRIYAGI INDONESIA",
    };

    return badWord[senteces.toUpperCase()] ?? senteces;
  }

  return "";
};

export const titleCase = (val: string) => {
  if (!val) return "";
  const sentence = val
    .trim()
    .toLowerCase()
    .split(" ");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0]?.toUpperCase() + sentence[i]?.slice(1);
  }

  return sentence?.join(" ");
};

export const productRectMini = (product: string, position: string) => {
  const type: any = {
    REGPACK: {
      rect: 61,
      center: 64,
    },
    LANDPACK: {
      rect: 60,
      center: 64,
    },
    ONEPACK: {
      rect: 61,
      center: 64.5,
    },
    INTERPACK: {
      rect: 60,
      center: 63.5,
    },
    DOCUPACK: {
      rect: 60,
      center: 63.5,
    },
    MIXPACK: {
      rect: 61.5,
      center: 65,
    },
    SDPACK: {
      rect: 62,
      center: 65,
    },
    BIGPACK: {
      rect: 62,
      center: 65,
    },
    DORPACK: {
      rect: 61,
      center: 64,
    },
    JAGOPACK: {
      rect: 60,
      center: 64,
    },
    OTOPACK150: {
      rect: 59,
      center: 62.5,
    },
    OTOPACK250: {
      rect: 59,
      center: 62.5,
    },
    JUMBOPACK: {
      rect: 58.9,
      center: 62.9,
    },
    SAMEDAY: {
      rect: 61,
      center: 64,
    },
    "": {
      rect: 60,
      center: 64,
    },
  };

  const checkProductTypeExist = product in type;
  if (checkProductTypeExist) {
    return position === "left" ? type[product].rect : type[product].center;
  } else {
    return position === "left" ? type[""].rect : type[""].center;
  }
};

export const destinationRect = (ursa: string) => {
  const type: any = {
    "8": {
      destinationLeft: 68,
      destinationBoldLeft: 68.2,
      verticalLineLeft: 70,
    },
    "7": {
      destinationLeft: 72,
      destinationBoldLeft: 72.2,
      verticalLineLeft: 74,
    },
    "6": {
      destinationLeft: 74,
      destinationBoldLeft: 74.2,
      verticalLineLeft: 76,
    },
    "5": {
      destinationLeft: 76,
      destinationBoldLeft: 76.2,
      verticalLineLeft: 78,
    },
    "4": {
      destinationLeft: 80,
      destinationBoldLeft: 80.2,
      verticalLineLeft: 82,
    },
    "3": {
      destinationLeft: 84,
      destinationBoldLeft: 84.2,
      verticalLineLeft: 86,
    },
    "2": {
      destinationLeft: 86,
      destinationBoldLeft: 86.2,
      verticalLineLeft: 90,
    },
    "1": {
      destinationLeft: 90,
      destinationBoldLeft: 90.2,
      verticalLineLeft: 94,
    },
    "0": {
      destinationLeft: 72,
      destinationBoldLeft: 72.2,
      verticalLineLeft: 74,
    },
  };

  const ursaLength = ursa.length.toString();
  const checkDestinationTypeExist = ursaLength in type;
  if (checkDestinationTypeExist) {
    return type[ursaLength];
  } else {
    return type["0"];
  }
};

export const dimensionRect = (weight: string) => {
  const type: any = {
    "10": {
      rectDimension: 87,
      fontVW: 7,
    },
    "9": {
      rectDimension: 87,
      fontVW: 8,
    },
    "8": {
      rectDimension: 88,
      fontVW: 8,
    },
    "7": {
      rectDimension: 88,
      fontVW: 9,
    },
    "6": {
      rectDimension: 89,
      fontVW: 9,
    },
    "5": {
      rectDimension: 89,
      fontVW: 10,
    },
    "0": {
      rectDimension: 89,
      fontVW: 10,
    },
  };

  const dimensionLength = weight.length.toString();
  const checkDimensionTypeExist = dimensionLength in type;
  if (checkDimensionTypeExist) {
    return type[dimensionLength];
  } else {
    return type["0"];
  }
};

export const totalBiayaRect = (price: string) => {
  const type: any = {
    "14": {
      rect: 84,
    },
    "13": {
      rect: 84,
    },
    "12": {
      rect: 85,
    },
    "11": {
      rect: 85,
    },
    "10": {
      rect: 85,
    },
    "9": {
      rect: 86,
    },
    "8": {
      rect: 86.5,
    },
    "7": {
      rect: 87,
    },
    6: {
      rect: 88,
    },
    "5": {
      rect: 88,
    },
    "4": {
      rect: 89,
    },
    "3": {
      rect: 90,
    },
    "2": {
      rect: 90,
    },
    "1": {
      rect: 91,
    },
    "0": {
      rect: 91,
    },
  };

  const priceLength = price.length.toString();
  const checkPriceTypeExist = priceLength in type;
  if (checkPriceTypeExist) {
    return type[priceLength];
  } else {
    return type["0"];
  }
};

export const isCodRetail = (
  bookingType: string,
  accountType: string,
  shipmentAlgo: string,
  isCod: boolean
) => {
  const codRetail =
    bookingType === "manual" &&
    (accountType === "partner" || accountType === "internal") &&
    isCod;
  return (
    codRetail ||
    isShipmentC1Cod(shipmentAlgo, isCod) ||
    isShipmentFavoritCOD(shipmentAlgo, isCod)
  );
};

export const codDfodLabel = (isCod: boolean, isDfod: boolean) => {
  let result = "";
  if (!isCod && !isDfod) {
    result = "non-cod";
  }

  if (isCod && !isDfod) {
    result = "cod";
  }

  if (isDfod) {
    result = "dfod";
  }

  return result;
};

export const formatPrice = (locale: string, value: number) => {
  return locale === "MY"
    ? formatPriceRM(value)
    : formatPriceRP(value);
}

export const limitLengthName = (name: string, limit: number) => {
  const filterredWord = name.length > limit ? `${name.substring(0, limit)}` : name;

  const words = filterredWord.trim().split(/\s+/);

  // Check if the string has more than 1 word and is longer than 60 characters
  if (words.length > 1 && name.length > limit) {
    // Take the last word and replace it with its initial
    const lastWord = words[words.length - 1];
    const initial = lastWord.charAt(0);

    // Replace the last word with its initial
    words[words.length - 1] = `${initial}`;

    let newWords = words.join(" ");
    if (newWords.length == limit) {
      words.pop();
      const lastWord = words[words.length - 1];
      const initial = lastWord.charAt(0);
      words[words.length - 1] = `${initial}`;
      newWords = words.join(" ");
    }
    return `${newWords}.`;
  }
  return name;
};

export const isShipmentCa = (value: string): boolean =>
  !!value?.match(/^AP|^AS|^AR|^AG|^AD|^AP|^AO/gi);
