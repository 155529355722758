/* eslint-disable @typescript-eslint/camelcase */
import ApiService from "@/app/infrastructures/services/ApiService";
import { UserDataMapper } from "@/data/persistences/mappers/UserDataMapper";
import { UserRepositoryInterface } from "@/data/persistences/repositories/contracts/UserRepositoryInterface";
import {
  UserEntities,
  UserDetailData,
  ReferenceEntities,
  RoleEntities
} from "@/domain/entities/User";
import { Endpoints } from "@/app/infrastructures/misc/Endpoints";
import {
  EditUserRequestInterface,
  AddUserRequestInterface
} from "@/data/payload/contracts/UserRequest";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import {
  EditUserApiRequest,
  AddUserApiRequest,
  RequestListUser,
  EditRoleApiRequest,
  EditAssigned3lcApiRequest
} from "@/data/payload/api/UserApiRequest";
import { AccountController } from "@/app/ui/controllers/AccountController";

export class UserApiRepository implements UserRepositoryInterface {
  private service: ApiService;
  private mapper: UserDataMapper;
  private endpoints: Endpoints;

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  // get account type
  get isInternal() {
    return this.dataProfile.account_type === "internal";
  }

  constructor(
    service: ApiService,
    mapper: UserDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getUserList(params: RequestListUser): Promise<UserEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListUser(params),
      {}
    );
    return this.mapper.convertUserDataFromApi(resp);
  }

  public async getDetailUser(id: any): Promise<UserDetailData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailUser(id),
      {}
    );
    return this.mapper.convertDetailUserDataFromApi(resp);
  }

  public async editUser(
    payload: EditUserRequestInterface
  ): Promise<ResponsePayload> {
    if (this.isInternal) {
      // pointing to private API
      const resp = await this.service.invokePrivate(
        "PUT",
        this.endpoints.editUser(),
        undefined,
        payload as EditUserApiRequest
      );
      return this.mapper.convertChangeDataFromApi(resp);
    }
    const resp = await this.service.invoke(
      "PUT",
      this.endpoints.editUser(),
      undefined,
      payload as EditUserApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getReference(
    account_type: any,
    search: string
  ): Promise<ReferenceEntities[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getReference(account_type, search),
      {}
    );
    return this.mapper.convertReferenceDataFromApi(resp);
  }

  public async getRole(account_type: any): Promise<RoleEntities[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getRole(account_type),
      {}
    );
    return this.mapper.convertRoleDataFromApi(resp);
  }

  public async addUser(
    payload: AddUserRequestInterface
  ): Promise<ResponsePayload> {
    if (this.isInternal) {
      // pointing to private API
      const resp = await this.service.invokePrivate(
        "POST",
        this.endpoints.addUser(),
        undefined,
        payload as AddUserApiRequest
      );
      return this.mapper.convertChangeDataFromApi(resp);
    }
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.addUser(),
      undefined,
      payload as AddUserApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async editRole(
    payload: EditUserRequestInterface
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "PUT",
      this.endpoints.editUser(),
      undefined,
      payload as EditRoleApiRequest
    );
    return this.mapper.convertFormDataFromApi(resp);
  }

  public async editAssigned3lc(
    payload: EditUserRequestInterface
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "PUT",
      this.endpoints.editUser(),
      undefined,
      payload as EditAssigned3lcApiRequest
    );
    return this.mapper.convertFormDataFromApi(resp);
  }
}
