/* eslint-disable @typescript-eslint/camelcase */
import {
  EditClientManagementRequestInterface,
  RenewRatesRequestInterface,
  ApproveRenewRatesInterface,
  RejectRenewRatesInterface
} from "../contracts/ClientManagementRequest";
import {
  ConvertObjectCamelToSnakeCase,
  toFormDataUtils
} from "@/app/infrastructures/misc/Utils";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class AddRenewRatesRequest implements RenewRatesRequestInterface {
  client_id: number;
  version_basic: string;
  discount: number | null;
  is_custom_rate: boolean;
  version_name: string;
  archive_file: any;
  start_date: string;
  end_date: string;
  is_custom_rate_only: boolean;
  constructor(
    client_id: number,
    version_basic: string,
    discount: number | null,
    is_custom_rate: boolean,
    version_name: string,
    archive_file: any,
    start_date: string,
    end_date: string,
    is_custom_rate_only: boolean
  ) {
    this.client_id = client_id;
    this.version_basic = version_basic;
    this.discount = discount;
    this.is_custom_rate = is_custom_rate;
    this.version_name = version_name;
    this.archive_file = archive_file;
    this.start_date = start_date;
    this.end_date = end_date;
    this.is_custom_rate_only = is_custom_rate_only;
  }
  toFormData(): FormData {
    return toFormDataUtils({
      client_id: this.client_id,
      version_basic: this.version_basic,
      is_custom_rate: this.is_custom_rate,
      discount: this.discount,
      start_date: this.start_date,
      end_date: this.end_date,
      version_name: this.version_name,
      archive_file: this.archive_file,
      is_custom_rate_only: this.is_custom_rate_only
    });
  }
}

export class EditClientManagementBasicInformationRequest {
  id = 0;
  companyName = "";
  clientAlias = "";
  category = "";
  address = "";
  districtCode = "";
  phone = "";
  email = "";
  taxNumber = "";
  contactName = "";
  contactJobTitle = "";
  contactEmail = "";
  contactPhone = "";
  bankName = "";
  bankAccountName = "";
  bankAccountNumber = "";
  bankAccountEmail = "";
  bankAccountLabel = "";
  isCod = false;
  isDfod = false;
  isDo = false;
  partnerPosId = 0;
  partnerPosQuantifierCommission = "";
  partnerPosNumeratorCommission = "";
  partnerPosCommission = 0;
  type = "";
  receiverName = "";
  receiverPhone = "";
  receiverAddress = "";
  receiverDistrict = "";
  receiverCity = "";
  codFee = 0;
  companySize = "";
  isEfakturWithNik = false;
  nikId = "";
  nikName = "";
  nikAddress = "";
  taxpayerName = "";
  taxpayerAddress = "";
  billingWaNumber = "";
  billingEmail = "";
  billingScheduleSo = "";
  billingPaymentPeriod = "";
  vaBankName = "";
  vaBankNumber = "";
  vaBankAccountName = "";
  vaBankLabel = "";
  vaBankEmail = "";
  contractStartDate = "";
  contractEndDate = "";
  contractAttachment: any = "";
  isReuploadContract = false;
  clientPadFee = 0;
  paymentMethod = "";
  clientCodConfigAmount = "";
  clientCodShipmentDiscount: string | number = 0;
  clientBranchDoPaymentType = "";
  isReturnConfig = false;
  returnConfigLimit: any = 0;
  returnConfigReasonIds = "";
  transactionCode = "";

  constructor(fields?: Partial<EditClientManagementBasicInformationRequest>) {
    Object.assign(this, fields);
  }
  public toFormData() {
    return toFormDataUtils(ConvertObjectCamelToSnakeCase(this));
  }
}

export class EditClientCurrentRatePeriodeRequest
  implements EditClientManagementRequestInterface {
  private id: number;
  private end_date: string;

  constructor(id: number, end_date: string) {
    this.id = id;
    this.end_date = end_date;
  }
  public toJSON(): string {
    return JSON.stringify({
      rate_version_id: this.id,
      end_date: this.end_date
    });
  }
}
export class EditRenewRatesClientManagementApiRequest
  implements RenewRatesRequestInterface {
  private readonly id: number;
  private company_name: string;
  private email: string;
  private phone: string;
  private address: string;
  private tax_number: string;
  private readonly version_basic: string;
  private readonly is_custom_rate: boolean;
  private readonly discount: number;
  private readonly price_rate_start_date: string;
  private readonly price_rate_end_date: string;
  private readonly version_name: string;
  private readonly archive_file: any;
  private readonly is_update_custom: boolean;
  private readonly rate_version_id: string;
  private readonly is_custom_rate_only: boolean;

  constructor(
    id: number,
    company_name: string,
    email: string,
    phone: string,
    address: string,
    tax_number: string,
    version_basic: string,
    is_custom_rate: boolean,
    discount: number,
    price_rate_start_date: string,
    price_rate_end_date: string,
    version_name: string,
    archive_file: any,
    is_update_custom: boolean,
    rate_version_id: string,
    is_custom_rate_only: boolean
  ) {
    this.id = id;
    this.company_name = company_name;
    this.email = email;
    this.phone = phone;
    this.address = address;
    this.tax_number = tax_number;
    this.version_basic = version_basic;
    this.is_custom_rate = is_custom_rate;
    this.discount = discount;
    this.price_rate_start_date = price_rate_start_date;
    this.price_rate_end_date = price_rate_end_date;
    this.version_name = version_name;
    this.archive_file = archive_file;
    this.is_update_custom = is_update_custom;
    this.rate_version_id = rate_version_id;
    this.is_custom_rate_only = is_custom_rate_only;
  }

  public toFormData() {
    const data = {
      client_id: this.id,
      version_basic: this.version_basic,
      is_custom_rate: this.is_custom_rate,
      discount: this.discount,
      start_date: this.price_rate_start_date,
      end_date: this.price_rate_end_date,
      version_name: this.version_name,
      archive_file: this.archive_file,
      is_update_custom: this.is_update_custom,
      rate_version_id: this.rate_version_id,
      is_custom_rate_only: this.is_custom_rate_only
    };
    return toFormDataUtils(data);
  }
}

export class ApproveRenewRatesRequest implements ApproveRenewRatesInterface {
  private id: number;

  constructor(id: number) {
    this.id = id;
  }

  public toJSON(): string {
    return JSON.stringify({
      id: this.id
    });
  }
}

export class RejectRenewRatesRequest implements RejectRenewRatesInterface {
  private id: number;
  private reject_reason: string;

  constructor(id: number, reject_reason: string) {
    this.id = id;
    this.reject_reason = reject_reason;
  }

  public toJSON(): string {
    return JSON.stringify({
      id: this.id,
      reject_reason: this.reject_reason
    });
  }
}

export class FreezeUnfreezeClient
  implements EditClientManagementRequestInterface {
  id: number;
  clientFreezeReason: string | undefined;

  constructor(id: number, clientFreezeReason: string) {
    this.id = id;
    this.clientFreezeReason = clientFreezeReason;
  }

  public toJSON(): string {
    return JSON.stringify({
      id: this.id,
      banned_reason: this.clientFreezeReason
    });
  }
}

export class RetryUploadClientRate
  implements EditClientManagementRequestInterface {
  clientId: number;
  rateVersionId: number;

  constructor(clientId: number, rateVersionId: number) {
    this.clientId = clientId;
    this.rateVersionId = rateVersionId;
  }

  public toJSON(): string {
    return JSON.stringify({
      rate_version_id: this.rateVersionId,
      client_id: this.clientId
    });
  }
}

export class RequestListClientManagement {
  page = 1;
  limit = 20;
  search = "";
  isCod = "" as boolean | string;
  version = "v1";
  isTotalData = true;

  constructor(fields?: Partial<RequestListClientManagement>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class RequestUpdateCurrentRates {
  clientId = 0;
  versionBasic: any = "";
  discount = "";
  archiveFile = "";
  isScheduled = true;
  rateActiveDate = "";

  constructor(fields?: Partial<RequestUpdateCurrentRates>) {
    Object.assign(this, fields);
  }

  public toFormData() {
    return toFormDataUtils(ConvertObjectCamelToSnakeCase(this));
  }
}

export class EditRenewRatesClientManagementApiRequestV2 {
  clientId = 0;
  versionBasic = "";
  isCustomRate = false;
  isCustomRateOnly = false;
  isScheduled = false;
  discount = 0;
  startDate = "";
  endDate = "";
  versionName = "";
  rateActiveDate = "";
  rateVersionId = 0;
  archiveFile = "";

  constructor(fields?: Partial<EditRenewRatesClientManagementApiRequestV2>) {
    Object.assign(this, fields)
  }

  toFormData() {
    const data = {
      client_id: this.clientId,
      version_basic: this.versionBasic,
      is_custom_rate: this.isCustomRate,
      is_custom_rate_only: this.isCustomRateOnly,
      is_scheduled: this.isScheduled,
      discount: this.discount,
      start_date: this.startDate,
      end_date: this.endDate,
      version_name: this.versionName,
      rate_active_date: this.rateActiveDate,
      rate_version_id: this.rateVersionId,
      archive_file: this.archiveFile
    };

    return toFormDataUtils(data);
  }
}

export class AddRenewRatesClientManagementApiRequestV2 {
  clientId = 0;
  versionBasic = "";
  isCustomRate = false;
  isCustomRateOnly = false;
  isScheduled = false;
  discount = 0;
  startDate = "";
  endDate = "";
  versionName = "";
  rateActiveDate = "";
  archiveFile = "";
  priceType = "";
  insuranceType = "";
  insurancePercentage = 0;
  "3lcOrigin" = "";
  constructor(fields?: Partial<AddRenewRatesClientManagementApiRequestV2>) {
    Object.assign(this, fields)
  }

  toFormData() {
    const data = {
      client_id: this.clientId,
      version_basic: this.versionBasic,
      is_custom_rate: this.isCustomRate,
      is_custom_rate_only: this.isCustomRateOnly,
      is_scheduled: this.isScheduled,
      discount: this.discount,
      start_date: this.startDate,
      end_date: this.endDate,
      version_name: this.versionName,
      rate_active_date: this.rateActiveDate,
      archive_file: this.archiveFile,
      price_type: this.priceType,
      insurance_type: this.insuranceType,
      insurance_percentage: this.insurancePercentage,
      "3lc_origin": this["3lcOrigin"]
    };

    return toFormDataUtils(data);
  }
}
