
import { Vue, prop } from "vue-class-component";

class Props {
  isHal = prop<boolean>({
    type: Boolean,
    default: false
  });
  flag = prop<string>({
    type: String,
    default: ""
  });
}

export default class STISCIcon extends Vue.with(Props) {}
