
import { Options, Vue, prop } from "vue-class-component";
import InvalidIcon from "./image/invalid.vue";
import STISCIcon from "./image/sti-sc.vue";

class Props {
  isInvalid = prop<boolean>({
    type: Boolean,
    default: false
  });
  flag = prop<string>({
    type: String,
    default: ""
  });
}

@Options({
  components: {
    InvalidIcon,
    STISCIcon
  }
})
export default class STISCReverseScanIllustration extends Vue.with(Props) {
  get isHal() {
    return this.flag.toLowerCase() == "segera hal";
  }

  get decideSTTIconComponent(): string {
    if (this.isInvalid) return "InvalidIcon";
    return "STISCIcon";
  }
}
