
import { Options, prop, Vue } from "vue-class-component";
import RadioButton from "primevue/radiobutton";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import { UserController } from "@/app/ui/controllers/UserController";
import Input from "@/app/ui/components/input/index.vue";
import { RoleEntities } from "@/domain/entities/User";
import { EditRoleApiRequest, EditAssigned3lcApiRequest } from "@/data/payload/api/UserApiRequest";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { debounce } from "lodash";
import { CityData } from '@/domain/entities/Location';

class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
  type = prop<string>({
    type: String,
    default: ""
  });
}

@Options({
  emits: ["update:modelValue", "success"],
  components: {
    RadioButton,
    Input
  }
})
export default class ModalEditDetail extends Vue.with(Props) {
  assigned3lc = [] as OptionsClass[];
  mounted() {
    this.assigned3lc = this.dataDetail.assigned3lc?.split(',').map(lc => new OptionsClass({name: lc.trim(), value: lc.trim()})) || [];
  }
  get title() {
    switch (this.type) {
      case "status":
        return "Ubah Status Akun";
      case "email":
        return "Ubah Email";
      case "role":
        return "Ubah Role";
      case "3lc":
        return "Ubah ditugaskan ke 3LC";
      default:
        return "Ubah";
    }
  }
  toggleModalEditDetail(bool: boolean) {
    this.$emit("update:modelValue", bool);
  }
  openSnackbar(message: string) {
    MainAppController.setSnackbarMessage(message);
    MainAppController.setSnackbar(true);
  }
  get isFormValid() {
    switch (this.type) {
      case "status":
        return true;
      case "role":
        return !!this.roleDefault.value;
      case "email":
        return !this.errorFormEmail;
      case "3lc":
        return this.assigned3lc.length;
      default:
        return false;
    }
  }
  submitConfirmation() {
    switch (this.type) {
      case "status":
        this.confirmationEditStatus();
        break;
      case "email":
        this.confirmationEdit();
        break;
      case "role":
        this.confirmationEdit();
        break;
      case "3lc":
        this.confirmationEditAssigned3lc();
        break;
      default:
        break;
    }
  }

  // confirmation change status
  statusAccount = UserController.userDetail.status.toLowerCase();
  confirmationEditStatus() {
    this.toggleModalEditDetail(false);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: `Konfirmasi ${
          this.statusAccount === "active" ? "Pengaktifan" : "Penonaktifan"
        }`,
        message:
          "Akun yang tidak aktif tidak dapat melakukan aktivitas apapun sampai akun diaktifkan kembali.",
        textCancel: "Kembali",
        textSuccess: `${
          this.statusAccount === "active" ? "Aktifkan" : "Nonaktifkan"
        }`,
        onSubmit: () => this.submitEditStatus(),
        onClose: () => {
          this.toggleModalEditDetail(true);
          MainAppController.closeMessageModal();
        },
        image: "are-you-sure"
      })
    );
  }
  async submitEditStatus() {
    const assigned3lc = this.assigned3lc.map(lc => lc.value).join(",");
    MainAppController.closeMessageModal();
    const resp = await UserController._onEditUser({
      id: Number(UserController.userDetail.id),
      isActive: this.statusAccount,
      email: "",
      type: "Status Akun",
      assigned3lc: assigned3lc
    });
    if (resp) {
      this.openSnackbar(
        `${
          this.statusAccount === "active" ? "Pengaktifan" : "Penonaktifan"
        } status berhasil !`
      );
      this.$emit("success");
    }
  }

  // confirmation change email
  // new email
  newEmail = "";
  get errorFormEmail() {
    return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.newEmail);
  }
  // old email
  get oldEmail() {
    return UserController.userDetail.email;
  }
  confirmationEdit() {
    this.toggleModalEditDetail(false);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Konfirmasi Perubahan",
        message: `Anda yakin akan menyimpan perubahan detail Akun dengan username “${UserController.userDetail.username}” ?`,
        textCancel: "Kembali",
        textSuccess: "Simpan",
        onSubmit:
          this.type === "email" ? this.submitEditEmail : this.submitEditRole,
        onClose: () => {
          this.toggleModalEditDetail(true);
          MainAppController.closeMessageModal();
        },
        image: "are-you-sure"
      })
    );
  }
  async submitEditEmail() {
    const assigned3lc = this.assigned3lc.map(lc => lc.value).join(",");
    MainAppController.closeMessageModal();
    const resp = await UserController._onEditUser({
      id: Number(UserController.userDetail.id),
      isActive: this.statusAccount,
      email: this.newEmail,
      type: "Email",
      assigned3lc: assigned3lc
    });
    if (resp) {
      this.openSnackbar(`Perubahan email berhasil !`);
      this.$emit("success");
    }
  }

  // Role
  get roleDefault(): OptionsClass {
    return new OptionsClass(UserController.roleDefault);
  }
  setRoleDefault(data: OptionsClass) {
    UserController.setRoleDefault(data);
  }
  get roleData() {
    return UserController.roleData.map(
      (item: RoleEntities) =>
        new OptionsClass({
          name: item.name,
          value: String(item.id)
        })
    );
  }
  async submitEditRole() {
    const assigned3lc = this.assigned3lc.map(lc => lc.value).join(",");
    try {
      MainAppController.showLoading();
      MainAppController.closeMessageModal();
      MainAppController.closeErrorMessage();
      await UserController.editRole(
        new EditRoleApiRequest({
          roleId: Number(this.roleDefault.value),
          accountId: UserController.userDetail.id,
          status: this.statusAccount,
          "assigned_3lc": assigned3lc
        })
      );
      this.openSnackbar("Perubahan role berhasil !");
      this.$emit("success");
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Role Gagal Diubah !", this.submitEditRole)
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  get dataDetail() {
    return UserController.userDetail;
  }

  dataListCity: CityData[] = [];

  // origin city
  async fetchCity(search: string) {
    const response = await LocationController.getCityList({
      search: search,
      status: "active",
      page: 1,
      limit: 20,
      newEndpoint: true
    });

    this.dataListCity = response.cityData;
  }
  filterCity = debounce((search: string) => {
    if (search.length > 2 || !search) this.fetchCity(search);
  }, 250);

  async fetchCityinfiniteScroll(search = "") {
    LocationController.cityData.pagination.page++;
    const response = await LocationController.getCityList({
      search: search,
      status: "active",
      page: LocationController.cityData.pagination.page,
      limit: 20,
      newEndpoint: true
    });

    this.dataListCity.push(...response.cityData);
  }

  get cityExcludeOptions() {
    return this.dataListCity.map(e => ({
      id: e.id,
      name: `${e.code}`,
      value: `${e.code}`
    }));
  }

  get cityOptions() {
    return [
      {
        name: "Semua",
        value: "ALL"
      },
      ...this.cityExcludeOptions
    ];
  }
  get isLoadingCity() {
    return LocationController.isLoading;
  }

  confirmationEditAssigned3lc() {
    this.toggleModalEditDetail(false);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Ubah penugasan 3LC ini?",
        message: "Pastikan data user yang Anda ubah telah benar.",
        textCancel: "Kembali",
        textSuccess: "Simpan",
        onSubmit: () => {
          this.submitEditAssigned3lc();
        },
        onClose: () => {
          this.toggleModalEditDetail(true);
          MainAppController.closeMessageModal();
        },
        image: "are-you-sure"
      })
    );
  }

  async submitEditAssigned3lc() {
    const accountId = UserController.userDetail.id;
    const assigned3lc = this.assigned3lc.map(lc => lc.value).join(",");
    MainAppController.closeMessageModal();
    const resp = await UserController.editAssigned3lc(
      new EditAssigned3lcApiRequest({
        accountId: accountId,
        status: this.statusAccount,
        "assigned_3lc": assigned3lc
      })
    );
    if (resp) {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "3LC akun berhasil diperbarui",
          message: `Akses akun “${accountId}” untuk 3LC “${assigned3lc}” sudah bisa diperbarui.`,
          image: "image-modal-success",
          textSuccess: "Ok",
          onSubmit: () => {
            MainAppController.closeMessageModal();
            this.$emit("success");
          }
        })
      );
    }
  }
}
