import { Pagination } from "@/domain/entities/Pagination";
import {
  SttDetail,
  SttValidation,
  SttPieceDetail,
  CreateUpdateDeliveryResponse,
  UpdateDelEntities,
  UpdateDelData,
  PrintData,
  SttDelivery
} from "@/domain/entities/UpdateDelivery";
import { AxiosResponse } from "axios";

export class UpdateDeliveryMapper {
  public convertUpdateDelListFromApi(res: AxiosResponse): UpdateDelEntities {
    const { data } = res;

    if (!data.data.length)
      return new UpdateDelEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    else
      return new UpdateDelEntities(
        new Pagination(data.meta.page, data.meta.limit),
        data.data.map(
          (item: any) =>
            new UpdateDelData({
              id: item.id,
              deliveryTotalStt: item.delivery_total_stt,
              deliveryTotalPiece: item.delivery_total_piece,
              deliveryDriverName: item.delivery_driver_name,
              deliveryDriverPhone: item.delivery_driver_phone,
              deliveryDeliveredBy: item.delivery_delivered_by,
              deliveryCreatedAt: item.delivery_created_at,
              deliveryCreatedBy: item.delivery_created_by
            })
        )
      );
  }

  public convertSttValidationFromApi(res: AxiosResponse): SttValidation {
    const {
      data: { data }
    } = res;
    if (data.is_allow_update_status) {
      return new SttValidation({
        isAllowUpdateStatus: data.is_allow_update_status,
        isPaid: data?.is_paid,
        sttDetail: new SttDetail({
          sttId: data.stt.stt_id,
          sttNo: data.stt.stt_no,
          sttAssessmentStatus: data.stt.stt_assessment_status?.toLowerCase(),
          sttProductType: data.stt.stt_product_type,
          sttTotalPiece: data.stt.stt_total_piece,
          sttDestinationCityCode: data.stt.stt_destination_city_id,
          sttDestinationCityName: data.stt.stt_destination_city_name,
          sttOriginCityCode: data.stt.stt_origin_city_id,
          sttOriginCityName: data.stt.stt_origin_city_name,
          sttIsWoodPacking: data.stt.stt_wood_packing !== "No",
          sttCommodityCode: data.stt.stt_commodity_code,
          sttCommodityName: data.stt.stt_commodity_name,
          sttGrossWeight: data.stt.stt_gross_weight,
          sttVolumeWeight: data.stt.stt_volume_weight,
          sttChargeAbleWeight: data.stt.stt_chargeable_weight,
          sttCodAmount: data.stt.stt_cod_amount,
          sttLastStatus: data.stt.stt_last_status_id,
          sttDeliveryAttempt: data.stt.stt_delivery_attempt
            ? Number(data.stt.stt_delivery_attempt) + 1
            : 1,
          sttRecipientAddress: data.stt.stt_recipient_address,
          sttRecipientName: data.stt.stt_recipient_name,
          sttRecipientPhone: data.stt.stt_recipient_phone,
          sttPieces: data.stt.piece.map((item: any) => {
            return new SttPieceDetail({
              sttPieceId: item.stt_piece_id,
              sttPieceGrossWeight: item.stt_piece_gross_weight,
              sttPieceVolumeWeight: item.stt_piece_volume_weight,
              sttPieceNo: item.stt_piece_no,
              sttPieceLastStatus: item.stt_piece_last_status_id
            });
          }),
          sttNoElexys: data.stt.stt_elexys_no ? undefined : "-",
          sttNeedToRelabel: data.stt.stt_need_to_relabel
        })
      });
    }
    return new SttValidation({
      isAllowUpdateStatus: data.is_allow_update_status,
      errorMessage: data.error_message,
      sttDetail: data.stt ? new SttDetail({
        sttAssessmentStatus: data.stt.stt_assessment_status?.toLowerCase(),
      }) : new SttDetail()
    });
  }

  convertUpdateDeliveryCreateFromApi(
    res: AxiosResponse
  ): CreateUpdateDeliveryResponse {
    const {
      data: { data }
    } = res;
    return new CreateUpdateDeliveryResponse(
      data.del_id,
      data.total_success,
      data.total_failed,
      data.stt_failed
    );
  }

  public convertPrintDataListFromApi(result: AxiosResponse): PrintData {
    const {
      data: { data }
    } = result;

    return new PrintData({
      deliveryMasterId: data.delivery_master_id,
      deliveryMasterTotalStt: data.delivery_master_total_stt,
      deliveryMasterTotalPiece: data.delivery_master_total_piece,
      deliveryMasterTotalGrossWeight: data.delivery_master_total_gross_weight,
      deliveryMasterTotalVolumeWeight: data.delivery_master_total_volume_weight,
      deliveryMasterPartnerName: data.delivery_master_partner_name,
      deliveryMasterPartnerType: data.delivery_master_partner_type,
      deliveryMasterDriverPhone: data.delivery_master_driver_phone,
      deliveryMasterDriverName: data.delivery_master_driver_name,
      deliveryMasterDestinationCityCode:
        data.delivery_master_destination_city_code,
      deliveryMasterDestinationCityName:
        data.delivery_master_destination_city_name,
      deliveryMasterCreatedAt: data.delivery_master_created_at,
      stt: data.stt.map(
        (item: any) =>
          new SttDelivery({
            sttNo: item.stt_no,
            sttNoElexys: item.elexys_stt_no,
            productType: item.product_type,
            totalPiece: item.total_piece,
            recipientName: item.recipient_name,
            recipientAddress: item.recipient_address,
            recipientPhone: item.recipient_phone,
            codAmount: item.cod_amount,
            origin: item.origin_city_id,
            destination: item.destination_city_id
          })
      )
    });
  }
}
