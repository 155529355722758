/* eslint-disable @typescript-eslint/camelcase */
import { toFormDataUtils } from "@/app/infrastructures/misc/Utils";
import {
  ClientRegistrationRequest,
  ClientRegistrationApproveRejectRequest,
  CreateClientBranchRequest,
  EditRenewRatesRequest
} from "../contracts/ClientRegistrationRequest";

export class ClientRegistrationApiRequest implements ClientRegistrationRequest {
  private company_name!: string;
  private email!: string;
  private phone!: string;
  private address!: string;
  private district_code!: string;
  private tax_number!: string;
  private version_basic!: string;
  private is_custom_rate!: boolean;
  private is_custom_rate_only!: boolean;
  private discount!: number;
  private price_rate_start_date!: string;
  private price_rate_end_date!: string;
  private version_name!: string;
  private archive_file!: any;
  private category!: string;
  private bank_name!: string;
  private bank_account_name!: string;
  private bank_account_number!: string;
  private bank_account_email!: string;
  private bank_account_label!: string;
  private contact_name!: string;
  private contact_job_title!: string;
  private contact_email!: string;
  private contact_phone!: string;
  private type!: string;
  private company_size!: string;
  private is_efaktur_with_nik!: any;
  private nik_id!: string;
  private nik_name!: string;
  private nik_address!: string;
  private taxpayer_name!: string;
  private taxpayer_address!: string;
  private billing_wa_number!: string;
  private billing_email!: string;
  private billing_schedule_so!: string;
  private billing_payment_periode!: string;
  private va_bank_name!: string;
  private va_bank_number!: string;
  private va_bank_account_name!: string;
  private va_bank_label!: string;
  private va_bank_email!: string;
  private contract_start_date!: string;
  private contract_end_date!: string;
  private contract_attachment!: any;
  private client_type!: string;
  private payment_method!: string;
  private transaction_code!: string;
  private custom_price_type!: string;
  private price_type!: string;
  private insurance_type!: string;
  private insurance_percentage!: number;
  private lc_origin!: string;
  private is_need_assessment!: boolean;
  private client_alias!: string;

  constructor(data: {
    company_name: string,
    email: string,
    phone: string,
    address: string,
    district: string,
    tax_number: string,
    version_basic: string,
    is_custom_rate: boolean,
    is_custom_rate_only: boolean,
    discount: number,
    price_rate_start_date: string,
    price_rate_end_date: string,
    version_name: string,
    archive_file: any,
    category: string,
    bank_name: string,
    bank_account_name: string,
    bank_account_number: string,
    bank_account_email: string,
    bank_account_label: string,
    contact_name: string,
    contact_job_title: string,
    contact_email: string,
    contact_phone: string,
    type: string,
    company_size: string,
    is_efaktur_with_nik: any,
    nik_id: string,
    nik_name: string,
    nik_address: string,
    taxpayer_name: string,
    taxpayer_address: string,
    billing_wa_number: string,
    billing_email: string,
    billing_schedule_so: string,
    billing_payment_periode: string,
    va_bank_name: string,
    va_bank_number: string,
    va_bank_account_name: string,
    va_bank_label: string,
    va_bank_email: string,
    contract_start_date: string,
    contract_end_date: string,
    contract_attachment: any,
    client_type: string,
    payment_method: string,
    transaction_code: string,
    custom_price_type: string,
    price_type: string,
    insurance_type: string,
    insurance_percentage: number,
    lc_origin: string,
    is_need_assessment: boolean,
    client_alias: string
  }) {
    this.initializeBasicInfo(data);
    this.initializeBankInfo(data);
    this.initializeContactInfo(data);
    this.initializeBillingInfo(data);
    this.initializeContractInfo(data);
    this.initializePricingInfo(data);
    this.initializeAdditionalInfo(data);
  }

  private initializeBasicInfo(data: any) {
    this.company_name = data.company_name;
    this.email = data.email;
    this.phone = data.phone;
    this.address = data.address;
    this.district_code = data.district;
    this.tax_number = data.tax_number;
    this.category = data.category;
    this.client_type = data.client_type;
    this.client_alias = data.client_alias;
  }

  private initializeBankInfo(data: any) {
    this.bank_name = data.bank_name;
    this.bank_account_name = data.bank_account_name;
    this.bank_account_number = data.bank_account_number;
    this.bank_account_email = data.bank_account_email;
    this.bank_account_label = data.bank_account_label;
    this.va_bank_name = data.va_bank_name;
    this.va_bank_number = data.va_bank_number;
    this.va_bank_account_name = data.va_bank_account_name;
    this.va_bank_label = data.va_bank_label;
    this.va_bank_email = data.va_bank_email;
  }

  private initializeContactInfo(data: any) {
    this.contact_name = data.contact_name;
    this.contact_job_title = data.contact_job_title;
    this.contact_email = data.contact_email;
    this.contact_phone = data.contact_phone;
  }

  private initializeBillingInfo(data: any) {
    this.billing_wa_number = data.billing_wa_number;
    this.billing_email = data.billing_email;
    this.billing_schedule_so = data.billing_schedule_so;
    this.billing_payment_periode = data.billing_payment_periode;
  }

  private initializeContractInfo(data: any) {
    this.contract_start_date = data.contract_start_date;
    this.contract_end_date = data.contract_end_date;
    this.contract_attachment = data.contract_attachment;
  }

  private initializePricingInfo(data: any) {
    this.version_basic = data.version_basic;
    this.is_custom_rate = data.is_custom_rate;
    this.is_custom_rate_only = data.is_custom_rate_only;
    this.discount = data.discount;
    this.price_rate_start_date = data.price_rate_start_date;
    this.price_rate_end_date = data.price_rate_end_date;
    this.version_name = data.version_name;
    this.archive_file = data.archive_file;
    this.custom_price_type = data.custom_price_type;
    this.price_type = data.price_type;
    this.insurance_type = data.insurance_type;
    this.insurance_percentage = data.insurance_percentage;
    this.lc_origin = data.lc_origin;
  }

  private initializeAdditionalInfo(data: any) {
    this.is_efaktur_with_nik = data.is_efaktur_with_nik;
    this.nik_id = data.nik_id;
    this.nik_name = data.nik_name;
    this.nik_address = data.nik_address;
    this.taxpayer_name = data.taxpayer_name;
    this.taxpayer_address = data.taxpayer_address;
    this.is_need_assessment = data.is_need_assessment;
    this.type = data.type;
    this.company_size = data.company_size;
    this.payment_method = data.payment_method;
    this.transaction_code = data.transaction_code;
  }

  public toFormData() {
    const data = {
      company_name: this.company_name,
      email: this.email,
      phone: this.phone,
      address: this.address,
      district_code: this.district_code,
      tax_number: this.tax_number,
      version_basic: this.version_basic,
      is_custom_rate: this.is_custom_rate,
      is_custom_rate_only: this.is_custom_rate_only,
      discount: this.discount,
      price_rate_start_date: this.price_rate_start_date,
      price_rate_end_date: this.price_rate_end_date,
      version_name: this.version_name,
      archive_file: this.archive_file,
      category: this.category,
      bank_name: this.bank_name,
      bank_account_name: this.bank_account_name,
      bank_account_number: this.bank_account_number,
      bank_account_email: this.bank_account_email,
      bank_account_label: this.bank_account_label,
      contact_name: this.contact_name,
      contact_job_title: this.contact_job_title,
      contact_email: this.contact_email,
      contact_phone: this.contact_phone,
      type: this.type,
      company_size: this.company_size,
      is_efaktur_with_nik: this.is_efaktur_with_nik,
      nik_id: this.nik_id,
      nik_name: this.nik_name,
      nik_address: this.nik_address,
      taxpayer_name: this.taxpayer_name,
      taxpayer_address: this.taxpayer_address,
      billing_wa_number: this.billing_wa_number,
      billing_email: this.billing_email,
      billing_schedule_so: this.billing_schedule_so,
      billing_payment_period: this.billing_payment_periode,
      va_bank_name: this.va_bank_name,
      va_bank_number: this.va_bank_number,
      va_bank_account_name: this.va_bank_account_name,
      va_bank_label: this.va_bank_label,
      va_bank_email: this.va_bank_email,
      contract_start_date: this.contract_start_date,
      contract_end_date: this.contract_end_date,
      contract_attachment: this.contract_attachment,
      client_type: this.client_type,
      payment_method: this.payment_method,
      transaction_code: this.transaction_code,
      custom_price_type: this.custom_price_type,
      price_type: this.price_type,
      insurance_type: this.insurance_type,
      insurance_percentage: this.insurance_percentage,
      lc_origin: this.lc_origin,
      is_need_assessment: this.is_need_assessment,
      client_alias: this.client_alias,
    };
    return toFormDataUtils(data);
  }
}


export class ClientRegistrationApproveApiRequest
  implements ClientRegistrationApproveRejectRequest {
  private id: number;

  constructor(id: number) {
    this.id = id;
  }

  public toJSON(): string {
    return JSON.stringify({
      id: this.id
    });
  }
}

export class ClientRegistrationRejectApiRequest
  implements ClientRegistrationApproveRejectRequest {
  private id: number;
  private reject_reason: string;

  constructor(id: number, reject_reason: string) {
    this.id = id;
    this.reject_reason = reject_reason;
  }

  public toJSON(): string {
    return JSON.stringify({
      id: this.id,
      reject_reason: this.reject_reason
    });
  }
}

export class CreateClientBranchRegistration
  implements CreateClientBranchRequest {
  private client_branch_name: string;
  private client_branch_address: string;
  private client_branch_district_code: string;
  private client_branch_email: string;
  private client_branch_phone_number: string;
  private client_branch_tax_number: string;
  private client_branch_parent_id: number;
  private client_branch_partner_pos_id: number;
  private client_branch_partner_pos_commission: number;
  private client_branch_partner_pos_commission_type: string;
  private client_branch_contact_name: string;
  private client_branch_contact_job_title: string;
  private client_branch_contact_email: string;
  private client_branch_contact_phone: string;
  private client_branch_is_cod: boolean;
  private client_branch_is_do: boolean;
  private client_branch_return_cod_name: string;
  private client_branch_return_cod_phone: string;
  private client_branch_return_cod_address: string;
  private client_branch_return_cod_city_code: string;
  private client_branch_return_cod_district_code: string;
  private client_branch_return_cod_fee: number;
  private client_branch_partner_pos_commission_numerator: string;
  private client_branch_pad_fee: number;
  private client_cod_config_amount: string;
  private client_cod_shipment_discount: number;
  private client_branch_do_payment_type: string;
  private client_is_dfod: boolean;
  private is_return_config: boolean;
  private return_config_limit: number;
  private return_config_reason_ids: string;
  private client_alias: string;

  constructor(
    client_branch_name: string,
    client_branch_address: string,
    client_branch_district_code: string,
    client_branch_email: string,
    client_branch_phone_number: string,
    client_branch_tax_number: string,
    client_branch_parent_id: number,
    client_branch_partner_pos_id: number,
    client_branch_partner_pos_commission: number,
    client_branch_partner_pos_commission_type: string,
    client_branch_contact_name: string,
    client_branch_contact_job_title: string,
    client_branch_contact_email: string,
    client_branch_contact_phone: string,
    client_branch_is_cod: boolean,
    client_branch_is_do: boolean,
    client_branch_return_cod_name: string,
    client_branch_return_cod_phone: string,
    client_branch_return_cod_address: string,
    client_branch_return_cod_city_code: string,
    client_branch_return_cod_district_code: string,
    client_branch_return_cod_fee: number,
    client_branch_partner_pos_commission_numerator: string,
    client_branch_pad_fee: number,
    client_cod_config_amount: string,
    client_cod_shipment_discount: number,
    client_branch_do_payment_type: string,
    client_is_dfod: boolean,
    is_return_config: boolean,
    return_config_limit: number,
    return_config_reason_ids: string,
    client_alias: string
  ) {
    this.client_branch_name = client_branch_name;
    this.client_branch_address = client_branch_address;
    this.client_branch_district_code = client_branch_district_code;
    this.client_branch_email = client_branch_email;
    this.client_branch_phone_number = client_branch_phone_number;
    this.client_branch_tax_number = client_branch_tax_number;
    this.client_branch_parent_id = client_branch_parent_id;
    this.client_branch_partner_pos_id = client_branch_partner_pos_id;
    this.client_branch_partner_pos_commission = client_branch_partner_pos_commission;
    this.client_branch_partner_pos_commission_type = client_branch_partner_pos_commission_type;
    this.client_branch_contact_name = client_branch_contact_name;
    this.client_branch_contact_job_title = client_branch_contact_job_title;
    this.client_branch_contact_email = client_branch_contact_email;
    this.client_branch_contact_phone = client_branch_contact_phone;
    this.client_branch_is_cod = client_branch_is_cod;
    this.client_branch_is_do = client_branch_is_do;
    this.client_branch_return_cod_name = client_branch_return_cod_name;
    this.client_branch_return_cod_phone = client_branch_return_cod_phone;
    this.client_branch_return_cod_address = client_branch_return_cod_address;
    this.client_branch_return_cod_city_code = client_branch_return_cod_city_code;
    this.client_branch_return_cod_district_code = client_branch_return_cod_district_code;
    this.client_branch_return_cod_fee = client_branch_return_cod_fee;
    this.client_branch_partner_pos_commission_numerator = client_branch_partner_pos_commission_numerator;
    this.client_branch_pad_fee = client_branch_pad_fee;
    this.client_cod_config_amount = client_cod_config_amount;
    this.client_cod_shipment_discount = client_cod_shipment_discount;
    this.client_branch_do_payment_type = client_branch_do_payment_type;
    this.client_is_dfod = client_is_dfod;
    this.is_return_config = is_return_config;
    this.return_config_limit = return_config_limit;
    this.return_config_reason_ids = return_config_reason_ids;
    this.client_alias = client_alias
  }

  public toFormData(): FormData {
    const data = {
      client_branch_name: this.client_branch_name,
      client_branch_address: this.client_branch_address,
      client_branch_district_code: this.client_branch_district_code,
      client_branch_email: this.client_branch_email,
      client_branch_phone_number: this.client_branch_phone_number,
      client_branch_tax_number: this.client_branch_tax_number,
      client_branch_parent_id: this.client_branch_parent_id,
      client_branch_partner_pos_id: this.client_branch_partner_pos_id,
      client_branch_partner_pos_commission: this
        .client_branch_partner_pos_commission,
      client_branch_partner_pos_commission_type: this
        .client_branch_partner_pos_commission_type,
      client_branch_contact_name: this.client_branch_contact_name,
      client_branch_contact_job_title: this.client_branch_contact_job_title,
      client_branch_contact_email: this.client_branch_contact_email,
      client_branch_contact_phone: this.client_branch_contact_phone,
      client_branch_is_cod: this.client_branch_is_cod,
      client_branch_is_do: this.client_branch_is_do,
      client_branch_cod_return_receiver_name: this
        .client_branch_return_cod_name,
      client_branch_cod_return_receiver_phone: this
        .client_branch_return_cod_phone,
      client_branch_cod_return_receiver_address: this
        .client_branch_return_cod_address,
      client_branch_cod_return_receiver_city_code: this
        .client_branch_return_cod_city_code,
      client_branch_cod_return_receiver_district_code: this
        .client_branch_return_cod_district_code,
      client_branch_cod_fee: this.client_branch_return_cod_fee,
      client_branch_partner_pos_commission_numerator: this
        .client_branch_partner_pos_commission_numerator,
      client_pad_fee: this.client_branch_pad_fee,
      client_cod_config_amount: this.client_cod_config_amount,
      client_cod_shipment_discount: this.client_cod_shipment_discount,
      client_branch_do_payment_type: this.client_branch_do_payment_type,
      client_branch_is_dfod: this.client_is_dfod,
      is_return_config: this.is_return_config,
      return_config_limit: this.return_config_limit,
      return_config_reason_ids: this.return_config_reason_ids,
      client_alias: this.client_alias
    };
    return toFormDataUtils(data);
  }
}

export class EditClientRegistrationApiRequest
  implements EditClientRegistrationApiRequest {
  id: number;
  company_name: string;
  email: string;
  phone: string;
  address: string;
  tax_number: string;
  version_basic: string;
  is_custom_rate: boolean;
  price_rate_start_date: string;
  price_rate_end_date: string;
  discount: number;
  district_code: string;
  version_name: string;
  archive_file: any;
  is_update_custom: boolean;
  client_type: string;
  category: string;
  bank_name: string;
  bank_account_name: string;
  bank_account_number: string;
  bank_account_email: string;
  bank_account_label: string;
  contact_name: string;
  contact_job_title: string;
  contact_email: string;
  contact_phone: string;
  pos_id: string;
  pos_commission: string;
  pos_quantifier: string;
  is_do: boolean;
  is_cod: boolean;
  client_branch_return_cod_name: string;
  client_branch_return_cod_phone: string;
  client_branch_return_cod_address: string;
  client_branch_return_cod_city_code: string;
  client_branch_return_cod_district_code: string;
  client_branch_return_cod_fee: number;
  is_custom_rate_only: boolean;

  constructor(
    id: number,
    company_name: string,
    email: string,
    phone: string,
    address: string,
    district_code: string,
    tax_number: string,
    version_basic: string,
    is_custom_rate: boolean,
    discount: number,
    price_rate_start_date: string,
    price_rate_end_date: string,
    version_name: string,
    archive_file: any,
    is_update_custom: boolean,
    client_type: string,
    category: string,
    bank_name: string,
    bank_account_name: string,
    bank_account_number: string,
    bank_account_email: string,
    bank_account_label: string,
    contact_name: string,
    contact_job_title: string,
    contact_email: string,
    contact_phone: string,
    pos_id: string,
    pos_commission: string,
    pos_quantifier: string,
    is_do: boolean,
    is_cod: boolean,
    client_branch_return_cod_name: string,
    client_branch_return_cod_phone: string,
    client_branch_return_cod_address: string,
    client_branch_return_cod_city_code: string,
    client_branch_return_cod_district_code: string,
    client_branch_return_cod_fee: number,
    is_custom_rate_only: any
  ) {
    this.id = id;
    this.company_name = company_name;
    this.email = email;
    this.phone = phone;
    this.address = address;
    this.district_code = district_code;
    this.tax_number = tax_number;
    this.version_basic = version_basic;
    this.is_custom_rate = is_custom_rate;
    this.discount = discount;
    this.price_rate_start_date = price_rate_start_date;
    this.price_rate_end_date = price_rate_end_date;
    this.version_name = version_name;
    this.archive_file = archive_file;
    this.is_update_custom = is_update_custom;
    this.client_type = client_type;
    this.category = category;
    this.bank_name = bank_name;
    this.bank_account_name = bank_account_name;
    this.bank_account_number = bank_account_number;
    this.bank_account_email = bank_account_email;
    this.bank_account_label = bank_account_label;
    this.contact_name = contact_name;
    this.contact_job_title = contact_job_title;
    this.contact_email = contact_email;
    this.contact_phone = contact_phone;
    this.pos_id = pos_id;
    this.pos_commission = pos_commission;
    this.pos_quantifier = pos_quantifier;
    this.is_do = is_do;
    this.is_cod = is_cod;
    (this.client_branch_return_cod_name = client_branch_return_cod_name),
      (this.client_branch_return_cod_phone = client_branch_return_cod_phone),
      (this.client_branch_return_cod_address = client_branch_return_cod_address),
      (this.client_branch_return_cod_city_code = client_branch_return_cod_city_code),
      (this.client_branch_return_cod_district_code = client_branch_return_cod_district_code),
      (this.client_branch_return_cod_fee = client_branch_return_cod_fee),
      (this.is_custom_rate_only = is_custom_rate_only);
  }

  public toFormData() {
    const data = {
      client_type: this.client_type,
      id: this.id,
      company_name: this.company_name,
      address: this.address,
      district_code: this.district_code,
      email: this.email,
      phone: this.phone,
      tax_number: this.tax_number,
      contact_name: this.contact_name,
      contact_job_title: this.contact_job_title,
      contact_email: this.contact_email,
      contact_phone: this.contact_phone,
      client_branch_cod_return_receiver_name: this
        .client_branch_return_cod_name,
      client_branch_cod_return_receiver_phone: this
        .client_branch_return_cod_phone,
      client_branch_cod_return_receiver_address: this
        .client_branch_return_cod_address,
      client_branch_cod_return_receiver_city_code: this
        .client_branch_return_cod_city_code,
      client_branch_cod_return_receiver_district_code: this
        .client_branch_return_cod_district_code,
      client_branch_cod_fee: this.client_branch_return_cod_fee,
      is_custom_rate_only: this.is_custom_rate_only
    };
    // parent
    if (this.pos_id) {
      return toFormDataUtils({
        ...data,
        pos_id: this.pos_id,
        pos_commission: this.pos_commission,
        pos_quantifier: this.pos_quantifier,
        is_do: this.is_do,
        is_cod: this.is_cod
      });
      // branch
    } else {
      return toFormDataUtils({
        ...data,
        category: this.category,
        bank_name: this.bank_name,
        bank_account_name: this.bank_account_name,
        bank_account_number: this.bank_account_number,
        bank_account_email: this.bank_account_email,
        bank_account_label: this.bank_account_label,
        version_basic: this.version_basic,
        discount: this.discount,
        is_custom_rate: this.is_custom_rate,
        price_rate_start_date: this.price_rate_start_date,
        price_rate_end_date: this.price_rate_end_date,
        version_name: this.version_name,
        archive_file: this.archive_file,
        is_update_custom: this.is_update_custom
      });
    }
  }
}

export class EditRenewRatesApiRequest implements EditRenewRatesRequest {
  private id: number;
  private versionBasic: string;
  private discount: string;
  private isCustomRate: boolean;
  private versionName: string;
  private archiveFile: any;
  private startDate: string;
  private endDate: string;
  private isCustomRateOnly: boolean;

  constructor(
    id: number,
    versionBasic: string,
    discount: string,
    isCustomRate: boolean,
    versionName: string,
    archiveFile: any,
    startDate: string,
    endDate: string,
    isCustomRateOnly: boolean
  ) {
    (this.id = id),
      (this.versionBasic = versionBasic),
      (this.discount = discount),
      (this.isCustomRate = isCustomRate),
      (this.versionName = versionName),
      (this.archiveFile = archiveFile),
      (this.startDate = startDate),
      (this.endDate = endDate),
      (this.isCustomRateOnly = isCustomRateOnly);
  }

  public toJSON(): string {
    return JSON.stringify({
      id: this.id,
      version_basic: this.versionBasic,
      discount: this.discount,
      is_custom_rate: this.isCustomRate,
      version_name: this.versionName,
      archive_file: this.archiveFile,
      start_date: this.startDate,
      end_date: this.endDate,
      is_custom_rate_only: this.isCustomRateOnly
    });
  }

  public toFormData() {
    const data = {
      id: this.id,
      version_basic: this.versionBasic,
      discount: this.discount,
      is_custom_rate: this.isCustomRate,
      version_name: this.versionName,
      archive_file: this.archiveFile,
      start_date: this.startDate,
      end_date: this.endDate,
      is_custom_rate_only: this.isCustomRateOnly
    };

    return toFormDataUtils({
      ...data
    });
  }
}

export class EditClientRegistrationApiRequestV2 {
  id = 0;
  companyName = "";
  email = "";
  phone = "";
  address = "";
  taxNumber = "";
  versionBasic = "";
  isCustomRate = false;
  isCustomRateOnly = false;
  discount = "";
  priceRateStartDate = "";
  priceRateEndDate = "";
  versionName = "";
  isUpdateCustom = false;
  districtCode = "";
  clientType = "";
  category = "";
  bankName = "";
  bankAccountLabel = "";
  bankAccountName = "";
  bankAccountNumber = "";
  bankAccountEmail = "";
  contactName = "";
  contactJobTitle = "";
  contactEmail = "";
  contactPhone = "";
  posId = 0;
  posCommission = 0;
  posQuantifier = "";
  isDo = false;
  isCod = false;
  clientBranchCodReturnReceiverName = "";
  clientBranchCodReturnReceiverPhone = "";
  clientBranchCodReturnReceiverAddress = "";
  clientBranchCodReturnReceiverDistrictCode = "";
  clientBranchCodReturnReceiverCityCode = "";
  clientBranchCodFee = 0;
  companySize = "";
  taxpayerName = "";
  taxpayerAddress = "";
  billingWaNumber = "";
  billingEmail = "";
  billingScheduleSo = "";
  billingPaymentPeriod = "";
  vaBankName = "";
  vaBankNumber = "";
  vaBankAccountName = "";
  vaBankLabel = "";
  vaBankEmail = "";
  contractStartDate = "";
  contractEndDate = "";
  paymentMethod = "";
  archiveFile = "";
  priceType = "";
  insuranceType = "";
  insurancePercentage: any = "";
  letterCodeOrigin = "";
  transactionCode = "";
  contractAttachment = "" as any;
  type = "";
  isReuploadContract = false;
  isNeedAssessment = false;
  clientAlias = "";

  constructor(fields?: Partial<EditClientRegistrationApiRequestV2>) {
    Object.assign(this, fields);
  }

  toFormData() {
    return toFormDataUtils({
      ...this.getBasicInfo(),
      ...this.getBankInfo(),
      ...this.getContactInfo(),
      ...this.getPosInfo(),
      ...this.getCodInfo(),
      ...this.getTaxpayerInfo(),
      ...this.getBillingInfo(),
      ...this.getContractInfo(),
      ...this.getPricingInfo(),
      ...this.getAdditionalInfo()
    });
  }

  private getBasicInfo() {
    return {
      id: this.id,
      company_name: this.companyName,
      email: this.email,
      phone: this.phone,
      address: this.address,
      tax_number: this.taxNumber,
      version_basic: this.versionBasic,
      is_custom_rate: this.isCustomRate,
      is_custom_rate_only: this.isCustomRateOnly,
      discount: this.discount,
      price_rate_start_date: this.priceRateStartDate,
      price_rate_end_date: this.priceRateEndDate,
      version_name: this.versionName,
      is_update_custom: this.isUpdateCustom,
      district_code: this.districtCode,
      client_type: this.clientType,
      category: this.category
    };
  }

  private getBankInfo() {
    return {
      bank_name: this.bankName,
      bank_account_label: this.bankAccountLabel,
      bank_account_name: this.bankAccountName,
      bank_account_number: this.bankAccountNumber,
      bank_account_email: this.bankAccountEmail
    };
  }

  private getContactInfo() {
    return {
      contact_name: this.contactName,
      contact_job_title: this.contactJobTitle,
      contact_email: this.contactEmail,
      contact_phone: this.contactPhone
    };
  }

  private getPosInfo() {
    return {
      pos_id: this.posId,
      pos_commission: this.posCommission,
      pos_quantifier: this.posQuantifier
    };
  }

  private getCodInfo() {
    return {
      is_do: this.isDo,
      is_cod: this.isCod,
      client_branch_cod_return_receiver_name: this.clientBranchCodReturnReceiverName,
      client_branch_cod_return_receiver_phone: this.clientBranchCodReturnReceiverPhone,
      client_branch_cod_return_receiver_address: this.clientBranchCodReturnReceiverAddress,
      client_branch_cod_return_receiver_district_code: this.clientBranchCodReturnReceiverDistrictCode,
      client_branch_cod_return_receiver_city_code: this.clientBranchCodReturnReceiverCityCode,
      client_branch_cod_fee: this.clientBranchCodFee
    };
  }

  private getTaxpayerInfo() {
    return {
      company_size: this.companySize,
      taxpayer_name: this.taxpayerName,
      taxpayer_address: this.taxpayerAddress
    };
  }

  private getBillingInfo() {
    return {
      billing_wa_number: this.billingWaNumber,
      billing_email: this.billingEmail,
      billing_schedule_so: this.billingScheduleSo,
      billing_payment_period: this.billingPaymentPeriod,
      va_bank_name: this.vaBankName,
      va_bank_number: this.vaBankNumber,
      va_bank_account_name: this.vaBankAccountName,
      va_bank_label: this.vaBankLabel,
      va_bank_email: this.vaBankEmail
    };
  }

  private getContractInfo() {
    return {
      contract_start_date: this.contractStartDate,
      contract_end_date: this.contractEndDate,
      contract_attachment: this.contractAttachment,
      payment_method: this.paymentMethod
    };
  }

  private getPricingInfo() {
    return {
      price_type: this.priceType,
      insurance_type: this.insuranceType,
      insurance_percentage: this.insurancePercentage,
      "3lc_origin": this.letterCodeOrigin,
      transaction_code: this.transactionCode,
      archive_file: this.archiveFile
    };
  }

  private getAdditionalInfo() {
    return {
      type: this.type,
      is_reupload_contract: this.isReuploadContract,
      is_need_assessment: this.isNeedAssessment,
      client_alias: this.clientAlias
    };
  }
}

export const getBasicInfo = (params: any) => {
  return {
    company_name: params.companyName,
    email: params.email,
    phone: params.phone,
    address: params.address,
    district: params.district?.code,
    tax_number: params.taxNumber,
    version_basic: params.versionBasic.rateVersionName,
    is_custom_rate: params.isCustomRate,
    is_custom_rate_only: params.isCustomRateOnly,
    discount: params.discount,
    price_rate_start_date: params.priceRateStartDate,
    price_rate_end_date: params.priceRateEndDate,
    version_name: params.versionName,
    archive_file: params.archiveFile,
    category: params.category,
    type: params.type,
    company_size: params.company_size,
  };
}
export const getBankInfo = (params: any) => {
  return {
    bank_name: params.bankName,
    bank_account_name: params.bankAccountName,
    bank_account_number: params.bankAccountNumber,
    bank_account_email: params.bankAccountEmail,
    bank_account_label: params.bankAccountLabel,
  };
}

export const getContactInfo = (params: any) => {
  return {
    contact_name: params.contactName,
    contact_job_title: params.contactJobTitle,
    contact_email: params.contactEmail,
    contact_phone: params.contactPhone,
  };
}

export const getTaxInfo = (params: any) => {
  return {
    is_efaktur_with_nik: params.is_efaktur_with_nik,
    nik_id: params.nik_id,
    nik_name: params.nik_name,
    nik_address: params.nik_address,
    taxpayer_name: params.taxpayer_name,
    taxpayer_address: params.taxpayer_address,
  };
}

export const getBillingInfo = (params: any) => {
  return {
    billing_wa_number: params.billing_wa_number,
    billing_email: params.billing_email,
    billing_schedule_so: params.billing_schedule_so,
    billing_payment_periode: params.billing_payment_periode,
    va_bank_name: params.va_bank_name,
    va_bank_number: params.va_bank_number,
    va_bank_account_name: params.va_bank_account_name,
    va_bank_label: params.va_bank_label,
    va_bank_email: params.va_bank_email,
  };
}

export const getContractInfo = (params: any) => {
  return {
    contract_start_date: params.contract_start_date,
    contract_end_date: params.contract_end_date,
    contract_attachment: params.contract_attachment,
  };
}

export const getAdditionalInfo = (params: any) => {
  return {
    client_type: params.client_type,
    payment_method: params.payment_method,
    transaction_code: params.transaction_code,
    custom_price_type: params.custom_price_type,
    price_type: params.price_type,
    insurance_type: params.insurance_type,
    insurance_percentage: params.insurance_percentage,
    lc_origin: params.lc_origin,
    is_need_assessment: params.isNeedAssessment || false,
    client_alias: params.client_alias || "",
  };
}
