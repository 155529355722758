import { jsPDF } from "jspdf";

interface FontManager {
  addFileToVFS: (fileName: string, fontData: string) => void;
  addFont: (fileName: string, fontName: string, weight: string) => void;
}

export class JsPdfFont {
  path = "";
  filename = "";
  variableName = "";
  customFontName = "";

  constructor(fields?: Partial<JsPdfFont>) {
    Object.assign(this, fields);
  }
}

const getFontInfo = (detail: JsPdfFont) => {
  const regex = /\/fonts\/([a-zA-Z]+)-([a-zA-Z]+)\.js/;

  const matches = detail.path.match(regex);

  if (matches && matches.length === 3) {
    const fontName = detail.customFontName || matches[1]; // Nama font, misalnya 'Chinese'
    const weight = matches[2].toLowerCase(); // Berat, misalnya 'bold', 'medium', dsb.
    return { fontName, weight };
  } else {
    throw new Error("Invalid file path format");
  }
};

export const loadFontsJsPdf = async (data: JsPdfFont[]) => {
  try {
    const fontPromises = data.map((fontConfig) =>
      fetch(fontConfig.path).then((response) => response.text())
    );

    const fontScripts = await Promise.all(fontPromises);

    const callAddFont = function(this: FontManager): void {
      fontScripts.forEach((scriptContent, index) => {
        eval(scriptContent);

        const fontData = (window as any)[data[index].variableName] || "";
        const fontInfo = getFontInfo(data[index]);

        this.addFileToVFS(data[index].filename, fontData);
        this.addFont(data[index].filename, fontInfo.fontName, fontInfo.weight);
      });
    };

    jsPDF.API.events.push(["addFonts", callAddFont]);
  } catch (error) {
    console.error("jsPDF: Error loading fonts:", error);
  }
};
