import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "inline-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_fill = _resolveComponent("button-fill")!
  const _component_ClientBranch = _resolveComponent("ClientBranch")!
  const _component_ContactInfo = _resolveComponent("ContactInfo")!
  const _component_ReturnConfig = _resolveComponent("ReturnConfig")!
  const _component_DeliveryService = _resolveComponent("DeliveryService")!
  const _component_STTReturn = _resolveComponent("STTReturn")!
  const _component_DOReturn = _resolveComponent("DOReturn")!
  const _component_ParentClientDetail = _resolveComponent("ParentClientDetail")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    onBack: _ctx.goBack,
    title: _ctx.getTitle(),
    "vertical-separator": false,
    fullPage: ""
  }, {
    "top-right": _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_button_fill, {
          isDisabled: !_ctx.isFormValidBranch,
          onClick: _ctx.showPopupRegistBranch,
          title: "Simpan",
          class: "bg-red-lp-300 px-8"
        }, null, 8, ["isDisabled", "onClick"])
      ])
    ]),
    data: _withCtx(() => [
      _createVNode(_component_ClientBranch, {
        modelValue: _ctx.clientBranchData,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.clientBranchData = $event)),
        error: _ctx.error,
        "onUpdate:error": _cache[2] || (_cache[2] = ($event: any) => (_ctx.error = $event))
      }, null, 8, ["modelValue", "error"]),
      _createVNode(_component_ContactInfo, {
        modelValue: _ctx.contactInfoData,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.contactInfoData = $event)),
        error: _ctx.error,
        "onUpdate:error": _cache[4] || (_cache[4] = ($event: any) => (_ctx.error = $event))
      }, null, 8, ["modelValue", "error"]),
      _createVNode(_component_ReturnConfig, {
        modelValue: _ctx.returnConfigData,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.returnConfigData = $event)),
        error: _ctx.error,
        "onUpdate:error": _cache[6] || (_cache[6] = ($event: any) => (_ctx.error = $event))
      }, null, 8, ["modelValue", "error"]),
      _createVNode(_component_DeliveryService, {
        modelValue: _ctx.sttReturnData,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.sttReturnData = $event)),
        error: _ctx.error,
        "onUpdate:error": _cache[8] || (_cache[8] = ($event: any) => (_ctx.error = $event)),
        onOnCheckDO: _cache[9] || (_cache[9] = ($event: any) => (_ctx.clientBranchDoPaymentType = '')),
        onOnCheckCOD: _ctx.onResetFormCOD
      }, null, 8, ["modelValue", "error", "onOnCheckCOD"]),
      (_ctx.sttReturnData.isCod)
        ? (_openBlock(), _createBlock(_component_STTReturn, {
            key: 0,
            modelValue: _ctx.sttReturnData.codRej,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.sttReturnData.codRej = $event)),
            error: _ctx.error,
            "onUpdate:error": _cache[11] || (_cache[11] = ($event: any) => (_ctx.error = $event)),
            form: _ctx.clientBranchData
          }, null, 8, ["modelValue", "error", "form"]))
        : _createCommentVNode("", true),
      (_ctx.sttReturnData.isDo)
        ? (_openBlock(), _createBlock(_component_DOReturn, {
            key: 1,
            error: _ctx.error,
            "onUpdate:error": _cache[12] || (_cache[12] = ($event: any) => (_ctx.error = $event)),
            modelValue: _ctx.clientBranchDoPaymentType,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.clientBranchDoPaymentType = $event))
          }, null, 8, ["error", "modelValue"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ParentClientDetail, {
        modelValue: _ctx.parentClientDetailModel.parent,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_ctx.parentClientDetailModel.parent = $event)),
        error: _ctx.error,
        "onUpdate:error": _cache[15] || (_cache[15] = ($event: any) => (_ctx.error = $event))
      }, null, 8, ["modelValue", "error"])
    ]),
    _: 1
  }, 8, ["onBack", "title"]))
}