import { AxiosResponse } from "axios";
import {
  PusatResolusiListData,
  PusatResolusiList,
  PusatResolusiStatusData,
  PusatResolusiStatus,
  PusatResolusiCategoryData,
  PusatResolusiCategory,
  CheckValidationData,
  DetailPusatResolusi,
  PusatResolusiModalStatusData,
  DetailForumPusatResolusi,
  ForumPusatResolusiList,
  StatusHistoryPusatResolusi
} from "@/domain/entities/PusatResolusi";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";
export class PusatResolusiMapper {
  public convertPusatResolusiListFromApi(
    res: AxiosResponse<any>
  ): PusatResolusiList {
    const { data } = res;

    return new PusatResolusiList(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data
        ? data.data.map((item: any) => {
            return new PusatResolusiListData({
              rcId: item.rc_id,
              rcTicketNoGenesis: item.rc_ticket_no_genesis,
              rcTicketNo: item.rc_ticket_no,
              rcSttNo: item.rc_stt_no,
              rcShipmentNo: item.rc_shipment_no,
              rcCategory: item.rc_category,
              rcStatus: item.rc_status,
              newMessage: item.has_unread_messages,
              rcCreatedAt: item.rc_created_at,
              rcCreatedName: item.rc_created_name,
              rcCreatedActorName: item.rc_created_actor_name,
              rcUpdatedAt: item.rc_updated_at,
              rcUpdatedName: item.rc_updated_name,
              rcUpdatedActorName: item.rc_updated_actor_name,
              rcCaseNumber: item.rc_case_number,
              rcCaseOwner: item.rc_case_owner
            });
          })
        : []
    );
  }

  public convertPusatResolusiStatustFromApi(
    res: AxiosResponse<any>
  ): PusatResolusiStatusData {
    const { data } = res;
    return new PusatResolusiStatusData(
      data.data
        ? data.data.map((item: any) => {
            return new PusatResolusiStatus({
              status: item.status,
              total: item.total
            });
          })
        : []
    );
  }

  public convertPusatResolusiCategoryFromApi(
    res: AxiosResponse<any>
  ): PusatResolusiCategoryData {
    const { data } = res;
    return new PusatResolusiCategoryData(
      data.data
        ? data.data.map((item: any) => {
            return new PusatResolusiCategory({
              value: item.value,
              name: item.name,
              categoryType: item.category_type
            });
          })
        : []
    );
  }

  public convertCheckValidationFromApi(
    res: AxiosResponse<any>
  ): CheckValidationData {
    const { data } = res;
    return new CheckValidationData({
      sttNo: data.data.stt_no,
      isValid: data.data.is_valid,
      reason: data.data.reason
    });
  }

  public convertModalStatusFromApi(
    res: AxiosResponse<any>
  ): PusatResolusiModalStatusData {
    const { data } = res;
    return new PusatResolusiModalStatusData({
      ticketNoSf: data.data.ticket_no_sf,
      ticketNoGenesis: data.data.ticket_no_genesis,
      sttOrShipmentNo: data.data.stt_or_shipment_no,
      category: data.data.category,
      senderName: data.data.sender_name,
      senderPhoneNumber: data.data.sender_phone_number,
      senderAddress: data.data.sender_address,
      recipientName: data.data.recipient_name,
      recipientPhoneNumber: data.data.recipient_phone_number,
      recipientAddress: data.data.recipient_address,
      caseNumber: data.data.case_number,
      caseOwner: data.data.case_owner,
      statusHistory: data.data.status_history
        ? data.data.status_history.map((item: any) => {
            return new StatusHistoryPusatResolusi({
              statusHistoryDescription: item.status_history_description,
              statusHistoryCreatedAt: item.status_history_created_at
            });
          })
        : []
    });
  }

  public convertRequestCreatePusatResolusiDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data
    });
  }

  public convertDetailPusatResolusiFromApi(
    res: AxiosResponse<any>
  ): DetailPusatResolusi {
    const { data } = res;
    return new DetailPusatResolusi({
      rcId: data.data.rc_id,
      rcTicketNoGenesis: data.data.rc_ticket_no_genesis,
      rcTicketNo: data.data.rc_ticket_no,
      rcSttNo: data.data.rc_stt_no,
      rcCategory: data.data.rc_category,
      rcStatus: data.data.rc_status,
      rcDescription: data.data.rc_description,
      rcImageFiles: [
        parsingLinkGcpToAws(data.data.rc_image_file_1),
        parsingLinkGcpToAws(data.data.rc_image_file_2),
        parsingLinkGcpToAws(data.data.rc_image_file_3)
      ],
      rcAttachment: [data.data.rc_attachment_1, data.data.rc_attachment_2],
      rcCreatedAt: data.data.rc_created_at,
      rcCreatedName: data.data.rc_created_name,
      rcUpdatedAt: data.data.rc_updated_at,
      rcUpdatedName: data.data.rc_updated_name,
      rcCreatedActorName: data.data.rc_created_actor_name,
      rcUpdatedActorName: data.data.rc_updated_actor_name,
      rcCaseNumber: data.data.rc_case_number,
      rcCaseOwner: data.data.rc_case_owner
    });
  }

  public convertRequestCreateForumPusatResolusiDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: data.data
    });
  }

  public convertForumPusatResolusiListFromApi(
    res: AxiosResponse<any>
  ): ForumPusatResolusiList {
    const { data } = res;
    return new ForumPusatResolusiList(
      data.data
        ? data.data.map((item: any) => {
            return new DetailForumPusatResolusi({
              dfId: item.df_id,
              dfDescription: item.df_description,
              dfImageFiles: [
                parsingLinkGcpToAws(item.df_image_file_1),
                parsingLinkGcpToAws(item.df_image_file_2),
                parsingLinkGcpToAws(item.df_image_file_3)
              ],
              dfAttachment: [item.df_attachment_1, item.df_attachment_2],
              dfCreatedAt: item.df_created_at,
              dfCreatedBy: item.df_created_by,
              dfCreatedName: item.df_created_name,
              dfCreatedActorId: item.df_created_actor_id,
              dfUpdatedActorRole: item.df_created_actor_role,
              dfCreatedActorName: item.df_created_actor_name,
              isUndread: item.is_unread
            });
          })
        : []
    );
  }
}
