
import { Options, Vue } from "vue-class-component";
import Notification from "@/app/ui/components/notification-update/index.vue";
import { downloadFontsJsPdf as downloadFontsChineseJsPdf } from "./app/ui/assets/fonts/jsPDF/chinese";
import { downloadFontsJsPdf as downloadFontsJapaneseJsPdf } from "./app/ui/assets/fonts/jsPDF/japanese";
import { downloadFontsJsPdf as downloadFontsKoreanJsPdf } from "./app/ui/assets/fonts/jsPDF/korean";

@Options({
  name: "App",
  components: {
    Notification
  }
})
export default class extends Vue {
  refreshing = false;
  registration: any = null;
  updateExists = false;
  created() {
    downloadFontsChineseJsPdf();
    downloadFontsJapaneseJsPdf();
    downloadFontsKoreanJsPdf();
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true
    });
    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  }
  async updateAvailable(event: any) {
    this.registration = event.detail;
    this.updateExists = true;
    // wait for 3s then force update
    await new Promise(resolve => setTimeout(resolve, 3000));
    // send message to SW to skip the waiting and activate the new SW
    if (this.registration?.waiting) {
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    } else {
      console.warn("No service worker in 'waiting' state.");
    }
  }

  // Called when the user accepts the update
  refreshApp() {
    this.updateExists = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) return;
    // send message to SW to skip the waiting and activate the new SW
    this.registration?.waiting.postMessage({ type: "SKIP_WAITING" });
  }

  get gtmId() {
    return process.env.VUE_APP_GTM_ID;
  }
}
