import { flags } from "@/feature-flags";

export class AuthNGenEntities {
  token = "";
  constructor(fields?: Partial<AuthNGenEntities>) {
    Object.assign(this, fields);
  }
}

export class FlightSchedule {
  flightDetails: Array<FlightDetail> = [];
  constructor(fields?: Partial<FlightSchedule>) {
    Object.assign(this, fields);
  }
}

export class FlightDetail {
  DailyFlightSNo = 0;
  FlightNo = "";
  DepartureDateTime = "";
  ArrivalDateTime = "";
  departureHour = "";
  arrivalHour = "";
  flightDate = "";
  Route = "";
  origin = "";
  destination = "";
  transitsData = [] as FlightDetail[];
  airportCodeOrigin = "";
  airportCodeDestination = "";
  flightDayTransit = 0;
  thresholdTime = 0;
  constructor(fields?: Partial<FlightDetail>) {
    Object.assign(this, fields);

    if (!fields?.departureHour) {
      this.departureHour = "";
    }
    if (!fields?.arrivalHour) {
      this.arrivalHour = "";
    }
    if (!fields?.thresholdTime) {
      this.thresholdTime = 0;
    }
  }
  get convertThresholdTime () {
    if(this.thresholdTime === 0) {
      return flags.cargo_config_flight_treshold_default_value.getValue();
    }
    return this.thresholdTime;
  }
}

export class CargoHistoryNgen {
  flightNumber = "";
  flightDate = "";
  etd = "";
  eta = "";
  origin = "";
  destination = "";
  pieces = 0;
  grossWeight = 0;
  volumeWeight = 0;
  updatedBy = "";
  updatedOn = "";
  userAction = "";
  status = "";
  constructor(fields?: Partial<CargoHistoryNgen>) {
    Object.assign(this, fields);
  }
}

export class ErrorData {
  code = 0;
  title = "";
  message = "";
  type = "";
  icon = "";
  status = false;
  constructor(fields?: Partial<ErrorData>) {
    Object.assign(this, fields);
  }
}

export class AwbDetails {
  awbNumber = "";
  awbDate = "";
  originCity = "";
  destinationCity = "";
  pieces = "";
  chargeableWeight = "";
  grossWeight = "";
  volumeWeight = "";
  product = "";
  commodity = "";
  agent = "";
  constructor(fields?: Partial<AwbDetails>) {
    Object.assign(this, fields);
  }
}

export class StockInformation {
  awbNumber = "";
  agentIssueDate = "";
  agentName = "";
  updatedBy = "";
  constructor(fields?: Partial<StockInformation>) {
    Object.assign(this, fields);
  }
}

export class AwbDetailData {
  awbDetails: AwbDetails = new AwbDetails();
  stockInformation: StockInformation = new StockInformation();
  awbTracking: CargoHistoryNgen[] = [];
  errorAwb = "";
  constructor(fields?: Partial<AwbDetailData>) {
    Object.assign(this, fields);
  }
}

export class TrackingBookingRequestIdData {
  bookingRequestId = "";
  AWBNo = "";
  statusMessage = "";
  constructor(fields?: Partial<TrackingBookingRequestIdData>) {
    Object.assign(this, fields);
  }
}
