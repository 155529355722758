import {
  AddBookingRequestInterface,
  GetEstimationPriceInterface,
  EditBookingRequestInterface
} from "@/data/payload/contracts/BookingRequest";
import {
  AddCustomer,
  AddManualBooking,
  GetEstimationPrice,
  CancelBooking,
  EditBooking,
  AddBookingCorporate,
  ValidateBookingPhone,
  RequestEstimationServiceLevelAgreement,
  RequestEstimateSlaDetail,
  SubmitPosterProductApiRequest,
  UploadHydraFileRequest,
  DeleteHydraFileRequest
} from "@/data/payload/api/BookingRequest";
import { BookingMapper } from "@/data/persistences/mappers/BookingMapper";
import { BookingRepositoryInterface } from "@/data/persistences/repositories/contracts/BookingRepositoryInterface";
import {
  CustomerListEntities,
  EstimationPrice,
  EstimationCodRetail,
  PrintDataEntitas,
  ShipmentIdList,
  CustomerExist,
  ProductDelivery,
  InsuranceTypeDelivery,
  EstimationServiceLevelAgreement,
  PosterProduct
} from "@/domain/entities/Booking";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class BookingApiRepository implements BookingRepositoryInterface {
  private service: ApiService;
  private mapper: BookingMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: BookingMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async addBookingManual(
    payload: AddBookingRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.addManualBooking(),
      undefined,
      payload as AddManualBooking
    );
    return this.mapper.convertPayloadData(resp);
  }

  async getListCustomer(search: string, cache: boolean, refId?: number, refType?: string): Promise<CustomerListEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListCustomer(search, cache, refId, refType),
      {}
    );
    return this.mapper.convertCustomerDataFromApi(resp);
  }

  async addCustomer(
    payload: AddBookingRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.addCustomer(),
      undefined,
      payload as AddCustomer
    );
    return this.mapper.convertPayloadDataCustomer(resp);
  }

  async getEstimationPrice(
    payload: GetEstimationPriceInterface
  ): Promise<EstimationPrice> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.getEstimationPrice("2"),
      undefined,
      payload as GetEstimationPrice
    );
    return this.mapper.convertEstimationPrice(resp);
  }

  async getEstimationPriceCodRetail(
    payload: GetEstimationPriceInterface
  ): Promise<EstimationCodRetail> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.getEstimationPrice("2"),
      undefined,
      payload as GetEstimationPrice
    );
    return this.mapper.convertEstimationPriceCodRetail(resp);
  }

  public async getPrintData(
    stt: number,
    accountType: string
  ): Promise<PrintDataEntitas> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPrintData(stt, accountType),
      {}
    );
    return this.mapper.convertPrintDataDataFromApi(resp);
  }

  async getListShipmentId(
    id: string,
    limit: number,
    page: number
  ): Promise<ShipmentIdList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getShipmentIdList(id, limit, page),
      {}
    );
    return this.mapper.convertShipmentIdDataFromApi(resp);
  }

  async cancelBooking(
    payload: AddBookingRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.cancelBooking(),
      undefined,
      payload as CancelBooking
    );
    return this.mapper.convertCancelDataFromApi(resp);
  }

  public async editBooking(
    id: number,
    payload: EditBookingRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.editBooking(id),
      undefined,
      payload as EditBooking
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getExistCustomer(
    name: string,
    phone: string,
    address: string
  ): Promise<CustomerExist> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getExistCustomer(name, phone, address),
      {}
    );
    return this.mapper.convertExistCustomerFromApi(resp);
  }

  public async getProductsDelivery(
    search: string,
    page: number,
    limit: number,
    status: string
  ): Promise<ProductDelivery> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getProductsDelivery(search, page, limit, status),
      {}
    );

    return this.mapper.convertProductDeliveryFromApi(resp);
  }

  public async getInsuranceTypesDelivery(
    insuranceConfig: number
  ): Promise<InsuranceTypeDelivery> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getInsuranceTypesDelivery(insuranceConfig),
      {}
    );

    return this.mapper.convertInsuranceDeliveryFromApi(resp);
  }

  async addBookingCorporate(
    payload: AddBookingRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.addBookingCorporate(),
      undefined,
      payload as AddBookingCorporate
    );
    return this.mapper.convertPayloadData(resp);
  }

  async ValidateBookingPhone(
    payload: AddBookingRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.validateBookingPhone(),
      undefined,
      payload as ValidateBookingPhone
    );
    return this.mapper.convertValidatePhoneFromApi(resp);
  }

  async getEstimationServiceLevelAgreement(
    params: RequestEstimationServiceLevelAgreement
  ): Promise<EstimationServiceLevelAgreement> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getEstimationServiceLevelAgreement(params),
      {}
    );
    return this.mapper.convertEstimationServiceLevelAgreement(resp);
  }

  async getEstimateSlaDetail(
    params: RequestEstimateSlaDetail
  ): Promise<EstimationServiceLevelAgreement> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getEstimateSlaDetail(params),
      {}
    );
    return this.mapper.convertEstimateSlaDetail(resp);
  }

  public async postToGetPosterProduct(
    payload: SubmitPosterProductApiRequest
  ): Promise<PosterProduct[]> {
    const resp = await this.service.invoke(
      "POST",
      this.endpoints.postToGetPosterProduct(),
      {},
      payload
    );
    return this.mapper.convertPosterProductDataFromApi(resp);
  }

  public async uploadHydraFile(payload: UploadHydraFileRequest): Promise<ResponsePayload> {
    const res = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.uploadHydraFile(),
      {},
      payload as UploadHydraFileRequest
    );
    return this.mapper.convertUploadHydraFileDataFromApi(res);
  }

  public async deleteHydraFile(payload: DeleteHydraFileRequest): Promise<ResponsePayload> {
    const res = await this.service.invoke(
      "post",
      this.endpoints.deleteHydraFile(),
      {},
      payload as DeleteHydraFileRequest
    );
    return this.mapper.convertDeleteHydraFileDataFromApi(res);
  }
}
