
import {
  formatInputMoney,
  formatInputMoneyWithDecimal,
  parsingErrorResponse,
  replaceComma
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { InsuranceTypeDeliveryData } from "@/domain/entities/Booking";
import debounce from "lodash/debounce";
import { Options, prop, Vue } from "vue-class-component";
import InfoInsurance from "@/app/ui/views/shipment/booking/component/mini-banner/info-insurance-v2.vue";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { CodConfigurationController } from "@/app/ui/controllers/CodConfigurationController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import Checkbox from "primevue/checkbox";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { Commodity } from "@/domain/entities/Commodity";
import { isCustomerApps } from "@/app/ui/views/track-delivery/delivery-tab/modules";
import { InsuranceController } from "@/app/ui/controllers/InsuranceController";
import { Insurance } from "@/domain/entities/Insurance";

class Props {
  formType = prop<string>({
    default: "",
    type: String
  });
  errorEmbargo = prop<any>({
    default: {
      originCity: false,
      destinationCity: false,
      productType: false,
      comodity: false,
      weightStatus: false,
      lengthStatus: false
    }
  });
  modelValue = prop<any>({
    required: true
  });
  errorEstimationPrice = prop<any>({
    required: true
  });
  errMessagePriceOutside = prop<string>({
    default: ""
  });
  isCodAmount = prop<boolean>({
    default: false,
    type: Boolean
  });
}
@Options({
  emits: [
    "update:modelValue",
    "setFieldIndexEstPrice",
    "onChangeDeliveryFeeType"
  ],
  components: {
    InfoInsurance,
    Checkbox
  },
  watch: {
    "modelValue.destinationCity"() {
      this.codStatusDisabled =
        !this.accountIsNonForeign ||
        (typeof this.modelValue.destinationCity === "object" &&
          this.modelValue.destinationCity?.isCod !== "yes") ||
        (!this.clientManagement.clientIsCod && this.formtype === "client");
      this.dfodStatusDisabled =
        !this.accountIsNonForeign ||
        (typeof this.modelValue.destinationCity === "object" &&
          this.modelValue.destinationCity?.isCod !== "yes") ||
        (!this.clientManagement.clientIsDfod && this.formtype === "client");
    }
  }
})
export default class SectionPackageDetail extends Vue.with(Props) {
  codStatusDisabled = false;
  dfodStatusDisabled = false;
  // deliveryFeeType = "non-cod";
  get deliveryFeeType() {
    if (this.manualBooking.isCOD && this.manualBooking.isDFOD) return "dfod";
    if (this.manualBooking.isCOD) return "cod";
    return "non-cod";
  }
  isInsuranceBasic = true;

  get errorEstimationPriceModel() {
    return this.errorEstimationPrice;
  }
  set errorEstimationPriceModel(val: any) {
    this.$emit("update:errorEstimationPrice", val);
  }

  mounted() {
    this.codStatusDisabled =
      !this.accountIsNonForeign || this.isPOSNonCodBookingRetail;
    this.dfodStatusDisabled =
      !this.accountIsNonForeign || this.isPOSNonCodBookingRetail;
    InsuranceController.setInsurance(new Insurance());
    this.setInsurance();
  }

  get isPOSNonCodBookingRetail() {
    return (
      !this.getAccount.isCodBooking &&
      this.getAccount.isPOS &&
      this.formType === "manual"
    );
  }

  get clientManagement() {
    return ClientManagementController.detailClientManagement;
  }

  setStatus(isCod = false, isDfod = false, nonCod = "") {
    this.codStatusDisabled = !isCod;
    this.dfodStatusDisabled = !isDfod;
    // this.deliveryFeeType = nonCod;
  }

  async setInsurance() {
    await this.getInsurancesTypeDelivery();
    this.isInsuranceBasic = !this.disabledBasic;
    this.modelValue.insurance = !this.disabledBasic ? "basic" : "free";
    this.onChangeInsurance();
  }

  get deliveryFeeTypes() {
    return [
      { name: "Non COD", value: "non-cod", disabled: false, logo: "" },
      {
        name: "Cash On Delivery",
        value: "cod",
        disabled: this.codStatusDisabled,
        logo: "cod-badge"
      },
      {
        name: "Delivery Fee on Delivery",
        value: "dfod",
        disabled: this.dfodStatusDisabled,
        logo: "dfod-badge"
      }
    ];
  }

  requestData: ApiRequestList = new ApiRequestList();
  listDataConfigurationCOD: ResponsePayloadV2 = new ResponsePayloadV2();
  isEligibleCOD = false;
  isEligibleDFOD = false;
  isInsurance = false;
  listCodActive = [] as any;
  listDfodActive = [] as any;

  async handleDeliveryFeeType() {
    if (this.deliveryFeeType === "cod" || this.deliveryFeeType === "dfod") {
      await this.fetchListConfigurationCOD();
    } else {
      this.listCodActive = [];
      this.listDfodActive = [];
    }
  }

  get disabledBasic() {
    return (
      ((this.listCodActive.ccoIsInsurance && this.deliveryFeeType === "cod") ||
        (this.listDfodActive.ccoIsInsurance &&
          this.deliveryFeeType === "dfod")) &&
      this.formType === "manual" &&
      this.dataProfile.account_type !== "client"
    );
  }

  async fetchListConfigurationCOD() {
    //call this if formtype manual dan getAccount.accountType partner
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    try {
      this.listDataConfigurationCOD = await CodConfigurationController.getList(
        new ApiRequestList({
          page: this.listDataConfigurationCOD.pagination.page,
          limit: this.listDataConfigurationCOD.pagination.limit,
          status: this.requestData.statusData.value
        })
      );
      // cod
      this.listCodActive = this.listDataConfigurationCOD.data.filter(
        (item: any) => item.ccoName === "COD Retail"
      )[0];

      // dfod
      this.listDfodActive = this.listDataConfigurationCOD.data.filter(
        (item: any) => item.ccoName === "DFOD Retail"
      )[0];

      if (this.disabledBasic) {
        this.isInsuranceBasic = !this.disabledBasic;
        this.disabledInsuranceBasic = this.disabledBasic;
        this.modelValue.insurance = "free";
      }
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Gagal Mendapat Konfigurasi COD !", () =>
          this.fetchListConfigurationCOD()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  onSetFieldIndexEstPrice() {
    // setFieldIndex(14);
    this.$emit("setFieldIndexEstPrice");
  }

  onChangeInsurance() {
    this.modelValue.insurance = this.isInsuranceBasic ? "basic" : "free";
    this.$emit("update:modelValue", {
      ...this.modelValue,
      insurance: this.isInsuranceBasic ? "basic" : "free"
    });

    this.getInsurance(this.modelValue.estimationPrice);
  }

  get infoMessageDetailGoods() {
    return [
      `Informasi Penting COD! ${
        this.formType === "client" ? "Client" : "POS"
      } tidak perlu menagih biaya pengiriman ke pengirim.`,
      `Informasi Penting DFOD! ${
        this.formType === "client" ? "Client" : "POS"
      } tidak perlu menagih biaya pengiriman ke pengirim.`
    ];
  }

  setDeliveryType(value: string) {
    switch (value) {
      case "dfod": {
        BookingController.setCOD(true);
        BookingController.setDfod(true);
        break;
      }
      case "cod": {
        BookingController.setCOD(true);
        BookingController.setDfod(false);
        break;
      }
      default: {
        BookingController.setCOD(false);
        BookingController.setDfod(false);
        break;
      }
    }
  }

  async onChangeDeliveryFeeType(value: string) {
    BookingController.setCODAmount(0);
    this.setDeliveryType(value);
    // this.deliveryFeeType = value;
    await this.handleDeliveryFeeType();
    this.errorEstimationPriceModel = false;
    this.fullFilledCOD(this.modelValue.estimationPrice, false);
    this.$emit("onChangeDeliveryFeeType");
  }

  errorCommodity = false;

  changeCommodities() {
    this.errorCommodity = !this.modelValue.commodityCode;
    this.$emit("onChangeCommodities");
  }

  get manualBooking() {
    return BookingController.manualBooking;
  }

  isTabActive(value: string) {
    return value === this.deliveryFeeType;
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }

  get prefixCurrency() {
    return this.accountIsNonForeign ? "IDR" : "RM";
  }

  formatEstimationPrice(value: string) {
    return this.accountIsNonForeign
      ? this.formatRupiah(value)
      : this.formatRinggit(value);
  }

  formatRupiah(value: string) {
    return formatInputMoney(value.replace(/[\D]+/g, ""));
  }

  formatRinggit(value: any) {
    return formatInputMoneyWithDecimal(value);
  }

  get placeholderItemPrice() {
    let placeholder = this.$t("bookingShipment.placeholder.itemPrice");

    if (this.bookingClientForm) {
      placeholder = "e.g 1";
    }

    return placeholder;
  }

  get placeholderCodAmount() {
    let placeholder = this.$t("bookingShipment.placeholder.codFee");

    if (this.bookingClientForm) {
      placeholder = "e.g 1";
    }

    return placeholder;
  }

  get bookingClientForm() {
    return (
      this.bookingPosForClient ||
      this.bookingClientForClient ||
      this.bookingInternalForClient
    );
  }

  get isFormBookClient() {
    return this.formType === "client";
  }

  get isFormBookManual() {
    return this.formType === "manual";
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
      isCodBooking:
        AccountController.accountData.account_type_detail.partnerIsCodBooking,
      accountTypeDetail: AccountController.accountData.account_type_detail,
      isPOS: AccountController.accountData.isPosAccount
    };
  }

  get bookingPosForClient() {
    return this.isFormBookClient && this.getAccount.accountType === "partner";
  }

  get bookingClientForClient() {
    return this.isFormBookManual && this.getAccount.accountType === "client";
  }

  get bookingInternalForClient() {
    return this.isFormBookClient && this.getAccount.accountType === "internal";
  }

  automaticSetCheckbox(price: any) {
    if (this.modelValue.productName.includes("OTOPACK")) {
      this.isInsuranceBasic = true;
      this.disabledInsuranceBasic = true;
      this.modelValue.insurance = "basic";
    } else {
      if (price >= 5000000 && !this.disabledBasic) {
        this.isInsuranceBasic = true;
        this.disabledInsuranceBasic = true;
        this.modelValue.insurance = "basic";
      } else {
        // this.isInsuranceBasic = !this.disabledBasic;
        this.disabledInsuranceBasic = this.disabledBasic;
        // this.modelValue.insurance = !this.disabledBasic ? "basic" : "free";
      }
    }
  }

  validationCOD(price: any, isFromInput: boolean) {
    // insurance tidak aktif dan yang boleh akun selain client
    if (
      !this.listCodActive.ccoIsInsurance &&
      this.dataProfile.account_type !== "client"
    ) {
      const minMaxPrice =
        price < this.listCodActive.ccoMinPrice ||
        price > this.listCodActive.ccoMaxPrice;
      if (isFromInput) {
        this.errorEstimationPriceModel = price && minMaxPrice;
        this.errMsgPrice =
          price > this.listCodActive.ccoMaxPrice
            ? `Harga barang COD maksimum ${formatPriceRP(
                this.listCodActive.ccoMaxPrice
              )}, cek & ubah lagi.`
            : `Harga barang COD minimum ${formatPriceRP(
                this.listCodActive.ccoMinPrice
              )}, cek & ubah lagi.`;
      }
    } else {
      //this.isInsuranceBasic = true;
      // this.modelValue.insurance = "basic";
      this.disabledInsuranceBasic = true;
      // auto centang
    }
    if (isFromInput && this.dataProfile.account_type !== "client") {
      this.errorEstimationPriceModel =
        price === 0 ||
        price < this.minEstimationPrice ||
        (this.manualBooking.productName.includes("OTOPACK") &&
          price > 0 &&
          price < 5000000);
      if (price === 0 || price < this.minEstimationPrice) {
        this.errMsgPrice = this.errorMessagePrice;
      }
      if (
        this.manualBooking.productName.includes("OTOPACK") &&
        price > 0 &&
        price < 5000000
      ) {
        this.errMsgPrice =
          "Harga barang harus di atas atau sama dengan  5 juta";
      }
    }
    // insurance aktif auto centang hanya untuk retail
    this.automaticSetCheckbox(price);
  }

  get errorMessagePrice() {
    if (!this.accountIsNonForeign) {
      return this.$t("bookingShipment.validate.itemPrice");
    }

    return this.isFormBookClient
      ? "Harga barang minimum Rp1."
      : "Harga barang minimum Rp10.000.";
  }

  validationDFOD(price: any, isFromInput: boolean) {
    // insurance tidak aktif dan yang boleh akun selain client
    if (
      !this.listDfodActive.ccoIsInsurance &&
      this.dataProfile.account_type !== "client"
    ) {
      const minMaxPrice =
        price < this.listDfodActive.ccoMinPrice ||
        price > this.listDfodActive.ccoMaxPrice;
      if (isFromInput) {
        this.errorEstimationPriceModel = price === 0 || minMaxPrice;
        this.errMsgPrice =
          price > this.listDfodActive.ccoMaxPrice
            ? `Harga barang DFOD maksimum ${formatPriceRP(
                this.listDfodActive.ccoMaxPrice
              )}, cek & ubah lagi.`
            : `Harga barang DFOD minimum ${formatPriceRP(
                this.listDfodActive.ccoMinPrice
              )}, cek & ubah lagi.`;
      }
    } else {
      // this.isInsuranceBasic = true;
      this.modelValue.insurance = "basic";
      this.disabledInsuranceBasic = true;
      // auto centang
    }
    if (isFromInput && this.dataProfile.account_type !== "client") {
      this.errorEstimationPriceModel =
        price === 0 ||
        price < this.minEstimationPrice ||
        (this.manualBooking.productName.includes("OTOPACK") &&
          price > 0 &&
          price < 5000000);
      if (price === 0 || price < this.minEstimationPrice) {
        this.errMsgPrice = this.errorMessagePrice;
      }
      if (
        this.manualBooking.productName.includes("OTOPACK") &&
        price > 0 &&
        price < 5000000
      ) {
        this.errMsgPrice =
          "Harga barang harus di atas atau sama dengan  5 juta";
      }
    }
    // insurance aktif auto centang hanya untuk retail
    this.automaticSetCheckbox(price);
  }

  get minEstimationPrice() {
    if (!this.accountIsNonForeign) {
      return 3.2;
    }

    return this.isFormBookClient ? 1 : 10000;
  }

  isEstimationTyped = false;
  errMsgPrice = "";
  fullFilledCOD(price: any, isFromInput: boolean) {
    if (this.isCodAmount && this.deliveryFeeType == "cod") {
      BookingController.manualBooking.codAmount = price;
    }
    if (this.accountIsNonForeign) {
      price = +price.replace(/\./g, "");
    } else {
      price = +price.replace(/,/g, ".");
    }
    this.errorEstimationPriceModel =
      !isFromInput &&
      price &&
      price < this.minEstimationPrice &&
      this.dataProfile.account_type !== "client";
    if (this.deliveryFeeType === "cod") {
      this.validationCOD(price, isFromInput);
      BookingController.setCOD(true);
      BookingController.setDfod(false);
      return;
    } else if (this.deliveryFeeType === "dfod") {
      this.validationDFOD(price, isFromInput);
      BookingController.setCOD(true);
      BookingController.setDfod(true);
      return;
    }
    BookingController.setCOD(false);
    BookingController.setDfod(false);
    this.errorEstimationPriceModel =
      ((price === 0 || price < this.minEstimationPrice) &&
        isFromInput &&
        this.dataProfile.account_type !== "client") ||
      (this.manualBooking.productName.includes("OTOPACK") &&
        price > 0 &&
        price < 5000000);
    if (
      (price === 0 || price < this.minEstimationPrice) &&
      isFromInput &&
      this.dataProfile.account_type !== "client"
    ) {
      this.errMsgPrice = this.errorMessagePrice;
    }
    if (
      this.manualBooking.productName.includes("OTOPACK") &&
      price > 0 &&
      price < 5000000
    ) {
      this.errMsgPrice = "Harga barang harus di atas atau sama dengan  5 juta";
    }
    // this.isInsuranceBasic = false;
    // this.disabledInsuranceBasic = false;
    // this.modelValue.insurance = "free";
    this.automaticSetCheckbox(price);
  }

  dataListCommodities: Commodity[] = [];
  get commodities() {
    return this.dataListCommodities.map((item) => {
      return {
        id: item.commodity_id,
        name: this.accountIsNonForeign
          ? item.commodity_name
          : item.commodity_name_en,
        code: item.commodity_code,
        status: item.commodity_status,
        isDangerousGoods: item.isDangerousGoods,
        isQuarantine: item.isQuarantine
      };
    });
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  disabledInsuranceBasic = false;

  fetchCommodityList = debounce(async (search: string) => {
    const commodities = await CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search,
        page: 1,
        limit: 20,
        commodityStatus: "active"
      })
    );
    this.dataListCommodities = commodities.data;
  }, 250);
  async fetchCommodityListInfiniteScroll(search = "") {
    CommodityController.commodities.pagination.page++;
    const commodities = await CommodityController.getCommodityList(
      new RequestListCommodity({
        search,
        page: CommodityController.commodities.pagination.page,
        limit: 20,
        commodityStatus: "active"
      })
    );
    this.dataListCommodities.push(...commodities.data);
  }

  get isLoadingCommodity() {
    return CommodityController.isLoading;
  }

  onFocusCommodityList() {
    // this.setFieldIndex(11);
    if (!this.modelValue.commodityCode) {
      this.getCommodityList();
    }
  }

  async getCommodityList() {
    const commodities = await CommodityController.getCommodityList(
      new RequestListCommodity({
        page: 1,
        limit: 20,
        commodityStatus: "active"
      })
    );
    this.dataListCommodities = commodities.data;
  }

  get insuranceDetail() {
    return BookingController.insuranceTypeDelivery.data.find(
      (item: InsuranceTypeDeliveryData) => item.value === "basic"
    );
  }

  get insuranceProfit() {
    const premi =
      (Number(this.insuranceDetail?.cost) / 100) *
      Number(
        this.modelValue.estimationPrice.replace(/[.]/g, "").replaceAll(",", ".")
      );
    const premiRound = this.accountIsNonForeign
      ? premi
      : (Math.ceil(premi * 10) / 10).toLocaleString(undefined, {
          maximumFractionDigits: 1
        });

    const profit =
      (Number(this.insuranceDetail?.commissionPos) / 100) * Number(premiRound);
    return profit;
  }

  // insurance types
  listInsurance: any = [
    {
      name: "",
      value: ""
    }
  ];

  async getInsurancesTypeDelivery() {
    await BookingController.getInsuranceTypesDelivery({
      insuranceConfig: 2
    });

    this.listInsurance = BookingController.insuranceTypeDelivery.data.map(
      (e: InsuranceTypeDeliveryData) => {
        return {
          name: e.name,
          value: e.value,
          status: e.status,
          cost: e.cost,
          commissionPos: e.commissionPos,
          maxProtection: e.maxProtection
        };
      }
    );
  }

  get formData(): any {
    return { ...this.modelValue, isInsurance: this.isInsuranceBasic, listCodActive: this.listCodActive, listDfodActive: this.listDfodActive };
  }

  inputEstimationPrice(value: string) {
    this.fullFilledCOD(value, true);
    this.getInsurance(value);
  }

  get bookingRetail() {
    return this.accountData.account_type && this.formType === "manual";
  }
  get accountData() {
    return AccountController.accountData;
  }
  get isCA(): boolean {
    return isCustomerApps(this.modelValue?.sttShipmentId);
  }
  validationGetInsurance(value: number): boolean {
    return (!!value && (this.bookingRetail || this.isCA) && this.accountData.isPosAccount);
  }
  getInsurance = debounce(async (value = "") => {
    value = +replaceComma(value);
    if (this.validationGetInsurance(value)) {
      try {
        const params = new ApiRequestList({
          page: undefined,
          limit: undefined,
          typeInsurance: this.isInsuranceBasic ? "basic" : "free",
          goodsPrice: value,
          country: this.accountData.countryCode
        });
        if (this.bookingRetail) {
          params.accountType = "pos";
          params.accountRefId = this.accountData.account_type_detail?.id;
        }
        if (this.isCA) {
          params.accountType = "client";
          params.accountRefId = 2311;
        }

        const res = await InsuranceController.getInsurance(params);
        InsuranceController.setInsurance(res);
      } catch (error) {
        console.log(error);
      }
    }
  }, 250);
}
