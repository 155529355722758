import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader rounded-full border-2 border-t-2 h-4 xxl:h-5 w-5 mx-auto"
}
const _hoisted_2 = {
  key: 1,
  class: "gap-x-2 justify-center items-center flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icons = _resolveComponent("Icons")!

  return (_openBlock(), _createBlock("div", {
    class: `${_ctx.isWidthFull ? 'w-full' : ''} font-medium text-12px xxl:text-14px group`
  }, [
    _createVNode("button", {
      id: _ctx.id,
      type: "submit",
      class: 
        `${_ctx.isWidthFull ? 'w-full' : ''} ${
          _ctx.rounded ? 'rounded-full' : 'rounded-md'
        } ${
          _ctx.small
            ? 'px-2 py-2'
            : _ctx.tight
            ? 'px-2 py-0'
            : _ctx.dense
            ? 'px-0 py-2'
            : _ctx.compact
            ? 'px-0 py-0'
            : 'px-6 py-2 xxl:py-2.5'
        } ${
          _ctx.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } flex items-center ${_ctx.textAlign ||
          'justify-center'} focus:outline-none ${_ctx.buttonStyle} ${_ctx.customClass} hover:border-${_ctx.colorHover} hover:text-${_ctx.textColorHover} group-hover:text-${_ctx.textColorHover} hover:bg-${_ctx.bgColorHover}`
      ,
      disabled: _ctx.disabled,
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock("span", _hoisted_1))
        : (_openBlock(), _createBlock("span", _hoisted_2, [
            (_ctx.iconLeft && !_ctx.iconV2)
              ? (_openBlock(), _createBlock("img", {
                  key: 0,
                  style: 
              `height: ${_ctx.sizeIcon}; width: ${_ctx.sizeIcon}; min-width: ${_ctx.sizeIcon}`
            ,
                  src: require(`@/app/ui/assets/svg/${_ctx.iconLeft}.svg`),
                  class: "w-auto",
                  alt: "icon left"
                }, null, 12, ["src"]))
              : _createCommentVNode("", true),
            (_ctx.iconLeft && _ctx.iconV2)
              ? (_openBlock(), _createBlock(_component_Icons, {
                  key: 1,
                  name: _ctx.iconLeft,
                  class: 
              `w-5 cursor-pointer text-${
                _ctx.disabled && (_ctx.outline || _ctx.flat)
                  ? 'gray-lp-600'
                  : _ctx.iconColor || _ctx.textColor || _ctx.color
              } group-hover:text-red-lp-100`
            ,
                  fill: _ctx.iconV2Fill
                }, null, 8, ["name", "class", "fill"]))
              : _createCommentVNode("", true),
            (_ctx.title)
              ? (_openBlock(), _createBlock("span", {
                  key: 2,
                  class: 
              `w-auto whitespace-nowrap font-montserrat ${
                _ctx.fontBold
                  ? 'font-bold'
                  : _ctx.fontWeight
                  ? `font-${_ctx.fontWeight}`
                  : 'font-medium'
              } text-${_ctx.textSize}px xxl:text-14px`
            
                }, _toDisplayString(_ctx.title), 3))
              : _createCommentVNode("", true),
            (_ctx.icon && !_ctx.iconV2)
              ? (_openBlock(), _createBlock("img", {
                  key: 3,
                  src: require(`@/app/ui/assets/svg/${_ctx.icon}.svg`),
                  style: 
              `height: ${_ctx.sizeIcon}; width: ${_ctx.sizeIcon}; min-width: ${_ctx.sizeIcon}`
            ,
                  class: "w-auto",
                  alt: "icon right"
                }, null, 12, ["src"]))
              : _createCommentVNode("", true),
            (_ctx.icon && _ctx.iconV2)
              ? (_openBlock(), _createBlock(_component_Icons, {
                  key: 4,
                  name: _ctx.icon,
                  class: 
              `w-5 cursor-pointer text-${
                _ctx.disabled && (_ctx.outline || _ctx.flat)
                  ? 'gray-lp-600'
                  : !_ctx.iconV2Fill ? _ctx.iconColor || _ctx.textColor || _ctx.color : ''
              }`
            ,
                  fill: _ctx.iconV2Fill
                }, null, 8, ["name", "class", "fill"]))
              : _createCommentVNode("", true)
          ]))
    ], 10, ["id", "disabled"])
  ], 2))
}