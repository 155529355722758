<template>
  <div
    class="bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative font-montserrat"
  >
    <Header>
      <div class="flex flex-col w-full items-start gap-y-3 lg:flex-row lg:items-center text-12px xxl:text-14px ">
        <div
          class="font-semibold text-20px mr-8 whitespace-no-wrap text-black-lp-200 capitalize"
        >
          User Management
        </div>
        <div class="flex flex-col w-full gap-y-2 mb-2 md:flex-row md:gap-y-2 md:mb-2 lg:w-auto lg:gap-y-0 lg:mb-0">
        <Search
          class="w-full"
          :onInput="onSearch"
          :onClear="clearSearch"
          :value="searchValue"
          placeholder="Cari username atau user ID"
        />
        <advance-filter-user-management />  
        </div>
      </div>
      <lp-button
        class="whitespace-nowrap"
        v-if="isCreateAble"
        @click="goAdd"
        title="Buat User"
        textColor="white"
      />
    </Header>
    <TableLazyLoad v-if="isLoading" :columns="columns" />
    <ErrorMessage
      v-if="
        dataUser.length === 0 &&
          !isLoading &&
          !isError &&
          (searchValue !== '' ||
            statusName !== '' ||
            typeName !== '' ||
            referenceName !== '')
      "
      errorType="notfound"
    />
    <ErrorMessage
      v-if="isError && !isLoading"
      :errorType="errorCause"
      :onTryAgain="fetchUserList"
    />
    <div
      v-if="
        dataUser.length === 0 &&
          !isLoading &&
          page !== 1 &&
          searchValue === '' &&
          statusName === '' &&
          typeName === '' &&
          referenceName === ''
      "
      class="h-screen"
    >
      <ErrorMessage errorType="lastPage" />
      <div class="absolute w-full bottom-0">
        <Pagination
          :onNext="nextPage"
          :onPrevious="prevPage"
          :page="page"
          :toPage="toPage"
        />
      </div>
    </div>
    <EmptyState
      data="data User"
      v-if="
        dataUser.length === 0 &&
          !isError &&
          !isLoading &&
          page === 1 &&
          searchValue === '' &&
          typeName === '' &&
          referenceName === ''
      "
    />
    <div
      class="border-t border-gray-lp-200 border-b h-screen pb-20 overflow-auto"
      v-if="isShowTable"
    >
    <div class="overflow-y-auto">
      <TableV2
        :columns="columns"
        :data="dataUser"
        class="my-2"
        @click="onClickRow"
        :isDetailAble="isDetailAble"
        :isRightFreeze="false"
        v-model:pagination="pagination"
        @request="fetchUserList"
        paginationStyle="v3"
      />
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { UserController } from "@/app/ui/controllers/UserController";
import router from "../../router";
import debounce from "lodash/debounce";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { checkRolePermission, ellipsisString } from "@/app/infrastructures/misc/Utils";
import { USER_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";
import AdvanceFilterUserManagement from "@/app/ui/views/user-management/advance-filter-user-management";

@Options({
  components: {
    AdvanceFilterUserManagement,
    EmptyState
  }
})
export default class UserManagement extends Vue {
  // Breadcrumb
  breadcrumb = [];

  // get data
  created() {
    this.firstPage();
    UserController.selectTypeAction("");
    UserController.selectStatus("");
    this.fetchUserList();
  }

  get isShowTable() {
    return this.dataUser.length > 0 && !this.isLoading && !this.isError;
  }
  get isCreateAble() {
    return checkRolePermission(USER_MANAGEMENT.CREATE) && !this.isError;
  }
  get isDetailAble() {
    return checkRolePermission(USER_MANAGEMENT.DETAIL);
  }

  fetchUserList() {
    UserController.fetchUserList();
  }

  get dataUser() {
    return UserController.userData.data.filter(user => {
      return user.account_type !== "system" && user.account_type !== "internal";
    });
  }

  get pagination() {
    return UserController.userData.pagination;
  }

  firstPage() {
    return UserController.setFirstPage();
  }

  // search
  search = "";
  get searchValue() {
    return UserController.search;
  }
  onSearch(value) {
    this.search = value;
    UserController.searchAction(value);
    this.firstPage();
  }

  clearSearch() {
    UserController.clear();
    this.search = "";
    this.firstPage();
  }

  columns = [
    {
      name: "User ID",
      key: "id",
      styleHead: "w-1/12 text-center whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: item => {
        return `<span class='text-center text-red-lp-200 flex justify-center'>${item.account_id}</span>`;
      }
    },
    {
      name: "Username",
      key: "Username",
      styleHead: "w-1/12 text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: item => {
        return `<div class='flex'>${this.ellipsisText(item.username)}</div>`;
      }
    },
    {
      name: "Email",
      key: "Email",
      styleHead: "w-1/12 text-left",
      styleBody: "text-left rounded py-0",
      render: item => {
        return `<div>${this.ellipsisText(item.account_email)}</div>`;
      }
    },
    {
      name: "No. Hp",
      key: "phoneNumber",
      styleHead: "w-1/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: item => {
        return `<span class="whitespace-no-wrap">
              ${
                item.account_phone_number !== ""
                  ? item.account_phone_number
                  : "-"
              }</span>`;
      }
    },
    {
      name: "Tipe Akun",
      key: "tipeAkun",
      styleHead: "w-1/12 text-left",
      styleBody: "",
      render: item => {
        return `
        <div class="text-black-lp-200 flex">
            <div class="rounded px-2 py-0 bg-gray-lp-400">
              <span class="capitalize">${item.account_type.toLowerCase()}</span>
            </div>
          </div>`;
      }
    },
    {
      name: "Reference",
      key: "reference",
      styleHead: "w-1/12 text-left",
      styleBody: "",
      render: item => {
        return `<span class="capitalize">${this.mappingReference(item)}</span>`;
      }
    },
    {
      name: "Role",
      key: "role",
      styleHead: "w-1/12 text-left",
      styleBody: "",
      render: item => {
        return `<div class="text-black-lp-200 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                    <span class="capitalize">
                      ${this.snakeCaseToNormalCase(item.account_role)}
                    </span>
                  </div>
                </div>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-1/12 text-left",
      styleBody: "",
      render: item => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.status.toLowerCase() === "active"
            ? "bg-green-lp-300"
            : "bg-red-300"
        } ${
          item.status.toLowerCase() === "active"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
      ><span class="capitalize">${item.status.toLowerCase()}</span></div></div>`;
      }
    }
  ];

  ellipsisText(text, maxChar = 12) {
    return ellipsisString(text, maxChar)
  }

  mappingReference(item) {
    let reference = "-";
    if (item.account_client !== undefined) {
      reference = this.snakeCaseToNormalCase(item.account_client.company_name);
    } else if (item.account_vendor !== undefined) {
      reference = this.snakeCaseToNormalCase(item.account_vendor.name);
    } else if (item.account_partner !== undefined) {
      reference = this.snakeCaseToNormalCase(item.account_partner.name);
    } else if (item.account_role !== undefined) {
      reference = this.snakeCaseToNormalCase(item.account_role);
    }
    return reference
  }

  // To Detail
  onClickRow(item) {
    this.breadcrumb.push(item.account_id);
    router.push({
      path: `/user-management/${item.account_id}`,
      state: {
        fullName: `${item.name} - ${item.cityCode}`,
        id: item.id
      }
    });
  }

  // To add
  goAdd() {
    this.$router.push(`/user-management/add`);
  }

  //Loading
  get isLoading() {
    return UserController.isLoading;
  }

  //Error
  get isError() {
    return UserController.isError;
  }

  //Error Cause
  get errorCause() {
    return UserController.errorCause;
  }

  // Pagination
  get page() {
    return UserController.page;
  }
  get limit() {
    return UserController.limit;
  }
  nextPage() {
    UserController.setNextPage();
  }

  prevPage() {
    UserController.setPrevPage();
  }

  toPage = debounce(value => {
    UserController.setPageAction(value);
  }, 500);

  snakeCaseToNormalCase(val) {
    return val.split("_").join(" ");
  }

  get statusName() {
    return UserController.status;
  }
  get typeName() {
    return UserController.accountType;
  }
  get referenceName() {
    return UserController.reference;
  }
}
</script>

<style>
.is-open {
  border-top: 30px solid transparent;
}
</style>
