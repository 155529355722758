import { JsPdfFont, loadFontsJsPdf } from "./module";

const fonts = [
  new JsPdfFont({
    path: `${process.env.VUE_APP_S3_AMAZON_AWS}/assets/fonts/Sinhala-Bold.js`,
    variableName: "sinhalaBoldFont",
    filename: "NotoSansSinhala-Bold-bold.ttf"
  }),
  new JsPdfFont({
    path: `${process.env.VUE_APP_S3_AMAZON_AWS}/assets/fonts/Sinhala-Bold.js`,
    variableName: "sinhalaBoldFont",
    filename: "NotoSansSinhala-Bold-bold.ttf",
    customFontName: "si"
  })
];

export const downloadFontsJsPdf = async () => await loadFontsJsPdf(fonts);
