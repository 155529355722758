import { EditInsuranceApiRequest } from "@/data/payload/api/InsuranceApiRequest";
import { EditInsuranceRequestInteface } from "@/data/payload/contracts/InsuranceRequest";
import { InsuranceMapper } from "@/data/persistences/mappers/InsuranceMapper";
import { InsuranceRepositoryInterface } from "@/data/persistences/repositories/contracts/InsuranceRepositoryInterface";
import { DetailInsurance, Insurance } from "@/domain/entities/Insurance";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ApiRequestList } from "@/domain/entities/MainApp";

export class InsuranceApiRepository implements InsuranceRepositoryInterface {
  private service: ApiService;
  private mapper: InsuranceMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: InsuranceMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetailInsurance(id: number): Promise<DetailInsurance> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailInsurance(id),
      {}
    );
    return this.mapper.convertDetailInsuranceFromApi(resp);
  }

  public async editInsurance(
    payload: EditInsuranceRequestInteface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePrivate(
      "put",
      this.endpoints.editConfigurablePrice(),
      undefined,
      payload as EditInsuranceApiRequest
    );
    return this.mapper.convertPayloadData(resp);
  }

  public async getInsurance(params: ApiRequestList): Promise<Insurance> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getInsurance(params),
      {}
    );
    return this.mapper.convertInsuranceFromApi(resp);
  }
}
