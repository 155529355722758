/* eslint-disable @typescript-eslint/camelcase */

import { Pagination } from "./Pagination";

export class Commodity {
  commodity_id = 0;
  commodity_code = "";
  commodity_name = "";
  commodity_name_en = "";
  commodity_group = "";
  hs_code = "";
  commodity_status = "";
  description = "";
  commodity_surcharge_applicable = "";
  commodity_document_surcharge = "";
  commodity_service_type = "";
  commodity_min_price = "";
  created_at = "";
  updated_at = "";
  created_by = "";
  updated_by = "";
  isDangerousGoods = false;
  isQuarantine = false;

  constructor(fields?: Partial<Commodity>) {
    Object.assign(this, fields);
  }
}

export class CommodityList {
  pagination: Pagination;
  data: Commodity[];
  constructor(pagination: Pagination, data: Commodity[]) {
    this.pagination = pagination;
    this.data = data;
  }
}
