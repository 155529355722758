
import { Options, Vue } from "vue-class-component";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import OverlayPanel from "primevue/overlaypanel";
import { FlagsPermissionClient } from "@/feature-flags/flags-permission-client";

@Options({
  components: {
    EmptyState,
    OverlayPanel
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    ClientRegistrationController.setSnackbarSuccess("");
    ClientRegistrationController.setSnackbarFailed("");
    next();
  },
  computed: {
    getCurrentRoute() {
      return "Client Registration";
    },
    isDetailAble() {
      return FlagsPermissionClient.permission_client_registration_detail_view.isEnabled();
    },
    isCreateAble() {
      return FlagsPermissionClient.permission_client_registration_add.isEnabled();
    }
  }
})
export default class ClientRegistrationV2 extends Vue {
  mounted() {
    this.refs = this.$refs;
    ClientRegistrationController.initClient();
  }

  onRequest() {
    ClientRegistrationController.fetchClientList();
  }

  get firstRequest() {
    return ClientRegistrationController.firstRequest;
  }

  get client() {
    return ClientRegistrationController.clientRegistrationData.data;
  }

  //Filter Search
  onSearch(value: string) {
    ClientRegistrationController.searchAction(value);
  }
  get searchValue() {
    return ClientRegistrationController.search;
  }
  clearSearch() {
    ClientRegistrationController.clear();
  }

  statusData = [
    { name: "Semua status", value: "" },
    { name: "Ditolak", value: "rejected" },
    { name: "Menunggu", value: "waiting" }
  ];
  statusSelect = false;
  statusName = "";
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    ClientRegistrationController.selectStatusAction({
      name: name,
      value: value
    });
    this.statusName = name;
    this.onCloseStatusSelect();
  }

  typeData = [
    { name: "Semua tipe klien", value: "" },
    { name: "Klien induk", value: "parent" },
    { name: "Klien cabang", value: "branch" }
  ];
  typeSelect = false;
  typeName = "";
  onOpenTypeSelect() {
    this.typeSelect = true;
  }
  onCloseTypeSelect() {
    this.typeSelect = false;
  }
  onSelectType(name: string, value: string) {
    ClientRegistrationController.selectTypeAction({
      name: name,
      value: value
    });
    this.typeName = name;
    this.onCloseTypeSelect();
  }

  // Table
  columns = [
    {
      name: "Klien ID",
      styleHead: "w-1/12 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<span class='text-left text-red-lp-200 flex justify-center'>${item.id}</span>`;
      }
    },
    {
      name: "Nama klien",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${item.clientName}</span>`;
      }
    },
    {
      name: "Alias",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-black-lp-300 truncate">${item.clientAlias || '-'}</div>`;
      }
    },
    {
      name: "Kode klien",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${item.clientCode}</span>`;
      }
    },
    {
      name: "Tipe klien",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${item.clientType.toLowerCase() === "client induk" ? "Klien induk" : "Klien cabang"}</span>`;
      }
    },
    {
      name: "Periode harga",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${formatDateWithoutTime(
          item.clientStartDate
        )} - ${formatDateWithoutTime(item.clientEndDate)}</div>`;
      }
    },
    {
      name: "Status kontrak",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `
          <div class="flex">
        <div
          class="rounded-full px-2 py-0 text-center ${this.extractColor(item)}"
        ><span class="capitalize">${
          item.clientStatus.toLowerCase() === "waiting" ? "Menunggu" : "Ditolak"
        }</span></div></div>`;
      }
    }
  ];
  onClickRow(item: any) {
    this.$router.push(`/client/client-registration/${item.id}`);
  }
  goAdd(route: string) {
    this.$router.push(`/client/client-registration/${route}`);
  }

  // Pagination Table
  get pagination() {
    return ClientRegistrationController.clientRegistrationData.pagination;
  }

  //Loading
  get isLoading() {
    return ClientRegistrationController.isLoading;
  }

  //Error
  get isError() {
    return ClientRegistrationController.isError;
  }

  //Error Cause
  get errorCause() {
    return ClientRegistrationController.isErrorCause;
  }

  refs: any = {};
  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs["op"].toggle(event);
  }
  get panelVisible() {
    return this.refs.op?.visible;
  }

  extractColor(item: any) {
    const color: any = {
      "approved": "bg-green-lp-300 text-green-lp-200",
      "waiting": "bg-yellow-lp-200 text-yellow-lp-100"
    }

    return color[item.clientStatus.toLowerCase()] ?? "bg-red-300 text-red-lp-200"
  }
}
