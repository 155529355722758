import {
  detectLanguage,
  JsPdfLanguage,
  specialLanguage
} from "@/app/infrastructures/misc/common-library/DetectLanguage";
import { ellipsisString } from "@/app/infrastructures/misc/Utils";
import { DeliverySenderReceiverSection } from "./delivery-sender-receiver";

export const DeliveryAddress = async (params: any, translate: Function) => {
  const leftMargin = params.startX + 5;
  params.doc.setTextColor("#333333");

  const {
    senderPositionY,
    recipientPositionY,
    sttRecipientTextSplitted
  } = await DeliverySenderReceiverSection(params, translate);

  // PENERIMA PHONE
  let phoneRecipientPositionY =
    recipientPositionY +
    0.5 +
    params.doc.getTextDimensions(sttRecipientTextSplitted).h;

  const fontLanguage = await detectLanguage(
    new JsPdfLanguage({
      text: params.data.sttRecipientAddress,
      product: params.data.sttProductType,
      jsPdf: params.doc
    })
  );
  const phoneAndRecipientAddress = params.doc.splitTextToSize(
    ellipsisString(
      params.data.sttRecipientPhone +
        " " +
        params.data.sttRecipientAddress.toUpperCase(),
      200 + params.data.sttRecipientPhone.length
    ),
    specialLanguage(fontLanguage) ? 45 : 70
  );

  params.doc.setFont(fontLanguage, "bold");
  params.doc.setFontSize(specialLanguage(fontLanguage) ? 5.5 : 6);
  params.doc.text(
    phoneAndRecipientAddress,
    leftMargin,
    phoneRecipientPositionY
  );

  if (phoneAndRecipientAddress.length > 3) {
    phoneRecipientPositionY += 1.5;
  }

  return {
    phoneRecipientPositionY,
    senderPositionY
  };
};
