import { FeedbackRepositoryInterface } from "@/data/persistences/repositories/contracts/FeedbackRepositoryInterface";
import ApiService from "../../services/ApiService";
import { FeedbackMapper } from "@/data/persistences/mappers/FeedbackMapper";
import { SubmitFeedbackApiRequest } from "@/data/payload/api/FeedbackApiRequest";
import { FeedbackEndpoints } from "../../endpoints/hydra/FeedbackEndpoints";

export class FeedbackApiRepository implements FeedbackRepositoryInterface {
  private service: ApiService;
  private mapper: FeedbackMapper;
  private endpoints: FeedbackEndpoints;

  constructor(
    service: ApiService,
    mapper: FeedbackMapper,
    endpoints: FeedbackEndpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  async submitFeedback(payload: SubmitFeedbackApiRequest): Promise<boolean> {
    await this.service.invoke(
      "POST",
      this.endpoints.feedback(),
      undefined,
      payload
    );

    return true;
  }
}
