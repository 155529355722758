import { ManualSTTData, ManualSTTEntities } from "@/domain/entities/ManualSTT";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class ManualSTTDataMapper {
  public convertManualSTTDataFromApi(
    res: AxiosResponse<any>
  ): ManualSTTEntities {
    const { data } = res;

    const manualStt: ManualSTTData[] = [];
    if (data.data.length === 0) {
      return new ManualSTTEntities(
        new Pagination(
          data.meta.page,
          data.meta.limit,
          data.meta.total_records
        ),
        []
      );
    } else {
      data.data.map((item: any) => {
        manualStt.push(
          new ManualSTTData({
            id: item.stt_manual_id,
            accountRefId: item.account_ref_id,
            accountType: item.account_type,
            sttManualStatus: item.stt_manual_status,
            sttManualCreatedAt: item.stt_manual_created_at,
            sttManualUpdatedAt: item.stt_manual_updated_at,
            sttManualMixpack: item.stt_manual_mixpack,
            sttManualName: item.stt_manual_name,
            sttManualCode: item.stt_manual_code,
            sttClientName: item.stt_client_name,
            campaignSttQuote: item.campaign_stt_quote
          })
        );
      });
    }
    return new ManualSTTEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      manualStt
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
