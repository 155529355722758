import { Pagination } from "./Pagination";

export class ReportApiRequest {
  service = "hydra";
  title = "";
  version = "1";
  isCheck? = false;
  startDate? = "";
  endDate? = "";
  reportType? = "";
  bookedForType? = "";
  bookedForId? = "";
  cityCode? = "";
  originCity? = "";
  destinationCity? = "";
  status? = "";
  fileName? = "";
  scrapBased? = "";
  missRouteBase? = "";
  partnerId? = "";
  posId? = "";
  type? = "";
  extension? = "csv";
  deliveredById? = "";
  deliveredByType? = "";
  cityDestinationCode? = "";
  manualSttForType = "";
  manualSttForId = "";
  async = false;
  campaignId = 0;
  feature = "report";
  posParentId = 0;
  bookedByPosBranchId = "";
  codType = "";
  dataType = "";
  createdBy = "";
  constructor(fields?: Partial<ReportApiRequest>) {
    Object.assign(this, fields);
  }
}

export class ReportCheckData {
  code = 0;
  message = "";
  success? = false;
  data? = false;

  constructor(fields?: Partial<ReportCheckData>) {
    Object.assign(this, fields);
  }
}

export class ReportListDownloadRequest {
  page = 1;
  limit = 10;
  constructor(fields?: Partial<ReportListDownloadRequest>) {
    Object.assign(this, fields);
  }
}

export class ReportListDownload {
  pagination: Pagination;
  data: ReportListDownloadData[];
  constructor(pagination: Pagination, data: ReportListDownloadData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ReportListDownloadData {
  id = 0;
  url = "";
  type = "";
  createdAt = "";
  status = "";

  constructor(fields?: Partial<ReportListDownloadData>) {
    Object.assign(this, fields);
  }
}

export class LastSyncSttReport {
  lastUpdatedAt = "";

  constructor(fields?: Partial<LastSyncSttReport>) {
    Object.assign(this, fields);
  }
}
