
import { Options, Vue } from "vue-class-component";
import { defineAsyncComponent } from "vue";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { CancelBooking } from "@/data/payload/api/BookingRequest";
import { ReasonData } from "@/domain/entities/PodDex";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { CustomProcessEditAddressForm } from "@/domain/entities/CustomProcess";
import { DistrictData } from "@/domain/entities/Location";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import convertPhoneNumberV2 from "@/app/infrastructures/misc/common-library/ConvertPhoneNumberV2";
import OverlayPanel from "primevue/overlaypanel";
import { GTMOnProcessSttEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/on-process-stt-events";
import { OptionsClass } from "@/domain/entities/MainApp";
import { ReasonListDex } from "@/data/payload/api/PodDexRequest";
import { TrackingController } from "@/app/ui/controllers/TrackingController";

const EditAddress = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/custom-process/component/update/edit-address.vue")
});

@Options({
  emits: ["update:isShow", "success"],
  components: { EditAddress, OverlayPanel },
  watch: {
    isShow() {
      if (
        this.props.isShow &&
        this.detailDataStt.sttLastStatus.match(/^REJECTED$/i)
      )
        this.onFindReasonRejected();
    }
  },
  props: {
    isShow: {
      default: false,
      type: Boolean
    },
    sttNumber: {
      type: String,
      default: ""
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    customPath: {
      type: String,
      default: "booking"
    }
  }
})
export default class DetailRejectPopup extends Vue {
  props: any = this.$props;
  sttNo = "";
  error = {
    sttNo: false,
    captionError: ""
  };

  onBlurSttNo() {
    if (this.sttNo.length < 17) {
      this.error.sttNo = true;
      this.error.captionError = this.confirmValue.errorInputNewStt;
    }
  }
  onInputSttNo() {
    if (this.sttNo.length === 17) this.getDetailSttNo();
  }
  onChangeSttNo() {
    this.error.sttNo = false;
    this.error.captionError = "";
  }
  async getDetailSttNo() {
    MainAppController.showLoading();
    try {
      const res = await TrackingController.trackStt({
        sttNo: this.sttNo,
        isGetSla: false,
        isSecondCall: false,
        cache: true,
       // archive: false
      });
      if (res) {
        if (this.isSttDetailAndCompanyNameEqual) {
          this.error.sttNo = false;
          this.error.captionError = "";
        } else {
          this.error.sttNo = true;
          this.error.captionError = this.confirmValue.errorInputNewStt;
        }
      } else {
        this.error.sttNo = true;
        this.error.captionError = this.confirmValue.errorInputNewStt;
      }
    } catch (error) {
      this.error.sttNo = true;
      this.error.captionError = this.confirmValue.errorInputNewStt;
    } finally {
      MainAppController.closeLoading();
    }
  }

  get isSttDetailAndCompanyNameEqual() {
    return (
      AccountController.accountData.account_type_detail.company_name.toUpperCase() ===
      TrackingController.trackingSttData.sttDetail.bookedName.toUpperCase()
    );
  }

  onSelectStatus(name: string, value: string) {
    if (!this.isShowInputNewStt) {
      this.error.sttNo = false;
      this.error.captionError = "";
      this.sttNo = "";
    }
    this.selectedReasonName = name;
    this.selectedReasonValue = value;
    this.openSelect = false;
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  get isAuth(): any {
    return this.$route?.meta?.isAuth;
  }

  async print(type: string, language = "id") {
    const book: any = await import(
      "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
    );
    const printType: any = {
      thermal: () =>
        book.default.methods.printBasic(
          this.newSttIdGenerated,
          0,
          language,
          false,
          true
        ),
      basic: () => book.default.methods.printBasic(this.newSttIdGenerated, 1)
    };
    await printType[type]();
  }

  get confirmValue() {
    return {
      title: this.$t("booking.titleConfirmReject"),
      message: this.$t("booking.messageConfirmReject"),
      textSuccess: this.$t("booking.submitConfirmReject"),
      textCancel: this.$t("booking.cancelConfirmReject"),
      messageInputNewStt: this.$t("booking.messageInputNewStt"),
      errorInputNewStt: this.$t("booking.errorInputNewStt"),
      custom: true
    };
  }
  customStyleButtonNewStt = {
    title: "Print Resi",
    textColor: "white",
    color: "red-lp-100",
    groupOptions: [
      {
        icon: "printer",
        label: "Print Thermal Resi",
        clickFunction: () => this.print("thermal")
      },
      {
        icon: "document-download-outline-grey",
        label: "Print Basic/Save to PDF",
        clickFunction: () => this.print("basic")
      }
    ]
  };

  togglePanelPrintSuccess(event: any) {
    const refs: any = this.$refs;
    refs["op-success"]?.toggle(event);
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  // handling
  confirmationCancel() {
    this.$emit("update:isShow", false);
  }

  async confirmationSubmit() {
    this.$emit("update:isShow", false);
    this.handleError();
    GTMOnProcessSttEvent("on_process_stt_cnx_confirm", {
      sttNumber: this.props.sttNumber,
      isGenerate: this.isGenerateNewStt,
      cnxReason: this.selectedReasonName
    });
    if (this.isGenerateNewStt) {
      this.$emit("update:isShow", false);
      await this.showEditAddress();
      return;
    }
    const resp = await BookingController.cancelBooking(
      new CancelBooking({
        sttNumber: this.props.sttNumber,
        reason: this.selectedReasonValue,
        sttNoReference: this.isShowInputNewStt ? this.sttNo : undefined
      })
    );
    if (resp?.success) {
      this.confirmationFinish = true;
      this.titleFinish = this.finishConfirmationValue.title;
      this.messageFinish = this.$t("booking.messageSuccessReject", {
        sttNumber: this.props.sttNumber
      });
      this.successFinish = this.finishConfirmationValue.textSuccess;
    }
  }

  handleError() {
    this.confirmationFinish = false;
  }

  // select
  openSelect = false;

  get isRejectedStatus(): boolean {
    return (
      !!this.detailDataStt.sttLastStatus.match(/^REJECTED$/i) &&
      !!this.detailDataStt.refundRemarks
    );
  }

  get rejectedReasons() {
    const list = [
      {
        name: this.$t("booking.placeholderReason"),
        value: ""
      },
      ...PodDexController.reasonList.map((reason: ReasonData) => {
        return { name: reason.reasonDescription, value: reason.reasonCode };
      })
    ];
    return list;
  }

  get isShowInputNewStt() {
    return (
      (this.selectedReasonValue === "RES59" ||
        this.selectedReasonValue === "RES58" || 
        this.selectedReasonValue === "RES71") &&
      AccountController.accountData.isPosAccount &&
      this.isLastSttStatusPUPOrBKD
    );
  }

  async onFindReasonRejected() {
    MainAppController.showLoading();
    if (this.detailDataStt.sttLastStatus.match(/^REJECTED$/i)) {
      await PodDexController.getReasonList(
        new ReasonListDex({
          statusCode: "REJECTED",
          reasonStatus: "active"
        })
      );
      const findReason = this.rejectedReasons.find(
        key =>
          key.name.toLowerCase() ===
          this.detailDataStt.refundRemarks.toLowerCase()
      );
      this.selectedReasonName = findReason ? findReason.name : "";
      this.selectedReasonValue = findReason ? findReason.value : "";

      if (!findReason) {
        ShipmentBookingController.shipmentBookingDetail.refundRemarks = "";
      }
    }
    MainAppController.closeLoading();
  }

  get isReasonAbleToGenerateNewStt() {
    const ableToGenerate =
      (this.dataProfile.isConsoleAccount ||
        this.dataProfile.isSubConsoleAccount) &&
      (this.isReasonAbleToGenerateNewSttREJECTED ||
        this.isReasonAbleToGenerateNewSttSTI) &&
      !this.detailDataStt.sttProductTypeName.match(/^JUMBOPACKH2H$/i) &&
      !this.detailDataStt.sttProductTypeName.match(/^INTERPACK$/i);
    this.isGenerateNewStt = ableToGenerate || false;
    return ableToGenerate;
  }

  get isReasonAbleToGenerateNewSttREJECTED() {
    return (
      this.detailDataStt.sttLastStatus === "REJECTED" &&
      this.dataProfile.countryCode !== "my"
    );
  }

  get isReasonAbleToGenerateNewSttSTI() {
    const reasonAbleGenerateNewSTT = PodDexController.reasonList.find(
      (reason: ReasonData) => reason.reasonCode === this.selectedReasonValue
    )?.reasonAbleGenerateNewSTT;
    return (
      this.detailDataStt.sttLastStatus === "STI" && reasonAbleGenerateNewSTT
    );
  }

  selectedReasonName = "";
  selectedReasonValue = "";
  isGenerateNewStt = false;

  // confirmation finish
  confirmationFinish = false;
  titleFinish = "";
  messageFinish = "";
  successFinish = "";
  get finishConfirmationValue() {
    return {
      title: this.$t("booking.titleSuccessReject"),
      textSuccess: "OK"
    };
  }

  directFinish() {
    this.selectedReasonValue = "";
    this.isGenerateNewStt = false;
    this.goBack();
  }

  closeModalSuccess() {
    this.confirmationFinish = false;
    this.selectedReasonValue = "";
    this.isGenerateNewStt = false;
    this.$emit("success");
  }

  goBack() {
    this.$router.push(`/shipment/${this.props.customPath}`);
  }

  get isDisabledButtonBatalkan() {
    const errorInputStt = this.isShowInputNewStt
      ? this.sttNo.length < 17 || this.error.sttNo
      : false;
    return errorInputStt || !this.selectedReasonValue;
  }

  get isLastSttStatusPUPOrBKD() {
    return (
      this.detailDataStt.sttLastStatus?.toUpperCase() == "PUP" ||
      this.detailDataStt.sttLastStatus?.toUpperCase() == "BKD"
    );
  }

  // booking cancel with generate stt
  get detailDataStt() {
    return ShipmentBookingController.shipmentBookingDetail;
  }

  editAddress = false;

  async showEditAddress() {
    await this.initDataEditAddress();
    this.editAddress = true;
  }

  closeEditAddress(status: boolean) {
    this.editAddress = status;
    this.$emit("update:isShow", true);
  }

  formEditAddress: CustomProcessEditAddressForm = new CustomProcessEditAddressForm();
  newSttGenerated = "";
  newSttIdGenerated = 0;

  async cancelBookingWithGenerateStt(newData: CustomProcessEditAddressForm) {
    GTMOnProcessSttEvent("on_process_stt_cnx_generate_new_stt", {
      sttNumber: this.props.sttNumber,
      cnxReason: this.selectedReasonName
    });
    this.formEditAddress = newData;
    this.$emit("update:isShow", false);
    const resp = await BookingController.cancelBooking(
      new CancelBooking({
        sttNumber: this.props.sttNumber,
        reason: this.selectedReasonValue,
        isGenerateNewStt: true,
        sttNextCommodity: this.formEditAddress?.sttNextCommodity,
        sttPiecePerPack: this.formEditAddress?.sttPiecePerPack,
        sttBookedBy: this.formEditAddress?.sttBookedBy,
        sttReceiptName: this.formEditAddress?.consigneeName,
        sttReceiptPhone: this.formEditAddress?.consigneePhone,
        sttReceiptAddress: this.formEditAddress?.consigneeAddress,
        sttReceiptAddressType: this.formEditAddress?.addressType,
        sttDestinationDistrictId: (this.formEditAddress
          ?.destinationCity as DistrictData)?.code,
        sttDestinationCityId: (this.formEditAddress
          ?.destinationCity as DistrictData)?.cityCode
      })
    );
    if (resp?.success) {
      this.newSttGenerated = resp.data.stt_success_ref_no[0].split(",")[1];
      this.newSttIdGenerated = resp.data.stt_success_ref_no[0].split(",")[2];
      this.confirmationFinish = true;
      this.titleFinish = "Pembatalan booking STT berhasil";
      this.messageFinish = `STT <strong>${this.props.sttNumber}</strong> berhasil dibatalkan dan telah dibuat STT baru dengan nomor <strong>${this.newSttGenerated}</strong>`;
      this.successFinish = "";
    }
  }

  async initDataEditAddress() {
    MainAppController.showLoading();
    const districtData: DistrictData[] = await LocationController.getDistrictList(
      {
        search: this.detailDataStt.sttDestinationDistrictName,
        status: "",
        page: 1,
        limit: 10
      }
    );
    const isFtzLocation = !!AccountController.accountData.account_location.city_free_trade_zone.match(
      /yes/gi
    );
    const isFtz =
      isFtzLocation ||
      !!this.detailDataStt?.sttPiecePerPack ||
      !!this.detailDataStt?.sttNextCommodity;

    this.formEditAddress = new CustomProcessEditAddressForm({
      isFtz,
      isFtzEditable:
        isFtzLocation || !!this.detailDataStt.sttOriginCityId.match(/^BTH$/gi),
      sttPiecePerPack: this.detailDataStt?.sttPiecePerPack,
      sttNextCommodity:
        this.detailDataStt?.sttNextCommodity || (isFtz ? 0 : ""),
      product: this.detailDataStt.sttProductTypeName,
      addressType: this.detailDataStt.sttRecipientAddressType,
      destinationCity: new DistrictData({
        isCod: districtData[0].isCod.toLowerCase(),
        name: this.detailDataStt.sttOriginAddress,
        cityName: this.detailDataStt.sttOriginCityName,
        code: this.detailDataStt.sttOriginDistrictId,
        cityCode: this.detailDataStt.sttOriginCityId
      }),
      sttIsCod: this.detailDataStt.sttCod.toLowerCase(),
      sttIsDo: this.detailDataStt.sttClient.clientIsDo,
      senderName: this.detailDataStt.sttRecipientName,
      senderAddress: this.detailDataStt.sttRecipientAddress,
      senderPhone: convertPhoneNumberV2(this.detailDataStt.sttRecipientPhone),
      consigneePhone: convertPhoneNumberV2(this.detailDataStt.sttSenderPhone),
      consigneeName: this.detailDataStt.sttSenderName,
      consigneeAddress: this.detailDataStt.sttSenderAddress,
      originCity: new DistrictData({
        name: this.detailDataStt.sttDestinationDistrictName,
        cityName: this.detailDataStt.sttDestinationCityName,
        code: this.detailDataStt.sttDestinationDistrictId
      })
    });
    MainAppController.closeLoading();
  }
}
