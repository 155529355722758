/* eslint-disable @typescript-eslint/camelcase */
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import {
  EditUserRequestInterface,
  AddUserRequestInterface
} from "../contracts/UserRequest";
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";

export class EditUserApiRequest implements EditUserRequestInterface {
  private account_id: number;
  private status: string;
  private email: string;
  private assigned_3lc: string;

  constructor(account_id: number, status: string, email: string, assigned_3lc: string) {
    this.account_id = account_id;
    this.status = status;
    this.email = email;
    this.assigned_3lc = assigned_3lc
  }

  public toJSON() {
    const account_id = this.account_id;
    const status = this.status;
    const email = this.email;
    const assigned_3lc = this.assigned_3lc;
    const payload = {
      account_id,
      status,
      assigned_3lc
    };
    if (email) {
      Object.assign(payload, {
        email
      });
    }
    return JSON.stringify(payload);
  }
}

export class AddUserApiRequest {
   username = "";
   email = "";
   phone_number = "";
   account_type = "";
   role_id = 0;
   reference_id = 0;
   assigned_3lc = "";

  constructor(fields?: Partial<AddUserApiRequest>
  ) {
    Object.assign(this, fields);
  }

  public toJSON() {
    const username = this.username;
    const email = this.email;
    const phone_number = this.phone_number;
    const account_type = this.account_type;
    const role_id = this.role_id;
    const reference_id = this.reference_id;
    const assigned_3lc = this.assigned_3lc;

    return JSON.stringify({
      username,
      email,
      phone_number,
      account_type,
      role_id,
      reference_id,
      assigned_3lc
    });
  }
}

export class RequestListUser {
  [x: string]: any;
  page = 1;
  limit = 10;
  account_type = "";
  reference = "";
  status = "";
  isTotalData = false;
  search = "";
  version = "1";
  accountType = "";

  constructor(fields?: Partial<RequestListUser>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class EditRoleApiRequest implements EditUserRequestInterface {
  accountId = 0;
  roleId = 0;
  status = "";
  assigned_3lc = "";
  constructor(fields?: Partial<EditRoleApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this));
  }
}

export class EditAssigned3lcApiRequest implements EditUserRequestInterface {
  accountId = 0;
  assigned_3lc = "";
  status = "";
  constructor(fields?: Partial<EditAssigned3lcApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this));
  }
}
