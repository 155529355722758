import { RequestPriorityDeliveryMapper } from "@/data/persistences/mappers/RequestPriorityDeliveryMapper";
import ApiService from "../../services/ApiService";
import { RequestPriorityDeliveryRepositoryInterface } from "@/data/persistences/repositories/contracts/RequestPriorityDeliveryRepositoryInterface";
import { RequestPriorityDeliveryEndpoint } from "../../endpoints/hydra/RequestPriorityDeliveryEndpoint";
import { CancelRequestPriorityDelivery, CreateRequestPriorityDelivery, RequestPriorityDelivery } from "@/domain/entities/RequestPriorityDelivery";
import { RequestPriorityDeliveryInterface } from "@/data/payload/contracts/RequestPriorityDelivery";
import { UrgentDeilveryRequest } from "@/domain/entities/UrgentDeliveryRequest";

export class RequestPriorityDeliveryApiRepository
    implements RequestPriorityDeliveryRepositoryInterface {
    private service: ApiService;
    private mapper: RequestPriorityDeliveryMapper;
    private endpoints: RequestPriorityDeliveryEndpoint;

    constructor(
        service: ApiService,
        mapper: RequestPriorityDeliveryMapper,
        endpoints: RequestPriorityDeliveryEndpoint
    ) {
        this.service = service;
        this.mapper = mapper;
        this.endpoints = endpoints;
    }
    public async createRpd(payload: RequestPriorityDeliveryInterface): Promise<UrgentDeilveryRequest> {
        const resp = await this.service.invoke(
            "post",
            this.endpoints.createRequestPriorityDelivery(),
            undefined,
            payload as CreateRequestPriorityDelivery
        );
        return this.mapper.convertCreateRpd(resp);
    }
    public async getDetailRpd(id: any): Promise<RequestPriorityDelivery> {
        const resp = await this.service.invoke("get", this.endpoints.getDetailRequestPriorityDelivery(id), {})
        return this.mapper.convertDetailRpd(resp);
    }
    public async cancelRpd(payload: RequestPriorityDeliveryInterface): Promise<UrgentDeilveryRequest> {
        const resp = await this.service.invoke(
            "post",
            this.endpoints.cancelRequestPriorityDelivery(),
            undefined,
            payload as CancelRequestPriorityDelivery
        );
        return this.mapper.convertCancelRpd(resp);
    }
}
