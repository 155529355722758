export class SubmitFeedbackApiRequest {
  feedback = "";
  rating = 0;

  constructor(fields?: Partial<SubmitFeedbackApiRequest>) {
    Object.assign(this, fields);
  }

  public toJSON(): string {
    const payload = {
      feedback: this.feedback,
      rating: this.rating
    };

    return JSON.stringify(payload);
  }
}
