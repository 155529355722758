
import { Options, Vue } from "vue-class-component";
import ContactInfo from "@/app/ui/views/sales/clients-v2/global-components/form-sections/contact-information.vue";
import Card from "@/app/ui/components/card/index.vue";
import DetailClientParent from "@/app/ui/views/sales/clients-v2/global-components/form-sections/client-parent.vue";
import Tax from "@/app/ui/views/sales/clients-v2/global-components/form-sections/tax.vue";
import PaymentInfo from "@/app/ui/views/sales/clients-v2/global-components/form-sections/payment-information.vue";
import BillingInfo from "@/app/ui/views/sales/clients-v2/global-components/form-sections/billing-information.vue";
import Agreement from "@/app/ui/views/sales/clients-v2/global-components/form-sections/agreement.vue";
import BankInfo from "@/app/ui/views/sales/clients-v2/global-components/form-sections/bank-information.vue";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import {
  convertArrayToString,
  convertPhoneNumber,
  formatDateNumber,
  optionBillingSchedule,
  optionCompanySize,
  optionRangeTimePayment,
  optionTaxTransactionCode,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { EditClientRegistrationApiRequestV2 } from "@/data/payload/api/ClientRegistrationApiRequest";
import { capitalize } from "vue";
import { BankDetail, ClientCurrentRate } from "@/domain/entities/ClientRegistration";

@Options({
  components: {
    Card,
    DetailClientParent,
    Tax,
    ContactInfo,
    PaymentInfo,
    BillingInfo,
    Agreement,
    BankInfo
  }
})
export default class EditParent extends Vue {
  form = {
    clientParent: {
      id: 0,
      companyName: "",
      companyAlias: "",
      clientCategory: {name: "", value: ""} as any,
      companySize: "" as any,
      address: "",
      district: "" as any,
      email: "",
      phone: "",
      isNeedAssessment: false
    },
    tax: {
      NIKAddress: "",
      NIKName: "",
      taxNumber: "",
      transactionCode: "" as any
    },
    contact: {
      name: "",
      level: "",
      email: "",
      phone: ""
    },
    payment: {
      paymentMethod: "" as any
    },
    billing: {
      noWhatsApp: "",
      billingSchedule: "" as any,
      timeRangePayment: "" as any,
      email: [] as any
    },
    agreement: {
      startDate: "",
      endDate: "",
      attachment: "",
      fileNameAttachment: "",
      attachmentUrl: ""
    }
  };

  error = {
    clientParent: {
      email: false,
      phone: false,
      alias: false
    },
    tax: {
      NIKNumber: false
    },
    contact: {
      contactEmail: false,
      contactPhone: false
    },
    billing: {
      emailBilling: false,
      contactWhatsApp: false
    },
    agreement: {
      invalidDate: false
    }
  };

  async created() {
    await this.fetchDetail();
  }

  async fetchDetail() {
    const res = !this.isClientManagement
      ? await ClientRegistrationController.getDetailV2(Number(this.id))
      : await ClientManagementController.getDetailV2(Number(this.id));
    if (res) {
      this.onSetValueClient();
    }
  }

  private findCompanySize() {
    const targetClient = this.isClientManagement ? this.detailClientManagement : this.detailClient;
    return optionCompanySize.find(size => size.value === targetClient.clientCompanySize.toLowerCase());
  }

  private findTransactionCode() {
    const targetClient = this.isClientManagement ? this.detailClientManagement : this.detailClient;
    return optionTaxTransactionCode.find(tc => tc.value.substr(0, 2) === targetClient.clientTax.transactionCode.substr(0, 2));
  }

  private findBillingSchedule() {
    const targetClient = this.isClientManagement ? this.detailClientManagement : this.detailClient;
    return optionBillingSchedule.find(b => b.value.toLowerCase() === targetClient.clientBilling.billingSchedule);
  }

  private findTimeRangePayment() {
    const targetClient = this.isClientManagement ? this.detailClientManagement : this.detailClient;
    return optionRangeTimePayment.find(time => time.value === targetClient.clientBilling.timeRangePayment);
  }

  private setClientParentDetails() {
    const targetClient = this.isClientManagement ? this.detailClientManagement : this.detailClient;
    this.form.clientParent = {
      id: targetClient.id,
      companyName: targetClient.clientName,
      companyAlias: targetClient.clientAlias,
      clientCategory: { name: targetClient.clientCategory ,value: targetClient.clientCategory},
      companySize: this.findCompanySize(),
      address: targetClient.clientAddress,
      district: targetClient.clientDistrict,
      email: targetClient.clientEmail,
      phone: targetClient.clientPhone.replace(/^0|^62/g, ""),
      isNeedAssessment: targetClient.clientIsNeedAssessment
    };
  }

  private setTaxDetails() {
    const targetClient = this.isClientManagement ? this.detailClientManagement : this.detailClient;
    this.form.tax = {
      NIKAddress: targetClient.clientTax.taxPayerAddress,
      NIKName: targetClient.clientTax.taxPayerName,
      taxNumber: targetClient.clientTax.taxNumber,
      transactionCode: this.findTransactionCode()
    };
  }

  private setContactDetails() {
    const targetClient = this.isClientManagement ? this.detailClientManagement : this.detailClient.clientContact;
    this.form.contact = {
      email: targetClient.clientContactEmail,
      level: targetClient.clientContactJobTitle,
      name: targetClient.clientContactName,
      phone: targetClient.clientContactPhone.replace(/^0|^62/g, "")
    };
  }

  private setBillingDetails() {
    const targetClient = this.isClientManagement ? this.detailClientManagement : this.detailClient;
    this.form.billing = {
      billingSchedule: this.findBillingSchedule(),
      email: targetClient.clientBilling.email.map((item: any) => ({ id: item, name: item, isError: false })),
      noWhatsApp: targetClient.clientBilling.noWhatsApp,
      timeRangePayment: this.findTimeRangePayment()
    };
  }

  private setAgreementDetails() {
    const targetClient = this.isClientManagement ? this.detailClientManagement : this.detailClient;
    this.form.agreement = {
      attachmentUrl: targetClient.clientBilling.attachmentUrl,
      fileNameAttachment: targetClient.clientBilling.attachmentUrl,
      startDate: targetClient.clientBilling.startDate,
      endDate: targetClient.clientBilling.endDate,
      attachment: targetClient.clientBilling.attachment
    };
  }

  private setBankDetailSettlement() {
    const targetBankDetail = this.isClientManagement
      ? ClientManagementController.detailClientManagement.bankSettlement
      : ClientRegistrationController.clientRegistrationDetail.bankSettlement;

    ClientRegistrationController.setBankDetailSettlement(
      new BankDetail({
        accountName: targetBankDetail.accountName,
        accountNo: targetBankDetail.accountNo,
        bankLabel: targetBankDetail.bankLabel,
        bankName: targetBankDetail.bankName,
        email: targetBankDetail.email,
        logo: targetBankDetail.logo
      })
    );
  }

  public onSetValueClient() {
    this.setClientParentDetails();
    this.setTaxDetails();
    this.setContactDetails();
    this.setBillingDetails();
    this.setAgreementDetails();
    this.setBankDetailSettlement();
    this.form.payment.paymentMethod = this.isClientManagement
      ? this.detailClientManagement.clientPaymentMethod
      : this.detailClient.clientPaymentMethod;
  }

  get isClientManagement() {
    return this.$route.params?.params === "management";
  }

  get id() {
    return this.$route.params.id;
  }

  get isLoading() {
    return (
      ClientRegistrationController.isLoading ||
      ClientManagementController.isLoading
    );
  }

  get isError() {
    return (
      ClientRegistrationController.isError || ClientManagementController.isError
    );
  }

  get errorCause() {
    return (
      ClientRegistrationController.isErrorCause ||
      ClientManagementController.isErrorCause
    );
  }

  get detailClient() {
    return ClientRegistrationController.clientRegistrationDetail;
  }

  get detailClientManagement() {
    return ClientManagementController.detailClientManagement;
  }

  goBack() {
    this.$router.back();
  }

  getTitle() {
    return this.$route.meta.name;
  }

  get isFormValidParent() {
  return (
    this.isClientParentValid() &&
    this.isTaxValid() &&
    this.isBillingValid() &&
    this.isContactValid() &&
    this.isPaymentValid() &&
    this.isAgreementValid() &&
    this.areNoErrorsPresent()
  );
}

  get _isClientParentValidOne() {
    const { clientParent } = this.form;
    return (
      !!clientParent.address &&
      !!clientParent.clientCategory &&
      !!clientParent.companyName
    );
  }

  get _isClientParentValidTwo() {
    const { clientParent } = this.form;
    return (
      !!clientParent.companyAlias &&
      !!clientParent.companySize?.value &&
      !!clientParent.district
    );
  }

  get _isClientParentValidThree() {
    const { clientParent } = this.form;
    return !!clientParent.email && !!clientParent.phone;
  }

  private isClientParentValid() {
    return (
      this._isClientParentValidOne &&
      this._isClientParentValidTwo &&
      this._isClientParentValidThree
    );
  }

private isTaxValid() {
  const { tax } = this.form;
  return (
    !!tax.NIKAddress &&
    !!tax.NIKName &&
    !!tax.taxNumber &&
    !!tax.transactionCode?.value
  );
}

private isBillingValid() {
  const { billing } = this.form;
  return (
    !!billing.billingSchedule?.value &&
    !!billing.email.length &&
    !!billing.noWhatsApp &&
    !!billing.timeRangePayment?.value
  );
}

private isContactValid() {
  const { contact } = this.form;
  return (
    !!contact.email &&
    !!contact.level &&
    !!contact.name &&
    !!contact.phone
  );
}

private isPaymentValid() {
  return !!this.form?.payment?.paymentMethod?.value;
}

private isAgreementValid() {
  const { agreement } = this.form;
  return (
    (!!agreement.fileNameAttachment ||
      !!agreement.attachmentUrl ||
      !!agreement.attachment) &&
    !!agreement.startDate &&
    !!agreement.endDate
  );
}

private areNoErrorsPresent() {
  const { clientParent, tax, contact, billing, agreement } = this.error;
  return (
    !clientParent.email &&
    !clientParent.phone &&
    !clientParent.alias &&
    !tax.NIKNumber &&
    !contact.contactEmail &&
    !contact.contactPhone &&
    !billing.emailBilling &&
    !billing.contactWhatsApp &&
    !agreement.invalidDate
  );
}

  showPopupRegistParent() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "badge-confirmation-general",
        title: "Ubah Detail Klien Induk?",
        message: `Pastikan data yang Anda ubah sudah sesuai untuk menghindari data ditolak.`,
        textCancel: "Kembali",
        textSuccess: "Ya, Ubah",
        onClose: () => {
          MainAppController.closeMessageModal();
        },
        onSubmit: async () => {
          MainAppController.closeMessageModal();
          await this.createClientParent();
        }
      })
    );
  }

  async createClientParent() {
  MainAppController.closeErrorMessage();

  const payload = this.buildClientPayload();

  try {
    MainAppController.showLoading();
    await ClientRegistrationController.editClientParentV2(payload);
    await this.navigateToClientDetails();
    this.showSuccessSnackbar();
  } catch (err) {
    this.handleClientEditError(err);
  } finally {
    MainAppController.closeLoading();
  }
}

private buildClientPayload() {
  return new EditClientRegistrationApiRequestV2({
    // client parent
    id: Number(this.id),
    companyName: this.form.clientParent.companyName,
    clientAlias: this.form.clientParent.companyAlias,
    category: this.form.clientParent.clientCategory?.value,
    companySize: capitalize(this.form.clientParent.companySize?.value ?? ""),
    address: this.form.clientParent.address,
    districtCode: this.form.clientParent.district.code,
    email: this.form.clientParent.email,
    phone: convertPhoneNumber(this.form.clientParent.phone),
    isNeedAssessment: this.form.clientParent.isNeedAssessment,

    // tax
    ...this.buildTaxPayload(),

    // contact
    ...this.buildContactPayload(),

    // payment
    paymentMethod: this.form.payment.paymentMethod?.value,

    // billing
    ...this.buildBillingPayload(),

    // agreement
    ...this.buildAgreementPayload(),

    // price
    ...this.buildPricePayload(),

    // other
    clientType: "parent",
    type: "parent",
    isReuploadContract: typeof this.form.agreement.attachment !== "string",

    // bank details
    ...this.buildBankDetailsPayload(),

    // bank settlement details
    ...this.buildBankSettlementPayload()
  });
}

private buildTaxPayload() {
  return {
    taxpayerName: this.form.tax.NIKName,
    taxpayerAddress: this.form.tax.NIKAddress,
    taxNumber: this.form.tax.taxNumber,
    transactionCode: this.form.tax.transactionCode?.value
  };
}

private buildContactPayload() {
  return {
    contactName: this.form.contact.name,
    contactJobTitle: this.form.contact.level,
    contactEmail: this.form.contact.email,
    contactPhone: convertPhoneNumber(this.form.contact.phone)
  };
}

private buildBillingPayload() {
  return {
    billingWaNumber: convertPhoneNumber(this.form.billing.noWhatsApp),
    billingScheduleSo: this.formatBillingSchedule(),
    billingPaymentPeriod: `${this.form.billing.timeRangePayment?.value} Days`,
    billingEmail: convertArrayToString(this.form.billing.email, "name")
  };
}

private buildAgreementPayload() {
  return {
    contractStartDate: formatDateNumber(this.form.agreement.startDate),
    contractEndDate: formatDateNumber(this.form.agreement.endDate),
    contractAttachment: this.form.agreement.attachment
  };
}

private buildPricePayload() {
  const currentRate = this.getCurrentRate();
  const insuranceDetails = this.getInsuranceDetails();

  return {
    versionBasic: currentRate.clientVersionBaseRate,
    isCustomRate: this.isClientManagement
      ? this.detailClientManagement.clientIsCustomRate
      : this.detailClient.clientIsCustomRate,
    isCustomRateOnly: currentRate.clientRatePriceType === "custom_client",
    discount: currentRate.clientDiscountBaseRate,
    priceRateStartDate: this.isClientManagement
        ? this.detailClientManagement.clientCurrentRate
            .clientRateversionStartDate
        : this.detailClient.clientCurrentRate.clientRateVersionStartDate,
      priceRateEndDate: this.isClientManagement
        ? this.detailClientManagement.clientCurrentRate.clientRateVersionEndDate
        : this.detailClient.clientCurrentRate.clientRateVersionEndate,
    versionName: currentRate.clientVersionCustomRate,
    archiveFile: currentRate.clientFileBaseRate,
    priceType: currentRate.clientRatePriceType,
    insuranceType: insuranceDetails.insuranceType,
    insurancePercentage: insuranceDetails.insurancePercentage,
    letterCodeOrigin: currentRate.clientRateVersionCustom3lc,
    clientType: "parent",
    type: "parent",
    isReuploadContract: typeof this.form.agreement.attachment !== "string",
  };
}

private getCurrentRate() {
  return this.isClientManagement
    ? this.detailClientManagement.clientCurrentRate
    : this.detailClient.clientCurrentRate;
}

private getInsuranceDetails() {
  return {
    insuranceType: this.isClientManagement
      ? this.detailClientManagement.insuranceType
      : this.detailClient.insuranceType,
    insurancePercentage: this.isClientManagement
      ? this.detailClientManagement.insuranceValuePercentage
      : this.detailClient.insuranceValuePercentage,
  };
}


private buildBankDetailsPayload() {
  return {
    bankName: this.bankDetail.bankName?.value || this.bankDetail.bankName,
    bankAccountName: this.bankDetail.accountName,
    bankAccountNumber: this.bankDetail.accountNo,
    bankAccountEmail: this.bankDetail.email,
    bankAccountLabel: this.bankDetail.bankLabel
  };
}

private buildBankSettlementPayload() {
  return {
    vaBankName: this.bankDetailSettlement.bankName?.name || this.bankDetailSettlement.bankName || "",
    vaBankAccountName: this.bankDetailSettlement.accountName ?? "",
    vaBankNumber: this.bankDetailSettlement.accountNo ?? "",
    vaBankEmail: this.bankDetailSettlement.email,
    vaBankLabel: this.bankDetailSettlement.bankLabel
      ? `${this.bankDetailSettlement.bankLabel.toUpperCase()} Virtual Account`
      : ""
  };
}

private async navigateToClientDetails() {
  await this.$router.push(
    `/client/client-${this.isClientManagement ? "management" : "registration"}/${this.id}`
  );
}

private showSuccessSnackbar() {
  MainAppController.setSnackbarMessage(
    `Perubahan data klien induk "${this.form.clientParent.companyName}" berhasil!`
  );
  MainAppController.setSnackbarTimeout(4000);
  MainAppController.setSnackbarPositivity(true);
  MainAppController.setSnackbar(true);
}

private handleClientEditError(err: any) {
  MainAppController.showErrorMessage(
    parsingErrorResponse(err, "Perubahan Gagal !", () => this.createClientParent())
  );
}

private formatBillingSchedule() {
  const billingSchedule = this.form.billing.billingSchedule?.value ?? "";
  return billingSchedule.startsWith("bi") ? "Bi-Weekly" : capitalize(billingSchedule);
}

  get bankDetail(): any {
    return ClientRegistrationController.bankDetail;
  }

  get bankDetailSettlement(): any {
    return ClientRegistrationController.bankDetailSettlement;
  }
}
