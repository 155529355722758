
/* eslint-disable @typescript-eslint/camelcase */
import { Options, prop, Vue } from "vue-class-component";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import SelectPhoneCountryV2 from "./select-phone-country-v2.vue";
import Checkbox from "primevue/checkbox";
import { ValidateInputPhoneNumber } from "@/app/infrastructures/misc/common-library/ValidateInputPhoneNumber";
import EditCustomerForm from "./edit-customer/edit-customer-form.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import { codePhoneCountry } from "@/app/ui/views/shipment/booking/booking-utils";
import SelectCustomerV2 from "@/app/ui/views/shipment/booking/create-booking-v2/components/select-customer-v2.vue";
import { OptionsClass } from "@/domain/entities/MainApp";
import { DetailClientManagement } from "@/domain/entities/ClientManagement";
import { RequestCustomerList } from "@/domain/entities/Customer";

class Props {
  modelValue = prop<any>({
    required: true
  });
  accountIsNonForeign = prop<boolean>({
    default: false,
    type: Boolean
  });

  disableSaveReceiver = prop<boolean>({
    default: false,
    type: Boolean
  });

  error = prop<any>({
    required: true
  });

  errorMessageName = prop<string>({
    required: true
  });

  errorMessageAddress = prop<string>({
    required: true
  });

  errorMessageDestinationCity = prop<string>({
    required: true
  });

  isClientDO = prop<boolean>({
    default: false,
    type: Boolean
  });

  isDO = prop<boolean>({
    default: false,
    type: Boolean
  });

  listKGs = prop<any>({
    default: [],
    type: Array
  });

  showErrorDistrict = prop<boolean>({
    default: false,
    type: Boolean
  });

  currentFieldIndex = prop<number>({
    required: true
  });

  postalCodeDestinationOptions = prop<any>({
    required: true
  });

  allowShowZipCode = prop<boolean>({
    default: false,
    type: Boolean
  });

  formType = prop<string>({
    required: true,
  });

  detailClientManagement = prop<DetailClientManagement>({
    default: new DetailClientManagement(),
    required: false,
  })
}

@Options({
  emits: ["setFieldIndex", "onChangeDistrict", "onSearchZipCode"],
  components: {
    SelectPhoneCountryV2,
    Checkbox,
    EditCustomerForm,
    SelectCustomerV2
  }
})
export default class SectionRecipientDetail extends Vue.with(Props) {
  isOpenUpload = false;
  isShowErrorAddress = false;
  originCityList: Array<any> = [];

  phoneNumberError = {
    success: false,
    error: false,
    message: ""
  };

  selectedCustomer: any = {
    customerAddress: "",
    customerId: 0,
    customerName: "",
    customerPhoneNumber: "",
    customerDistrictId: 0,
    customerDistrictName: "",
    customerZipCode: "",
    customerAddressType: ""
  };

  errorMessageAddressLocal = "";
  errorMessageNameLocal = "";

  errorEmbargo = {
    originCity: false,
    destinationCity: false,
    productType: false,
    comodity: false,
    weightStatus: false,
    lengthStatus: false
  };

  mounted(): void {
    this.modelValue.addressReceiverType = "";
    this.modelValue.phoneCodeReceiver = this.accountIsNonForeign
      ? "+62"
      : "+60";
    this.modelValue.phoneFlagReceiver = this.accountIsNonForeign
      ? "indonesia"
      : "malaysia";
  }

  get isFormBookClient() {
    return this.formType === "client";
  }

  get maxAddressLength() {
    return FlagsMedusa.config_max_address.getValue();
  }

  // FUNCTIONS

  showForm() {
    this.isOpenUpload = true;
  }

  onSaveCustomer = debounce((event: any, data) => {
    if (!event.target.checked) {
      return;
    }
    if (data.receiver && data.phoneReceiver && data.phoneCodeReceiver) {
      BookingController.addCustomer({
        name:
          typeof data.receiver === "object"
            ? data.receiver.customerName
            : data.receiver,
        phone: `${data.phoneCodeReceiver.substring(1)}${data.phoneReceiver}`,
        address: data.addressReceiver
      });
    }
  }, 1000);

  validateReceiverName(value: string) {
    const min3Chars = value.length < 3;
    const empty = value === "";
    this.errorMessageNameLocal = "";
    if (min3Chars) {
      this.errorMessageNameLocal = "Nama penerima minimum 3 karakter.";
    }
    if (empty) {
      this.errorMessageNameLocal = "Nama penerima wajib diisi.";
    }
    const recieverError = min3Chars || empty;
    this.error.receiver = recieverError;
    this.$emit("update:error", { ...this.error, receiver: recieverError });
  }

  validateAddressRecipient(value: string) {
    const min3Chars = value.length < 3;
    const empty = value === "";
    this.errorMessageAddressLocal = "";
    if (min3Chars) {
      this.errorMessageAddressLocal = "Alamat penerima minimum 3 karakter.";
    }
    if (empty) {
      this.errorMessageAddressLocal = "Alamat penerima wajib diisi.";
    }
    const recieverError = min3Chars || empty;
    this.error.addressReceiver = recieverError;
    this.$emit("update:error", {
      ...this.error,
      addressReceiver: recieverError
    });
  }

  replaceNewLine(value: string) {
    const removedNewLineAddress = removeNewLineWhitespace(value, " ");
    this.modelValue.addressReceiver = removedNewLineAddress;
    this.$emit("update:modelValue", { ...this.modelValue });
    return removedNewLineAddress;
  }

  setPhoneReceiver(val: string, code = "") {
    let selectedPhoneCode = "";
    if (
      this.modelValue.phoneCodeReceiver !== null &&
      this.modelValue.phoneCodeReceiver !== ""
    ) {
      selectedPhoneCode = this.modelValue.phoneCodeReceiver.substring(1);
    }
    const phoneNumberWithCode = `${selectedPhoneCode}${val}`;
    this.phoneNumberError = ValidateInputPhoneNumber(phoneNumberWithCode);
    this.setErrorPhoneCodeReceiver(this.phoneNumberError.error);
    this.modelValue.phoneReceiver = val;
    this.$emit("update:modelValue", { ...this.modelValue });
    this.fetchCustomer(val, code);
  }

  setPhoneCodeReceiver(val: string) {
    this.modelValue.phoneCodeReceiver = val;
    this.$emit("update:modelValue", { ...this.modelValue });
  }

  setErrorPhoneCodeReceiver(status: boolean) {
    this.error.phoneCodeReceiver = status;
    this.$emit("update:error", { ...this.error, phoneCodeReceiver: status });
  }

  get isShipperAndConsigneeAddressIdentical() {
    if (
      this.modelValue.addressSender === "" ||
      this.modelValue.addressReceiver === ""
    ) {
      return false;
    }
    return (
      String(this.modelValue.addressSender).toLowerCase() ===
      String(this.modelValue.addressReceiver).toLowerCase()
    );
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
      isCodBooking:
        AccountController.accountData.account_type_detail.partnerIsCodBooking,
      accountTypeDetail: AccountController.accountData.account_type_detail
    };
  }

  filterDistrict(response: any) {
    return response
      .filter((key: any) => key.status.toLowerCase() === "active")
      .map((e: any) => ({
        id: e.id,
        name: `${e.name}, ${e.cityName}`,
        code: `${e.code}`,
        cityId: e.cityId,
        cityCode: `${e.cityCode}`,
        status: e.status,
        type: e.type,
        "vendor_code": e.vendorCode,
        isCod: e.isCod,
        isFtz: e.isFtz,
        originZipCode: e.originZipCode
      }));
  }

  onChooseReceivers(item: any, isChoosen = true) {
    if (!item) {
      return false;
    }
    this.fetchDistrictId(item.customerDistrictId).then((res: any) => {
      item = {
        ...item,
        customerPhoneCode: codePhoneCountry(item.customerPhoneNumber).code,
        customerPhoneNumber: codePhoneCountry(item.customerPhoneNumber).number,
        customerDistrictId: item.customerDistrictId > 0 ? res : "",
        customerAddressType: this.mapAddressType(item.customerAddressType)
      };

      BookingController.setDataReceiver(item);
      const destinationValue = {
        cityCode: res.cityCode,
        code: res.code,
        id: res.id,
        isCod: res.isCod,
        name: res.name,
        originZipCode: item.customerZipCode,
        status: res.status,
        'vendor_code': res.vendorCode
      };
      this.modelValue.destinationCity = destinationValue;
      this.modelValue.postalCodeDestination = new OptionsClass({
        name: item.customerZipCode,
        value: item.customerZipCode
      });
      this.onChangeDistrict("district");
      this.fetchDestinationCity(item.customerDistrictName);
      this.$emit("update:modelValue", { ...this.modelValue });
      this.isShowErrorAddress = true;
    });
    this.error.phoneCharMinimalReceiver =
      item.customerPhoneNumber.length < 5 && isChoosen;

    this.validatePhoneRecipient(item.customerPhoneNumber);
    this.validateReceiverName(item.customerName);
    this.setErrorPhoneCodeReceiver(this.phoneNumberError.error);
    this.validateAddressRecipient(item.customerAddress);
  }

  validatePhoneRecipient(value: string) {
    if (!value) {
      return;
    }
    const phoneArray = value.split("");
    if (this.detectPhoneNumber(phoneArray) && phoneArray.length > 14) {
      const newPhone = value.slice(0, -1);
      BookingController.setPhoneReceiver(newPhone);
    } else {
      BookingController.setPhoneReceiver(value);
    }
  }

  detectPhoneNumber(phoneArray: Array<string>) {
    return phoneArray.find(
      () => phoneArray[0] === "0" && phoneArray[1] === "8"
    );
  }

  setNameCustomer(search: string, type: string) {
    if (type === "senders") {
      BookingController.setNameSender(search); // set payload even if not choosing
    }
    if (type === "receivers") {
      BookingController.setNameReceiver(search); // set payload even if not choosing
    }
    if (search.length === 0) {
      return;
    }
    this.fetchCustomer(search);
  }

  fetchCustomer(search: string, code = "") {
    if (search.length > 2) {
      if (this.isFormBookClient) {
        if (this.detailClientManagement.clientPhone !== '') this.fetchBySearch(search, code, this.detailClientManagement.id, "client");
      } else {
        this.fetchBySearch(search, code);
      }
    }
  };

  fetchBySearch = debounce((search: string, code = "", refId?: number, refType?: string) => {
    BookingController.getCustomerList({
      search: code.substring(1) + search,
      cache: true,
      refId: refId,
      refType: refType
    });
  }, 1000)

  get isLoadingCustomer() {
    return BookingController.isLoadingCustomer;
  }

  get receivers() {
    return this.getPhoneNumbers;
  }

  get getPhoneNumbers() {
    const list = BookingController.customerList.data;
    if (this.isFormBookClient && this.detailClientManagement.clientPhone == "") return []
    if (list.length !== 0) {
      return BookingController.customerList.data.map(item => {
        return {
          ...item,
          customerPhoneNumber: `${item.customerPhoneNumber}`
        };
      });
    } else {
      return list;
    }
  }

  destinationCityList: Array<any> = [];
  onFocusDestinationCity() {
    if (!this.destinationCityList.length || !this.modelValue.destinationCity) {
      LocationController.setLoadingDistrict(true);
      this.fetchDestinationCity("");
    }
    this.$emit("setFieldIndex", 10);
  }

  fetchDestinationCity = debounce(async (search: string) => {
    this.isShowErrorAddress = false;
    const typeAccuracyAllowed = true;
    const response = await LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: typeAccuracyAllowed ? 50 : 10,
      excludeCountry: this.modelValue.productName === "INTERPACK" ? "ID" : "",
      type: this.districtType,
      cache: true
    });
    this.destinationCityList = this.filterDistrict(response);
  }, 250);

  get districtType() {
    let destinationType = "";
    if (this.getAccount.accountType === "client") {
      if (this.isDO || this.isClientDO) {
        destinationType = "within-city,forward-area";
      }
    }

    return destinationType;
  }

  get isLoadingDestinationCity() {
    return LocationController.isLoadingDistrict;
  }

  get errorDestinationCity() {
    return BookingController.errorDestination;
  }

  async onChangeDistrict(type: string) {
    if (type !== "zipcode") {
      BookingController.setPostalCodeDestination("");
    }
    this.$emit("onChangeDistrict", type);
  }

  get isVendorNinjaDestination() {
    return BookingController.isVendorNinjaDestination;
  }

  setIsVendorNinjaDestination(val: boolean) {
    BookingController.setIsVendorNinjaDestination(val);
  }

  checkVendorDestinationDistrict() {
    const clientType = this.vendorTypeForDestinationDistrict;
    const vendorCode = this.vendorCodeForDestinationDistrict;

    if (clientType && vendorCode) {
      if (this.listKGs.length >= 1) {
        this.setIsVendorNinjaDestination(true);
      }
    } else {
      this.setIsVendorNinjaDestination(false);
    }

    return this.isVendorNinjaDestination;
  }

  get vendorTypeForDestinationDistrict() {
    return (
      this.modelValue.destinationCity?.type?.toLowerCase() === "vendor" ||
      this.modelValue.destinationCity?.type?.toLowerCase() === "vendor_lanjutan"
    );
  }

  get vendorCodeForDestinationDistrict() {
    return (
      this.modelValue.destinationCity?.vendor_code?.toLowerCase() === "ninja" ||
      this.modelValue.destinationCity?.vendor_code?.toLowerCase() === "nx" ||
      this.modelValue.destinationCity?.vendor_code?.toLowerCase() === "pi"
    );
  }

  // address type types
  get listAddressType() {
    const defaultChoices: any = [
      {
        name: this.$t("bookingShipment.column.office"),
        value: "office"
      },
      {
        name: this.$t("bookingShipment.column.home"),
        value: "home"
      }
    ];

    return defaultChoices;
  }

  get addressTypeValue() {
    return this.modelValue.addressReceiverType;
  }

  isErrorSelectAddressType = false;
  onSelectAddressType(name: string, value: string) {
    this.modelValue.addressReceiverType = value;
    if (!value) {
      this.isErrorSelectAddressType = true;
    } else {
      this.isErrorSelectAddressType = false;
    }
  }

  setPostalCodeDestination = debounce(async (search: string, self = this) => {
    if (search) {
      BookingController.setPostalCodeDestination({
        id: search,
        name: search
      });
    }
    if (!this.modelValue.destinationCity?.originZipCode) {
      const response = await LocationController.getZipCodeList({
        search: search,
        page: 1,
        limit: 10
      });
      const zipCode = response.zipCodeData.map((item: any) => {
        return {
          id: item.originZipCode,
          name:
            response.zipCodeData.length > 1
              ? item.originZipCode + " - " + item.name + ", " + item.cityName
              : item.originZipCode,
          districtName: item.name,
          cityName: item.cityName,
          status: item.status,
          code: item.code,
          cityCode: item.cityCode,
          vendorCode: item.vendorCode,
          type: item.type,
          isCod: item.isCod
        };
      });

      self.$emit("onSearchZipCode", zipCode);
    }
  }, 250);

  onChangeZipCode() {
    if (
      !this.modelValue.destinationCity?.originZipCode &&
      this.modelValue.postalCodeDestination
    ) {
      const disctrictName =
        this.modelValue.postalCodeDestination.districtName +
        ", " +
        this.modelValue.postalCodeDestination.cityName;
      const destinationCity = {
        id: this.modelValue.postalCodeDestination.id,
        name: disctrictName,
        status: this.modelValue.postalCodeDestination.status,
        code: this.modelValue.postalCodeDestination.code,
        cityCode: this.modelValue.postalCodeDestination.cityCode,
        vendorCode: this.modelValue.postalCodeDestination.vendorCode,
        type: this.modelValue.postalCodeDestination.type,
        isCod: this.modelValue.postalCodeDestination.isCod
      };
      this.modelValue.destinationCity = destinationCity;

      this.$emit("update:modelValue", { ...this.modelValue });
      BookingController.setDestinationCity(destinationCity);
      this.onChangeDistrict("zipcode");
      this.isShowErrorAddress = true;
    }
  }

  updatePostalCode(value: any) {
    if (value === "") {
      BookingController.setDestinationCity("");
    }
  }

  async fetchDistrictId(search: number) {
    if (search) {
      const responseDistrictData = await LocationController.getDetailDistrict({
        id: search,
        notSaveDetail: true
      });
      const disctrictName =
        responseDistrictData.name + ", " + responseDistrictData.cityName;
      return {
        id: search,
        name: disctrictName,
        status: responseDistrictData.status,
        code: responseDistrictData.code,
        cityCode: responseDistrictData.cityCode,
        vendorCode: responseDistrictData.vendorCode,
        type: responseDistrictData.type,
        isCod: responseDistrictData.isCod,
        countryCode: responseDistrictData.countryCode,
        countryName: responseDistrictData.countryName
      } as any;
    }
  }

  private mapAddressType(addressType: string): string {
    if (addressType === "rumah") {
      return "home";
    } else if (addressType === "kantor") {
      return "office";
    } else {
      return "home";
    }
  }

  editItem(item: any) {
    this.selectedCustomer = item;
    this.showForm();
  }
}
