
import { Options, Vue } from "vue-class-component";
import FormSection from "@/app/ui/views/cargo/booking-cargo/first-step/form-section.vue";
import CargoTableSttSection from "@/app/ui/views/cargo/booking-cargo/third-step/cargo-table-stt-section.vue";
import ReadyToCargoSection from "@/app/ui/views/cargo/booking-cargo/first-step/ready-to-cargo-section.vue";
import { CargoController } from "@/app/ui/controllers/CargoController";
import CargoTypeSection from "@/app/ui/views/cargo/booking-cargo/third-step/cargo-type-section.vue";
import ModalCargoType from "@/app/ui/views/cargo/booking-cargo/components/modal-cargo-type.vue";
import { OptionsClass } from "@/domain/entities/MainApp";
import { getTokenNGen } from "@/app/infrastructures/misc/Cookies";
import ModalPlaneAuto from "@/app/ui/views/cargo/booking-cargo/components/modal-plane-auto.vue";
import PlaneSchedule from "@/app/ui/views/cargo/booking-cargo/components/plane-schedule/index.vue";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import PopupDetailKoli from "../components/popup-detail-koli.vue";
import { storeDatatoLocalStorage } from "@/app/infrastructures/misc/LocalStorage";
import { FlagsPermissionCargo } from "@/feature-flags/flags-permission-cargo";

@Options({
  components: {
    ReadyToCargoSection,
    FormSection,
    CargoTableSttSection,
    CargoTypeSection,
    ModalCargoType,
    ModalPlaneAuto,
    PlaneSchedule,
    PopupDetailKoli,
  }
})
export default class FirstStep extends Vue {
  get isPlaneAutoFlow(): boolean {
    return CargoController.isPlaneAutoFlow;
  }
  get showModalCargoType() {
    return CargoController.showModalCargoType;
  }

  get showSelectAirportFromLocalStorage() {
    return CargoController.showSelectAirportFromLocalStorage;
  }
  setModalCargoType(value: boolean) {
    CargoController.setShowModalCargoType(value);
  }
  async mounted() {
    CargoController.setPlaneAutoFlow(false);
    //show modal cargo when refresh on select airport modal
    if (this.showSelectAirportFromLocalStorage) {
      CargoController.setShowModalCargoType(true);
    }
    //check if it comes from reload on showplaneschedule, then it will reset flightdata
    if (this.isShowPlaneScheduleFromLocalStorage && this.flowBooking != "rtc-process") {
       if (!this.flowBooking.includes("rtc")) {
        CargoController.resetFlightData();
        CargoController.setShowPlaneSchedule(false);
        CargoController.setResetDefaultAirportOrigin();// reset/remove airport origin when user reload on select plane schedule in all flowbooking
        } 
        else {
        CargoController.getAirportListOrigin({
            airportCode: this.calculateFlightDate[0].flight_origin,
            cityCode: this.calculateFlightDate[0].flight_origin,
            page: 1,
            limit: 10,
            isLocation: false,
        })
        this.setFlightDataFromRTC();
      }
    }
    CargoController.setFillForm(true);
    if (
      this.flagsGamification &&
      this.flowBooking === "manual" &&
      !CargoController.isModalGamificationDone &&
      !CargoController.isFromRtc
    ) {
      CargoController.setIsModalGamification(true);
      CargoController.setIsModalGamificationDone(false);
    }
  }

  get flagsGamification() {
    return FlagsPermissionCargo.feature_is_gamification.isEnabled();
  }

  get calculateFlightDate() {
    return ReadyToCargoController.calculateFlightDate
  }

  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }
  
  setFlightDataFromRTC() {
    const backupFlight = JSON.parse(localStorage.getItem("backup-flight-rtc") || "[]");
    this.form.flightsData = backupFlight;
    CargoController.setEditFlightData(backupFlight);
    storeDatatoLocalStorage('backup-flight-rtc', []);
  }

  get detailRTC() {
    return ReadyToCargoController.readyToCargoDetail
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  get isShowPlaneScheduleFromLocalStorage() {
    return CargoController.isShowPlaneScheduleFromLocalStorage;
  }
  get destinationCity(): OptionsClass {
    return CargoController.destinationCity;
  }
  // cargo flight auto
  get form() {
    return CargoController.form;
  }
  get isNGenValid(): boolean {
    return !!getTokenNGen();
  }
  get showPlaneSchedule() {
    return CargoController.isShowPlaneSchedule;
  }
  set showSelectAirport(bool: boolean) {
    CargoController.setShowSelectAirport(bool);
  }
  async onResetPopup() {
    CargoController.setAirportsPopupModel(
      JSON.parse(JSON.stringify(this.form.airports))
    );
    this.showSelectAirport = this.isNGenValid;
    if (!this.showPlaneSchedule) CargoController.resetModalPlaneAuto();
  }
  onResetChangeTransitAirport() {
    this.form.isChangeAirport = true;
    this.onResetPopup();
  }

  isReadyToCargoSectionClose = false;

  get isEditReadyToCargo() {
    return CargoController.isEditReadyToCargo;
  }

  get isRTC() {
    return ReadyToCargoController.flowBooking.includes('rtc') || (this.flowBooking === "rebooking" && this.$route.query.rcId);
  }

  get isFromRebookingRTC() {
    return CargoController.isRebookingFromRTC;
  }

  get isRTCEdit() {
    return this.flowBooking === "rtc-edit" || (this.flowBooking === "rebooking" && this.$route.query.rcId && this.form.cargoType === 'plane' && !this.form.flightsData.length);
  }

  get showPopupDimensionPieces() {
    return CargoController.showPopupDimensionPieces;
  }

  get formDetailPieces() {
    return CargoController.formDetailPieces;
  }
}
