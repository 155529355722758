/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { UserPresenter } from "@/app/ui/presenters/UserPresenter";
import {
  ReferenceEntities,
  RoleEntities,
  UserDetailData,
  UserEntities
} from "@/domain/entities/User";
import { Pagination } from "@/domain/entities/Pagination";
import {
  AddUserApiRequest,
  EditRoleApiRequest,
  EditUserApiRequest,
  RequestListUser,
  EditAssigned3lcApiRequest
} from "@/data/payload/api/UserApiRequest";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { OptionsClass } from "@/domain/entities/MainApp";

export interface UserState {
  userData: UserEntities;
  userDetail: UserDetailData;
  referenceData: ReferenceEntities[];
  roleData: RoleEntities[];
  isLoading: boolean;
  isLoadingDropdown: boolean;
  isError: boolean;
  isErrorEdit: boolean;
  errorCause: string;
  message: string;
  openModal: boolean;
  isRoute: boolean;
  cacheRoute: boolean;
  username: string;
  cacheUsername: string;
  email: string;
  cacheEmail: string;
  phoneNumber: string;
  cachePhoneNumber: string;
  openModalSuccess: boolean;
  isLoadingDetail: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "user" })
class UserStore extends VuexModule implements UserState {
  public userData = new UserEntities(new Pagination(1, 20), []);
  public userDetail = new UserDetailData(
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  );
  public referenceData: Array<ReferenceEntities> = [];
  public roleData: Array<RoleEntities> = [];
  public isLoading = false;
  public isLoadingDetail = true;
  public isLoadingDropdown = false;
  public isError = false;
  public isErrorEdit = false;
  public errorCause = "";
  public search = "";
  public accountType = "";
  public reference: any = "";
  public status = "";
  public page = 1;
  public limit = 10;
  public message = "";
  public openModal = false;
  public isRoute = false;
  public cacheRoute = false;
  public username = "";
  public cacheUsername = "";
  public email = "";
  public cacheEmail = "";
  public phoneNumber = "";
  public cachePhoneNumber = "";
  public referenceDataList: Array<any> = [];
  public referenceDataListAdd: Array<any> = [
    { name: "Semua Refrence", value: "" }
  ];
  public roleDataList: Array<any> = [];
  public openModalSuccess = false;

  @Action
  public getUserList(params: RequestListUser) {
    this.setLoadingList(true);
    const presenter = container.resolve(UserPresenter);
    return presenter
      .getUserList(params)
      .then((res: UserEntities) => {
        this.setUserData(res);
        this.setError(false);
        this.setErrorCause("");
        return res;
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return new UserEntities(new Pagination(1, 20), []);
      })
      .finally(() => {
        this.setLoadingList(false);
      });
  }

  @Action
  public getDetailUser(params: { id: any }) {
    this.setLoadingDetail(true);
    const presenter = container.resolve(UserPresenter);
    presenter
      .getDetailUser(params.id)
      .then((res: UserDetailData) => {
        if (res.account_type === "internal" || res.account_type === "system") {
          this.setError(true);
          this.setErrorCause("server");
          return;
        }
        this.setError(false);
        this.setErrorCause("");
        this.setUserDetail(res);
        this.setRoute(res.status.toLowerCase() === "active");
        this.setCacheRoute(res.status.toLowerCase() === "active");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Action
  public _onEditUser(params: {
    id: number;
    isActive: string;
    email: string;
    type: string;
    assigned3lc?: string;
  }) {
    const presenter = container.resolve(UserPresenter);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    return presenter
      .editUser(
        new EditUserApiRequest(params.id, params.isActive, params.email, params.assigned3lc || "")
      )
      .then(() => {
        return true;
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, `Ubah ${params.type} Gagal`)
        );
        return false;
      })
      .finally(() => {
        this.setLoading(false);
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public editRole(payload: EditRoleApiRequest) {
    const presenter = container.resolve(UserPresenter);
    return presenter.editRole(payload);
  }

  @Action
  public editAssigned3lc(payload: EditAssigned3lcApiRequest) {
    const presenter = container.resolve(UserPresenter);
    return presenter.editAssigned3lc(payload);
  }

  @Action
  public _onAddUser(params: {
    username: string;
    email: string;
    phoneNumber: string;
    accountType: string;
    roleId: number;
    referenceId: number;
    assigned3Lc: string;
  }) {
    this.setLoading(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(UserPresenter);
    presenter
      .addUser(
        new AddUserApiRequest({
          username: params.username,
          email: params.email,
          phone_number: params.phoneNumber,
          account_type: params.accountType,
          role_id: params.roleId,
          reference_id: params.referenceId,
          assigned_3lc: params.assigned3Lc
        })
      )
      .then(() => {
        this.setErrorEdit(false);
        this.setErrorCause("");
        this.setOpenModalSuccess(true);
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Buat User Gagal", () => {
            MainAppController.closeErrorMessage();
            this._onAddUser(params);
          })
        );
      })
      .finally(() => {
        this.setLoading(false);
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public async getReference(search: string) {
    const presenter = container.resolve(UserPresenter);
    return presenter
      .getReference(this.accountType, search)
      .then((res: ReferenceEntities[]) => {
        this.setReference(res);
        this.setReferenceDataList(this.newReferenceData);
        return;
      })
      .catch(() => {
        this.setReference([]);
        this.setReferenceDataList([]);
        return;
      });
  }

  @Action
  public getReferenceAdd(params: { account_type: any }) {
    const presenter = container.resolve(UserPresenter);
    presenter
      .getReference(params.account_type, "")
      .then((res: ReferenceEntities[]) => {
        this.setReference(res);
        this.setReferenceDataListAdd(this.newReferenceData);
      })
      .catch(() => {
        this.setError(true);
      });
  }

  @Action
  public getRole(params: { account_type: any }) {
    const presenter = container.resolve(UserPresenter);
    presenter
      .getRole(params.account_type)
      .then((res: RoleEntities[]) => {
        this.setRole(res);
        this.setRoleDataList(this.newRoleData);

        // set role default value
        const defaultValue =
          res.find(
            (item: RoleEntities) => item.name === this.userDetail.role_name
          ) || new RoleEntities(0, "");
        this.setRoleDefault(
          new OptionsClass({
            name: defaultValue.name,
            value: String(defaultValue.id)
          })
        );
      })
      .catch(() => {
        this.setError(true);
      });
  }

  get newReferenceData() {
    return this.referenceData.map(item => ({
      name:
        item.names !== undefined
          ? item.names
          : item.companyName !== undefined
          ? item.companyName
          : "Semua Reference",
      value: item.id,
      isStatusClientActive:
        item.status && item.status.toLowerCase() === "approved"
    }));
  }

  get newRoleData() {
    return this.roleData.map(item => ({
      name: item.name,
      value: item.id
    }));
  }

  @Action
  public setFirstPage() {
    this.setFirst();
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    this.setFirstPage();
    this.getUserList(
      new RequestListUser({
        search: value,
        account_type:
          this.reference !== ""
            ? this.accountType
            : this.accountType === "internal"
            ? this.accountType
            : "",
        reference: this.accountType !== "" ? this.reference : "",
        status: this.status,
        page: this.userData.pagination.page,
        limit: this.userData.pagination.limit,
        version: "2",
        isTotalData: true
      })
    );
  }

  @Action
  public clear() {
    this.searchAction("");
    this.setFirstPage();
    this.getUserList(
      new RequestListUser({
        search: "",
        account_type:
          this.reference !== ""
            ? this.accountType
            : this.accountType === "internal"
            ? this.accountType
            : "",
        reference: this.accountType !== "" ? this.reference : "",
        status: this.status,
        page: this.userData.pagination.page,
        limit: this.userData.pagination.limit,
        version: "2",
        isTotalData: true
      })
    );
  }

  @Action
  public selectTypeAction(value: any) {
    if (this.accountType !== value) {
      this.selectReference("");
    }
    this.selectType(value);
    this.setFirstPage();
    if (value.match(/^internal$|^customer-service$/) || value === "") {
      this.getUserList(
        new RequestListUser({
          search: this.search,
          account_type: this.accountType,
          reference: "",
          status: this.status,
          page: this.userData.pagination.page,
          limit: this.userData.pagination.limit,
          version: "2",
          isTotalData: true
        })
      );
    }
  }

  @Action
  public selectTypeActionAdd(value: any) {
    this.selectType(value);
    this.getRole({ account_type: value });
  }

  @Action
  public selectReferenceAction(value: string) {
    this.selectReference(value);
    this.setFirstPage();
    this.getUserList(
      new RequestListUser({
        search: this.search,
        account_type: this.accountType.replace("_", "-"),
        reference: this.reference,
        status: this.status,
        page: this.userData.pagination.page,
        limit: this.userData.pagination.limit,
        version: "2",
        isTotalData: true
      })
    );
  }

  @Action
  public selectStatusAction(value: string) {
    this.selectStatus(value);
    if (
      !this.accountType.match(/^internal$|^customer-service$/) &&
      this.accountType !== "" &&
      this.reference === ""
    ) {
      return;
    }
    this.setFirstPage();
    this.getUserList(
      new RequestListUser({
        search: this.search,
        account_type: this.accountType,
        reference: this.reference,
        status: this.status,
        page: this.userData.pagination.page,
        limit: this.userData.pagination.limit,
        version: "2",
        isTotalData: true
      })
    );
  }

  @Action
  public fetchUserList() {
    this.getUserList(
      new RequestListUser({
        search: this.search,
        account_type: this.accountType,
        reference: this.reference,
        status: this.status,
        page: this.userData.pagination.page,
        limit: this.userData.pagination.limit,
        version: "2",
        isTotalData: true
      })
    );
  }

  @Mutation
  public setLoadingList(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setLoadingDetail(bool: boolean) {
    this.isLoadingDetail = bool;
  }

  @Mutation
  private setUserData(res: UserEntities) {
    this.userData = res;
  }

  @Mutation
  private setUserDetail(res: UserDetailData) {
    this.userDetail = res;
  }

  @Mutation
  private setReference(res: ReferenceEntities[]) {
    this.referenceData = res;
  }

  @Mutation
  private setRole(res: RoleEntities[]) {
    this.roleData = res;
  }

  @Mutation
  private setFirst() {
    this.userData.pagination.page = 1;
  }

  @Mutation
  private setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public selectType(value: string) {
    this.accountType = value;
  }

  @Mutation
  public selectReference(value: any) {
    this.reference = value;
  }

  @Mutation
  public selectStatus(value: string) {
    this.status = value;
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  @Mutation
  public setError(bool: boolean) {
    this.isError = bool;
  }

  @Mutation
  private setErrorEdit(bool: boolean) {
    this.isErrorEdit = bool;
  }

  @Mutation
  private setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setMessage(value: string) {
    this.message = value;
  }

  @Action
  public handleError() {
    this.setError(false);
    this.setErrorEdit(false);
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  public setRoute(value: boolean) {
    this.isRoute = value;
  }

  @Mutation
  public setCacheRoute(value: boolean) {
    this.cacheRoute = value;
  }

  @Mutation
  public setUsername(value: string) {
    this.username = value;
  }

  @Mutation
  public setCacheUsername(value: string) {
    this.cacheUsername = value;
  }

  @Mutation
  public setEmail(value: string) {
    this.email = value;
  }

  @Mutation
  public setReferenceDataList(value: any) {
    this.referenceDataList = value;
  }

  @Mutation
  public setReferenceDataListAdd(value: any) {
    this.referenceDataListAdd = value;
  }

  @Mutation
  public setRoleDataList(value: any) {
    this.roleDataList = value;
  }

  roleDefault = new OptionsClass();
  @Mutation
  public setRoleDefault(value: OptionsClass) {
    this.roleDefault = value;
  }

  @Mutation
  public setCacheEmail(value: string) {
    this.cacheEmail = value;
  }

  @Mutation
  public setPhoneNumber(value: string) {
    this.phoneNumber = value;
  }

  @Mutation
  public setCachePhoneNumber(value: string) {
    this.cachePhoneNumber = value;
  }

  @Action
  resetFilter() {
    this.selectReference("");
    this.selectStatus("");
    this.selectType("");
    this.setFirstPage();
    this.getUserList(
      new RequestListUser({
        search: this.search,
        account_type: this.accountType,
        reference: this.reference,
        status: this.status,
        page: this.userData.pagination.page,
        limit: this.userData.pagination.limit,
        version: "2",
        isTotalData: true
      })
    );
  }
}

export const UserController = getModule(UserStore);
