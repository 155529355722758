import {
  TrackingSttData,
  TrackingSttDetailData,
  TrackingSttListHistory
} from "@/domain/entities/TrackingStt";
import {
  TrackingCargoData,
  CargoHistory
} from "@/domain/entities/TrackingCargo";
import { AxiosResponse } from "axios";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";
import moment from "moment";

export class TrackingMapper {
  public convertTrackingSttDataFromApi(res: AxiosResponse): TrackingSttData {
    const {
      data: { data }
    } = res;

    return new TrackingSttData(
      new TrackingSttDetailData({
        sttNo: data.stt_detail.stt_no,
        totalPieces: data.stt_detail.total_pieces,
        status: data.stt_detail.status,
        statusLabel: data.stt_detail.status_label,
        productType: data.stt_detail.product_type,
        estimationsDate: data.stt_detail.estimation_date,
        estimations: data.stt_detail.estimations,
        chargeableWeight: data.stt_detail.chargeable_weight,
        senderName: data.stt_detail.sender_name,
        originLocation: data.stt_detail.origin_location,
        recipientName: data.stt_detail.recipient_name,
        destinationLocation: data.stt_detail.destination_location,
        sttId: data.stt_detail.stt_id,
        isPaid: data.is_paid,
        rebuttalDex: data.stt_detail.rebuttal_dex,
        sttNumberElexys: data.stt_detail.se_elexys_stt_no || "-",
        sttNoRef: data.stt_detail.stt_return_ref_no || "-",
        shipmentId: data.stt_detail.shipment_id,
        sttIsCod: data.stt_detail.is_cod,
        sttIsDfod: data.stt_detail.is_dfod,
        name: data.stt_detail.name,
        bookedName: data.stt_detail.booked_name,
        sttAssessmentStatus: data.stt_detail.stt_assessment_status,
        sttNeedToRelabel: data.stt_detail.stt_need_to_relabel,
        bookedForPhone: data.stt_detail.booked_for_phone,
        bookedByPhone: data.stt_detail.booked_by_phone
      }),
      data.stt_list_tracking.length
        ? data.stt_list_tracking.map(
            (item: any) =>
              new TrackingSttListHistory(
                item.actor_name,
                item.actor_type.replace("-", " ").toUpperCase(),
                item.status_date,
                moment(item.status_date).format("YYYY-MM-DD"),
                item.status,
                item.status_label,
                item.total_pieces,
                item.location,
                item.description,
                item.created_name,
                item.attachment.map((attachment: string) =>
                  parsingLinkGcpToAws(attachment)
                ),
                item.GMT,
                item.country_Code,
                item.hub_name,
                item.stt_weight_attach_files,
                item.actor_phone
              )
          )
        : []
    );
  }

  public convertTrackingCargoDataFromApi(
    res: AxiosResponse
  ): TrackingCargoData {
    const { data } = res;
    const cargoHistory: CargoHistory[] = [];
    if (data.data.cargo_type.toLowerCase() !== "plane") {
      data.data.history.map((item: any) =>
        cargoHistory.push(
          new CargoHistory(
            item.actor_name,
            item.actor_type,
            item.status_date,
            item.status,
            item.status_label,
            item.total_pieces,
            item.location,
            item.description
          )
        )
      );
    }
    return new TrackingCargoData(
      data.data.cargo_id,
      data.data.cargo_no,
      data.data.cargo_vehicle_no,
      data.data.cargo_type,
      data.data.cargo_total_stt,
      data.data.cargo_total_stt_piece,
      data.data.cargo_origin_city_code,
      data.data.cargo_origin_city_name,
      data.data.cargo_destination_city_code,
      data.data.cargo_destination_city_name,
      data.data.cargo_origin_district_code,
      data.data.cargo_origin_district_name,
      data.data.cargo_destination_district_code,
      data.data.cargo_destination_district_name,
      data.data.cargo_destination_district_type,
      data.data.cargo_total_gross_weight,
      data.data.cargo_total_volume_weight,
      data.data.cargo_total_custom_gross_weight,
      data.data.cargo_total_custom_volume_weight,
      cargoHistory
    );
  }
}
