
import { Vue, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import { PusatResolusiController } from "@/app/ui/controllers/PusatResolusiController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { CheckValidationRequest, CreatePusatResolusiRequest } from "@/data/payload/api/PusatResolusiApiRequest";
import { PusatResolusiCategory } from "@/domain/entities/PusatResolusi";
import debounce from "lodash/debounce";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import ModalStatusPusatResolusi from "@/app/ui/views/pusat-resolusi/components/modal-status/index.vue"
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
@Options({
  components: {
    DetailLayout,
    ModalStatusPusatResolusi
  }
})
export default class CreateCargoPage extends Vue {
  mounted() {
    this.filterCategories("");
    this.form.stt = this.sttNo ?? "";
  }

  form = {
    stt: "",
    shipment: "",
    category: {
      name: "",
      value: ""
    },
    description: "",
    attachmentImage: [],
    attachmentFile: []
  };

  
  get sttNo(): any {
    return this.$route.query.sttNo;
  }

  get isDisabled() {
    return (
      this.validateSttOrShipment ||
      !this.form.category.value ||
      !this.form.description ||
      this.disableSttOrShipment
    );
  }

  get validateSttOrShipment() {
    if (this.isPickupProblem) {
      return !this.form.shipment;
    } else {
      return !this.form.stt;
    }
  }

  get disableSttOrShipment() {
    if (this.isPickupProblem) {
      return this.isShipmentValid;
    } else {
      return this.isSttValid;
    }
  }

   get isQueryParams() {
    return this.sttNo;
   }

  // submit
  onSubmitConfirmation() {
    dataLayer("laporkan_pusat_resolusi_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
    this.confirmation = true;
  }


  async onSubmit() {
    this.confirmation = false;
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      const save = await PusatResolusiController.createPusatResolusi(new CreatePusatResolusiRequest({
        category: this.form.category.value,
        sttOrShipment: this.isPickupProblem ? this.form.shipment : this.form.stt,
        description: this.form.description,
        imageFile1: this.form.attachmentImage[0],
        imageFile2: this.form.attachmentImage[1],
        imageFile3: this.form.attachmentImage[2],
        attachment1: this.form.attachmentFile[0],
        attachment2: this.form.attachmentFile[1]
      }))

      if (save.data.is_submitted) {
        MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Kendala Berhasil Dikirim",
          message: "Tim kami akan memproses kendala Anda",
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "image-modal-success"
        })
      );
      } else {
        this.noTicket = save.data.source === "genesis" ? save.data.ticket_no_genesis : save.data.ticket_no_sf;
        this.sourceTicket = save.data.source;
        this.confirmation = true;
        this.isConfirmationSubmit = false;
      }
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          "Pelaporan Kendala Gagal",
          () => this.onSubmit()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.$router.push("/pusat-resolusi")
  }

  cancelCreate() {
    this.confirmation = false;
    this.isConfirmationSubmit = true;
  }

  // goback
  leavePageConfirmation = false;
  onBack() {
    this.leavePageConfirmation = true;
  }
  goBack() {
    MainAppController.closeMessageModal();
    this.$router.push("/pusat-resolusi");
  }

  labelStt = "No. STT";

  // stt validation
  checkValidateStt(value: string) {
    const lengthOfValue = value.length;
    this.resetValidationStt(lengthOfValue);
    this.callValidate(lengthOfValue, "retail");
  }

  isSttValid = false;
  reasonSttValidate =  "";
  resetValidationStt(lengthOfValue: number) {
    if (lengthOfValue <= 0) {
      this.isSttValid = false;
      this.reasonSttValidate =  "";
    }
  }

  // shipment validation
  isShipmentValid = false;
  reasonShipmentValidate =  "";
  resetValidationShipment(lengthOfValue: number) {
    if (lengthOfValue <= 0) {
      this.isShipmentValid = false;
      this.reasonShipmentValidate =  "";
    }
  }

  checkValidateShipment(value: string) {
    const lengthOfValue = value.length;
    this.resetValidationShipment(lengthOfValue);
    const shipment = value?.toLocaleLowerCase();
    const startWithValue = shipment.startsWith("tkp");
    if (startWithValue) {
      this.callValidate(lengthOfValue, "kr");
    } else {
      this.callValidate(lengthOfValue, "shipment");
    }
  }

  async callValidate(lengthOfValue: number, type: string) {
    const typeDigit: any = {
      "kr": 13,
      "shipment": 8,
      "retail": 17
    }

    const bookTypeDigit = typeDigit[type];
    if (lengthOfValue === bookTypeDigit) {
      setTimeout(async () => {
        try {
          const validate = await PusatResolusiController.checkValidationStatus(new CheckValidationRequest({
            sttOrShipment: this.isPickupProblem ? this.form.shipment : this.form.stt,
            category: this.form.category.value
          }));
          if (type === "retail") {
            this.isSttValid = !validate.isValid;
            this.reasonSttValidate = validate.reason;
          } else {
            this.isShipmentValid = !validate.isValid;
            this.reasonShipmentValidate = validate.reason;
          }
        } catch (error) {
          if (type === "retail") {
            this.isSttValid = true;
            this.reasonSttValidate = "Validasi No. STT gagal";
          } else {
            this.isShipmentValid = true;
            this.reasonShipmentValidate = "Validasi No. Shipment gagal";
          }
        } 
      }, 100)
    }
  }

  proofPhotos = ["Foto 1", "Foto 2", "Foto 3"];

  categoryList: any[] = [];
  filterCategories = debounce(async (search: string) => {
   if (!search) {
      const getData = await PusatResolusiController.getCategories();
      this.categoryList = getData.data;
   } else {
      this.categoryList = this.categoryList.filter((item: PusatResolusiCategory) => item.name.toLocaleLowerCase().includes(search));
   }
  }, 250);

  isPickupProblem = false;
  onSelectCategory(item: PusatResolusiCategory) {
    if (item.categoryType === "shipment") {
      this.isPickupProblem = true;
      this.labelStt = "No. Shipment";
      this.clearSttShipment();
    } else {
      this.isPickupProblem = false;
      this.labelStt = "No. STT";
      this.clearSttShipment();
    }
    this.form.category = item;
  }

  clearSttShipment() {
    if (!this.isQueryParams) {
      this.form.stt = "";
      this.form.shipment = "";
      this.isSttValid = false;
      this.isShipmentValid = false;
    }
  }

  confirmation = false;
  isConfirmationSubmit = true;

  noTicket = "";
  sourceTicket = "";
  onReport() {
    dataLayer("lihat_laporan_pusat_resolusi", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
    if (this.sourceTicket === "genesis") {
      this.$router.push(`/pusat-resolusi/detail?params=${this.noTicket}`);
    } else {
      this.confirmation = false;
      this.isConfirmationSubmit = true;
      // show report
      PusatResolusiController.setNoTicket(this.noTicket);
      PusatResolusiController.setModalStatus(true);
    }
  }
}
