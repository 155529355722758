/* eslint-disable @typescript-eslint/camelcase */
import { Pagination } from "@/domain/entities/Pagination";
import {
  CargoEntities,
  CargoData,
  CargoDetailStt,
  CargoEditResponse,
  CargoCommodityList,
  CargoCommodityData,
  CargoProductEntities,
  CargoProductData,
  SearchCargoOptions,
  SearchCargoOption,
  AirportEntities,
  AirportData,
  CargoAddResponse,
  DimensionData,
  ListLastReadCargo,
  AirportDetail,
  CargoFlightDetail
} from "@/domain/entities/Cargo";
import { AxiosResponse } from "axios";
import {
  ResponseBagSttDetail,
  ResponsePayload
} from "@/domain/entities/ResponsePayload";

export class CargoMapper {
  public convertCargoDataFromApi(res: AxiosResponse<any>): CargoEntities {
    const { data } = res;
    return new CargoEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_data),
      data.data?.length ? data.data.map(
        (item: any) =>
          new CargoData({
            id: item.cargo_id,
            cargoNumber: item.cargo_no,
            cargoType: item.cargo_type,
            vehiclePoliceNumber: item.cargo_vehicle_no,
            originCityCode: item.cargo_origin_city_code,
            originCityName: item.origin_city_name,
            destinationCityCode: item.cargo_destination_city_code,
            destinationCityName: item.destination_city_name,
            airportOriginCityCode: item.cargo_origin_airport_code,
            airportOriginCityName: item.airport_origin_name,
            airportDestinationCityCode: item.cargo_destination_airport_code,
            airportDestinationCityName: item.airport_destination_name,
            originDistrictCode: item.cargo_origin_district_code,
            destinationDistrictCode: item.cargo_destination_district_code,
            totalStt: item.cargo_total_stt,
            totalPieces: item.cargo_total_stt_piece,
            totalGrossWeight: item.cargo_total_gross_weight,
            totalVolumeWeight: item.cargo_total_volume_weight,
            totalEstCargoGrossWeight: 0,
            totalEstCargoVolumeWeight: 0,
            totalActualCargoGrossWeight: item.cargo_total_custom_gross_weight,
            totalActualCargoVolumeWeight:
              item.cargo_total_custom_volume_weight,
            cargoActualPiece: item.cargo_actual_piece,
            partnerName: "",
            partnerType: "",
            cargoCommodity: "",
            cargoCommodityCode: "",
            cargoProduct: "",
            cargoProductCode: "",
            estimationDepartureData: "",
            estimationDepartureDate: item.cargo_estimation_departure_date,
            departureDate: item.cargo_departure_date,
            departureTime: item.cargo_departure_ngen,
            estimationArrivalDate: item.cargo_estimation_arrival_date,
            arrivalDate: item.cargo_arrival_date,
            arrivalTime: item.cargo_arrival_ngen,
            createdAt: item.cargo_created_at,
            createdBy: item.cargo_created_name,
            updatedAt: item.cargo_updated_at,
            updatedBy: item.cargo_updated_name,
            detailStt: [],
            totalPiecesBagStt: 0,
            actualDimension: new DimensionData(),
            transit: item.total_transit
          })
      ) : []
    )
  }

  public convertRetryCargoDataFromApi(res: AxiosResponse<any>): CargoEntities {
    const { data } = res;

    return new CargoEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_data),
      data.data?.length
        ? data.data.map((item: any) => {
            return new CargoData({
              id: item.rc_id,
              cargoNumber: item.rc_no,
              bookingRequestId: item.rc_booking_request_id,
              isFromRtc: item.is_from_rtc,
              cargoType: item.rc_type,
              vehiclePoliceNumber: item.rc_vehicle_no || "-",
              originCityCode: item.rc_origin_city_code,
              originCityName: JSON.parse(item.rc_remarks).origin_city_name,
              destinationCityCode: item.rc_destination_city_code,
              destinationCityName: JSON.parse(item.rc_remarks)
                .destination_city_name,
              airportOriginCityCode: item.rc_origin_airport_code,
              airportOriginCityName: JSON.parse(item.rc_remarks)
                .airport_origin_name,
              airportDestinationCityCode: item.rc_destination_airport_code,
              airportDestinationCityName: JSON.parse(item.rc_remarks)
                .airport_destination_name,
              originDistrictCode: item.rc_origin_district_code ?? "",
              destinationDistrictCode: item.rc_destination_district_code ?? "",
              totalStt: item.rc_total_stt,
              totalPieces: item.rc_total_stt_piece,
              totalGrossWeight: item.rc_total_gross_weight,
              totalVolumeWeight: item.rc_total_volume_weight,
              totalEstCargoGrossWeight: 0,
              totalEstCargoVolumeWeight: 0,
              totalActualCargoGrossWeight: item.rc_total_custom_gross_weight,
              totalActualCargoVolumeWeight: item.rc_total_custom_volume_weight,
              cargoActualPiece: item.rc_cargo_actual_piece,
              partnerName: "",
              partnerType: "",
              cargoCommodity: "",
              cargoCommodityCode: "",
              cargoProduct: "",
              cargoProductCode: "",
              estimationDepartureData: "",
              departureDate: item.rc_departure_date,
              arrivalDate: item.rc_arrival_date,
              createdAt: item.rc_created_at,
              createdBy: item.rc_created_name,
              updatedAt: item.rc_updated_at,
              updatedBy: item.rc_updated_name,
              detailStt: [],
              totalPiecesBagStt: 0,
              actualDimension: new DimensionData(),
              reasonFailed: item.rc_reason_failed
            });
          })
        : []
    );
  }

  public convertCargoDetailFromApi(result: AxiosResponse<any>): CargoData {
    const {
      data: { data }
    } = result;
    return new CargoData({
      id: 0,
      cargoNumber: data.cargo_number,
      cargoType: data.cargo_type,
      vehiclePoliceNumber: data.vehicle_number,
      originCityCode: data.origin_city_code,
      originCityName: data.origin_city,
      destinationCityCode: data.destination_city_code,
      destinationCityName: data.destination_city,
      airportOriginCityCode: data.origin_airport_code,
      airportOriginCityName: data.origin_airport_name,
      airportDestinationCityCode: data.destination_airport_code,
      airportDestinationCityName: data.destination_airport_name,
      originDistrictCode: "",
      destinationDistrictCode: "",
      totalStt: data.total_stt,
      totalPieces: data.total_piece,
      totalGrossWeight: data.total_gross_weight,
      totalVolumeWeight: data.total_volume_weight,
      totalEstCargoGrossWeight: data.total_est_cargo_gross_weight,
      totalEstCargoVolumeWeight: data.total_est_cargo_volume_weight,
      totalActualCargoGrossWeight: data.total_actual_cargo_gross_weight,
      totalActualCargoVolumeWeight: data.total_actual_cargo_volume_weight,
      cargoActualPiece: data.cargo_actual_piece,
      partnerName: data.partner_name,
      partnerType: data.partner_type,
      cargoCommodity: data.cargo_commodity,
      cargoCommodityCode: data.cargo_commodity_code,
      cargoProduct: data.cargo_product,
      cargoProductCode: data.cargo_product_code,
      shcCode: data.shc_code,
      shcDescription: data.shc_description,
      estimationDepartureData: data.estimation_departure_data,
      departureDate: data.departure_date,
      arrivalDate: data.arrival_date || data.aririval_date,
      createdAt: data.created_at,
      createdBy: data.created_name,
      updatedAt: data.updated_at,
      updatedBy: data.update_by,
      detailStt: data.detail_stt
        ? data.detail_stt.map(
            (stt: any) =>
              new CargoDetailStt({
                baggingNumber: stt.bag_no,
                baggingVendorNumber: stt.bag_vendor_no,
                sttNumber: stt.stt_no,
                totalPieces: stt.stt_total_piece,
                grossWeigth: stt.stt_gross_weight,
                volumeWeight: stt.stt_volume_weight,
                productType: stt.stt_product_type,
                commodity: stt.stt_commodity_name,
                origin: stt.stt_origin_city_id,
                destination: stt.stt_destination_city_id,
                sttNoRefExternal: stt.stt_no_ref_external
              })
          )
        : [],
      flightsDetail: data.cargo_flight_details?.map((item: any) => {
        return new CargoFlightDetail({
          cfNumber: item.cf_number,
          cfEstDepartureTime: item.cf_est_departure_time,
          cfAirportOrigin: item.cf_airport_origin
        });
      }),
      totalPiecesBagStt: data.detail_stt
        ? data.detail_stt.reduce((a: any, b: any) => a + b.TotalPiece, 0)
        : 0,
      actualDimension: new DimensionData({
        length: data.cargo_actual_length_dimension,
        width: data.cargo_actual_width_dimension,
        height: data.cargo_actual_height_dimension
      }),
      estDimension: new DimensionData({
        length: data.cargo_est_length_dimension,
        width: data.cargo_est_width_dimension,
        height: data.cargo_est_height_dimension
      }),
      nog: data.cargo_nog,
      partnerId: data.partner_id || 0,
      flightNumbers: data.flight_numbers,
      nGenCreatedAt: data.ngen_created_at
    });
  }

  public convertRetryCargoDetailFromApi(result: AxiosResponse<any>): CargoData {
    const {
      data: { data }
    } = result;
    return new CargoData({
      id: data.rc_id,
      cargoNumber: data.rc_no,
      bookingRequestId: data.rc_booking_request_id,
      cargoType: data.rc_type,
      vehiclePoliceNumber: data.rc_vehicle_no,
      originCityCode: data.rc_origin_city_code,
      originCityName: data.rc_origin_city_name,
      destinationCityCode: data.rc_destination_city_code,
      destinationCityName: data.rc_destination_city_name,
      airportOriginCityCode: data.rc_origin_airport_code,
      airportOriginCityName: data.rc_origin_airport_name,
      airportDestinationCityCode: data.rc_destination_airport_code,
      airportDestinationCityName: data.rc_destination_city_name,
      originDistrictCode: "",
      destinationDistrictCode: "",
      totalStt: data.rc_total_stt,
      totalPieces: data.rc_total_stt_piece,
      totalGrossWeight: data.rc_total_gross_weight,
      totalVolumeWeight: data.rc_total_volume_weight,
      totalEstCargoGrossWeight: data.rc_total_estimate_gross_weight,
      totalEstCargoVolumeWeight: data.rc_total_estimate_volume_weight,
      totalActualCargoGrossWeight: data.rc_total_custom_gross_weight,
      totalActualCargoVolumeWeight: data.rc_total_custom_volume_weight,
      cargoActualPiece: data.rc_actual_piece,
      partnerName: data.rc_partner_name,
      partnerType: data.rc_partner_type,
      cargoCommodity: data.rc_commodity_group_name,
      cargoCommodityCode: data.rc_commodity_group_code,
      cargoProduct: data.rc_cargo_product_type_code,
      cargoProductCode: data.rc_cargo_product_code,
      shcCode: data.rc_shc_code,
      shcDescription: data.rc_shc_description,
      estimationDepartureData: data.rc_estimation_departure_data,
      departureDate: data.rc_departure_date,
      arrivalDate: data.rc_arrival_date || data.rc_aririval_date,
      createdAt: data.rc_created_at,
      createdBy: data.rc_created_name,
      updatedAt: data.rc_updated_at,
      updatedBy: data.rc_update_by,
      detailStt:
        data.rc_detail.length > 0
          ? data.rc_detail.map(
              (stt: any) =>
                new CargoDetailStt({
                  baggingNumber: stt.rcd_bag_no,
                  baggingVendorNumber: stt.bag_vendor_no,
                  sttNumber: stt.rcd_stt_no,
                  totalPieces: stt.rcd_stt_total_piece,
                  grossWeigth: stt.rcd_stt_gross_wight,
                  volumeWeight: stt.rcd_stt_volume_weight,
                  productType: stt.rcd_stt_product_type,
                  commodity: stt.stt_commodity_name,
                  origin: stt.rcd_stt_origin_city_id,
                  destination: stt.rcd_stt_destination_city_id,
                  commodityGroup: stt.stt_commodity_name,
                  commodityGroupName: stt.stt_commodity_name,
                  commodityGroupCode: stt.stt_commodity_code,
                  bagCustomGrossWeight: stt.rcd_bag_custom_gross_weight,
                  sttNoRefExternal: stt.rcd_stt_no_ref_external
                })
            )
          : [],
      totalPiecesBagStt: data.rc_detail.reduce(
        (a: any, b: any) => a + b.TotalPiece,
        0
      ),
      actualDimension: new DimensionData({
        length: data.rc_actual_length_dimension,
        width: data.rc_actual_width_dimension,
        height: data.rc_actual_height_dimension
      }),
      estDimension: new DimensionData({
        length: data.rc_est_length_dimension,
        width: data.rc_est_width_dimension,
        height: data.rc_est_height_dimension
      }),
      nog: data.rc_nog,
      partnerId: data.rc_partner_id || 0,
      cargoStatus: data.rc_status,
      isFromRtc: data.is_from_rtc
    });
  }

  public convertCargoEditFromApi(result: AxiosResponse): CargoEditResponse {
    const {
      data: { data }
    } = result;
    return new CargoEditResponse(
      data.cargo_id,
      data.cargo_no,
      data.total_stt_success,
      data.total_stt_failed,
      data.list_stt_failed,
      data.total_stt_add_success,
      data.total_stt_removee_success
    );
  }

  public convertCargoProductDataFromApi(
    res: AxiosResponse<any>
  ): CargoProductEntities {
    const { data } = res;

    const cargos: CargoProductData[] = [];
    if (data.data.length === 0) {
      return new CargoProductEntities([]);
    } else {
      data.data.map((item: any) => {
        cargos.push(
          new CargoProductData(
            item.cargo_product_type_id,
            item.cargo_product_type_code,
            item.cargo_product_type_status
          )
        );
      });
    }
    return new CargoProductEntities(cargos);
  }

  public convertCargoCommodityFromApi(
    result: AxiosResponse
  ): CargoCommodityList {
    const { data } = result;
    const cargoCommodity: CargoCommodityData[] = [];
    for (const commodity of data.data) {
      cargoCommodity.push(
        new CargoCommodityData(
          commodity.commodity_group_id,
          commodity.commodity_group_code,
          commodity.commodity_group_name,
          commodity.created_at,
          commodity.updated_at
        )
      );
    }
    return new CargoCommodityList(
      new Pagination(data.meta.page, data.meta.limit),
      cargoCommodity
    );
  }

  public convertAppendBagOrSttDataFromApi(
    result: AxiosResponse<any>
  ): ResponseBagSttDetail {
    const { data } = result;
    return new ResponseBagSttDetail(
      data.data.is_allow_update_status,
      data.data.error_message,
      data.data.bag_or_stt
    );
  }

  public convertSearchCargoOptionsFromApi(
    result: AxiosResponse
  ): SearchCargoOptions {
    const { data } = result;
    const cargoOptions: SearchCargoOption[] = [];
    if (data.data !== null) {
      for (const cargoOption of data.data) {
        cargoOptions.push(
          new SearchCargoOption(
            cargoOption.transport_label,
            cargoOption.transport_type
          )
        );
      }
    }
    return new SearchCargoOptions(cargoOptions);
  }

  public convertAirportDataFromApi(
    result: AxiosResponse<any>
  ): AirportEntities {
    const { data } = result;

    if (data.data.length === 0) {
      return new AirportEntities(new Pagination(1, 10), []);
    } else {
      return new AirportEntities(
        new Pagination(data.meta.page, data.meta.limit),
        data.data.map(
          (item: any) =>
            new AirportData(
              item.airport_code,
              item.airport_name,
              item.city_code,
              item.city_name
            )
        )
      );
    }
  }

  public convertAirportDetailDataFromApi(
    result: AxiosResponse<any>
  ): AirportDetail {
    const { data } = result;

    return new AirportDetail(data.data.airport_code, data.data.airport_name);
  }

  public convertCargoAddFromApi(result: AxiosResponse): CargoAddResponse {
    const {
      data: { data }
    } = result;
    return new CargoAddResponse(
      data.cargo_no,
      data.total_stt_success,
      data.total_stt_failed,
      data.list_stt_failed,
      data.is_cargo_using_awb,
      data.booking_request_id
    );
  }

  public convertEPLDownloadData(result: AxiosResponse): string {
    const { data } = result;
    return data;
  }

  public convertListLastReadCargoFromApi(
    res: AxiosResponse
  ): ListLastReadCargo[] {
    const { data } = res;
    const lastReadCargo: ListLastReadCargo[] = [];
    if (data.data === null) {
      return [];
    } else {
      if (data.data.cargo_success !== null) {
        data.data.cargo_success.map((item: any) => {
          lastReadCargo.push(
            new ListLastReadCargo({
              cargoNumber: item.cargo_number,
              awbNumber: item.awb_no,
              status: item.status,
              destination: item.destination,
              message: item.message
            })
          );
        });
      }
      if (data.data.cargo_failed !== null) {
        data.data.cargo_failed.map((item: any) => {
          lastReadCargo.push(
            new ListLastReadCargo({
              cargoNumber: item.cargo_number,
              awbNumber: item.awb_no,
              status: item.status,
              destination: item.destination,
              message: item.message
            })
          );
        });
      }
      return lastReadCargo;
    }
  }

  public convertPayloadData(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertLastIdCargoAndRetryCargo(result: AxiosResponse): number {
    const {
      data: { data }
    } = result;
    return data.last_id;
  }
}
