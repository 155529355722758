import { container } from "tsyringe";
import { FeedbackApiRepository } from "../../repositories/api/FeedbackApiRepository";
import ApiService from "../../services/ApiService";
import { FeedbackMapper } from "@/data/persistences/mappers/FeedbackMapper";
import { FeedbackPresenter } from "@/app/ui/presenters/FeedbackPresenter";
import { FeedbackEndpoints } from "../../endpoints/hydra/FeedbackEndpoints";

export class FeedbackComponent {
  public static init() {
    container.register<FeedbackApiRepository>(FeedbackApiRepository, {
      useFactory: d => {
        return new FeedbackApiRepository(
          d.resolve(ApiService),
          d.resolve(FeedbackMapper),
          d.resolve(FeedbackEndpoints)
        );
      }
    });
    container.register<FeedbackMapper>(FeedbackMapper, {
      useClass: FeedbackMapper
    });
    container.register<FeedbackPresenter>(FeedbackPresenter, {
      useFactory: d => {
        return new FeedbackPresenter(d.resolve(FeedbackApiRepository));
      }
    });
  }
}
