import { JsPdfFont, loadFontsJsPdf } from "./module";

const fonts = [
  new JsPdfFont({
    path: `${process.env.VUE_APP_S3_AMAZON_AWS}/assets/fonts/NotoSans-Bold.js`,
    variableName: "notoSansBoldFont",
    filename: "NotoSans-Bold-bold.ttf",
    customFontName: "Hindi"
  }),
  new JsPdfFont({
    path: `${process.env.VUE_APP_S3_AMAZON_AWS}/assets/fonts/NotoSans-Bold.js`,
    variableName: "notoSansBoldFont",
    filename: "NotoSans-Bold-bold.ttf",
    customFontName: "Kazakh"
  }),
  new JsPdfFont({
    path: `${process.env.VUE_APP_S3_AMAZON_AWS}/assets/fonts/NotoSans-Bold.js`,
    variableName: "notoSansBoldFont",
    filename: "NotoSans-Bold-bold.ttf",
    customFontName: "Greek"
  }),
  new JsPdfFont({
    path: `${process.env.VUE_APP_S3_AMAZON_AWS}/assets/fonts/NotoSans-Bold.js`,
    variableName: "notoSansBoldFont",
    filename: "NotoSans-Bold-bold.ttf",
    customFontName: "Turkish"
  }),
  new JsPdfFont({
    path: `${process.env.VUE_APP_S3_AMAZON_AWS}/assets/fonts/NotoSans-Bold.js`,
    variableName: "notoSansBoldFont",
    filename: "NotoSans-Bold-bold.ttf",
    customFontName: "Vietnamese"
  })
];

export const downloadFontsJsPdf = async () => await loadFontsJsPdf(fonts);
